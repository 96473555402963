import React, { useRef, useEffect, useState } from "react";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
//import Tde from "../../routes/tde/Tde";
import Tde from "../../routes/tde/";
import { Modal, Spin, Alert, notification } from "antd";
import DownloadInvoice from "../../routes/chartering/routes/make-payment/DownloadInvoice";
import moment from "moment";
import URL_WITH_VERSION, {
  postAPICall,
  getAPICall,
  apiDeleteCall,
  openNotificationWithIcon,
  useStateCallback,
  URL_WITHOUT_VERSION,
} from "../../shared";
import {
  DeleteOutlined,
  SaveOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Attachment from "../../shared/components/Attachment";
import Remarks from "../../shared/components/Remarks";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import Email from "../Email";
import CustomLoader from "../Loading/CustomLoader";
import data from "../../routes/dynamic-vspm/vesselCi/data";

const openNotification = (keyName, tdespecific = false) => {
  let msg = "Please generate the Invoice No. First";
  if (tdespecific) {
    msg = "Payment status should not be PREPARED...";
  }
  notification.info({
    message: `Can't Open ${keyName}`,
    description: msg,
    placement: "topRight",
  });
};

const MakePayment = (props) => {
  const paymentData = window.state;
  let localstoragepayment = JSON.parse(localStorage.getItem("make-payment"));
  if (
    paymentData &&
    Object.keys(paymentData).length > 0 &&
    paymentData?.formData?.id
  ) {
    props = structuredClone(paymentData);
  } else if (
    localstoragepayment &&
    Object.keys(localstoragepayment).length > 0
  ) {
    props = structuredClone(localstoragepayment);
  }

  const [state, setState] = useStateCallback({
    showTDEForm: false,
    loadFrm: false,
    frmName: "tc_make_payment",
    responseData: { frm: [], tabs: [], active_tab: {} },
    formData: props.formData || {},
    //"period_to":'',
    checkbox: {},
    invList: {},
    frmOptions: [],
    isRemarkModel: false,
    tdeData: null,
    // "showDownloadInvoice": false,
    passData: {
      bunkerdeliveryredeliveryterm: [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ],
    },
    makepaymentid: props?.formData?.id,
    loadInvoiceNo: undefined,
    loadData: {},
    showInvoicePopup: false,
    editMode: true,
  });

  const [sharebtn, setSharebtn] = useState();
  const [shareLink, setShareLink] = useState();

  const formdataref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      editformdata(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    getFormData();
    localStorage.removeItem("paymentsavedata");
  }, []);

  const getFormData = async () => {
    try {
      let _frmData = Object.assign(state.passData, state.formData);
      let tci_id = _frmData["actualID"] || _frmData["id"];
      let voy_id = _frmData["actualID"] || _frmData["tci_id"];
      let editmode = state.editMode;

      if (voy_id) {
        const response1 = await getAPICall(
          `${URL_WITH_VERSION}/tci/voyage/${voy_id}`
        );

        const respSData = await response1["data"];

        if (respSData) {
          const frmOptions = [{ key: "voyage_manager_id", data: respSData }];
          setState((prev) => ({ ...prev, frmOptions: frmOptions }));
        }

        if (state.formData.hasOwnProperty("actualID")) {
          const request = await getAPICall(
            `${URL_WITH_VERSION}/make_payment/payment?tci_id=` + tci_id
          );
          const response = await request["data"];

          if (response && response.length > 0) {
            _frmData["period_form"] = moment(response[0]["period_to"]).format(
              "YYYY-MM-DD HH:mm"
            );
          }
        }
        if (_frmData["-"]?.length > 0) {
          _frmData["-"].map((key) => {
            key["disablefield"] = ["description", "amount"];
          });

          if (_frmData["disablefield"]) {
            _frmData["---------------"]["disablefield"] = [
              "misc_adjustment",
              "hire_adjustment",
              "gratuity",
              "other_exp",
              "victualling",
              "other_revenue",
              "ballast_bonus",
              "ilohc",
              "ihc",
              "lashing_stow",
              "exp_allowance",

              "off_hire",
              "vessel_off_hire",
            ];
          }
          _frmData["disablefield"] = [
            // "po_number",
            "days",
            // "purchase_type",
            "period_to",
            "period_form",
            "invoice_type",
            "final_amt_loc",
            // "curr_exe",
            // "exch_rate",
          ];
        } else {
          _frmData["-"] = [
            {
              acc_head: "",
              amount: "0",
              amount_usd: "0",
              code: "",
              days: "",
              description: "",
              id: -9e6,
              period_form: "",
              period_to: "",
              visiblefield: [],
            },
          ];
        }

        if (
          _frmData.hasOwnProperty("id") &&
          _frmData["id"] > 0 &&
          _frmData["invoice_no"]
        ) {
          editmode = false;
        }

        setState(
          (prevState) => ({ ...prevState, formData: _frmData }),
          () => {
            setState((prevState) => ({
              ...prevState,
              loadFrm: true,
              editMode: editmode,
            }));
          }
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          loadFrm: true,
          editMode: false,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const editformdata = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loadFrm: false }));
      const respData = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/edit?e=${id}&frm=time_charter_in_form`
      );
      const editData = await respData["data"];
      const updatedFormdata = Object.assign(state.passData, editData);
      // desable the checkbox and remove delete button once it is saved
      updatedFormdata["---------------"]["disablefield"] = [
        "add_tc_hire",
        "gratuity",
        "other_exp",
        "broker_comm",
        "victualling",
        "other_revenue",
        "ballast_bonus",
        "ilohc",
        "ihc",
        "exp_allowance",
        "lashing_stow",
        "bad_vlsfo",
        "bad_ifo",
        "bad_mgo",
        "bad_lsmgo",
        "bad_ulsfo",
        "bor_vlsfo",
        "bor_mgo",
        "bor_ifo",
        "bor_lsmgo",
        "bor_ulsfo",
        "off_hire",
        "vessel_off_hire",
      ];
      updatedFormdata["disablefield"] = [
        "period_to",
        "period_form",
        "purchase_type",
      ];

      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/tci/edit?e=${updatedFormdata["tc_id"]}`
      );
      const data1 = await response1["data"];

      let bunker = [];
      bunker = [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ];

      data1["bunkers"].map((e) => {
        if (e.description === 3 || e.description === 6 || e.description === 4) {
          let index = e.description === 3 ? 0 : 2;
          let cDesc = JSON.parse(e.c_fields_values);
          let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
          let iv = {
            IFO: "ifo",
            VLSFO: "vlsfo",
            ULSFO: "ulsfo",
            MGO: "mgo",
            LSMGO: "lsmgo",
          };
          cDesc.map(
            (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
          );
          bunker[index] = Object.assign(item, bunker[index]);
        }
      });

      updatedFormdata["bunkerdeliveryredeliveryterm"] = bunker;

      let _paymentsavedata =
        JSON.parse(localStorage.getItem("paymentsavedata")) || {};
      _paymentsavedata["period_form"] = updatedFormdata["period_form"];
      localStorage.setItem("paymentsavedata", JSON.stringify(_paymentsavedata));

      setState(
        (prevState) => ({
          ...prevState,
          loadFrm: false,
          makepaymentid: updatedFormdata.id,
          formData: updatedFormdata,
          editMode: false, //remove delete button
        }),
        () => {
          setState((prevState) => ({ ...prevState, loadFrm: true }));
          navigate(`/make-payment/${updatedFormdata.invoice_no}`);
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const saveFormData = async (vData) => {
    try {
      setState((prevState) => ({ ...prevState, loadFrm: false }));
      let ismakepaymentsave =
        localStorage.getItem("savemakepayment") != "undefined"
          ? JSON.parse(localStorage.getItem("savemakepayment"))
          : undefined;

      let paymentsavedata = JSON.parse(localStorage.getItem("paymentsavedata"));

      if (vData["purchase_type"] != "1") {
        vData["period_to"] = vData["period_form"];
      }

      if (
        vData["purchase_type"] == "2" &&
        vData["-"] &&
        vData["-"].length > 0 &&
        !vData["-"].some((el) => el["code"])
      ) {
        openNotificationWithIcon(
          "info",
          "Invoice for special type is generated.",
          2
        );
        setState((prevState) => ({ ...prevState, loadFrm: true }));
        return;
      }

      if (
        vData["purchase_type"] == "3" &&
        vData["-"] &&
        vData["-"].length > 0 &&
        !vData["-"].some((el) => el["code"])
      ) {
        openNotificationWithIcon(
          "info",
          "Vessel still in progress, hire not raised till redelivery.",
          2
        );
        setState((prevState) => ({ ...prevState, loadFrm: true }));
        return;
      }

      if (paymentsavedata?.["id"]) {
        if (
          dayjs(paymentsavedata?.["period_form"]).format("YYYY-MM-DD HH:mm") ==
            dayjs(vData["period_form"]).format("YYYY-MM-DD HH:mm") &&
          !vData["invoice_no"]
        ) {
          openNotificationWithIcon(
            "info",
            "Invoice has already been created for the selected date.",
            2
          );
          setState((prevState) => ({ ...prevState, loadFrm: true }));
          return;
        }
      }

      let type = "save";
      let suMethod = "POST";
      if (vData.hasOwnProperty("id")) {
        type = "update";
        suMethod = "PUT";
      } else {
        vData["-"].forEach((e) => delete e["id"]);
      }
      const { frmName } = state;
      let suURL = `${URL_WITH_VERSION}/make_payment/${type}?frm=${frmName}`;
      if (vData && vData.hasOwnProperty("lob")) {
        delete vData["lob"];
      }
      formdataref.current = vData;
      if (ismakepaymentsave || vData["purchase_type"] != "1") {
        postAPICall(suURL, vData, suMethod, (data) => {
          if (data && data.data) {
            openNotificationWithIcon("success", data.message);
            const _vData = { ...vData, invoice_no: data.row.invoice_no };
            localStorage.setItem(
              "paymentsavedata",
              JSON.stringify({
                id: data.row.payment_id,
                invoiceNo: data.row.invoice_no,
              })
            );
            setState((prevState) => ({
              ...prevState,
              makepaymentid: data.row.payment_id,
              loadFrm: true,
            }));
            localStorage.removeItem("ismakepaymentsave");
            if (
              props.hasOwnProperty("onUpdateMakePayment") &&
              type === "update"
            ) {
              // setState(prevState => ({ ...prevState, makepaymentid: data.row.payment_id, loadFrm: true }));
              props.onUpdateMakePayment(data.row.payment_id, "TCI");
            } else {
              editformdata(data.row.invoice_no);
              //  showDownloadInvoice(true, _vData);
            }
            // if (props.hasOwnProperty('modalCloseEvent')) {
            //   props.modalCloseEvent();
            // }
          } else {
            openNotificationWithIcon("error", data.message);
            setState((prevState) => ({
              ...prevState,
              loadFrm: true,
              formData: formdataref.current,
            }));
          }
        });
      } else {
        openNotificationWithIcon(
          "info",
          "Period To Date Should Be Less OR Equal To Redelivery Date."
        );
        setState((prevState) => ({
          ...prevState,
          loadFrm: true,
          formData: formdataref.current,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const _deleteInvoice = (postData) => {
    const id = postData.id;
    if (props?.lastInvoiceId) {
      if (props.lastInvoiceId !== id) {
        openNotificationWithIcon("error", "Delete latest invoice first...", 3);
        return;
      }
    }
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => deleteInvoice(postData),
    });
  };

  const deleteInvoice = async (data) => {
    const { frmName } = state;
    let URL = `${URL_WITH_VERSION}/make_payment/delete?frm=${frmName}`;
    apiDeleteCall(
      URL,
      { id: data["id"], payment_status: data["payment_status"] },
      (resp) => {
        if (resp && resp.data) {
          openNotificationWithIcon("success", resp.message);
          // if (props.hasOwnProperty("modalCloseEvent")) {
          //   props.modalCloseEvent();
          // }
          // setTimeout(() => {
          //   if (props.hasOwnProperty("refreshTable")) {
          //     props.refreshTable();
          //   }
          // }, 500);

          setState(
            (prevstate) => ({
              ...prevstate,
              formData: {},
            }),
            () => {
              window.location.href = "#/make-payment";
              window.location.reload();
            }
          );
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      }
    );
  };

  const showDownloadInvoice = (boolVal, _loadInvoice = undefined) => {
    let loadInvoice = { ...props.formData, ..._loadInvoice };

    if (loadInvoice && !loadInvoice.hasOwnProperty("invoice_no")) {
      openNotificationWithIcon("info", "Plese Create Invoice First");
      return;
    }
    // let period_to_date = document.getElementsByName("period_to");
    // if (period_to_date[0].value && loadInvoice) {
    //   loadInvoice["period_to"] = period_to_date[0].value;
    // }
    if (
      loadInvoice &&
      (loadInvoice["period_to"] === "" ||
        loadInvoice["period_to"] === undefined)
    ) {
      loadInvoice["period_to"] = new Date();
    }
    if (boolVal) {
      if (loadInvoice && loadInvoice.hasOwnProperty("actualID")) {
        loadInvoice["tci_id"] = loadInvoice["actualID"];
      }
      setState(
        (prevState) => ({
          ...prevState,
          loadInvoiceNo: loadInvoice,
          loadData: { TCIH: true, TCIBB: true },
        }),
        () => {
          setState((prevState) => ({
            ...prevState,
            downloadInvoice: boolVal,
            showInvoicePopup: true,
          }));
        }
      );
    } else {
      setState((prevState) => ({ ...prevState, downloadInvoice: boolVal }));
      if (props.hasOwnProperty("modalCloseEvent")) {
        // props.modalCloseEvent();
      }
    }
  };

  const closeDonloadInvoice = () => {
    setState((prevState) => ({ ...prevState, downloadInvoice: false }));
    if (props.hasOwnProperty("modalCloseEvent")) {
      // props.modalCloseEvent();
    }
  };

  const getCheckBoxVal = (code, checkboxesValue, checkboxesInfo) => {
    let checkboxesValueUpdated = { ...checkboxesValue };
    switch (code) {
      case "TCIH":
        //val = "add_tc_hire";
        checkboxesValueUpdated = { ...checkboxesValue, add_tc_hire: false };
        break;
      case "TCIGRA":
        //val = "gratuity";
        checkboxesValueUpdated = { ...checkboxesValue, gratuity: false };
        break;
      case "TCIOTHEXP":
        //val = "other_exp";
        checkboxesValueUpdated = { ...checkboxesValue, other_exp: false };
        break;
      case "TCIBCOM":
        // val = "broker_comm";
        checkboxesValueUpdated = { ...checkboxesValue, broker_comm: false };
        break;
      case "TCIVIC":
        //val = "victualling";
        checkboxesValueUpdated = { ...checkboxesValue, victualling: false };
        break;
      case "TCIOTHREV":
        //val = "other_revenue";
        checkboxesValueUpdated = { ...checkboxesValue, other_revenue: false };
        break;
      case "TCIBB":
        //val = "ballast_bonus";
        checkboxesValueUpdated = { ...checkboxesValue, ballast_bonus: false };
        break;
      case "TCIILOHC":
        //val = "ilohc";
        checkboxesValueUpdated = { ...checkboxesValue, ilohc: false };
        break;
      case "TCIIHC":
        // val = "ihc";
        checkboxesValueUpdated = { ...checkboxesValue, ihc: false };
        break;
      case "TCIREA":
        //val = "exp_allowance";
        checkboxesValueUpdated = { ...checkboxesValue, exp_allowance: false };
        break;
      case "TCILAST":
        //val = "lashing_stow";
        checkboxesValueUpdated = { ...checkboxesValue, lashing_stow: false };
        break;
      case "TCIBADVLSFO":
        //val = "bad_vlsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_vlsfo: false };
        break;
      case "TCIBADIFO":
        //val = "bad_ifo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_ifo: false };
        break;
      case "TCIBADMGO":
        // val = "bad_mgo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_mgo: false };
        break;
      case "TCIBADLSMGO":
        //val = "bad_lsmgo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_lsmgo: false };
        break;
      case "TCIBADULSFO":
        // val = "bad_ulsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_ulsfo: false };
        break;
      case "TCIBARVLSFO":
        // val = "bor_vlsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_vlsfo: false };
        break;
      case "TCIBARMGO":
        // val = "bor_mgo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_mgo: false };
        break;
      case "TCIBARIFO":
        // val = "bor_ifo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_ifo: false };
        break;
      case "TCIBARLSMGO":
        // val = "bor_lsmgo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_lsmgo: false };
        break;
      case "TCIBARULSFO":
        // val = "bor_ulsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_ulsfo: false };
        break;
      case "TCIOFFHD":
        // val = "off_hire";
        // if TCIOFFHD is more then once then it should not be false
        const no1 = checkboxesInfo.filter((obj) => obj.code === "TCIOFFHD");
        if (no1?.length < 2) {
          checkboxesValueUpdated = { ...checkboxesValue, off_hire: false };
        }
        break;

      case "TCIVOFHD":
        // val = "vessel_off_hire";
        // if TCIVOFHD is more then once then it should not be false
        const no = checkboxesInfo.filter((obj) => obj.code === "TCIVOFHD");
        if (no?.length < 2) {
          checkboxesValueUpdated = {
            ...checkboxesValue,
            vessel_off_hire: false,
          };
        }
        break;
    }
    return checkboxesValueUpdated;
  };
  const showShareEmail = () => {
    try {
      const pageUrl = window.location.href;
      const linkBlob = new Blob([pageUrl], { type: "text/plain" });
      const emailContent = pageUrl;
      setState((prev) => ({ ...prev, isShowShare: true }));
      setSharebtn(linkBlob);
      setShareLink(emailContent);
    } catch (err) {
      console.log("err", err);
    }
  };
  const onclose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };
  const handleClose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };
  const onok = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };
  const onClickExtraIcon = async (action, data, fulldata) => {
    const checkboxesValue = fulldata["---------------"];
    const checkboxesInfo = fulldata["-"];
    const amount = (
      parseFloat(fulldata["amount"]) - parseFloat(data.amount)
    ).toFixed(2);

    //const checkboxesInfo2 = checkboxesInfo.filter((obj) => obj.code !== data.code);
    let checkboxesInfo2 = [];
    if (data.code === "TCIVOFHD" || data.code === "TCIOFFHD") {
      checkboxesInfo2 = checkboxesInfo.filter(
        (obj) => !(obj.code === data.code && obj.pass_id === data.pass_id)
      );
    } else {
      checkboxesInfo2 = checkboxesInfo.filter((obj) => obj.code !== data.code);
    }

    const updatedcheckboxesValue = getCheckBoxVal(
      data.code,
      checkboxesValue,
      checkboxesInfo
    );
    const updatedfulldata = {
      ...fulldata,
      "---------------": updatedcheckboxesValue,
      "-": checkboxesInfo2,
      amount: amount,
      invoice_total: amount,
    };

    if (fulldata.hasOwnProperty("exch_rate")) {
      let exch_rate = parseFloat(fulldata["exch_rate"]).toFixed(2);
      let final_amt_loc = "0.00";
      if (!isNaN(exch_rate)) {
        final_amt_loc = (amount * exch_rate).toString();
      } else {
        exch_rate = "";
      }
      updatedfulldata.final_amt_loc = final_amt_loc;
      updatedfulldata.exch_rate = exch_rate;
    }

    setState(
      (prevState) => ({
        ...prevState,
        loadFrm: false,
        formData: updatedfulldata,
      }),
      () => {
        setState((prevState) => ({ ...prevState, loadFrm: true }));
      }
    );
  };

  const {
    frmName,
    editMode,
    formData,
    loadFrm,
    showTDEForm,
    tdeData,
    downloadInvoice,
    loadInvoiceNo,
    loadData,
    makepaymentid,
    isRemarkModel,
    frmOptions,
  } = state;

  const ShowAttachment = async (isShowAttachment) => {
    let loadComponent = undefined;
    const { id } = state.formData;
    if (id && isShowAttachment) {
      const attachments = await getAttachments(id, "EST");
      const callback = (fileArr) =>
        uploadAttachment(fileArr, id, "EST", "port-expense");
      loadComponent = (
        <Attachment
          uploadType="Estimates"
          attachments={attachments}
          onCloseUploadFileArray={callback}
          deleteAttachment={(file) =>
            deleteAttachment(file.url, file.name, "EST", "port-expense")
          }
          tableId={0}
        />
      );
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: loadComponent,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: undefined,
      }));
    }
  };

  const handleRemark = () => {
    setState((prevState) => ({
      ...prevState,
      isRemarkModel: true,
    }));
  };

  return (
    <>
      <div className="body-wrapper">
        {frmName && loadFrm === true ? (
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                <NormalFormIndex
                  key={"key_" + frmName + "_0"}
                  formClass="label-min-height"
                  formData={formData}
                  showForm={true}
                  frmCode={frmName}
                  addForm={true}
                  editMode={editMode}
                  frmOptions={frmOptions}
                  showButtons={[
                    // { "id": "cancel", "title": "Reset", "type": "danger" },
                    {
                      id: "save",
                      title: "Save",
                      type: "primary",
                      event: (data) => {
                        saveFormData(data);
                      },
                    },
                  ]}
                  showToolbar={[
                    {
                      isLeftBtn: [
                        {
                          key: "s1",
                          isSets: [
                            {
                              id: "7",
                              key: "menu-fold",
                              type: <MenuFoldOutlined />,
                              withText: "List",
                              showToolTip: true,
                              event: "menu-fold",
                            },

                            {
                              id: "1",
                              key: "save",
                              type: <SaveOutlined />,
                              withText: "Save",
                              showToolTip: true,
                              event: (key, data) => saveFormData(data),
                            },
                            makepaymentid && {
                              id: "2",
                              key: "delete",
                              type: <DeleteOutlined />,
                              withText: "Delete",
                              showToolTip: true,
                              event: (key, data) => _deleteInvoice(data),
                            },
                            {
                              id: "3",
                              key: "edit",
                              type: <EditOutlined />,
                              withText: "Remark ",
                              showToolTip: true,
                              event: (key, data) => handleRemark(),
                            },
                            {
                              id: "22",
                              key: "mail",
                              type: <MailOutlined />,
                              withText: "Mail",
                              showToolTip: true,
                            },
                          ],
                        },
                      ],

                      isRightBtn: [
                        {
                          key: "s2",
                          isSets: [
                            {
                              key: "tci",
                              isDropdown: 0,
                              withText: "",
                              type: "TCI Page",
                              menus: null,
                              modalKey: "",
                              event: (key, data) => {
                                navigate(`/TC-IN/${formData.tc_id}`);
                              },
                            },

                            {
                              key: "share",
                              isDropdown: 0,
                              withText: "",
                              type: "Share",
                              menus: null,
                              modalKey: "Share",
                              event: (key, data) => {
                                if (data["invoice_no"]) {
                                  showShareEmail(data);
                                } else {
                                  openNotificationWithIcon(
                                    "error",
                                    "Please save the form first",
                                    3
                                  );
                                }
                              },
                            },
                            {
                              key: "download_invoice",
                              isDropdown: 0,
                              withText: "Create Invoice",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                showDownloadInvoice(
                                  true,
                                  data && data.hasOwnProperty("id")
                                    ? data
                                    : undefined
                                  //props.formData && props.formData.hasOwnProperty("id") ? props.formData : undefined,
                                );
                              },
                            },
                            {
                              key: "tde",
                              isDropdown: 0,
                              withText: "TDE",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                //showTDE(key, true);
                                if (formData?.invoice_no) {
                                  if (data?.payment_status === 241) {
                                    openNotification("tde", true);
                                    return;
                                  }

                                  setState((prevState) => ({
                                    ...prevState,
                                    showTDEForm: true,
                                  }));
                                } else {
                                  openNotification("tde");
                                }
                              },
                            },
                            {
                              key: "attachment",
                              isDropdown: 0,
                              withText: "Attachment",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                data &&
                                data.hasOwnProperty("id") &&
                                data["id"] > 0
                                  ? ShowAttachment(true)
                                  : openNotificationWithIcon(
                                      "info",
                                      "Please save Invoice First.",
                                      3
                                    );
                              },
                            },
                          ],
                        },
                      ],
                      //isResetOption: false
                    },
                  ]}
                  sideList={{
                    showList: true,
                    title: "Make Payment List",

                    uri: "/make_payment/list?l=0 ",
                    // headers : { order_by: { id: "desc" } },
                    columns: [
                      "invoice_no",
                      "payment_status",
                      "tc_id",
                      "vessel",
                    ],
                    icon: true,
                    searchString:
                      '"vessel Name","Invoice No", "TCI ID", "Invoice Status" ',
                    rowClickEvent: (evt) => editformdata(evt.invoice_no),
                  }}
                  inlineLayout={true}
                  isShowFixedColumn={["-", "Bunker Delivery / Redelivery Term"]}
                  tableRowDeleteAction={(action, data, fulldata) =>
                    onClickExtraIcon(action, data, fulldata)
                  }
                  summary={[{ gKey: "-", showTotalFor: ["amount_usd"] }]}
                />
              </div>
            </div>
          </article>
        ) : (
          <CustomLoader />
        )}
      </div>

      {showTDEForm ? (
        <Modal
          title="TDE"
          open={showTDEForm}
          width="80%"
          onCancel={() =>
            setState((prevState) => ({ ...prevState, showTDEForm: false }))
          }
          style={{
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
          maskClosable={false}
        >
          <Tde
            invoiceType="hire_payable"
            //isEdit={ tdeData !== null && tdeData.id && tdeData.id > 0 ? true : false}
            //deleteTde={() => showTDE(undefined, false)}
            //modalCloseEvent={() => showTDE(undefined, false)}
            //formData={tdeData}
            //saveUpdateClose={() => setState(prevState => ({ ...prevState, isVisible: false }))}
            formData={formData}
            //deleteTde={() => setState(prevState => ({ ...prevState, showTDEForm: false }))}
            modalCloseEvent={() =>
              setState((prevState) => ({ ...prevState, showTDEForm: false }))
            }
            invoiceNo={formData.invoice_no}
          />
        </Modal>
      ) : undefined}
      {state.isShowAttachment ? (
        <Modal
          style={{ top: "2%" }}
          title="Upload Attachment"
          open={state.isShowAttachment}
          onCancel={() => ShowAttachment(false)}
          width="50%"
          footer={null}
        >
          {state.loadComponent}
        </Modal>
      ) : undefined}

      {downloadInvoice ? (
        <Modal
          title="Invoice Print"
          open={downloadInvoice}
          width="80%"
          onCancel={() => showDownloadInvoice(false, state.formData)}
          style={{
            // maxHeight: 790,
            // overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          {/* <ConfirmStatement vesselID={tciID.vessel_id} chartrerID={tciID.chartrer_id} tciID={tciID.tci_id} from={tciID.delivery_date} to={tciID.redelivery_date} /> */}

          <DownloadInvoice
            loadInvoice={loadInvoiceNo}
            loadData={loadData}
            invoiceType={"TCI"}
            makepaymentid={makepaymentid}
            showInvoicePopup
            closeDonloadInvoice={closeDonloadInvoice}
            billTo={props?.billTo}
          />
        </Modal>
      ) : undefined}

      {state.isShowShare && (
        <Modal
          title="New Message"
          open={true}
          onCancel={() => onclose()}
          onOk={() => onok()}
          footer={null}
        >
          <Email
            title={window.corrector(
              `Make Payment || ${state.formData.invoice_no}`
            )}
            attachmentFile={sharebtn}
            // link={shareLink}
            showpage={true}
            condition={false}
            shareLink={shareLink}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {isRemarkModel && (
        <Modal
          width={600}
          title="Remark"
          open={isRemarkModel}
          onOk={() => {
            setState({ isRemarkModel: true });
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isRemarkModel: false }))
          }
          footer={false}
        >
          <Remarks
            remarksID={formData.tc_id}
            remarkType="make_payment"
            // idType="Bunker_no"
          />
        </Modal>
      )}
    </>
  );
};

export default MakePayment;
