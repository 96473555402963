import React, { useRef, useEffect, forwardRef, useState } from "react";
import { Modal, Spin } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import Email from "../../../../components/Email";
import { PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import html2canvas from "html2canvas";
import URL_WITH_VERSION, {
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../../shared";
import InvoicePopup from "../../../create-invoice/InvoicePopup";

const ComponentToPrint = forwardRef((props, ref) => {
  const { invoiceData, invType, totalAmount, aa, loadInvoice, billTo } = props;

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-invoice">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                {invoiceData?.my_company_logo && (
                  <img
                    className="reportlogo"
                    src={invoiceData?.my_company_logo}
                    crossOrigin="anonymous"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center invoice-top-address">
                  {invoiceData?.my_company_name && (
                    <h3>
                      <b>{invoiceData?.my_company_name}</b>
                    </h3>
                  )}
                  {invoiceData?.my_company_address && (
                    <p> {invoiceData?.my_company_address}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    <td>
                      <h4 className="mb-1">
                        <b>Bill To</b>
                      </h4>
                      {invoiceData && invoiceData["counterparty_name"]
                        ? billTo
                        : "N/A"}
                      <p className="m-0">
                        {invoiceData && invoiceData["counterparty_address"]
                          ? invoiceData["counterparty_address"]
                          : "N/A"}
                      </p>
                    </td>
                    <td className="text-right">
                      <p>
                        <span className="h3 font-weight-bold mr-4">
                          {invoiceData && invoiceData["tax_invoice"]
                            ? "Tax Invoice"
                            : undefined}
                        </span>
                        <span className="h3 font-weight-bold mr-4">
                          {invoiceData && invoiceData["credit_note"]
                            ? "Credit Note"
                            : undefined}
                        </span>
                        <span className="h2 font-weight-bold">
                          Payment Invoice
                        </span>
                      </p>
                      {/* <h2 className="font-weight-bold">Payment Invoice</h2> */}
                      <p className="m-0">
                        Invoice Type Name: <b> {invType}</b> | Invoice Date:{" "}
                        <b>
                          {moment(loadInvoice["created_on"]).format(
                            "DD-MM-YYYY"
                          )}
                        </b>{" "}
                        | Due Date:{" "}
                        <b>
                          {moment(loadInvoice["due_date"]).format("DD-MM-YYYY")}
                        </b>
                        Invoice No.:{" "}
                        <b>
                          {loadInvoice["invoice_no"] ||
                            invoiceData["invoice_no"]}
                        </b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr className="mb-0 mt-0 invoice-line" />
          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    <td>
                      <ul className="list-unstyled">
                        <li className="laytime-list">
                          <span>Vessel:</span>{" "}
                          <span> {invoiceData["vessel_name"]}</span>
                        </li>
                        <li className="laytime-list">
                          <span>CP Date:</span>{" "}
                          <span>
                            {!invoiceData["cp_date"]
                              ? ""
                              : moment(invoiceData["cp_date"]).format(
                                  "MMM, DD YYYY"
                                )}
                          </span>
                        </li>

                        <li className="laytime-list">
                          {invType == "TCO" ? (
                            <span>Chartered TO:</span>
                          ) : (
                            <span>Chartered From:</span>
                          )}

                          <span>
                            {" "}
                            {invoiceData && invoiceData["counterparty_name"]
                              ? invoiceData["counterparty_name"]
                              : "N/A"}
                          </span>
                        </li>

                        {/* <li className="laytime-list">
                            <span>Tax Invoice:</span>{" "}
                            <span>
                              {" "}
                              {invoiceData && invoiceData["tax_invoice"]
                                ? "YES"
                                : "NO"}
                            </span>
                          </li> */}
                      </ul>
                    </td>
                    <td className="text-right">
                      <ul className="list-unstyled">
                        <li className="laytime-list">
                          <span>Delivery:</span>{" "}
                          <span>
                            {" "}
                            {invoiceData["delivery_date"] === "" ||
                            !invoiceData["delivery_date"]
                              ? ""
                              : moment(invoiceData["delivery_date"]).format(
                                  "MMM, DD YYYY"
                                )}
                          </span>
                        </li>
                        <li className="laytime-list">
                          <span>Redelivery:</span>{" "}
                          <span>
                            {invoiceData["redelivery_date"] === "" ||
                            !invoiceData["redelivery_date"]
                              ? ""
                              : moment(invoiceData["redelivery_date"]).format(
                                  "MMM, DD YYYY"
                                )}
                          </span>
                        </li>

                        <li className="laytime-list">
                          <span>Printed On:</span>{" "}
                          <span>
                            {invoiceData["printed"] === "" ||
                            !invoiceData["printed"]
                              ? ""
                              : moment(invoiceData["printed"]).format(
                                  "MMM, DD YYYY"
                                )}
                          </span>
                        </li>

                        {/* <li className="laytime-list">
                            <span>Credit Note:</span>{" "}
                            <span>
                              {" "}
                              {invoiceData && invoiceData["credit_note"]
                                ? "YES"
                                : "NO"}
                            </span>
                          </li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div className="col-md-12">
              <p className="border-bottom">
                <b>
                  Description <span className="float-right">Amount (USD)</span>
                </b>
              </p>

              {aa && aa.length > 0
                ? aa.map((item, index) => {
                    return (
                      <p className="m-0 invoice-list">
                        <span>{item.details}</span>
                        <span className="float-right">{item?.amount}</span>
                      </p>
                    );
                  })
                : undefined}
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <h5 style={{ marginLeft: "5rem" }}>
                  <b> Invoice Amount:</b>
                </h5>
                <span>
                  {invoiceData?.invoice_total < 0
                    ? invoiceData?.invoice_total
                    : invoiceData?.invoice_total}
                </span>
              </div>

              <div style={{ display: "flex", gap: "4rem" }}>
                <h5>
                  <b> VAT/GST % :</b>
                </h5>
                <span>
                  {invoiceData?.vat_gst < 0
                    ? invoiceData?.vat_gst
                    : invoiceData?.vat_gst}
                </span>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-3 ml-auto">
              <h5 className="m-0">
                <b> Total Amount(USD):</b>
                <span className="float-right">
                  {invoiceData?.amount < 0
                    ? invoiceData?.amount
                    : invoiceData?.amount}
                </span>
              </h5>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-3 ml-auto">
              <h5 className="m-0">
                <b> Final Amount ({invoiceData["curr_name"] || "USD-USA"}):</b>
                <span className="float-right">
                  {invoiceData?.final_amt_loc < 0
                    ? invoiceData?.final_amt_loc
                    : invoiceData?.final_amt_loc}
                </span>
              </h5>
            </div>
          </div>

          {/* <div className="row p10">
            <div className="col-md-3 ml-auto">
            <h5 className="m-0">
                <b>Total Amount(USD-USA)</b>{" "}
                <span className="float-right">
                  {" "}
                  {invoiceData?.amount < 0
                    ? invoiceData.amount
                    : invoiceData.amount}
                </span>
              </h5>
              <h5 className="m-0">
                <b>Invoice Amount(USD-USA)</b>{" "}
                <span className="float-right">
                  {" "}
                  {totalAmount < 0
                    ? totalAmount.toFixed(2)
                    : totalAmount.toFixed(2)}
                </span>
              </h5>
              <h5 className="m-0">
                <b>Vat Gst %</b>{" "}
                <span className="float-right">
                  {" "}
                  {invoiceData?.vat_gst < 0
                    ? invoiceData?.vat_gst
                    : invoiceData?.vat_gst}
                </span>
              </h5>
              <h5 className="m-0">
                <b>Final Amount(USD-USA)</b>{" "}
                <span className="float-right">
                  {" "}
                  {invoiceData?.final_amt_loc < 0
                    ? invoiceData?.final_amt_loc
                    : invoiceData?.final_amt_loc}
                </span>
              </h5>
            </div>
          </div> */}
          {/* <div className="row p10">
            <div className="col-md-3 ml-auto">
              <h5 className="m-0">
                <b>Final Amount({invoiceData["curr_name"] || "USD-USA"})</b>{" "}
                <span className="float-right">
                  {" "}
                  {invoiceData["final_amt_loc"] || totalAmount.toFixed(2)}
                </span>
              </h5>
            </div>
          </div> */}
          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    {invoiceData && invoiceData.acc_type == "78" ? (
                      <td>
                        <div className="col-12 text-left">
                          <h4 className="m-0">
                            <b> Bank Account Details</b>
                          </h4>
                          <ul className="list-unstyled">
                            <li className="laytime-list">
                              <span>Beneficiary Name:</span>{" "}
                              <span>
                                {" "}
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"]["benificiary_name"]
                                  ? invoiceData["bank_ajent"][
                                      "benificiary_name"
                                    ]
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Bank Name:</span>{" "}
                              <span>
                                {" "}
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"][
                                  "benificiary_bank_name"
                                ]
                                  ? invoiceData["bank_ajent"][
                                      "benificiary_bank_name"
                                    ]
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span> Bank Branch:</span>{" "}
                              <span>
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"]["branch"]
                                  ? invoiceData["bank_ajent"]["branch"]
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Account No:</span>{" "}
                              <span>
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"]["account_no"]
                                  ? invoiceData["bank_ajent"]["account_no"]
                                  : "N/A"}
                              </span>
                            </li>

                            <li className="laytime-list">
                              <span>IBAN:</span>{" "}
                              <span>
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"]["iban"]
                                  ? invoiceData["bank_ajent"]["iban"]
                                  : "N/A"}
                              </span>
                            </li>

                            <li className="laytime-list">
                              <span>Swift Code:</span>{" "}
                              <span>
                                {invoiceData["bank_ajent"] &&
                                invoiceData["bank_ajent"]["swift_code"]
                                  ? invoiceData["bank_ajent"]["swift_code"]
                                  : "N/A"}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </td>
                    ) : undefined}

                    <td className="text-right">
                      <h4 className="mb-1">
                        <b>Bill From :</b>
                      </h4>
                      {invoiceData?.my_company_name && (
                        <h3>
                          <b>{invoiceData?.my_company_name}</b>
                        </h3>
                      )}
                      <p className="m-0">{invoiceData?.my_company_address}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr className="mb-0 mt-0 invoice-line" />
          <div>
            <span className="invoice-disclaimer">
              Disclaimer: This is a system-generated invoice and does not
              require a signature.
            </span>
          </div>
        </div>
      </div>
    </article>
  );
});

const DownloadInvoice = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const [state, setState] = useStateCallback({
    name: "Printer",
    loadInvoice: props.loadInvoice || {},
    loadData: props.loadData || {},
    invType: props.invoiceType || "",
    DATA: {},
    isshowmodal: false,
    loader: false,
    makepaymentid: props.makepaymentid || null,
    showInvoicePopup: props.showInvoicePopup || false,
  });
  const componentRef = useRef();
  const [billTo, setBillTo] = React.useState("");

  const printDocument = () => {
    const { DATA, invType } = state;

    html2canvas(document.getElementById("divToPrint"), {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 2,
    }).then(function (canvas) {
      // download image
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${invType}Report.pdf`);
    }).catch = (err) => {};
  };

  useEffect(() => {
    const getFormData = async () => {
      let { loadInvoice, loadData, invType, makepaymentid } = state;
      let totalAmount = 0;
      let request = null;
      let request1 = null;
      setState((prevState) => ({ ...prevState, loader: true }));
      try {
        if (invType == "TCI") {
          request = await getAPICall(
            `${URL_WITH_VERSION}/tci/edit?e=${loadInvoice.tc_id}&sn=1`
          );
        }
        if (invType == "TCO") {
          request = await getAPICall(
            `${URL_WITH_VERSION}/tco/edit?ae=${loadInvoice.tc_id}&sn=1`
          );
        }

        let response = await request["data"];

        loadInvoice["date_from"] = loadInvoice["delivery"];
        loadInvoice["date_to"] = loadInvoice["re_delivery"];
        loadInvoice["chartrer_id"] = loadInvoice["charterer_from"];
        if (invType == "TCO") {
          loadInvoice["chartrer_id"] = response["charterer"];
        }

        loadInvoice["account_head"] = loadData;
        loadInvoice["vessel_name"] = response["vessel_name"];
        loadInvoice["chartrer_name"] = response["chartrer_name"];
        loadInvoice["id"] = makepaymentid;
        if (invType == "TCI") {
          request1 = await awaitPostAPICall(
            `${URL_WITH_VERSION}/report/hire-statement`,
            loadInvoice
          );
        }
        if (invType == "TCO") {
          request1 = await awaitPostAPICall(
            `${URL_WITH_VERSION}/report/tco-hire-statement`,
            loadInvoice
          );
        }

        let response1 = await request1["data"];

        if (
          response1 &&
          response1.invoice.length > 0 &&
          response1.invoice[0].description
        ) {
          loadInvoice["-"] = response1.invoice;
        }

        const aa = [];

        loadInvoice["-"] &&
          loadInvoice["-"].length > 0 &&
          loadInvoice["-"].map((item, id) => {
            item &&
              item["description"] &&
              item["description"].length > 0 &&
              item["description"].map((el) => {
                let cc = {};
                cc["details"] = el.details;
                cc["amount"] = el.amount;
                aa.push(cc);
                totalAmount = totalAmount + el.amount * 1;
              });
          });

        let _data = {
          acc_type: loadInvoice["acc_type"],
          my_company: loadInvoice["my_company"],
          counterparty: loadInvoice["chartrer_id"],
          invoice_no: loadInvoice["invoice_no"],
        };

        if (response) {
          setState(
            (prevState) => ({
              ...prevState,
              invoiceData: response1,
              totalAmount: totalAmount,
              aa: aa,
              DATA: Object.assign({}, _data),
              loader: false,
              totalAmt: response["amount"],
              finalAmtLoc: response["final_amt_loc"],
              vatGst: response["vat_gst"],
            }),
            () =>
              setState((prevState) => ({
                ...prevState,
                showInvoicePopup: true,
              }))
          );
        }
      } catch (err) {
        openNotificationWithIcon("error", "Something Went wrong.", 5);
      }
    };
    getFormData();
  }, []);

  useEffect(() => {
    // const {invoiceType,loadInvoice}=props
    // const { invoice_no, charterer_name, vessel_name } = loadInvoice;
    // const tempArray = [invoiceType, invoice_no, charterer_name, vessel_name];

    // setTitleArray(tempArray)
    setUserInput(props);
  }, []);

  const getData = (billToValue) => {
    setBillTo(billToValue);
  };

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const getpopupdata = (data) => {
    setState((prevState) => ({
      ...prevState,
      invoiceData: {
        ...state.invoiceData,
        my_company_name: data.my_company_name,
        my_company_address: data.my_company_address,
        my_company_logo: data.my_company_logo,
        bank_ajent: { ...data.bank_ajent },
        tax_invoice: data.tax_invoice,
        credit_note: data.credit_note,
        my_company: data.id,
        isSaved: data.isSaved,
        acc_type: data.acc_type,
      },
    }));
  };

  const handleok = () => {
    const { invoiceData } = state;
    if (invoiceData["isSaved"]) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, showInvoicePopup: false }));
      }, 2000);
      setState((prevState) => ({ ...prevState, invoiceReport: invoiceData }));
    } else {
      openNotificationWithIcon(
        "info",
        "Please click on Save to generate invoice.",
        3
      );
    }
  };

  const cancelInvoicePop = () => {
    setState((prevState) => ({ ...prevState, showInvoicePopup: false }));
    if (props.hasOwnProperty("closeDonloadInvoice")) {
      props.closeDonloadInvoice(false);
    }
  };

  const printReceipt = () => window.print();

  const {
    isshowmodal,
    invoiceData,
    totalAmount,
    aa,
    invType,
    loadInvoice,
    DATA,
    showInvoicePopup,
    loader,
  } = state;

  return (
    <>
      {showInvoicePopup && !loader ? (
        <Modal
          title=" Edit Account Details"
          style={{ top: "2%" }}
          open={showInvoicePopup}
          onOk={handleok}
          width="90%"
          okText="Create PDF"
          onCancel={cancelInvoicePop}
          maskClosable={false}
        >
          <InvoicePopup
            data={invoiceData}
            type={invType}
            updatepopup={(data) => getpopupdata(data)}
            getData={getData}
          />
        </Modal>
      ) : (
        <div className="body-wrapper modalWrapper">
          <article className="article toolbaruiWrapper">
            <div className="box box-default">
              <div className="box-body">
                <div className="toolbar-ui-wrapper">
                  <div className="leftsection" />
                  <div className="rightsection">
                    <span className="wrap-bar-menu">
                      <ul className="wrap-bar-ul">
                        <li
                          onClick={sendEmailReportModal}
                          style={{
                            cursor: loading ? "not-allowed" : "pointer",
                          }}
                        >
                          Send Email
                        </li>
                        <li>
                          <span className="text-bt" onClick={printDocument}>
                            Download{" "}
                          </span>
                        </li>
                        <li>
                          <ReactToPrint
                            trigger={() => (
                              <span className="text-bt">
                                <PrinterOutlined />
                                Print
                              </span>
                            )}
                            content={() => componentRef.current}
                          />
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </article>

          {invoiceData ? (
            <article className="article">
              <div className="box box-default">
                <div className="box-body">
                  <ComponentToPrint
                    invoiceData={invoiceData}
                    invType={invType}
                    totalAmount={totalAmount}
                    aa={aa}
                    loadInvoice={loadInvoice}
                    ref={componentRef}
                    billTo={billTo}
                  />
                </div>
              </div>
            </article>
          ) : undefined}

          {emailModal && (
            <Modal
              title="New Message"
              visible={emailModal}
              onOk={() => {
                setEmailModal(false);
              }}
              onCancel={() => {
                setEmailModal(false);
              }}
              footer={null}
            >
              {pdfData && (
                <Email
                  handleClose={() => {
                    setEmailModal(false);
                  }}
                  attachmentFile={pdfData}
                  // title={window.emailTitltCorrectFunction('Hire_Payable_List_Invoice',titleArray) }
                  title={window.corrector(
                    `Hire_Payable_List_Invoice||${props.invoiceType}||${props.loadInvoice.invoice_no}||${props.loadInvoice.chartrer_name}||${props.loadInvoice.vessel_name}`
                  )}

                  //  title={`Hire_Payable_List_Invoice|| ${props.invoiceType}|| ${props.loadInvoice.invoice_no}(${props.loadInvoice.chartrer_name})||${props.loadInvoice.vessel_name}`}
                />
              )}
            </Modal>
          )}

          {loading && (
            <div
              style={{
                position: "absolute",
                top: "10%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DownloadInvoice;
