import React, { useEffect, useState } from "react";
import { Select, Input, DatePicker, Button, message, Form } from "antd";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterData,
  setFilterPayload,
  setIsFiltering,
} from "../../actions/emailActions";
import { useLocation } from "react-router";
import dayjs from "dayjs";
import { debounce } from "lodash";

const { RangePicker } = DatePicker;

const SearchFilters = (props) => {
  const { type, groupId } = props;
  const [vessels, setVessels] = useState([]);
  const [tags, setTags] = useState([]);
  const [unique, setUnique] = useState([]);
  const [labels, setLabels] = useState([]);
  const [uniqueMail, setUniqueMail] = useState([]);
  const [previousFilterData, setPreviousFilterData] = useState(null);
  const [uniqueSender, setUniqueSender] = useState([]);
  const [loading, setLoading] = useState(false);

  const filterPayload = useSelector((state) => state.email.filterPayload);

  const dispatch = useDispatch();
  const [form] = Form.useForm(); // AntD Form hook
  const location = useLocation();
  useEffect(() => {
    form.resetFields();
    dispatch(setIsFiltering(false));
  }, [location]);

  const handleSubmit = async (values) => {
    try {
      const payload = cleanFilterData(values);
      let url;

      if (type == "group") {
        url = `${
          process.env.REACT_APP_MT_URL
        }mail/groupFilter/${groupId}?page=${1}&pageSize=10`;

        const resp = await postAPICall(url, payload, "POST");
        console.log("handleSubmit", resp);

        if (resp && resp.emails.length > 0) {
          dispatch(setIsFiltering(true));
          dispatch(setFilterData(resp));
        } else {
          message.info("No emails found matching the given criteria.");
          dispatch(setIsFiltering(false));
        }
      } else {
        url = `${process.env.REACT_APP_MT_URL}mail/getFIlterTonnageMails?page=1&pageSize=20`;
      }
      dispatch(setIsFiltering(true));
      // const resp = await postAPICall(url, payload, "POST");
      // console.log("handleSubmit", resp);

      // if (resp && resp.emails.length > 0) {
      //   dispatch(setIsFiltering(true));
      //   dispatch(setFilterData(resp));
      // } else {
      //   message.info("No emails found matching the given criteria.");
      //   dispatch(setIsFiltering(false));
      // }
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  const cleanFilterData = (values) => {
    let dateRangeString = "";
    if (
      values?.receivedDate &&
      values?.receivedDate[0] &&
      values?.receivedDate[1]
    ) {
      const formattedStartDate = dayjs(
        new Date(values?.receivedDate[0])
      ).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(values?.receivedDate[1])).format(
        "YYYY/MM/DD"
      );

      dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
    }

    return {
      owner_email: "*",
      query: [
        {
          ...filterPayload?.query?.[0],
          date: dateRangeString ? [dateRangeString] : [],
          // date: values?.receivedDate ? [...(filterPayload?.query?.[0]?.date || []), ...values.receivedDate] : filterPayload?.query?.[0]?.date || [],
          Sender: values.senderEmail ? values.senderEmail : [],
          vessel_name: values.vessel_name ? values.vessel_name : [],
          subject: [],
          dwt:
            values?.dwt?.start && values?.dwt?.end
              ? [`${values.dwt.start} - ${values.dwt.end}`]
              : [],
          label: values.labelSubject ? values.labelSubject : [],
          attach: values.withAttachment ? [values.withAttachment] : [],
          Invoice: [],
          text: values.emailBody ? [values.emailBody] : [],
          email: values.emailReceiver ? values.emailReceiver : [],
          tags: values.tags ? values.tags : [],
          comments: [],
          unRead: values.unRead || "",
          priority: values.priority || "",
        },
      ],
      // query: [
      //   {
      //     date: values.receivedDate ? values.receivedDate : [],
      //     Sender: values.senderEmail ? values.senderEmail : [],
      // vessel_name: values.vessel_name ? values.vessel_name : [],
      // subject: [],
      // dwt:
      //   values?.dwt?.start && values?.dwt?.end
      //     ? [values.dwt.start, values.dwt.end]
      //     : [],
      // label: values.labelSubject ? values.labelSubject : [],
      // attach: values.withAttachment ? [values.withAttachment] : [],
      // Invoice: [],
      // text: values.emailBody ? [values.emailBody] : [],
      // email: values.emailReceiver ? values.emailReceiver : [],
      // tags: values.tags ? values.tags : [],
      // comments: [],
      // unRead: values.unRead || "",
      // priority: values.priority || "",
      //   },
      // ],
    };
  };

  const handleApplyFilters = () => {
    form.validateFields().then((values) => {
      const filterData = cleanFilterData(values);

      const hasData = Object.values(filterData.query[0]).some((field) =>
        Array.isArray(field) ? field.length > 0 : field
      );

      // const isDifferentFromPrevious =
      //   JSON.stringify(filterData) !== JSON.stringify(previousFilterData);
      if (hasData) {
        dispatch(setFilterPayload(filterData));
        handleSubmit(values);
        setPreviousFilterData(filterData);
      } else {
        dispatch(setIsFiltering(false));
        message.destroy();
        message.info("No changes in filters or no data present.");
      }
    });
  };

  const handleResetFilters = () => {
    form.resetFields();
    dispatch(setIsFiltering(false));
    message.destroy();
    message.info("Filters have been reset.");
    dispatch(setFilterPayload([]));
  };

  const findUniqueData = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/find-unique`;
      const payload = { owner_email: "*" };
      const response = await postAPICall(url, payload, "post");
      if (response?.data) {
        setUnique(response.data);
      } else {
        openNotificationWithIcon("info", response?.msg);
      }
    } catch (e) {
      console.error("Error fetching unique data:", e);
    }
  };

  useEffect(() => {
    console.log("UNIQUE--------------", unique?.unique_vessel_names);
    if (unique?.unique_vessel_names) setVessels(unique?.unique_vessel_names);
    if (unique?.unique_tags_mails) setTags(unique?.unique_tags_mails);
    if (unique?.unique_label_names) setLabels(unique?.unique_label_names);
    if (unique?.unique_receiver_mails)
      setUniqueMail(unique?.unique_receiver_mails);
    if (unique?.unique_emails) setUniqueSender(unique?.unique_emails);
  }, [unique]);

  useEffect(() => {
    findUniqueData();
  }, []);

  const handleVesselSearch = debounce(async (value) => {
    if (value.length > 2) {
      try {
        const url = `${process.env.REACT_APP_MT_URL}mail/get-suggestions`;
        const payload = {
          collection_type: "tonnage",
          field_name: "vessel_name",
          queryText: value,
        };
        const response = await postAPICall(url, payload, "post");
        setVessels((prev) => [...prev, ...response.data]);
      } catch (e) {
        console.error("Error fetching vessels:", e);
      }
    }
  }, 400);

  console.log("VESSAL------------------", vessels);

  return (
    <Form form={form} layout="vertical">
      <div className="search-filters" style={{ paddingBottom: "55px" }}>
        <Form.Item name="vessel_name" label="Vessels">
          <Select
            allowClear
            mode="tags"
            placeholder="Select Vessel"
            showSearch
            onSearch={handleVesselSearch}
          >
            {vessels.map((vessel, index) => (
              <Select.Option key={index} value={vessel}>
                {vessel}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="DWT" name="dwt">
          <Input.Group compact>
            <Form.Item name={["dwt", "start"]} noStyle>
              <Input
                style={{ width: "50%" }}
                placeholder="Start DWT"
                type="number"
              />
            </Form.Item>
            <Form.Item name={["dwt", "end"]} noStyle>
              <Input
                style={{ width: "50%" }}
                placeholder="End DWT"
                type="number"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item label="Sender Email" name="senderEmail">
          <Select mode="tags" placeholder="Select Sender">
            {uniqueSender?.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Received Date" name="receivedDate">
          <RangePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Tags" name="tags">
          <Select mode="tags" placeholder="Select Tags">
            {tags.map((tag, index) => (
              <Select.Option key={index} value={tag}>
                {tag}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Labels" name="labelSubject">
          <Select mode="tags" placeholder="Select Labels">
            {labels.map((label, index) => (
              <Select.Option key={index} value={label}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Unread Mails" name="unRead">
          <Select placeholder="Yes/No">
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="High Priority Mails" name="priority">
          <Select placeholder="Yes/No">
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="With Attachment" name="withAttachment">
          <Select placeholder="Yes/No">
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Email Receiver" name="emailReceiver">
          <Select mode="tags" placeholder="Select Receiver">
            {uniqueMail.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Email Body" name="emailBody">
          <Input placeholder="Email Body" />
        </Form.Item>

        <Form.Item
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            background: "white",
            width: "95%",
            padding: "10px 10px 0px",
            boxShadow: "0px -16px 80px 2px #BFBFD3",
          }}
        >
          <Button style={{ width: "49%" }} onClick={handleResetFilters}>
            Reset Filters
          </Button>
          <Button
            type="primary"
            style={{ width: "49%", marginBottom: "10px" }}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SearchFilters;
