import { useEffect, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  capitaliseFirstLetter,
  convertToCSV,
  disabledDate,
  formatDate121,
  formatNumber,
  rowDataFormatter,
} from "./constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import MenuItem from "antd/es/menu/MenuItem";
import dayjs from "dayjs";
import BestMatchDrawerContent from "./BestMatchDrawerContent";
import CurrentMail from "./CurrentMail";
import TonnageViewForm from "./ViewTonnageForm";
import ViewCargoForm from "./ViewCargoForm";
import { useLocation, useNavigate, useParams } from "react-router";
import { getAllLabel, getCargoChilds, getTonnageChilds } from "../../services/email_utils";
import { setCargoChildren, setTonnageChildren } from "../../actions/emailActions";
import { useDispatch } from "react-redux";
import CargoAnalysis from "./CargoAnalysis";

const AllCargoList = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState({});
  const [IsFilter, setIsFilter] = useState(false);
  const [dwtFilter, setDwtFilter] = useState({
    cp_qty: { min: null, max: null, combined: null },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
  });

  const [filters, setFilters] = useState({
    date: [],
    cargo_name: [],
    cargo_type: [],
    company_name: [],
    cp_qty: { min: null, max: null, combined: null },
    discharge_option: [],
    discharge_region: [],
    vessel_sub_type: [],
    laycan: [],
    load_option: [],
    load_region: [],
    processing: [],
    open_date: [],
    open_area: [],
    build_year: [],
    sender: [],
    tags: [],
  });

  const [apiFilters, setApiFilters] = useState({});

  const [currentBestMatch, setCurrentBestMatch] = useState({});
  const [isBestMatch, setIsBestMatch] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [tonnageMail, setTonnageMail] = useState({});
  const [tonnageMailOpen, setTonnageMailOpen] = useState(false);

  const [isView, setIsView] = useState(false);
  const [viewDetail, setViewDetail] = useState({});
  const [assignedMails, setAssignedMails] = useState([]);

  const [isFilterSave, setIsFilterSave] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [isChildListing, setIsChildListing] = useState(false);
  const [form] = Form.useForm();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const [childName, setChildName] = useState("");
  const [childData, setChildData] = useState([]);

  const [isEditBox, setIsEditBox] = useState(false);
  const [childrenData, setChildrenData] = useState([]);
  const [showCargoAnalysisModal, setShowCargoAnalysisModal] =
    useState(false);

  const { RangePicker, YearPicker } = DatePicker;

  const dynamicChildFunction = async () => {
    const respose = await getCargoChilds();
    dispatch(setCargoChildren(respose?.data));
  };

  const handleSaveFilter = async (values) => {
    try {
      if (allData.length === 0) {
        openNotificationWithIcon("info", "No data to save filter.");
        return;
      }

      if (values?.filterName === "") {
        openNotificationWithIcon("info", "Please enter a filter name.");
        return;
      }

      const updatedData = allData.map((obj) => {
        return {
          ...obj, // Spread existing object properties
          cargo_category_name: values?.filterName,
          url: values?.filterName?.replace(/\s+/g, '')?.toLowerCase(),
        };
      });

      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/add-cargo`;
      const payload = {
        filter_data: updatedData,
        filter_set: [filters],
      };
      await postAPICall(url, payload, "post", (value) => {
        if (value.status === "1") {
          openNotificationWithIcon("success", value.msg);
          setIsFilterSave(false);
          dynamicChildFunction();
        }else{
          openNotificationWithIcon('info',value?.msg)
        }
      });
    } catch (e) {
      console.error("Error saving filter:", e);
      openNotificationWithIcon("error", "Failed to Save Filter. Try again...");
    }
  };

  const handleResetFilter = () => {
    setFilters({
      date: [],
      cargo_name: [],
      cargo_type: [],
      company_name: [],
      cp_qty: { min: null, max: null, combined: null },
      discharge_option: [],
      discharge_region: [],
      vessel_sub_type: [],
      laycan: [],
      load_option: [],
      load_region: [],
      processing: [],
      open_date: [],
      open_area: [],
      build_year: [],
      sender: [],
      tags: [],
    });
    setPage(1);
    navigate(`/mails/all-cargo-list`);
    fetchTonnageData(page, pageSize);
  };

  const handleTonnagemail = async (id) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/get-mail/${id}`;
      const response = await postAPICall(url, {}, "get", (data) => {
        if (data.data.length > 0) {
          setTonnageMail(data.data[0]);
          setTonnageMailOpen(true);
          setLoading(false);
        } else {
          openNotificationWithIcon("info", "Mail Not Found!");
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleView = (row) => {
    setViewDetail(row);
    setIsView(true);
  };

  const ActionCell = ({ data }) => {
    const content = (
      <div>
        <div className="tonnage_dropdown">
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
            onClick={() => {
              setCurrentBestMatch(data);
              setIsBestMatch(true);
            }}
          >
            <Icon icon={"ri:shake-hands-line"} /> Best Match
          </Button>
        </div>

        <div className="tonnage_dropdown">
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => handleTonnagemail(data["refid"])}
          >
            <Icon icon={"mdi:email"} /> View Mail
          </Button>
        </div>
      </div>
    );

    return (
      <>
        <Popover content={content} placement="leftBottom">
          <div style={{ padding: "4px" }}>
            <Icon
              icon={"fluent:tap-single-32-regular"}
              fontSize={20}
              className="bg-icon-color"
            />
          </div>
        </Popover>
      </>
    );
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      align: "center",
      key: "sNo",

      render: (text, row, index) => (
        <div
        //   onClick={() => handlePopoverOpen(row)}
        // onMouseLeave={() => handlePopoverClose()}
        >
          {/* {index + 1} */}
          {pageSize * (page - 1) + index + 1}
          {/* <Typography>{index + 1}</Typography> */}
        </div>
      ),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      width: "3rem",
    },
    {
      title: "Date Received",
      dataIndex: "Time",
      key: "DateReceived",
      sorter: (a, b) => moment(b.DateTime).unix() - moment(a.DateTime).unix(),
      sortDirections: ["descend", "ascend"],
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip
            title={capitaliseFirstLetter(
              formatDate121(row.Time, row.DateTime, true)
            )}
            arrow
          >
            <div style={{ width: "5rem" }} className="overflowText">
              {" "}
              {capitaliseFirstLetter(
                formatDate121(row.Time, row.DateTime, false)
              ) || "N/A"}
            </div>
          </Tooltip>

          {row.DateTime && (
            <div style={{ display: "flex", gap: "4px" }}>
              <div
                style={{ display: "flex", gap: "2px" }}
                className="allIconColor"
              >
                <Icon
                  icon="ph:users"
                  fontSize={16}
                  style={{ color: "green" }}
                />
                <span style={{ color: "green" }}>{row.New_From?.length}</span>
              </div>
              <Tooltip title={row.New_From[0]} arrow>
                <div
                  style={{ display: "flex", gap: "2px" }}
                  className="allIconColor"
                >
                  <Icon
                    icon="mdi:email-outline"
                    fontSize={16}
                    style={{ color: "#1C5CE9" }}
                  />
                  <span style={{ color: "#1C5CE9" }}>
                    {row.New_From?.length}
                  </span>
                </div>
              </Tooltip>
            </div>
          )}
        </span>
      ),
    },
    {
      title: "Cargo Name",
      dataIndex: "cargo",
      key: "cargo",
      //   sorter: (a, b) => a.cargo_name.localeCompare(b.cargo_name),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={capitaliseFirstLetter(rowDataFormatter(row.cargo))}
          arrow
        >
          <span className="overflowTextWrap">
            <div style={{ width: "9rem" }} className="overflowText">
              {capitaliseFirstLetter(rowDataFormatter(row.cargo)) || "N/A"}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Cargo Type",
      dataIndex: "cargo_type",
      key: "cargo_type",
      //   sorter: (a, b) => a.cargo_type.localeCompare(b.cargo_type),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip title={rowDataFormatter(row.cargo_type)} arrow>
          <span className="overflowTextWrap">
            <div style={{ width: "5rem" }}>
              {capitaliseFirstLetter(rowDataFormatter(row.cargo_type)) || "N/A"}
            </div>
            <div
              style={{ padding: "2px" }}
              onClick={() => {
                //   dispatch(setVesselName(row.cargo_name))
                //   setShowMap(true)
                localStorage.setItem("tabChangeMap", "false");
              }}
            >
              {row.cargo_type === "dry" && (
                <Icon
                  icon="lucide:ship"
                  fontSize={16}
                  style={{ color: "#EC9C38" }}
                />
              )}
              {row.cargo_type === "tanker" && (
                <Icon
                  icon="lucide:ship"
                  fontSize={16}
                  style={{ color: "#0051F2" }}
                />
              )}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      //   sorter: (a, b) => a.company.localeCompare(b.company),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const formattedText =
          capitaliseFirstLetter(rowDataFormatter(row.company)) || "N/A";
        const showTooltip = formattedText !== "N/A";

        return (
          <span className="overflowTextWrap">
            {showTooltip ? (
              <Tooltip title={formattedText} arrow>
                <div style={{ width: "12rem" }} className="overflowText">
                  {formattedText}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "12rem" }} className="overflowText">
                {formattedText}
              </div>
            )}
            {/* <div
              style={{ padding: "2px" }}
              //   onClick={() => {
              //     dispatch(setVesselName(row.cargo_name));
              //     setShowMap(true);
              //   }}
            >
              <Icon
                icon="tabler:ship"
                fontSize={16}
                style={{ color: "#078244" }}
              />
            </div> */}
          </span>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "New_From",
      // sorter: (a, b) => a.New_From?.localeCompare(b.New_From),
      key: "sender",
      // width: '',
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          {row.sender_full_details?.name ? (
            <>
              <Tooltip
                title={
                  <span>
                    {/* {capitaliseFirstLetter(`${row.New_From?.[0]}`)} */}
                    {/* <br /> */}
                    {typeof row.sender_full_details === "string" ? (
                      capitaliseFirstLetter(row.sender_full_details)
                    ) : row.sender_full_details ? (
                      <>
                        {capitaliseFirstLetter(row.sender_full_details.name) ||
                          "N/A"}
                        <br />
                        {row.sender_full_details?.phone_numbers}
                        <br />
                        {row.sender_full_details?.company_name}
                        <br />
                        {row.sender_full_details?.emails}
                        <br />
                        {row.sender_full_details?.skype}
                        <br />
                        {row.sender_full_details?.websites}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                }
                arrow
              >
                <div style={{ width: "12rem" }} className="overflowText">
                  {capitaliseFirstLetter(row.New_From?.[0]) || "N/A"}
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={row.Sender_mail} arrow>
                <div style={{ width: "12rem" }} className="overflowText">
                  {capitaliseFirstLetter(row.Sender_names || row.Sender_mail) ||
                    "N/A"}
                </div>
              </Tooltip>
            </>
          )}
        </span>
      ),
    },

    {
      title: "Cargo Qty",
      dataIndex: "cargo_size",
      key: "cargoQty",
      // width: '9rem',
      sorter: (a, b) => a?.cargo_size - b?.cargo_size,
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip title={capitaliseFirstLetter(row.cargo_size)} arrow>
            <div style={{ width: "6rem" }} className="overflowText">
              {capitaliseFirstLetter(row.cargo_size) || "N/A"}
            </div>
          </Tooltip>
        </span>
      ),
    },

    {
      title: "Voyage /TCT",
      dataIndex: "tct",
      key: "voyageTCT",
      // width: '8rem',
      sorter: (a, b) => a.tct?.localeCompare(b.tct),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip title={capitaliseFirstLetter(row.tct)} arrow>
            <div style={{ width: "6rem" }} className="overflowText">
              {capitaliseFirstLetter(row.tct) || "N/A"}
            </div>
          </Tooltip>
        </span>
      ),
    },

    {
      title: "Laycan",
      dataIndex: "laycan",
      key: "laycan",
      // width: '8rem',
      sorter: (a, b) => moment(b.laycan).unix() - moment(a.laycan).unix(), // Sorting dates, latest first
      sortDirections: ["descend", "ascend"], // Default sorting direction
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip title={capitaliseFirstLetter(row.laycan)} arrow>
            <div style={{ width: "10rem" }} className="overflowText">
              {capitaliseFirstLetter(row.laycan) || "N/A"}
            </div>
          </Tooltip>
        </span>
      ),
    },

    {
      title: "Load Port / Delivery",
      dataIndex: "load_port",
      key: "loadPortDelivery",
      // width: '9rem',
      sorter: (a, b) => a.load_port?.port?.localeCompare(b.load_port?.port),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const port = row.load_port?.port || "N/A";
        const hasPort = port !== "N/A";
        return (
          <span className="overflowTextWrap">
            {hasPort ? (
              <Tooltip
                title={`${row.load_port?.region}, ${row.load_port?.country}`}
                arrow
              >
                <div style={{ width: "10rem" }} className="overflowText">
                  {capitaliseFirstLetter(port)}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "10rem" }} className="overflowText">
                {port}
              </div>
            )}
            {hasPort && (
              <div>
                <Icon
                  icon="tabler:ship"
                  fontSize={16}
                  className="allIconColor"
                />
              </div>
            )}
          </span>
        );
      },
    },

    {
      title: "Dis. Port / Redelivery",
      dataIndex: "discharge_port",
      key: "dischargePortRedelivery",
      // width: '11rem',
      sorter: (a, b) =>
        a.discharge_port?.port?.localeCompare(b?.discharge_port?.port),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const portName =
          capitaliseFirstLetter(row?.discharge_port?.port) || "N/A";
        const showTooltip = portName !== "N/A";

        return (
          <span className="overflowTextWrap">
            {showTooltip ? (
              <Tooltip
                title={`${row.discharge_port?.region}, ${row.discharge_port?.country}`}
                arrow
              >
                <div style={{ width: "9rem" }} className="overflowText">
                  {portName}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "9rem" }} className="overflowText">
                {portName}
              </div>
            )}
          </span>
        );
      },
    },

    {
      title: "Load Port / Region",
      dataIndex: "region",
      key: "region",
      // width: '9rem',
      sorter: (a, b) => a.load_port?.region?.localeCompare(b.load_port?.region),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const region = row.load_port?.region || "N/A";
        const hasRegion = region !== "N/A";
        return (
          <span className="overflowTextWrap">
            {hasRegion ? (
              <Tooltip
                title={`${row.load_port?.region}, ${row.load_port?.country}`}
                arrow
              >
                <div style={{ width: "9rem" }} className="overflowText">
                  {capitaliseFirstLetter(region)}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "9rem" }} className="overflowText">
                {region}
              </div>
            )}
            {row.load_port?.port && (
              <div>
                <Icon
                  icon="tabler:ship"
                  fontSize={16}
                  className="allIconColor"
                />
              </div>
            )}
          </span>
        );
      },
    },

    {
      title: "Dis. Port / Region",
      dataIndex: "region",
      key: "region",
      sorter: (a, b) =>
        a.discharge_port?.region?.localeCompare(b.discharge_port?.region),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const region = row.discharge_port?.region;
        const tooltipTitle = region
          ? `${region}, ${row.discharge_port?.country}`
          : "";

        return (
          <span className="overflowTextWrap">
            {region ? (
              <Tooltip title={tooltipTitle} arrow>
                <div style={{ width: "9rem" }} className="overflowText">
                  {capitaliseFirstLetter(region) || "N/A"}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "9rem" }} className="overflowText">
                {"N/A"}
              </div>
            )}
          </span>
        );
      },
      width: "11rem",
    },

    {
      title: "Load Rate",
      dataIndex: "load_rate",
      key: "loadRate",
      // width: '7rem',
      sorter: (a, b) => a?.load_rate?.localeCompare(b.load_rate),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span
          className="overflowText"
          style={{
            width: "6.5rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={row.load_rate} arrow>
            <div style={{ width: "9rem" }} className="overflowText">
              {capitaliseFirstLetter(row.load_rate) || "N/A"}
            </div>
          </Tooltip>
          {/* <Box>{row.load_rate && <Icon icon='tabler:ship' fontSize={16} className='allIconColor' />}</Box> */}
        </span>
      ),
    },

    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      // width: '9rem',
      sorter: (a, b) => a?.tags[0]?.localeCompare(b?.tags[0]),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip
            title={row?.tags?.filter((tag) => tag !== null).join(", ")}
            arrow
          >
            <div style={{ width: "5rem" }} className="overflowText">
              {" "}
              {capitaliseFirstLetter(
                row?.tags?.length > 0 ? row.tags[0] : ""
              ) || "N/A"}
            </div>
          </Tooltip>
          {/* <Box>{row.discharge_rate && <Icon icon='humbleicons:ship' fontSize={16} className='allIconColor' />}</Box> */}
        </span>
      ),
    },

    {
      title: "Discharge Rate",
      dataIndex: "discharge_rate",
      key: "dischargeRate",
      //   sorter: (a, b) => a?.discharge_rate?.localeCompare(b?.discharge_rate),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={capitaliseFirstLetter(row.discharge_rate)} arrow>
            <span style={{ width: "8rem" }} className="overflowText">
              {" "}
              {capitaliseFirstLetter(row.discharge_rate) || "N/A"}
            </span>
          </Tooltip>
          {/* <Box>{row.discharge_rate && <Icon icon='humbleicons:ship' fontSize={16} className='allIconColor' />}</Box> */}
        </span>
      ),
      width: "9rem",
    },

    {
      title: "Processing",
      dataIndex: "Processing",
      key: "processing",
      sorter: true,
      align: "center",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "rgb(236 156 56 / 20%)"
              : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip title={capitaliseFirstLetter(row.Processing)} arrow>
          <span
            className="overflowText"
            style={{
              fontWeight: "600",
              color: "green",
              width: "6rem",
            }}
          >
            Processed
          </span>
        </Tooltip>
      ),
      width: "6rem",
    },
    {
      title: "Action",
      dataIndex: "flag",
      key: "action",
      fixed: "right",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.cargo_type === "dry" || row.cargo_type === "Dry Cargo"
              ? "white"
              : "white",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <ActionCell data={row} />
          <Tooltip title={"Click to view cargo in detail"}>
            <div onClick={() => handleView(row)}>
              <Icon
                icon={"tabler:eye"}
                fontSize={20}
                className="bg-icon-color"
              />
            </div>
          </Tooltip>
        </span>
      ),
      width: "3.5rem",
    },
  ];

  const fetchTonnageData = async (userPage, pagesize) => {
    setLoading(true);
    try {
      const userInput = {
        isToken: true,
        url: `${process.env.REACT_APP_MT_URL}mail/cargo-tonnage-mails?page=${userPage}&pageSize=${pagesize}`,
        payload: {
          Mailtype: "cargo",
        },
        showMessage: false,
      };
      const responseData = await postAPICall(
        userInput.url,
        userInput.payload,
        "POST",
        (value) => {
          if (value?.emails) {
            setData(value?.emails);
            setAllData(value?.emails);
            setCount(value?.totalCount);
            setLoading(false);

            setPagination({
              ...pagination,
              current: page,
              pageSize,
              total: value?.totalCount, // Assuming the response contains the total count of items
            });
          } else {
            setData([...data]);
            setCount(0);
            setLoading(false);
          }
        }
      );
    } catch (error) {
      //   dispatch(setSpinner(false));
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleTonnageFilter = async (number, size) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/cargo-filter?page=${
        number || page
      }&pageSize=${size || pageSize}`;

      // Clone the filters state to avoid direct mutation
      const updatedFilters = { ...filters };

      // Update cloned filters object immutably
      // Handle the case where cp_qty might be [[]]
      updatedFilters.cp_qty =
        filters.cp_qty.combined && filters.cp_qty.combined.length > 0
          ? [filters.cp_qty.combined]
          : [];

      // Update build_year, handle potential empty cases
      updatedFilters.build_year = filters.build_year || [];

      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };

      // Function to check if cp_qty has valid data
      const isValidCpQty = (cp_qty) => {
        return (
          cp_qty.min !== null || cp_qty.max !== null || cp_qty.combined !== null
        );
      };

      // Check if any filter has data
      const hasData = Object.entries(payload.query[0]).some(([key, field]) => {
        if (Array.isArray(field)) {
          // If the field is an array, check if it has any data
          return field.length > 0;
        } else if (typeof field === "object" && key === "cp_qty") {
          // If the field is cp_qty, check if it has valid values
          return isValidCpQty(field);
        } else {
          // Check for other types of fields (non-array, non-object)
          return field !== null && field !== undefined;
        }
      });
      console.log("payload.query[0]", payload.query[0])
      console.log("hasData", hasData);

      if (hasData) {
        setIsFilter(true);
        setLoading(true);

        // API call to fetch filtered data
        const responseData = await postAPICall(
          url,
          payload,
          "post",
          (value) => {
            if (value) {
              setFilteredData(value?.emails);
              setAllData(value?.emails);
              setCount(value?.totalCount);
              setLoading(false);
            }
          }
        );
      } else {
        // Reset to unfiltered data if no filters are applied
        if (childName !== "") {
          setAllData(data);
          fetchTonnageData(page, pageSize);
          setIsFilter(false);
          setLoading(false);
          return;
        }

        fetchTonnageData(page, pageSize);
        navigate(`/mails/all-cargo-list`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleSelectChange = (value, field) => {
    setPage(1);

    setFilters((prev) => ({
      ...prev,
      [field]: Array.isArray(value) ? value : [value], // Always store as array
    }));
  };

  const handleInputChange = (e, field) => {
    setPage(1);
    setDwtFilter((prev) => ({
      ...prev,
      cp_qty: { ...prev.cp_qty, [field]: e.target.value },
    }));
  };

  const handleDWTEnter = () => {
    const { min, max } = dwtFilter.cp_qty;

    // If both min and max are empty, clear the filter
    if (!min && !max) {
      setFilters((prev) => ({
        ...prev,
        cp_qty: [],
      }));
      return;
    }

    // Validation checks for missing values
    if (!min) {
      return openNotificationWithIcon(
        "info",
        "Please Fill the Min value first."
      );
    }

    if (!max) {
      return openNotificationWithIcon(
        "info",
        "Please Fill the Max value also."
      );
    }

    // Set filters when both min and max are filled
    setPage(1);
    setFilters((prev) => ({
      ...prev,
      cp_qty: {
        min,
        max,
        combined: `${min} - ${max}`,
      },
    }));
  };

  const onFiltername = (value) => {
    setFilterName(value);
  };

  const renderChip = (value, name) => {
    // Check if the value is an array and has items
    if (Array.isArray(value)) {
      return value.length ? (
        value.map((item) => (
          <Tag
            color="processing"
            key={item}
            closable
            onClose={() => handleRangeChipClose(item, name)}
          >
            {item}
          </Tag>
        ))
      ) : (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    }

    // If value is a string and not empty, render it as a single tag
    if (typeof value === "string" && value.trim()) {
      return (
        <Tag
          color="processing"
          key={value}
          closable
          onClose={() => handleRangeChipClose(value, name)}
        >
          {value}
        </Tag>
      );
    }

    if (filters.cp_qty.min && filters.cp_qty.max && filters.cp_qty.combined) {
      return (
        <Tag color="processing" key={filters.cp_qty.combined} closable>
          {filters.cp_qty.combined}
        </Tag>
      );
    }

    // If value is empty, render a fallback message
    return (
      <span style={{ fontSize: "12px", color: "lightgray" }}>
        No Active Filter
      </span>
    );
  };

  const getFilterValue = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/getCargomailtype`;
      const payload = {
        owner_email: "*",
      };
      const responseData = await postAPICall(url, payload, "post", (value) => {
        if (value) {
          setFilterInputs(value);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderDateRangeChip = (range) => {
    if (range && range.length === 2) {
      const formattedRange = `${dayjs(range[0]).format(
        "YYYY-MM-DD"
      )} to ${dayjs(range[1]).format("YYYY-MM-DD")}`;

      return <Tag color="processing">{formattedRange}</Tag>;
    } else if (typeof range[0] === "string") {
      <Tag color="processing">{range}</Tag>;
    } else if (range.length === 0) {
      return (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    } else {
      <span style={{ fontSize: "12px", color: "lightgray" }}>
        No Active Filter
      </span>;
    }
  };

  const handleSetApiFilter = (filterObj) => {
    // handleSetApiFilter(setFilters(value?.filter_set[0]))
    console.log("handleSetApiFilter ==> ", filterObj);

    // setIsChildListing(true);

    // setFilters(filterObj)

    setFilters((prev) => ({
      ...filterObj,
      cp_qty: filterObj?.cp_qty.length !== 0 ? { min: null, max: null, combined: filterObj?.cp_qty } : [],
    }));
  };

  console.log("FILLLTTERSSS", filters);
  

  const handleChildListing = async (child, page, pageSize) => {
    try {
      if (child) {
        const url = `${
          process.env.REACT_APP_MT_URL
        }voyage-estimate/get-cargo-paranet/${child}?pageSize=${
          pageSize || 50
        }&page=${page || 1}`;
        const payload = {};
        setLoadingNew(true);
        setIsChildListing(true);
        const responseData = await postAPICall(url, payload, "get", (value) => {
          if (value?.filter_data) {
            setChildData(value?.filter_data);
            setAllData(value?.filter_data);
            setChildrenData(value?.filter_data)
            // setApiFilters(value?.filter_set[0])
            handleSetApiFilter(value?.filter_set[0]);
            setCount(value?.totalCount);
            setLoadingNew(false);
          } else {
            setLoadingNew(false);
          }
        });
      }
    } catch (err) {
      console.error("Error fetching child listing:", err);
      setLoadingNew(false);
    }
  };

  useEffect(() => {
    // Extract lastKeyword dynamically when location changes
    const lastKeyword = location.pathname.split("/").pop();

    if (lastKeyword !== "all-cargo-list") {
      // Decode URI and remove all spaces, then lowercase the keyword
      const keyWord = decodeURIComponent(lastKeyword)
        .replace(/\s+/g, "")
        .toLowerCase();
      setChildName(keyWord);
      handleChildListing(keyWord);
      setIsChildListing(true);
      getFilterValue();
    } else {
      fetchTonnageData(page, pageSize);
      getFilterValue();
      setChildName("");
      setIsChildListing(false);
      setFilters({
        date: [],
        cargo_name: [],
        cargo_type: [],
        company_name: [],
        cp_qty: { min: null, max: null, combined: null },
        discharge_option: [],
        discharge_region: [],
        vessel_sub_type: [],
        laycan: [],
        load_option: [],
        load_region: [],
        processing: [],
        open_date: [],
        open_area: [],
        build_year: [],
        sender: [],
        tags: [],
      })
    }
  }, [location.pathname]);

  useEffect(() => {
    // alert(isChildListing)
    // if(!isChildListing){
    handleTonnageFilter();
    // }
  }, [filters]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (IsFilter) {
      handleTonnageFilter(pagination.current, pagination.pageSize);
      return;
    } else if (!isChildListing) {
      fetchTonnageData(pagination.current, pagination.pageSize);
      return;
    } else if (isChildListing) {
      handleChildListing(childName, pagination.current, pagination.pageSize);
      return;
    }
  };

  const handleRangeChipClose = (range, name) => {
    // console.log("before close", filters);
    setFilters((prev) => ({
      ...prev,
      [name]: prev[name].filter((date) => date !== range),
    }));
    // console.log("after close", filters)
  };

  const multipleRangeChip = (range, name) => {
    if (Array.isArray(range) && range.length > 0) {
      return range.map((rangeValue, index) => (
        <Tag
          color="processing"
          key={index}
          closable
          onClose={() => handleRangeChipClose(rangeValue, name)}
        >
          {rangeValue}
        </Tag>
      ));
    } else {
      return (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    }
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(new Date(dates[0])).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;

      if (value) {
        setFilters((prev) => ({
          ...prev,
          [value]: [...(prev[value] || []), dateRangeString],
        }));
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("oceanToken").split(".");
    if (token) {
      const userDetail = JSON.parse(atob(token[1]));
      setAssignedMails(userDetail.emailClients);
    }
  }, []);

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownload = () => {
    const csv = convertToCSV(allData);
    const csvName =
      typeof slug === "undefined" || slug === null ? "Tonnage" : slug;
    downloadCSV(csv, `${csvName}.csv`);
  };

  const emailAssignedContent = (
    <div>
      {assignedMails?.map((mail) => {
        return (
          <div style={{ padding: "4px", fontSize: "14px" }}>
            <Icon
              icon="mdi:email-outline"
              fontSize={16}
              style={{ color: "#1C5CE9" }}
            />
            <span style={{ paddingLeft: "4px" }}>{mail?.import_email}</span>
          </div>
        );
      })}
    </div>
  );

  const filterUpdate = async () => {
    try {
      setLoadingNew(true);
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/delete-cargo-by-category/${childName}`;
      const payload = {
        filter_set: [filters],
        filter_data: allData,
        url: childName,
        cargo_category_name: childName,
      };
      const responseData = await postAPICall(url, payload, "PUT", (value) => {
        if (value) {
          console.log("valuevaluevalue", value);
          if(value.status === '1'){
            openNotificationWithIcon("success", value?.msg)
            setLoadingNew(false);
          }else {
            openNotificationWithIcon("imfo", value?.msg)
            setLoadingNew(false);
          }
        }
      });
    } catch (err) {
      console.error("Error updating filter:", err);
      setLoadingNew(false);
    }
  };

  const deleteFilter = async() => {
    try{
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/delete-cargo-by-category/${childName}`;
      await postAPICall(url, {}, 'DELETE', (data) =>{
        if(data?.status === "1"){
          handleResetFilter()
          openNotificationWithIcon("success", data?.msg)
          navigate("/mails/all-cargo-list")
        }else {
          openNotificationWithIcon("info", data?.msg)
        }
      })
    } catch (err) {
      console.error("Error deleting filter:", err);
    }
  }

  const handleNewFilterName = (value) => {
    handleChangeFilterName(value?.filterName);
  };

  const handleChangeFilterName = async (value) => {
    try {
      const keyWord = decodeURIComponent(value).replace(/\s+/g, "").toLowerCase();
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/delete-cargo-by-category/${childName}`;
      const payload = {
        filter_set: [filters] ,
        filter_data: childrenData,
        url: keyWord,
        cargo_category_name: value,
      }
      await postAPICall(url, payload, 'PUT', (resp) => {
        console.log("handleNewFilterName", resp);
        if (resp.status === '1') {
          openNotificationWithIcon('success', resp.msg);
          setIsEditBox(false);
          filterUpdate();
          navigate(`/mails/all-cargo-list/${keyWord}`);
          // const respChild = getCargoChilds()
          // console.log("respChild", respChild)
          // dispatch(setCargoChildren(respChild?.data));
        }
      })
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }

  return (
    <div className="tonnage-cargo-table" style={{ padding: "10px" }}>
      <div className="tonnage-table-filter-box">
        <div className="table-responsive">
          <table className="tonnage-filter-table cargo">
            <thead>
              <tr>
                <th>Date Received</th>
                <th>Cargo Name</th>
                <th>Cargo Type</th>
                <th>Company Name</th>
                <th>Sender</th>
                <th>Cargo Qty [MT]</th>
                <th>Laycan</th>
                <th>Load Port</th>
                <th>Discharge Port</th>
                <th>Load Port Region</th>
                <th>Discharge Port Region</th>
                <th>Tags</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <RangePicker
                    disabledDate={disabledDate}
                    // onChange={(dates) => handleSelectChange(dates, "date")}
                    value={null}
                    onChange={(event, dateString) =>
                      onChange(event, "date", dateString)
                    }
                  />
                </td>
                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    showSearch
                    placeholder="Choose Cargo"
                    mode="multiple"
                    maxTagCount={0}
                    value={filters.cargo_name}
                    allowClear
                    onChange={(value) =>
                      handleSelectChange(value, "cargo_name")
                    }
                  >
                    {filterInputs?.unique_cargo_names?.map((vessel) => (
                      <Select.Option key={vessel}>
                        {vessel?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    mode="multiple"
                    maxTagCount={0}
                    value={filters.cargo_type}
                    placeholder="Choose Type"
                    showSearch
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "cargo_type")
                        : setFilters((prev) => ({
                            ...prev,
                            cargo_type: [],
                          }));
                    }}
                  >
                    {filterInputs?.unique_cargo_type_names?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    placeholder="Choose Company"
                    maxTagCount={0}
                    value={filters.company_name}
                    popupMatchSelectWidth={false}
                    mode="multiple"
                    showSearch
                    onChange={(value) =>
                      handleSelectChange(value, "company_name")
                    }
                  >
                    {filterInputs?.unique_company_name?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    placeholder="Choose Sender"
                    mode="multiple"
                    maxTagCount={0}
                    value={filters.sender}
                    popupMatchSelectWidth={false}
                    onChange={(value) => handleSelectChange(value, "sender")}
                  >
                    {filterInputs?.unique_sender_names?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <div style={{ display: "flex", gap: "7px" }}>
                    <Input
                      bordered
                      placeholder="Min"
                      onChange={(e) => handleInputChange(e, "min")}
                      // onPressEnter={handleDWTEnter}
                      style={{ minWidth: "80px" }}
                    />
                    <Input
                      bordered
                      placeholder="Max"
                      onChange={(e) => handleInputChange(e, "max")}
                      onPressEnter={handleDWTEnter}
                      onBlur={handleDWTEnter}
                      style={{ minWidth: "80px" }}
                    />
                  </div>
                </td>
                <td>
                  <RangePicker
                    // onChange={(dates) => handleSelectChange(dates, "laycan")}
                    onChange={(event, dateString) =>
                      onChange(event, "laycan", dateString)
                    }
                    disabledDate={disabledDate}
                    // onChange={(dates) => handleSelectChange(dates, "date")}
                    value={null}
                  />
                </td>

                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    maxTagCount={0}
                    value={filters.load_option}
                    mode="multiple"
                    placeholder="Choose Type"
                    showSearch
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "load_option")
                        : setFilters((prev) => ({
                            ...prev,
                            load_option: [],
                          }));
                    }}
                  >
                    {filterInputs?.unique_load_port_names?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>

                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    placeholder="Choose Type"
                    showSearch
                    mode="multiple"
                    maxTagCount={0}
                    value={filters?.discharge_option}
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "discharge_option")
                        : setFilters((prev) => ({
                            ...prev,
                            discharge_option: [],
                          }));
                    }}
                  >
                    {filterInputs?.unique_discharge_port?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>

                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    placeholder="Choose Load port Region"
                    showSearch
                    mode="multiple"
                    maxTagCount={0}
                    value={filters?.load_region}
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "load_region")
                        : setFilters((prev) => ({
                            ...prev,
                            load_region: [],
                          }));
                    }}
                  >
                    {filterInputs?.unique_load_port_area?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>

                <td>
                  <Select
                    style={{ minWidth: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    placeholder="Choose Discharge Port Region"
                    showSearch
                    mode="multiple"
                    maxTagCount={0}
                    value={filters?.discharge_region}
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "discharge_region")
                        : setFilters((prev) => ({
                            ...prev,
                            discharge_region: [],
                          }));
                    }}
                  >
                    {filterInputs?.unique_discharge_port_area?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>

                <td>
                  <Select
                    style={{ width: "150px" }}
                    placeholder="Choose Tags"
                    mode="multiple"
                    maxTagCount={0}
                    value={filters.tags}
                    popupMatchSelectWidth={false}
                    onChange={(value) => handleSelectChange(value, "tags")}
                  >
                    {filterInputs?.unique_load_port_area?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
              </tr>

              <tr>
                <td className="filter-results text-center">
                  {multipleRangeChip(filters.date, "date")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.cargo_name, "cargo_name")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.cargo_type, "cargo_type")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.company_name, "company_name")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.sender, "sender")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters?.cp_qty?.combined, "cp_qty")}
                </td>
                <td className="filter-results text-center">
                  {multipleRangeChip(filters.laycan, "laycan")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.load_option, "load_option")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.discharge_option, "discharge_option")}
                </td>

                <td className="filter-results text-center">
                  {renderChip(filters.load_region, "load_region")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.discharge_region, "discharge_region")}
                </td>

                <td className="filter-results text-center">
                  {renderChip(filters.tags, "tags")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Flex justify="space-between" align="center">
        <h1 style={{ fontSize: "22px", padding: "10px 16px" }}>Cargo List</h1>
        <Flex align="center" className="tonnage-table-upper-box">
          <Tooltip title="Email Assigned">
            <Popover
              trigger={["click", "hover"]}
              content={emailAssignedContent}
              placement="bottomRight"
            >
              <Button>
                <Icon
                  icon="ph:users"
                  className="mail-icon-color"
                  fontSize={20}
                />
              </Button>
            </Popover>
          </Tooltip>

          {IsFilter && childName?.length === 0 && (
              <>
                <Tooltip title="Reset Filter">
                  <Button onClick={() => handleResetFilter()}>
                    <Icon
                      icon="system-uicons:reset"
                      fontSize={20}
                      className="mail-icon-color"
                    />
                  </Button>
                </Tooltip>

                <Tooltip title={childName.length === 0 ? "Save this Filter" : 'Update this Filter'}>
                  <Button
                    onClick={() => {
                      if (childName.length === 0) {
                        setIsFilterSave(true);
                      } else {
                        filterUpdate();
                      }
                    }}
                  >
                    <Icon
                      icon="prime:save"
                      className="mail-icon-color"
                      fontSize={20}
                    />
                  </Button>
                </Tooltip>
              </>
            )}

            {childName.length > 0 && (
              <>
                <Tooltip title="Delete Filter">
                  <Button onClick={() => deleteFilter()}>
                    <Icon
                      icon="tabler:trash"
                      fontSize={20}
                      className="mail-icon-color"
                    />
                  </Button>
                </Tooltip>

                <Tooltip title="Edit Name">
                  <Button onClick={() => setIsEditBox(true)}>
                    <Icon
                      icon="tabler:edit"
                      fontSize={20}
                      className="mail-icon-color"
                    />
                  </Button>
                </Tooltip>
              </>
            )}


          <Tooltip title="Download List">
            <Button onClick={handleDownload}>
              <Icon
                icon="system-uicons:download"
                fontSize={20}
                className="mail-icon-color"
              />
            </Button>
          </Tooltip>
          <Tooltip title="All Cargo Analysis">
              <span
                className="analysis-button"
                onClick={() => {
                  setShowCargoAnalysisModal(true);
                }}
              >
                <Icon
                  icon="carbon:text-link-analysis"
                  fontSize={16}
                  className="mail-icon-color"
                />
                Analysis
              </span>
            </Tooltip>
          <div>
            <span style={{ fontWeight: "600", paddingLeft: "10px" }}>
              {formatNumber(count) + " results"}
            </span>
          </div>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        dataSource={allData}
        scroll={{ x: "max-content" }}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          current: page,
          pageSize: pageSize, // Number of items per page
          showSizeChanger: true, // Allow users to change page size
          pageSizeOptions: ["10", "20", "30", "50", "100"], // Page size options
          total: count, // Total number of items
          showTotal: (total) => `Total ${total} items`,
        }}
      />

      {/* Tonnage Mail */}
      <Modal
        width={800}
        open={tonnageMailOpen}
        onCancel={() => setTonnageMailOpen(false)}
        centered
        footer={false}
      >
        <CurrentMail mail={tonnageMail} />
      </Modal>

      <Drawer
        open={isBestMatch}
        className="bestmatch-drawer"
        style={{
          height: "100vh",
          overflowY: "hidden",
          minWidth: "600px",
          maxWidth: "600px",
          marginLeft: "auto",
        }}
        onClose={() => setIsBestMatch(false)}
        title={
          <>
            <span style={{ paddingRight: "8px" }}>Best Match</span>
            <Icon icon={"ri:shake-hands-line"} />
          </>
        }
      >
        <BestMatchDrawerContent currentMatch={currentBestMatch} cargo={true} />
      </Drawer>

      <Spin fullscreen spinning={loadingNew} />

      {/* VIEW IN FORM MODAL */}
      <Modal
        width={900}
        footer={false}
        open={isView}
        onCancel={() => setIsView(false)}
        centered
        title={viewDetail?.cargo?.toUpperCase() || "Cargo Details"}
      >
        <ViewCargoForm data={viewDetail} />
      </Modal>

      <Modal
        open={isFilterSave}
        centered
        title="Save Filter with a label."
        okText="Save"
        // onOk={() => handleSaveFilter()}
        onCancel={() => {
          setFilterName("");
          setIsFilterSave(false);
          form.resetFields();
        }}
      >
        {/* <Input onChange={(e) => onFiltername(e.target.value)} value={filterName} type="text" /> */}
        <Form form={form} onFinish={handleSaveFilter} layout="vertical">
          <Form.Item
            label="Add Filter Name"
            name="filterName"
            required
            rules={[
              {
                required: true,
                message: "Please input a filter name!",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "auto",
                display: "block",
                marginTop: "10px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      
      {/* Edit Filter Name Modal */}
      <Modal
        open={isEditBox}
        onCancel={() => setIsEditBox(false)}
        title="Edit the Filter Name"
        centered
        footer={false}
        className="new_filter_name_box"
      >
        <Form onFinish={handleNewFilterName} layout="vertical">
          <Form.Item
            label="New Filter Name"
            name="filterName"
            required
            rules={[
              {
                required: true,
                message: "Please input a filter name!",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "auto",
                display: "block",
                marginTop: "10px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {showCargoAnalysisModal && (
        <Modal
          // centered
          title="All Cargo Analysis"
          open={showCargoAnalysisModal}
          closable={true}
          onCancel={() => setShowCargoAnalysisModal(false)}
          footer={null}
          width={"85vw"}
          wrapClassName=""
          style={{ top:"20px" }}
        >
          {/* <div style={{ maxHeight: "500px", overflowY: "auto" }}> */}
            <CargoAnalysis />
          {/* </div> */}
        </Modal>
      )}

    </div>
  );
};

export default AllCargoList;
