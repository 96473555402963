import React, { useRef, useState, useCallback } from 'react';
import { AutoComplete, Spin, Tooltip } from 'antd';
import { getAPICall, IMAGE_PATH, openNotificationWithIcon } from '../../shared';
import debounce from 'lodash/debounce';
import { SearchOutlined } from '@ant-design/icons';

const SearchVesselInput = ({ state, setState, setVesselData, setIsLoading }) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const autoCompleteRef = useRef(null);

    const handleSearch = useCallback(debounce(async (newValue) => {
        if (newValue.length < 3) {
            setOptions([]);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            const response = await getAPICall(`${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${newValue}`);
            if (response && Array.isArray(response)) {
                const newOptions = response.map((item) => ({
                    value: item.SHIPNAME,
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={`${IMAGE_PATH}icons/ship.png`} alt="ship" style={{ width: 30, marginRight: 8 }} />
                            <div>
                                <strong>{item.SHIPNAME}</strong><br />
                                MMSI: {item.MMSI}<br />
                                IMO: {item.IMO}
                            </div>
                        </div>
                    ),
                    details: item,
                }));
                setOptions(newOptions);
            }
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }, 300), []);

    const handleChange = (newValue) => {
        setValue(newValue);
        handleSearch(newValue);
    };

    // const handleSelect = async (value, option) => {

    //     let details = option.details;
    //     try {
    //         setIsLoading(true);
    //         const addFleetResponse = await getAPICall(`${process.env.REACT_APP_MT_URL}marine/add-fleet/${details?.IMO}/${details?.SHIP_ID}`);
    //         if (addFleetResponse?.data === "fleet added") {
    //             const vesselDetailsResponse = await getAPICall(`${process.env.REACT_APP_MT_URL}marine/get-vessel-details/${details?.MMSI}/${details?.SHIP_ID}/${details?.SHIPNAME}/${details?.IMO}`);
    //             if (vesselDetailsResponse?.data) {
    //                 let reqData = { ...vesselDetailsResponse?.data }
    //                 const getVesselCode = await getAPICall(`${process.env.REACT_APP_URL}v1/vessel/generate/code?vessel_name=${vesselDetailsResponse?.data?.vessel_name}`);
    //                 if (getVesselCode?.data) {
    //                     reqData = { ...reqData, vessel_code: getVesselCode?.data?.vessel_code }
    //                 } else {
    //                     openNotificationWithIcon('info', getVesselCode?.message)
    //                 }
    //                 await setVesselData(reqData)
    //             } else if (vesselDetailsResponse?.error) {
    //                 openNotificationWithIcon('error', vesselDetailsResponse?.error)
    //                 setIsLoading(false);
    //             }
    //         } else if (addFleetResponse?.error) {
    //             openNotificationWithIcon('info', addFleetResponse?.error)
    //             setIsLoading(false);
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // };

    const handleSelect = async (value, option) => {
        let details = option.details;
    
        try {
            setIsLoading(true);
    
            const addFleetResponse = await getAPICall(`${process.env.REACT_APP_MT_URL}marine/add-fleet/${details?.IMO}/${details?.SHIP_ID}`);
            if (addFleetResponse?.data !== "fleet added") {
                openNotificationWithIcon('info', addFleetResponse?.error || "Failed to add fleet");
                setIsLoading(false);
                return;
            }
    
            const vesselDetailsResponse = await getAPICall(`${process.env.REACT_APP_MT_URL}marine/get-vessel-details/${details?.MMSI}/${details?.SHIP_ID}/${details?.SHIPNAME}/${details?.IMO}`);
            if (!vesselDetailsResponse?.data) {
                openNotificationWithIcon('error', vesselDetailsResponse?.error || "Failed to get vessel details");
                setIsLoading(false);
                return;
            }
    
            let reqData = { ...vesselDetailsResponse?.data };
            // const getVesselCode = await getAPICall(`${process.env.REACT_APP_URL}v1/vessel/generate/code?vessel_name=${vesselDetailsResponse?.data?.vessel_name}`);
            
            // if (getVesselCode?.data) {
            //     reqData.vessel_code = getVesselCode?.data?.vessel_code;
            // } else {
            //     openNotificationWithIcon('info', getVesselCode?.message || "Failed to generate vessel code");
            // }
    
            await setVesselData(reqData);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    return (

        <div style={{ position: "relative", lineHeight: "1" }}>
             <Tooltip title="Enter vessel name or IMO number">
            {/* <Tooltip title="Search & add vessel from Marine Traffic"> */}
                <AutoComplete
                    ref={autoCompleteRef}
                    value={value}
                    options={loading ? [] : options}
                    style={{ width: 300, borderRadius: '10px', overflow: 'hidden', border: "1px solid " }}
                    onSelect={handleSelect}
                    onSearch={handleChange}
                    onChange={handleChange}
                    placeholder="Search & add vessel from Marine Traffic"
                    // placeholder="Enter vessel name or IMO number"
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    notFoundContent={loading ? <div style={{ textAlign: "center;", padding: '8px', width: '50px', margin: '0 auto' }}><Spin size="small" /></div> : null}
                />
            </Tooltip>
            <div
                style={{
                    position: "absolute",
                    right: "0.1rem",
                    top: "45%",
                    transform: "translateY(-50%)",
                    color: "black",
                    lineHeight: "1",
                }}
            >
                <SearchOutlined />
            </div>
        </div>
    );
};

export default SearchVesselInput;
