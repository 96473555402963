import React, { Component } from "react";
import { Modal } from "antd";
import DownloadInvoice from "../chartering/routes/make-payment/DownloadInvoice";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  postAPICall,
  getAPICall,
  openNotificationWithIcon,
  objectToQueryStringFunc,
} from "../../shared";
import TdeReport from "../operation-reports/TdeReport";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Attachment from "../../shared/components/Attachment";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";

class Tde extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frmOptions: [],
      frmVisible: false,
      frmName: "tde_form",
      formData: this.props.formData || {},
      formdata1: {},
      receivablePayableType: this.props.receivablePayableType || undefined,
      invoiceType: this.props.invoiceType || undefined,
      popupFroms: this.props.popupFroms || {},
      showTDEForm: false,
      modals: {
        DownloadInvoice: false,
        isShowTdeReport: false,
        estimateID: undefined,
      },
      portDetails: this.props.PortExpense || null,
      VoyID: this.props.vendor,
      msg: "",
    };
  }

  componentDidMount = async () => {
    let vendor = [];
    //this.setState({...this.state,frmVisible:true})
    const {
      formData,
      receivablePayableType,
      invoiceType,
      portDetails,
      estimateID,
      match,
      showLeftBtn,
    } = this.state;

    // if (
    //   this.props &&
    //   this.props.hasOwnProperty("invoiceType") &&
    //   this.props.invoiceType == "FreightInvoice"
    // ) {
    //   if (
    //     formData &&
    //     formData.hasOwnProperty("freightcommission") &&
    //     formData.freightcommission.length > 1
    //   ) {
    //     this.setState({ frmVisible: false });
    //     // formData.freightcommission.map((i) => {
    //     //   vendor.push({
    //     //     id: i.broker,
    //     //     name: i.broker_name,
    //     //   });
    //     // });
    //     this.setState({
    //       frmOptions: [{ key: "vendor", data: vendor }],
    //     });
    //   }
    // }

    let request = undefined,
      response = undefined;
    let _formData = {};
    let respDataC = {},
      respData = {},
      fixData = null,
      fieldOptions = undefined,
      _showLeftBtn = Object.assign([], showLeftBtn);
    let amount = 0.0;
    let total_due = 0.0;
    let total = 0.0;

    if (invoiceType && invoiceType.toLowerCase() === "freightinvoice") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }

      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "tde") {
      formData["--"] = formData["--"]?.[0];
      formData["----"] = formData["----"]?.[0];

      var values =
        formData["invoice_amount"] && formData["invoice_amount"].split(",");
      if (values) {
        var a1 = values[0];
        var a2 = values[1];
        var a3 = a1 + a2;
        amount = parseFloat(a3);
      }

      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base.split(",");
              var v1 = values[0];
              var v2 = values[1];
              var value = v1 + v2;
              total += parseFloat(value);
            }
          });
        }

        total_due = parseFloat(amount) - total;

        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState({ ...this.state, formData: formData }, () =>
          this.setState({ ...this.state, frmVisible: true })
        );
      }
      //this.setState({ ...this.state, formData: formData }, () => this.setState({ ...this.state, frmVisible: true }));
    } else if (invoiceType && invoiceType.toLowerCase() === "port_expense") {
      let port_details = portDetails ? portDetails["port_call"] : "";
      let port_expense =
        port_details && portDetails["port_expense"] != -1
          ? portDetails["port_expense"]
          : null;
      if (port_expense != null) {
        if (port_expense["invoice_type"] == 209) {
          amount = `${port_expense["agreed_est_amt"]}`;
        } else if (port_expense["invoice_type"] == 208) {
          amount = `${port_expense["total_amt"]}`;
        }
      }
      if (
        formData["paymententrytable"] &&
        formData["paymententrytable"].length > 0
      ) {
        formData["paymententrytable"].map((p) => {
          if (p.payment_status == 208) {
            var values = p.amount_base.split(",");
            var v1 = values[0];
            var v2 = values[1];
            var value = v1 + v2;
            total = parseFloat(value);
          }
        });
      }
      total_due = amount - total;

      let isEdit = this.props.isEdit;
      if (isEdit === true) {
        if (formData["accounting"]) {
          formData["accounting"] = Object.assign(formData["accounting"], [
            {
              //'account' : formData['swift_code'],
              vessel_name: formData["vessel"],
              voyage: formData["voyage_manager_id"],
              ap_ar_acct: formData["ar_pr_account_no"],
              vessel_code: formData["vessel"],
              company: formData["bill_via"],
              amount: `${amount}`,
              description: `port-expense @ ${amount}`,
              port: "",
              lob: formData["lob"],
            },
          ]);
        }

        //formData['bill_via'] = 147
        formData["invoice_amount"] = amount;
        formData["account_base"] = amount;
        formData["--"] = { total: amount };
        //formData['inv_status'] = port_expense != null ?  port_expense['pda_adv_status']: 145
        formData["----"] = { total_due: total_due.toFixed(2), total: total };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        let _formData = {
          // port_id: portDetails["portid"],
          // //'invoice': portDetails['port_expense'] ? portDetails['port_expense']['invoice_type'] : 0,
          // invoice_date: portDetails["port_expense"]
          //   ? portDetails["port_expense"]["pda_inv_date"]
          //   : 0,
          invoice_no: formData["disburmnt_inv"],
          po_number: formData["po_no"],
          vessel: port_details
            ? port_details["vessel"]
            : formData["vessel_name"],
          voyage_manager_id: formData["voyage_manager_id"],
          ar_pr_account_no: formData["account_no"],
          vendor: formData["agent_full_name"],
          bill_via: formData["my_company_id"],
          invoice_type: portDetails
            ? portDetails["port_expense"]["invoice_type"]
            : 77,
          inv_status:
            port_expense != null ? port_expense["pda_adv_status"] : 145,
          invoice_amount: amount,
          account_base: amount,
        };
        _formData["accounting"] = [
          {
            //'account' : formData['swift_code'],
            vessel_name: formData["vessel_name"],
            voyage: port_details
              ? port_details["portcalldetails"]["voyage_number"]
              : formData["voyage_manager_id"],
            ap_ar_acct: formData["account_no"],
            vessel_code: formData["vessel_code"],
            company: formData["my_company_id"],
            amount: `${amount}`,
            description: `port-expense @ ${amount}`,
            port: "",
            lob: formData["lob"],
          },
        ];
        _formData["--"] = { total: amount };
        _formData["----"] = { total_due: total_due.toFixed(2), total: total };
        this.setState(
          { ...this.state, formData: _formData, formdata1: _formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (
      invoiceType &&
      invoiceType.toLowerCase() === "commission_invoice"
    ) {
      let isEdit = this.props.isEdit;
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }
      if (isEdit === true) {
        if (this.props["..."] && this.props["..."].length > 0) {
          formData["accounting"] = [];
          this.props["..."].map((e, ind) => {
            amount += parseFloat(e.commission_amount);
            formData["accounting"].push({
              //'account' : formData['swift_code'],
              vessel_name: formData["vessel"],
              voyage: formData["voyage_id_name"],
              ap_ar_acct: formData["ap_ar_acct"],
              vessel_code: formData["vessel"],
              company: formData["bill_via"],
              amount: `${e.commission_amount}`,
              description: e.description,
              lob: formData["lob"],
              port: "select port",
              id: -9e6 + ind,
            });
          });
        }
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status == 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["--"] = { total: amount };
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        delete formData["ap_ar_acct"];
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            //  formdata1: _formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        let new_array = [];
        if (formData["..."] && formData["..."].length > 0) {
          formData["..."].map((e, ind) => {
            total += parseFloat(e.commission_amount);
            let data = {
              account: formData["swift_code"],
              vessel_name: formData["vessel"],
              voyage: formData["voyage_id_name"],
              ap_ar_acct: formData["ap_ar_acct"],
              vessel_code: formData["vessel_code"],
              company: formData["bill_via"],
              amount: `${e.commission_amount}`,
              description: e.description,
              lob: formData["lob"],
              port: "select port",
              id: -9e6 + ind,
            };
            new_array.push(data);
          });
        }

        _formData = {
          invoice: formData["acc_type"],
          invoice_no: formData["invoice_no"],
          invoice_date: formData["due_date"],
          invoice_type: formData["invoice_type"],
          po_number: formData["po_number"],
          received_date: formData["received_date"],
          payment_term: formData["payment_terms"],
          vessel: formData["vessel"],
          vendor: formData["broker"],
          bill_via: formData["bill_via"],
          voyage_manager_id: formData["voyage_id"],
          payment_terms: formData["payment_terms"],
          inv_status: formData["com_status"],
          invoice_amount: total > 0 ? total : 0.0,
          account_base: total > 0 ? total : 0.0,
          ar_pr_account_no: formData["ap_ar_acct"],
          accounting: new_array,
        };
        _formData["--"] = { total: total > 0 ? total : 0.0 };
        _formData["----"] = {
          remittance_bank: formData["remittance_bank"],
          total_due: total > 0 ? total : 0.0,
          total: 0,
        };
        if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
          _formData["id"] = formData["id"];
        }
        this.setState(
          { ...this.state, formData: _formData, formdata1: _formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "tc-commission") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }
      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "claim-invoice") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }
      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0]
              // var v2 = values[1]

              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        // console.log("formData", formData);
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "portexpense") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }
      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0]
              // var v2 = values[1]
              var value = values;
              total += parseFloat(value);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "hire_payable") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }

      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (
      invoiceType &&
      invoiceType.toLowerCase() === "deviationinvoice"
    ) {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];
      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }

      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total = +parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "bunker-invoice") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values = formData["invoice_amount"];

      if (values) {
        // var a1 = values[0]
        // var a2 = values[1]
        // var a3 = a1 + a2
        amount = parseFloat(values);
      }

      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }
        total_due = parseFloat(amount) - total;
        formData["----"] = {
          ...formData["----"],
          total_due: total_due.toFixed(2),
          total: total,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          ...formData["----"],
          total_due: amount,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else if (invoiceType && invoiceType.toLowerCase() === "other-expense") {
      formData["--"] = { total: formData["invoice_amount"] };
      var values =
        formData["invoice_amount"] && formData["invoice_amount"].split(",");
      if (values) {
        // var a1 = values[0];
        // var a2 = values[1];
        // var a3 = a1 + a2;
        amount = parseFloat(values);
      }
      if (this.props.isEdit === true) {
        if (
          formData["paymententrytable"] &&
          formData["paymententrytable"].length > 0
        ) {
          formData["paymententrytable"].map((p) => {
            if (p.payment_status === 208) {
              var values = p.amount_base;
              // var v1 = values[0];
              // var v2 = values[1];
              // var value = v1 + v2;
              total += parseFloat(values);
            }
          });
        }

        total_due = parseFloat(amount) - total;
        formData["----"] = {
          total_due: total_due.toFixed(2),
          total: total,
          remittance_bank: formData.remittance_bank,
        };
        this.setState(
          {
            ...this.state,
            tde_id: formData["id"],
            formData: formData,
            formdata1: formData,
          },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      } else {
        formData["----"] = {
          total_due: amount,
          remittance_bank: formData.remittance_bank,
          total: total,
        };
        this.setState(
          { ...this.state, formData: formData, formdata1: formData },
          () => this.setState({ ...this.state, frmVisible: true })
        );
      }
    } else {
      if (formData && formData["vendor"]) {
        request = await getAPICall(
          `${URL_WITH_VERSION}/tde/address/bank?e=${formData.vendor}`
        );
        response = await request["data"];
      }
      this.setState(
        {
          ...this.state,
          formData: _formData,
          estimateID:
            estimateID ||
            (match && match.params && match.params.id
              ? match.params.id
              : undefined),
        },
        () => this.setState({ ...this.state, frmVisible: true })
      );
    }
  };

  TdeReport = async (showTdeReport) => {
    const responseReport = await getAPICall(
      `${URL_WITH_VERSION}/tde/report?e=${this.props.formData.id}`
    );
    const respDataReport = await responseReport["data"];

    if (responseReport) {
      this.setState({ ...this.state, reportFormData: respDataReport }, () =>
        this.setState({ ...this.state, isShowTdeReport: showTdeReport })
      );
    } else {
      openNotificationWithIcon("error", "Unable to show report", 5);
    }
  };

  showHideModal = (visible, modal) => {
    const { modals } = this.state;
    let _modal = {};
    _modal[modal] = visible;
    this.setState({
      ...this.state,
      modals: Object.assign(modals, _modal),
    });
  };

  editTdeData = async (id) => {
    try {
      this.setState({ ...this.state, frmVisible: false });

      const resp2 = await getAPICall(`${URL_WITH_VERSION}/tde/edit?e=${id}`);

      if (resp2 && resp2.data) {
        const setData = resp2.data;
        setData["--"] = { ...setData?.["--"]?.[0] };
        setData["----"] = { ...setData?.["----"]?.[0] };
        if (setData["paymententrytable"][0]["payment_status"] === 208) {
          let diff = setData["--"]["total"] - setData["----"]["total"];

          setData["----"] = {
            ...setData["----"],
            total_due: diff.toFixed(2),
          };

          this.setState({ ...this.state, formData: setData, frmVisible: true });
        } else {
          // setData["----"]["total_due"] = setData["account_base"];

          setData["----"] = {
            ...setData["----"],
            total_due: setData["account_base"],
          };

          this.setState({ ...this.state, formData: setData, frmVisible: true });
        }
      }
    } catch (err) {
      console.log("err", err);
    }

    console.log("formdataaaa",this.state.formData)
  };

  saveFormData = (data) => {
    // console.log("data", data);
    this.setState({ ...this.state, frmVisible: false });
    const { frmName } = this.state;
    let suURL = `${URL_WITH_VERSION}/tde/save?frm=${frmName}`;
    let suMethod = "POST";
    let total_pay = 0;
    data["voyage"] = data["voyage_manager_id"];
    data["paymententrytable"].map((p) => {
      total_pay += Number(p.amount_base);
    });
    data["total_pay"] = total_pay;
    postAPICall(suURL, data, suMethod, (data) => {
      // console.log("dataaaa", data);
      if (data && data.data) {
        openNotificationWithIcon("success", data.message, 2);

        this.setState({ ...this.state, frmVisible: true });
        if (this.props.isEdit === false) {
          // setTimeout(() => {
          //   this.props.saveUpdateClose(data.row);
          // }, 3000);
        }
        if (this.props.hasOwnProperty("modalCloseEvent")) {
          setTimeout(() => {
            this.props.modalCloseEvent();
          }, 3000);
        }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  updateFormData = (data) => {
    // console.log("data", data);
    const { frmName } = this.state;
    let suURL = `${URL_WITH_VERSION}/tde/update?frm=${frmName}`;
    let suMethod = "PUT";
    this.setState({ ...this.state, frmVisible: false });
    let total_pay = 0;
    data["voyage"] = data["voyage_manager_id"];
    data["paymententrytable"].map((p) => {
      total_pay += Number(p.amount_base);
    });
    data["total_pay"] = total_pay;
    postAPICall(suURL, data, suMethod, (data) => {
      // console.log("dattaaa", data);
      if (data && data.data) {
        this.setState((prev) => ({ ...prev, frmVisible: true }));
        openNotificationWithIcon("success", data.message);
        this.editTdeData(data.row.id);
        if (this.props.isEdit === true) {
          // setTimeout(() => {
          //   this.props.saveUpdateClose(data.row);
          // }, 3000);
          // this.props.saveUpdateClose();
        }

        if (this.props.hasOwnProperty("modalCloseEvent")) {
          setTimeout(() => {
            this.props.modalCloseEvent();
          }, 3000);
        }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  _onDeleteFormData = (postData) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => this.deleteFormData(postData),
    });
  };

  deleteFormData = async (data) => {
    const { frmName, modelVisible } = this.state;
    this.setState({ ...this.state, frmVisible: false });

    let delete_data = {
      id: data.id,
    };
    let suURL = `${URL_WITH_VERSION}/tde/delete`;
    let suMethod = "delete";

    postAPICall(suURL, delete_data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        if (this.props.isEdit === true) {
          this.setState({ modelVisible: !modelVisible });
          this.props.saveUpdateClose();
        }
        if (this.props.hasOwnProperty("deleteTde")) {
          this.props.deleteTde();
          this.props.saveUpdateClose();
        }
        if (this.props.hasOwnProperty("modalCloseEvent")) {
          this.props.modalCloseEvent();
        }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  resetFromData = () => {
    const { modelVisible, invoiceType, formdata1 } = this.state;
    if (
      invoiceType.toLowerCase() === "tde" ||
      (formdata1.hasOwnProperty("trans_no") && formdata1["trans_no"] != "")
    ) {
      openNotificationWithIcon("info", "Sorry, You Cannot reset it", 3);
    } else {
      this.setState({
        msg: `Are you sure to reset - TDE Information`,
        modelVisible: !modelVisible,
      });
    }
  };

  resetform = () => {
    const { formdata1, invoiceType } = this.state;
    this.setState({ formData: Object.assign({}, formdata1) }, () =>
      this.setState({ frmVisible: true })
    );
  };

  onClickExtraIcon = async (action, data) => {
    let groupKey = action["gKey"];
    let frm_code = "tde_form";
    // console.log({ action });
    // console.log({ data });
    let delete_id = data && data.id;
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey.replace(/\s/g, "").toLowerCase(),
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  render() {
    const {
      frmName,
      frmOptions,
      formData,
      isShowTdeReport,
      tde_id,
      reportFormData,
      modelVisible,
      msg,
      frmVisible,
    } = this.state;

    const ShowAttachment = async (isShowAttachment) => {
      let loadComponent = undefined;
      const { id } = this.state.formData;

      if (id && isShowAttachment) {
        const attachments = await getAttachments(id, "EST");
        const callback = (fileArr) =>
          uploadAttachment(fileArr, id, "EST", "port-expense");
        loadComponent = (
          <Attachment
            uploadType="Estimates"
            attachments={attachments}
            onCloseUploadFileArray={callback}
            deleteAttachment={(file) =>
              deleteAttachment(file.url, file.name, "EST", "port-expense")
            }
            tableId={0}
          />
        );
        this.setState((prevState) => ({
          ...prevState,
          isShowAttachment: isShowAttachment,
          loadComponent: loadComponent,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isShowAttachment: isShowAttachment,
          loadComponent: undefined,
        }));
      }
    };

    return (
      <div className="modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              {frmVisible ? (
                <NormalFormIndex
                  key={"key_" + frmName + "_0"}
                  formClass="label-min-height"
                  formData={formData}
                  showForm={true}
                  frmCode={frmName}
                  addForm={true}
                  frmOptions={frmOptions}
                  showButtons={
                    [
                      // { "id": "cancel", "title": "Reset", "type": "danger" },
                      // { "id": "save", "title": "Save", "type": "primary", "event": (data) => { this.saveFormData(data) } }
                    ]
                  }
                  showToolbar={[
                    {
                      isLeftBtn: [
                        {
                          key: "s1",
                          isSets: [
                            // { id: "0", key: "search", type: <SearchOutlined />, withText: "", event: (key, data) => console.log("data") },
                            {
                              id: "1",
                              key: tde_id === undefined ? "save" : "update ",
                              type: <SaveOutlined />,
                              withText:
                                tde_id === undefined ? "Save" : "Update ",
                              showToolTip: true,
                              event: (key, data) =>
                                tde_id === undefined
                                  ? this.saveFormData(data)
                                  : this.updateFormData(data),
                            },
                            {
                              id: "2",
                              key: formData.trans_no ? "delete" : "reset",
                              type: <DeleteOutlined />,
                              // type: formData.trans_no ? "delete" : "reload",
                              withText: formData.trans_no ? "Delete" : "Reload",
                              showToolTip: true,
                              event: (key, data) => {
                                formData.trans_no
                                  ? this._onDeleteFormData(data)
                                  : this.resetFromData(data);
                              },
                            },
                          ],
                        },
                      ],
                      isRightBtn: [
                        {
                          key: "s2",
                          isSets: [
                            // { key: "pay_current_invoice", isDropdown: 0, withText: "Pay Current Invoice", type: "", menus: null, "event": (key) => { console.log(key); } },
                            // { key: "attachments", isDropdown: 0, withText: "Attachments", type: "", menus: null, "event": (key) => { console.log(key); } },
                            // { key: "reverse", isDropdown: 0, withText: "Reverse", type: "", menus: null, "event": (key) => { console.log(key, true); } },
                            // {
                            //   key: 'report',
                            //   isDropdown: 0,
                            //   withText: 'Report',
                            //   type: '',
                            //   menus: null,
                            //   event: key => '',
                            // },
                            {
                              key: "attachment",
                              isDropdown: 0,
                              withText: "Attachment",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                data &&
                                data.hasOwnProperty("id") &&
                                data["id"] > 0
                                  ? ShowAttachment(true)
                                  : openNotificationWithIcon(
                                      "info",
                                      "Please save the Invoice First.",
                                      3
                                    );
                              },
                            },
                          ],
                        },
                      ],
                      isResetOption: false,
                    },
                  ]}
                  inlineLayout={true}
                  isShowFixedColumn={["Accounting", "Payment Entry Table"]}
                  tableRowDeleteAction={(action, data) =>
                    this.onClickExtraIcon(action, data)
                  }
                />
              ) : undefined}
            </div>
            <div className="box-body" />
          </div>
        </article>

        <Modal
          style={{ top: "2%" }}
          title="Download Invoice"
          open={this.state.modals["DownloadInvoice"]}
          onCancel={() => this.showHideModal(false, "DownloadInvoice")}
          width="90%"
          footer={null}
        >
          <DownloadInvoice />
        </Modal>

        {isShowTdeReport ? (
          <Modal
            style={{ top: "2%" }}
            title="Report"
            open={isShowTdeReport}
            onOk={this.handleOk}
            onCancel={() => this.TdeReport(false)}
            width="95%"
            footer={null}
          >
            <TdeReport data={reportFormData} />
          </Modal>
        ) : undefined}
        {this.state.isShowAttachment ? (
          <Modal
            style={{ top: "2%" }}
            title="Upload Attachment"
            open={this.state.isShowAttachment}
            onCancel={() => ShowAttachment(false)}
            width="50%"
            footer={null}
          >
            {this.state.loadComponent}
          </Modal>
        ) : undefined}

        {modelVisible ? (
          <Modal
            title="Confirmation"
            open={modelVisible}
            onOk={() => {
              this.setState(
                { frmVisible: false, modelVisible: !modelVisible },
                () => this.resetform()
              );
            }}
            onCancel={() => this.setState({ modelVisible: !modelVisible })}
          >
            <p>{msg}</p>
          </Modal>
        ) : undefined}
      </div>
    );
  }
}

export default Tde;
