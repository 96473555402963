import React from "react";

export const VoyageEstimateCalcArr = [
  {
    title: "Freight",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Freight Commission",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Misc. Revenue",
    formula: "Sum of (Extra Rev)",
  },
  {
    title: "Demurrage Amt",
    formula: "Sum of (Demurrage in Port Itinerary)",
  },
  {
    title: "Demm Comm Amt",
    formula: "Sum of (Total Demurrage* B.comm%)",
  },
  {
    title: "Despatch Amt",
    formula: "Sum of (Despatch in Port Itinerary)",
  },
  {
    title: "Gross Revenue",
    formula: "Freight + Misc. Revenue + Despatch - Demurrage",
  },

  {
    title: "Net Revenue",
    formula: "Gross Revenue - Freight Commission - Demm Comm Amt",
  },

  {
    title: "Revenue",
    formula: "Gross Revenue - Freight Commission - Demm Comm Amt",
  },

  {
    title: "Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate * Total Voyage Days",
  },

  {
    title: "TCI Address Commission",
    formula: "Vessel Expenses *Add Comm.%",
  },

  {
    title: "TCI Bro Commission",
    formula: "Vessel Expenses *Broker Comm.%",
  },

  {
    title: "Port Expenses",
    formula: "Sum of (Port Expense in Port Itinerary)",
  },

  {
    title: "Emmission Expenses",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "Misc. Expenses (Other Cost + Ballast Bonus)",
    formula: "(Sum of Othercost) + Ballast Bonus",
  },

  //Bunker Expenses

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA + Port Consp +TC Bunker Adjustment",
  },

  {
    title: "Gross Expenses",
    formula:
      "Vessel Expenses + Misc. Expenses + Port Expenses + Bunker Expenses +Emmission Expenses",
  },

  {
    title: "Net Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  {
    title: "TCE Hire ( Net Daily )",
    formula:
      "(Net Revenue - Gross Expenses + Vessel Expenses + Misc. Expenses + TCI Add. Comm.) / Total Voyage Days",
  },

  {
    title: "Gross TCE",
    formula: "TCE Hire/(1-Add Comm.%)",
  },

  {
    title: "Average Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  {
    title: "Breakeven & Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
];

export const TCEstimateCalcArr = [
  {
    title: "Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  {
    title: "Gross TCO Hire $",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Gross TCO Hire B. Comm",
    formula: "Sum of (Daily Hire * Duration*B.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO Hire Add Comm",
    formula: "Sum of (Daily Hire * Duration*A.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross TCO BB Comm",
    formula: "Sum of (Ballast Bonus*(A.comm% + B.Comm%)) in TCO Term",
  },

  {
    title: "Net Ballast Bonus",
    formula: "Gross TCO BB-Gross TCO BB Comm",
  },

  {
    title: "Total TCO Net Hire",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Total Net TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross Revenue",
    formula: "Gross TCO Hire $ + Gross TCO BB + Misc. Revenue",
  },

  {
    title: "Net Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  /// expenses---

  {
    title: "Expenses",
    formula: "Gross Expenses - (TCI Add. Comm. + TCI Bro. Comm.)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "TCI Hire Expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "TCI Add. Comm.",
    formula: "TCI Hire Expenses *Add Comm.% ",
  },

  {
    title: "TCI Bro. Comm.",
    formula: "TCI Hire Expenses *Broker% ",
  },

  {
    title: "TCI BB Comm",
    formula: "Ballast Bonus*Broker%",
  },

  {
    title: "TCI Ballst Bonus",
    formula: "Ballast Bonus",
  },

  {
    title: "Port Expenses",
    formula: "Sum of (P.EXP) in Port Itinerary",
  },

  {
    title: "Emission Expense",
    formula: "Sum of (Co2 Price Per MT * Total EU ETS (MT)) in Euets",
  },

  {
    title: "Other Expenses",
    formula: "Other Cost",
  },

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA +Port Consp +TC Bunker Adjustment",
  },

  // Gross Expenses

  {
    title: "Gross Expenses",
    formula:
      "Vessel expenses +Port Expenses +Bunker Expenses +Other Expenses + Emission Expense",
  },

  {
    title: "Net Expenses",
    formula: "Gross Expenses-(TCI Add. Comm. + TCI Bro. Comm.)",
  },

  //

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
];

export const VoyageReletCalcArr = [
  {
    title: "Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  {
    title: "Freight (Cargo )",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in sell Cargo",
  },

  {
    title: "VC Cargo Commision",
    formula:
      "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in sell Cargo",
  },

  {
    title: "Other Revenue",
    formula: "Sum of (Extra Rev) in sell Cargo",
  },

  {
    title: "Gross Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  {
    title: "Net Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  // expenses:Expenses

  {
    title: "Expenses",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
  },

  {
    title: "VC Frieght",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
  },

  {
    title: "VC Frieght Commission",
    formula:
      "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in Purchase Cargo",
  },

  {
    title: "Other Expenses",
    formula: "Other Cost  + Ballast Bonus",
  },

  {
    title: "Gross Expenses",
    formula: "VC Frieght + (Other Cost  + Ballast Bonus)",
  },

  {
    title: "Net Expenses",
    formula: " Gross Expenses - VC Frieght Commission;",
  },

  // yoyage result

  {
    title: "Voyage Result",
    formula: "Net Revenue- Net Expenses",
  },
  {
    title: "Profit (Loss)",
    formula: "Net Revenue- Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue- Net Expenses)/Total Voyage Days",
  },
];

const ViewCalculation = ({ desc }) => {
  return (
    <div style={{ top: 100 }}>
      <p>{desc}</p>
    </div>
  );
};

export default ViewCalculation;
