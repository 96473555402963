import React, { useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Row,
  Col,
  Select,
  Input,
  Tooltip,
  Pagination,
  Button,
  Menu,
  Modal,
  Dropdown,
  DatePicker,
} from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import TOOLBARCONFIGURATION from "../../constants/toolbar-configuration";
import {
  DownOutlined,
  DownloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router";

const Option = Select.Option;
const { RangePicker } = DatePicker;

function withRouter(Component) {
  return function Wrapper(props) {
    const location = useLocation();

    useEffect(() => {}, [location]);

    return <Component {...props} location={location} />;
  };
}
class ToolbarUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeUrl: null,
      search: { searchOptions: "all", searchValue: "" },
      dowloadOptions: this.props.dowloadOptions || [],
      showRangePicker: false,
      selectedRange: null,
    };
    this.onClickButton = _.debounce(this.onClickButton, 1000);
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    try {
      let pageOptions = {};
      let dropdownColumns = [{ label: "All", value: "all" }];
      let allOptions = "";
      if (props && props.routeUrl && !state.routeUrl) {
        pageOptions = TOOLBARCONFIGURATION[props.routeUrl]
          ? TOOLBARCONFIGURATION[props.routeUrl]
          : {};

        pageOptions["isCenterSection"] = Object.assign(
          pageOptions["isCenterSection"],
          props &&
            props.hasOwnProperty("optionValue") &&
            props["optionValue"].hasOwnProperty("pageOptions")
            ? props.optionValue.pageOptions
            : {}
        );
        pageOptions["isLeftSection"].forEach((e) => {
          //e.isDropdownOption[0]['value'] = ''
          if (
            e.hasOwnProperty("isDropdown") &&
            e.hasOwnProperty("isDropdownOption") &&
            e.isDropdown === 1 &&
            e?.isDropdownOption?.length > 0
          ) {
            e.isDropdownOption.forEach((loc) => {
              allOptions =
                (allOptions !== "" ? allOptions + ";" : "") + loc.value;
              //dropdownColumns.push({'label': loc.label, 'value': loc.value})
            });
            //dropdownColumns[0]['value'] = allOptions
            e.isDropdownOption[0]["value"] = allOptions;
            e.defaultDropdownValue = allOptions;
          } else {
            props.optionValue.columns.forEach((poc) => {
              if (
                poc.hasOwnProperty("dataIndex") &&
                poc.dataIndex !== "action"
              ) {
                allOptions =
                  (allOptions !== "" ? allOptions + ";" : "") + poc.dataIndex;
                dropdownColumns.push({
                  label: poc.title,
                  value: poc.dataIndex,
                });
              } else if (poc.hasOwnProperty("key") && poc.key !== "action") {
                allOptions =
                  (allOptions !== "" ? allOptions + ";" : "") + poc.key;
                dropdownColumns.push({ label: poc.title, value: poc.key });
              }
            });

            dropdownColumns[0]["value"] = allOptions;
            e.defaultDropdownValue = allOptions;
            e.isDropdownOption = dropdownColumns;
          }
        });

        return {
          routeUrl: props.routeUrl,
          tcArray: pageOptions,
          search: props.optionValue.search ? props.optionValue.search : {},
        };
      }
      return null;
    } catch (err) {}
  }

  componentDidMount() {
    if (this.state.search.searchValue) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    //alert("sdsdssdsds");
    let currentProps = this.props;

    if (this.props.location.pathname !== prevProps.location.pathname) {
      // this.setState({ ...this.state, loading: true });
      // setTimeout(() => {
      //   this.setState({ ...this.state, loading: false });
      // }, 100);

      let pageOptions = TOOLBARCONFIGURATION[this.props.routeUrl]
        ? TOOLBARCONFIGURATION[this.props.routeUrl]
        : {};
      let arr = this.props.optionValue.column?.map((item) => ({
        label: item.title,
        value: item.title,
      }));
      pageOptions.isLeftSection[0].isDropdownOption = arr;
    }
  }

  handleDateChange = (dates, dateStrings) => {
    // let start = dateStrings[0];
    // let enddate = dateStrings[1];
    // Update the selected range in the state
    this.setState({
      selectedRange: dateStrings, // [startDate, endDate]
    });

    // Call the search function with the selected range
    const search = { ...this.state.search, searchValue: dateStrings };
    this.setState({ search }, () => {
      this.props.callback(this.state.search);
    });
  };

  onClickButton = (val, type) => {
    // console.log("val", val, "type", type);

    const { tcArray } = this.state;

    if (type === "searchOptions") {
      let search = this.state.search;
      let showRangePicker = false;
      if (
        val === "due_date" ||
        val === "invoice_date" ||
        val === "inv_date" ||
        val === "pda_inv_date" ||
        val === "fda_inv_date"
      ) {
        showRangePicker = true;
      }
      if (val.includes(";")) {
        search = { search, isAll: "All" };
      } else {
        search = { ...search, isAll: "" };
      }
      search["searchOptions"] = val;
      this.setState({
        ...this.state,
        search: search,
        showRangePicker,
      });
    } else if (type === "searchValue") {
      let search = this.state.search;
      search["searchValue"] = val;
      if (!search["searchOptions"] || search["searchOptions"] === "") {
        search["searchOptions"] =
          tcArray["isLeftSection"][0]["defaultDropdownValue"];
        search["isAll"] = "All";
      }

      this.setState({ ...this.state, search: search }, () => {
        this.props.callback(this.state.search);
      });
    } else {
      let opt = {
        actionName: type,
        actionVal: val,
        tcArray: tcArray,
        search: this.state.search,
      };
      if (type === "reset-serach") {
        this.setState(
          {
            ...this.state,
            search: { searchOptions: "all", searchValue: "", isAll: "All" },
          },
          () => {
            if (typeof this.props.callback === "function") {
              this.props.callback(opt);
            }
          }
        );
      } else if (typeof this.props.callback === "function") {
        this.props.callback(opt);
      }
    }
  };

  render() {
    const { routeUrl, tcArray, search, dowloadOptions } = this.state;
    // console.log("showrangepickerrr", this.state.showRangePicker);
    // console.log("tcArray", tcArray);
    let downloadOptionMenu = undefined;
    if (dowloadOptions && dowloadOptions.length > 0) {
      downloadOptionMenu = (
        <Menu>
          {dowloadOptions.map((e, i) => (
            <Menu.Item
              key={"menu-" + i}
              onClick={typeof e.event === "function" ? e.event : () => {}}
            >
              {e.title}
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    // console.log("tcArray.isLeftSection.....", tcArray.isLeftSection);
    return (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            {tcArray
              ? tcArray.isLeftSection.map((el, i) => {
                  if (tcArray.isLeftSection.length > 0) {
                    return (
                      <Row gutter={16} key={i}>
                        {el.isDropdown === 1 ? (
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Select
                              value={
                                search.isAll ||
                                search.searchOptions ||
                                el.defaultDropdownValue
                              }
                              onChange={(ev) =>
                                this.onClickButton(ev, "searchOptions")
                              }
                              popupMatchSelectWidth={false}
                            >
                              {el.isDropdownOption?.map((d, i) => {
                                return (
                                  <Option key={i} value={d.value}>
                                    {d.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        ) : undefined}
                        {this.state.showRangePicker == true ? (
                          <RangePicker
                            onChange={this.handleDateChange}
                            placeholder={["Start Date", "End Date"]}
                            style={{ width: "100%" }}
                          />
                        ) : // <RangePicker />
                        el.isInput === 1 ? (
                          <Col xs={10} sm={10} md={10} lg={12} xl={12}>
                            <Tooltip title="Please enter a value and hit enter key to search.">
                              <Input
                                ref={this.inputRef}
                                defaultValue={
                                  search.searchValue || el.defaultValue
                                }
                                placeholder="Please Enter"
                                allowClear
                                onChange={(ev) => {
                                  if (ev.target.value.length > 0) {
                                    this.onClickButton(
                                      ev.target.value,
                                      "searchValue"
                                    );
                                  } else if (ev.target.value.length <= 0) {
                                    this.onClickButton(
                                      ev.target.value,
                                      "reset-serach"
                                    );
                                  }
                                }}
                              />
                            </Tooltip>
                          </Col>
                        ) : undefined}
                      </Row>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
          </Col>

          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            {tcArray ? (
              tcArray.isCenterSection.isPagination === 1 ? (
                <Row
                  key={"pg-" + routeUrl}
                  type="flex"
                  align="middle"
                  justify="center"
                >
                  <span>
                    <b>Total :</b> {tcArray.isCenterSection.totalRows}
                  </span>

                  <Pagination
                    simple
                    defaultCurrent={tcArray.isCenterSection.pageIndex}
                    total={tcArray.isCenterSection.totalRows}
                    // total={
                    //   tcArray.isCenterSection.totalRows >= 0
                    //     ? tcArray.isCenterSection.totalRows
                    //     : 1
                    // }
                    defaultPageSize={tcArray.isCenterSection.pageLimit}
                    // defaultPageSize={
                    //   tcArray.isCenterSection.pageLimit > 0
                    //     ? tcArray.isCenterSection.pageLimit
                    //     : tcArray.isCenterSection.totalRows
                    // }
                    onChange={(ev) => this.onClickButton(ev, "pageIndex")}
                    showSizeChanger={false}
                  />
                  <Select
                    defaultValue={tcArray.isCenterSection.pageLimit.toString()}
                    style={{ width: 120 }}
                    onChange={(ev) => this.onClickButton(ev, "pageLimit")}
                  >
                    <Option value="10">10 Per Page</Option>
                    <Option value="20">20 Per Page</Option>
                    <Option value="30">30 Per Page</Option>
                    <Option value="40">40 Per Page</Option>
                    <Option value="100">100 Per Page</Option>
                    <Option value="500">500 Per Page</Option>
                    {/* <Option value="0">ALL</Option> */}
                  </Select>
                </Row>
              ) : undefined
            ) : undefined}
          </Col>

          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Row type="flex" justify="end">
              <div className="wrap-button">
                {typeof this.props.showGraph === "function" && (
                  <Button
                    id="graph"
                    onClick={() => {
                      if (typeof this.props.showGraph === "function") {
                        this?.props?.showGraph();
                      }
                    }}
                  >
                    <Tooltip title="Analytics">
                      <BarChartOutlined />
                    </Tooltip>
                  </Button>
                )}

                {tcArray
                  ? tcArray.isRightSection.map((el, i) => {
                      if (tcArray.isRightSection.length > 0) {
                        if (
                          this.props.routeUrl === "Vessel-schedule-list-toolbar"
                        ) {
                          return null;
                        }
                        if (this.props.routeUrl === "portdata-list-toolbar") {
                          return null;
                        }

                        return (
                          <span key={(i + 1).toString()}>
                            {el.buttonSet.map((set, j) => {
                              if (
                                dowloadOptions &&
                                dowloadOptions.length > 0 &&
                                set.key === "download"
                              ) {
                                return (
                                  <Tooltip key={j} title={set.tooltip}>
                                    <Dropdown overlay={downloadOptionMenu}>
                                      <Button>
                                        {set.type}&nbsp;
                                        {set.text ? set.text : undefined}
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  </Tooltip>
                                );
                              } else {
                                if (this.props.showcolumnfilter) {
                                } else {
                                  return (
                                    <Tooltip key={j} title={set.tooltip}>
                                      <Button
                                        size={set.size ? set.size : "default"}
                                        onClick={(ev) =>
                                          this.onClickButton("", set.key)
                                        }
                                      >
                                        {set.type}
                                        {set.text ? set.text : undefined}
                                      </Button>
                                    </Tooltip>
                                  );
                                }
                              }
                            })}
                          </span>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
              </div>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ToolbarUI);
