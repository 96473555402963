import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Popover,
  Select,
  Switch,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import * as moment from "moment";
import Cookies from "universal-cookie";
import {
  BunkerDetailsCalculation,
  findCpPassage,
  portfuelCons,
} from "../shared/NormalForm/normalhelper";
import URL_WITH_VERSION, {
  awaitPostAPICall,
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
  postAPICall,
} from "../shared/index";
import VesselTable from "./VesselTable";
import { updateThetableBYDate } from "./syncDate";
import {
  calculateAverageBunkerPlan,
  calculateFifoBunkerPlan,
  calculateLifoBunkerPlan,
} from "./utils";
import "../components/Layout/Tooltip/Tooltip.css";
import ToolTipPopover from "./ToolTipPopover";
import { findspeed } from "./changeVessel";

// import Fieldvalidateor  from '../shared/Validator';

const Option = Select.Option;
const GroupEle = Input.Group;
const cookies = new Cookies();
export class OriginalState {
  constructor(stateValue = {}) {
    this.frmState = "[]";
    this.state = JSON.stringify(stateValue);
  }
  setFormFields(formFields) {
    let ff = this.getFormFields();
    formFields.map((e) => {
      let ffe = ff.filter((e1) => e1["f_id"] === e["f_id"]);
      if (ffe.length === 0) ff.push(e);
    });
    this.frmState = JSON.stringify(ff);
  }
  getOriginalState = () => {
    return JSON.parse(this.state);
  };
  getFormFields = () => {
    return JSON.parse(this.frmState);
  };
}

class DynamicFormFields {
  constructor(reduxState, formName, originalState, cb, instanceBy = undefined) {
    this.groupItems = [];
    this.reduxState = reduxState;
    this.stateObject = {};
    this.firstEntry = true;
    this.instanceBy = instanceBy;
    this.formFields = undefined;
    this.formName = formName;
    this.originalState = originalState || new OriginalState();
    this.reduxState.subscribe(() => {
      cb(this.reduxState.getState()[formName]);
    });
    this.spdConsResponse = {};

    this.popoverVisible = false;
    this.vesselInfoDrawer = false;
    this.setPopoverVisible = (visible) => {
      this.popoverVisible = visible;
    };
  }
  Popover = true;

  setVesselInfoDrawerVisible = (visible) => {
    this.vesselInfoDrawer = visible;
  };

  getVesselInfoDrawerVisible = () => {
    return this.vesselInfoDrawer;
  };

  setStateObject = (stateObject) => {
    this.stateObject = stateObject;
  };

  getState = () => {
    return this.stateObject;
  };

  // Method to update the state immutably
  updateState = (newState) => {
    this.stateObject = { ...this.stateObject, ...newState };
  };

  // General method to handle updates for dynamic items
  handleGroupUpdate = (groupName, index, fieldName, value) => {
    const tempState = { ...this.getState() };
    // console.log(this.getGroupKey(groupName))
    let groupKey = this.getGroupKey(groupName);
    if (tempState.hasOwnProperty(groupKey)) {
      tempState[groupKey][index] = {
        ...tempState[groupKey][index],
        [fieldName]: value,
      };
      this.updateState(tempState);
    }
  };

  // togglepopover = (key) => {
  //   this.Popover =key;
  //   return this.Popover;
  // };

  getStateObject = (key, index = undefined, groupName = undefined) => {
    let returnVal = this.stateObject;
    let groupKey = this.getGroupKey(groupName);

    if (key && groupKey && this.stateObject.hasOwnProperty(groupKey)) {
      if (index >= 0) {
        returnVal = this.stateObject[groupKey][index][key];
      } else {
        returnVal = this.stateObject[groupKey][key];
      }
    } else if (key && this.stateObject.hasOwnProperty(key)) {
      returnVal = this.stateObject[key];
    } else if (key && !this.stateObject.hasOwnProperty(key)) {
      returnVal = null;
    }

    return returnVal;
  };

  setFormField = (formFields, updateState, cb = undefined) => {
    if (!this.formFields) {
      this.formFields = formFields;
    }

    if (cb && typeof cb === "function" && updateState === true) {
      cb(this.formFields);
    }
  };

  hasGroupKey = (groupName) => {
    let hasKey = true;
    let hKey = this.getGroupKey(groupName);

    if (hKey) {
      hasKey = this.groupItems.indexOf(hKey) >= 0;

      if (!hasKey) {
        this.groupItems.push(hKey);
      }
    }

    return hasKey;
  };

  resetGroupList = () => (this.groupItems = []);

  getGroupKey = (groupName = undefined) => {
    let key = undefined;
    if (groupName && groupName !== "") {
      key = this.groupItems[this.groupItems.length - 1];
      if (groupName) {
        groupName =
          typeof groupName === "object" &&
          groupName.hasOwnProperty("length") &&
          groupName.length > 0
            ? groupName[0]
            : groupName;
        key = groupName
          .replaceAll(" ", "")
          .replaceAll("_", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("/", "")
          .toLowerCase();
      }
    }
    return key;
  };

  getFieldValue = (
    groupName,
    fieldName,
    fieldType,
    index = -1,
    defaultValue = undefined,
    row
  ) => {
    try {
      let gKey = this.getGroupKey(groupName);

      let value = this.stateObject[fieldName] || defaultValue || undefined;

      if (gKey && this.stateObject.hasOwnProperty(gKey) && index < 0) {
        value = this.stateObject?.[gKey]?.[fieldName];
      } else if (gKey && this.stateObject.hasOwnProperty(gKey) && index > -1) {
        value = "";
        if (
          this.stateObject.hasOwnProperty(gKey) &&
          this.stateObject[gKey] &&
          this.stateObject[gKey].hasOwnProperty("length") &&
          this.stateObject[gKey].length > 0 &&
          this.stateObject[gKey][index]
        ) {
          value = this.stateObject[gKey][index][fieldName];
        }
      }
      if (
        fieldType &&
        fieldType !== "" &&
        value &&
        value !== "" &&
        (fieldType === "date" ||
          fieldType === "time" ||
          fieldType === "datetime")
      ) {
        if (value === "now") {
          value = dayjs();
        } else if (value === "0000:00:00" || value == "0000-00-00 00:00:00") {
          value = null;
        } else {
          if (
            value !== "0000-00-00 00:00:00" &&
            value !== "0000-00-00 00:00" &&
            value !== "0000-00-00" &&
            value !== "Invalid date"
          ) {
            value = dayjs(value);
          } else {
            value = null;
          }
        }
      }

      if (fieldType === "boolean") {
        // let df =
        //   defaultValue &&
        //   (defaultValue === "1" || defaultValue === 1 || defaultValue === true);
        // value =
        //   (value && (value === "1" || value === 1 || value === true)) || df;
        const isTruthy = (v) => v === "1" || v === 1 || v === true;
        const df = isTruthy(defaultValue);
        value = isTruthy(value);
      }

      if (fieldName == "vessel_id") {
        let vname = row?.f_dyc_extras?.options?.filter(
          (el) => el.id == value
        )?.[0]?.["vessel_name"];
        // console.log('vname',vname)

        value = vname ? vname : value;

        // this.togglepopover(false)
      }

      if (fieldName == "my_company_lob" || "my_company" || "vessel_owner") {
        let cname = row?.f_dyc_extras?.options?.filter(
          (el) => el.id == value
        )?.[0]?.["name"];

        value = cname ? cname : value;
      }

      if (!value && fieldType !== "boolean") {
        value = defaultValue;
      }

      return value;
    } catch (err) {
      console.log("err", err);
    }
  };

  deleteRow = (editPart) =>
    this.reduxState.dispatch({
      type: "delete",
      groupName: this.getGroupKey(editPart.gKey),
      index: editPart.index,
    });

  disabledDate = (current, row, index) => {
    try {
      let isDisabled = false;

      if (row.f_evt && row.f_evt !== "" && row.f_evt !== null) {
        let f_event = row.f_evt;
        if (typeof row.f_evt === "string") {
          f_event = JSON.parse(row.f_evt);
        }

        if (
          f_event.hasOwnProperty("disableDate") &&
          f_event["disableDate"]["amar"] == "amar"
        ) {
          let fev = f_event["disableDate"]["fieldName"].split("< ")[1];
          let date = moment(this.stateObject[fev]);
          isDisabled = current && current < date.endOf("day");
        }

        if (
          f_event.hasOwnProperty("disableDate") &&
          f_event["disableDate"] === "< now"
        ) {
          isDisabled =
            current && current < moment().subtract(1, "days").endOf("day");
        } else if (
          f_event.hasOwnProperty("disableDate") &&
          f_event["disableDate"] === "> now"
        ) {
          isDisabled =
            current && current > moment().add(1, "days").endOf("day");
        } else if (
          f_event.hasOwnProperty("disableDate") &&
          typeof f_event["disableDate"] === "object" &&
          f_event["disableDate"].hasOwnProperty("fieldName")
        ) {
          if (
            f_event["disableDate"].hasOwnProperty("group") &&
            f_event["disableDate"]["group"] !== ""
          ) {
            let gKey = this.getGroupKey(f_event["disableDate"]["group"]);
            if (
              f_event["disableDate"].hasOwnProperty("sameIndex") &&
              f_event["disableDate"]["sameIndex"] === true
            ) {
              if (f_event["disableDate"]["fieldName"].indexOf("< ") >= 0) {
                let fev = f_event["disableDate"]["fieldName"].split("< ")[1];
                let date = this.isValidDate(
                  this.stateObject?.[gKey]?.[index]?.[fev]
                );
                date = date
                  ? moment(this.stateObject?.[gKey]?.[index]?.[fev])
                  : undefined;

                if (date && date?.toString() !== "Invalid Date") {
                  isDisabled = current && current < date.endOf("day");
                } else {
                  isDisabled = false;
                }
              }
            } else if (
              f_event["disableDate"].hasOwnProperty("index") &&
              f_event["disableDate"]["index"] <= -1 &&
              index > 0
            ) {
              if (f_event["disableDate"]["fieldName"].indexOf("< ") >= 0) {
                let fev = f_event["disableDate"]["fieldName"].split("< ")[1];
                let date = moment(
                  this.stateObject[gKey][
                    index + f_event["disableDate"]["index"]
                  ][fev]
                );
                if (date.toString() !== "Invalid Date") {
                  isDisabled = current && current < date.endOf("day");
                }
              }
            } else if (
              f_event["disableDate"].hasOwnProperty("index") &&
              f_event["disableDate"]["index"] >= 0 &&
              index > 0
            ) {
              if (f_event["disableDate"]["fieldName"].indexOf("< ") >= 0) {
                let fev = f_event["disableDate"]["fieldName"].split("< ")[1];
                let date = moment(this.stateObject[gKey][index][fev]);
                if (date.toString() !== "Invalid Date") {
                  isDisabled = current && current < date.endOf("day");
                }
              }
            }
          }
        } else if (f_event.hasOwnProperty("disableDate")) {
          let date = new Date(f_event["disableDate"]);
          if (date.toString() !== "Invalid Date") {
            isDisabled = current && current < moment(date).endOf("day");
          }
        }
      }
      return isDisabled;
    } catch (err) {
      console.log("err", err);
    }
  };

  isShowField = (row, index) => {
    let showField = true;
    let sv = this.stateObject;
    if (sv && JSON.stringify(sv) !== JSON.stringify({})) {
      if (row && row["group_name"] && row["group_name"] !== "" && index >= 0) {
        let g = this.getGroupKey(row["group_name"]);
        if (sv.hasOwnProperty(g) && !sv[g]) sv[g] = [];
        showField = !(
          sv.hasOwnProperty(g) &&
          sv[g][index] &&
          sv[g][index].hasOwnProperty("visiblefield") &&
          sv[g][index]["visiblefield"].length > 0 &&
          sv[g][index]["visiblefield"].indexOf(row.f_name) >= 0
        );
      } else if (
        row &&
        !row["group_name"] &&
        !index &&
        sv.hasOwnProperty("visiblefield") &&
        sv["visiblefield"].length > 0 &&
        sv["visiblefield"].indexOf(row.f_name) >= 0
      ) {
        showField = false;
      }
    }
    return showField && row != undefined;
  };

  getPlaceHolder = (fPlaceholder, fName, defaultValue) => {
    let returnValue = fPlaceholder;
    if (!returnValue) {
      returnValue = defaultValue;
      if (fName && fName !== "") {
        returnValue = fName;
      }
    }

    return returnValue;
  };

  handleButtonClick = (evt, row, record) => {
    this.onChangeEvent(evt, row, record.id, record);
    let currVessel = record.id;
    localStorage.setItem("currVessel", currVessel);
    localStorage.setItem("currentvessel1", currVessel);
  };

  handleCompanyChange = (evt, row, record) => {
    this.onChangeEvent(evt, row, record.id);
  };
  vesselname = null;

  popOverIds = [
    4539, 1078, 183, 3990, 2519, 909, 4541, 3998, 187, 2523, 436, 2857, 766, 37,
    2714, 1280,
  ];
  vesselTableIds = [4539, 1078, 183, 3990, 2519];
  companyTableIds = [
    909, 4541, 3998, 187, 2523, 436, 2857, 766, 37, 2714, 1280,
  ];

  emptyCell = (
    row,
    text,
    record,
    rowKey,
    showPrePost = true,
    showPopup = undefined
  ) => {
    let { editable } = record;

    let showField = this.isShowField(row, record.index);
    let columns = [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 100,
      },
      {
        title: "DWT",
        dataIndex: "vessel_dwt",
        key: "vessel_dwt",
        width: 100,
      },
      {
        title: "IMO no.",
        dataIndex: "imo_no",
        key: "imo_no",
        width: 100,
      },
      {
        title: "Ves. Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
      },
      {
        title: "Type Code",
        dataIndex: "type_code",
        key: "type_code",
        width: 100,
      },
      {
        title: "B.Speed",
        dataIndex: "spd_ballast",
        key: "spd_ballast",
      },
      {
        title: "L.Speed",
        dataIndex: "spd_laden",
        key: "spd_laden",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Button
            onClick={(evt) => {
              this.handleButtonClick(evt, row, record);
              this.vesselname = record.vessel_name;
              this.setVesselInfoDrawerVisible(true);
            }}
          >
            Select
          </Button>
        ),
      },
    ];

    let companyColumns = [
      {
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        width: "10rem",
      },
      {
        title: "Company Type",
        dataIndex: "address_type_name",
        key: "address_type_name",
        width: "16rem",
        render: (text) => (Array.isArray(text) ? text.join("/ ") : text),
      },
      {
        title: "Country",
        dataIndex: "country_name",
        key: "country_name",
        width: "8rem",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Button
            onClick={(evt) => {
              this.handleCompanyChange(evt, row, record);
            }}
          >
            Select
          </Button>
        ),
      },
    ];
    const isvesselFixed = JSON.parse(localStorage.getItem("fixedvessel"));

    // object to add popover table in another field
    const popoverTableList = {
      vessel: {
        ids: this.vesselTableIds,
        columns: columns,
        searchKeys: ["vessel_name", "imo_no", "type_code", "vessel_dwt"], // searchkeys to search on the basis of these keys in table
        isFixed: isvesselFixed,
        placement: "rightBottom",
      },
      company: {
        ids: this.companyTableIds,
        columns: companyColumns,
        searchKeys: ["name", "country_name", "address_type_name"],
        placement: "bottom",
        // isFixed: isvesselFixed,
      },

      // add more fields to add popover
    };

    // function to return the data needed to pass to popover table component
    const getPopoverTable = (id, isFixed) => {
      return (
        Object.values(popoverTableList).find(
          (data) => data.ids.includes(id) && data?.isFixed == null
        ) || null
      );
    };

    switch (row && row.f_type) {
      case "dropdown":
        let optionChildren = [],
          optionsNames = [];
        if (
          row.hasOwnProperty("f_dyc_extras") &&
          row.f_dyc_extras !== null &&
          row.f_dyc_extras !== "" &&
          typeof row.f_dyc_extras === "string"
        ) {
          row.f_dyc_extras = JSON.parse(row.f_dyc_extras);
        }

        if (
          row.hasOwnProperty("f_dyc_extras") &&
          row.f_dyc_extras !== null &&
          row["f_dyc_extras"].hasOwnProperty("options") &&
          row["f_dyc_extras"]["options"] &&
          row["f_dyc_extras"]["options"].length > 0
        ) {
          row["f_dyc_extras"]["options"].map((e, i) => {
            optionChildren.push(
              <Option key={e.name + i} value={e?.id?.toString()}>
                {e?.name}
              </Option>
            );
            optionsNames.push(e.name);
          });
        } else if (
          row.hasOwnProperty("f_dyc_extras") &&
          row.f_dyc_extras !== null &&
          row["f_dyc_extras"].hasOwnProperty("generateYear") &&
          row["f_dyc_extras"]["generateYear"] !== null &&
          row["f_dyc_extras"]["generateYear"] !== ""
        ) {
          let year = new Date().getFullYear();
          let minYear;
          if (row["f_dyc_extras"]["generateYear"].hasOwnProperty("minYear")) {
            minYear = year - row["f_dyc_extras"]["generateYear"]["minYear"];
          }

          for (let yr = minYear; yr <= year; yr++) {
            optionChildren.push(
              <Option key={"year-" + yr} value={yr}>
                {yr}
              </Option>
            );
          }
        }

        let dValue = this.getFieldValue(
          row.group_name,
          row.f_name,
          row.f_type,
          record.index,
          row.f_default
        );
        dValue = dValue ? dValue.toString() : dValue;
        let showText = "";
        if (row && row["f_dyc_extras"] && row["f_dyc_extras"]["options"]) {
          showText = row["f_dyc_extras"]["options"].filter((e) =>
            text && (text >= 0 || text !== "")
              ? e.id.toString() === text.toString()
              : false
          );
        }
        if (
          row &&
          row["f_dyc_extras"] &&
          row["f_dyc_extras"]["filteredList"] &&
          row["f_dyc_extras"]["filteredList"] !== "" &&
          this.stateObject.hasOwnProperty(row["f_dyc_extras"]["filteredList"])
        ) {
          optionChildren = [];
          row["f_dyc_extras"]["options"].map((e, i) => {
            if (
              e[row["f_dyc_extras"]["filteredList"]] * 1 ===
              this.stateObject[row["f_dyc_extras"]["filteredList"]] * 1
            ) {
              optionChildren.push(
                <Option key={e.name + i} value={e.id.toString()}>
                  {e.name}
                </Option>
              );
            }
          });
        }

        return (
          <>
            {this.popOverIds.includes(row.f_id) ? (
              <Popover
                placement={(() => {
                  const popTable = getPopoverTable(row.f_id);
                  return popTable ? popTable.placement : "rightBottom";
                })()}
                destroyTooltipOnHide={true}
                content={(() => {
                  // using IIFE to generate popover dynamically based on conditions
                  const popTable = getPopoverTable(row.f_id, isvesselFixed); // calling function here to check for id to put popover on that particular field
                  // console.log(popTable);

                  if (popTable) {
                    return (
                      <div style={{ height: "350px" }}>
                        <VesselTable
                          columns={popTable.columns}
                          data={row["f_dyc_extras"]["options"]}
                          searchKeys={popTable.searchKeys}
                        />
                      </div>
                    );
                  }
                  return null;
                })()}
              >
                {editable ? (
                  showField ? (
                    <Input
                      autocomplete="off"
                      showSearch
                      id={row.f_name}
                      value={this.getFieldValue(
                        row.group_name,
                        row.f_name,
                        row.f_type,
                        record.index,
                        row.f_default,
                        row
                      )}
                      placeholder={this.getPlaceHolder(
                        row.f_placeholder,
                        row.name,
                        "Select"
                      )}
                      style={this.getStyle(row)}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={(input, option) =>
                        input.length >= 3
                          ? ("" + option.props.children)
                              .toLowerCase()
                              .indexOf(("" + input).toLowerCase()) >= 0
                          : false
                      }
                      disabled
                      name={row.f_name}
                      dropdownMatchSelectWidth={false}
                      mode={
                        row["f_dyc_extras"] &&
                        row["f_dyc_extras"]["multiSelected"] &&
                        row["f_dyc_extras"]["multiSelected"] * 1 === 1
                          ? "multiple"
                          : "default"
                      }
                    />
                  ) : (
                    ""
                  )
                ) : showText && showText !== "" && showText.length === 1 ? (
                  showText[0].name
                ) : (
                  row.f_default || "N/A"
                )}
              </Popover>
            ) : row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                {editable ? (
                  showField ? (
                    <Select
                      //this is written for deselect the  dropdown for some fields

                      allowClear={JSON.parse(row?.f_classname || false)}
                      showSearch
                      value={dValue}
                      defaultValue={dValue}
                      id={row.f_name}
                      placeholder={this.getPlaceHolder(
                        row.f_placeholder,
                        row.name,
                        "Select"
                      )}
                      style={this.getStyle(row)}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={(input, option) =>
                        input.length >= 3
                          ? ("" + option.props.children)
                              .toLowerCase()
                              .indexOf(("" + input).toLowerCase()) >= 0
                          : false
                      }
                      onChange={(evt) =>
                        this.onChangeEvent(evt, row, record.index)
                      }
                      disabled={this.isDisableField(row, record.index)}
                      name={row.f_name}
                      dropdownMatchSelectWidth={false}
                      mode={
                        row["f_dyc_extras"] &&
                        row["f_dyc_extras"]["multiSelected"] &&
                        row["f_dyc_extras"]["multiSelected"] * 1 === 1
                          ? "multiple"
                          : "default"
                      }
                    >
                      {optionChildren}
                    </Select>
                  ) : (
                    ""
                  )
                ) : showText && showText !== "" && showText.length === 1 ? (
                  showText[0].name
                ) : (
                  row.f_default || "N/A"
                )}
              </ToolTipPopover>
            ) : editable ? (
              showField ? (
                <Select
                  //this is written for deselect the  dropdown for some fields
                  allowClear={JSON.parse(row?.f_classname || false)}
                  showSearch
                  value={dValue}
                  defaultValue={dValue}
                  id={row.f_name}
                  placeholder={this.getPlaceHolder(
                    row.f_placeholder,
                    row.name,
                    "Select"
                  )}
                  style={this.getStyle(row)}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={(input, option) =>
                    input.length >= 3
                      ? ("" + option.props.children)
                          .toLowerCase()
                          .indexOf(("" + input).toLowerCase()) >= 0
                      : false
                  }
                  onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
                  disabled={this.isDisableField(row, record.index)}
                  name={row.f_name}
                  dropdownMatchSelectWidth={false}
                  mode={
                    row["f_dyc_extras"] &&
                    row["f_dyc_extras"]["multiSelected"] &&
                    row["f_dyc_extras"]["multiSelected"] * 1 === 1
                      ? "multiple"
                      : "default"
                  }
                >
                  {optionChildren}
                </Select>
              ) : (
                ""
              )
            ) : showText && showText !== "" && showText.length === 1 ? (
              showText[0].name
            ) : (
              row.f_default || "N/A"
            )}
          </>
        );

      case "no-field":
        break;
      case "show-text":
        return (
          <>
            {row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                <div className="input-fields-layout" style={this.getStyle(row)}>
                  {this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                </div>
              </ToolTipPopover>
            ) : (
              <div className="input-fields-layout" style={this.getStyle(row)}>
                {this.getFieldValue(
                  row.group_name,
                  row.f_name,
                  row.f_type,
                  record.index,
                  row.f_default
                )}
              </div>
            )}
          </>
        );
      case "int":
      case "dec":
      case "number":
        return (
          <>
            {row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                {editable ? (
                  showField ? (
                    !showPrePost ||
                    (!row.f_prefix && !row.f_postfix && !rowKey) ? (
                      <input
                        key={
                          row.group_name + "-" + row.f_name + "-" + record.index
                        }
                        type="number"
                        className="input-number-field"
                        placeholder={this.getPlaceHolder(
                          row.f_placeholder,
                          row.name,
                          "Please enter " + row.name
                        )}
                        min={row.f_min_len > 0 ? row.f_min_len : 0}
                        max={row.f_max_len > 0 ? row.f_max_len : undefined}
                        disabled={this.isDisableField(row, record.index)}
                        value={
                          this.getFieldValue(
                            row.group_name,
                            row.f_name,
                            row.f_type,
                            record.index,
                            row.f_default
                          ) || ""
                        }
                        onChange={(evt) => {
                          let prevValue = this.getFieldValue(
                            row.group_name,
                            row.f_name,
                            row.f_type,
                            record.index,
                            row.f_default
                          );
                          window.AbstractInputLogTracker(
                            row,
                            evt,
                            prevValue,
                            record
                          );
                          this.onChangeEvent(evt, row, record.index);
                        }}
                        onBlur={(evt) =>
                          this.rowEvents(
                            row,
                            evt.hasOwnProperty("target")
                              ? evt.target.value
                              : evt,
                            record.index,
                            true
                          )
                        }
                        style={this.getStyle(row)}
                        id={
                          row.f_name +
                          (record.index !== undefined ? record.index : "")
                        }
                        onWheel={(evt) => evt.currentTarget.blur()}
                      />
                    ) : (row.f_prefix || row.f_postfix) && !rowKey ? (
                      <GroupEle compact>
                        {row.f_prefix ? (
                          <span className="ant-input-group-addon prefix-left">
                            {row.f_prefix}
                          </span>
                        ) : undefined}
                        <input
                          key={row.group_name + "-" + row.f_name}
                          type="number"
                          className="input-number-field"
                          placeholder={this.getPlaceHolder(
                            row.f_placeholder,
                            row.name,
                            "Please enter " + row.name
                          )}
                          min={row.f_min_len > 0 ? row.f_min_len : 0}
                          max={row.f_max_len > 0 ? row.f_max_len : undefined}
                          disabled={this.isDisableField(row, record.index)}
                          value={this.getFieldValue(
                            row.group_name,
                            row.f_name,
                            row.f_type,
                            record.index,
                            row.f_default
                          )}
                          onChange={(evt) => {
                            let prevValue = this.getFieldValue(
                              row.group_name,
                              row.f_name,
                              row.f_type,
                              record.index,
                              row.f_default
                            );
                            window.AbstractInputLogTracker(
                              row,
                              evt,
                              prevValue,
                              record
                            );
                            this.onChangeEvent(evt, row, record.index);
                          }}
                          onBlur={(evt) =>
                            this.rowEvents(
                              row,
                              evt.hasOwnProperty("target")
                                ? evt.target.value
                                : evt,
                              record.index,
                              true
                            )
                          }
                          style={this.getStyle(row, false, true)}
                          id={
                            row.f_name +
                            (record.index !== undefined ? record.index : "")
                          }
                          onWheel={(evt) => evt.currentTarget.blur()}
                        />
                        {row.f_postfix ? (
                          <span className="ant-input-group-addon prefix-right">
                            {row.f_postfix}
                          </span>
                        ) : undefined}
                      </GroupEle>
                    ) : text >= 0 || text !== "" ? (
                      text
                    ) : (
                      row.f_default || "0"
                    )
                  ) : (
                    ""
                  )
                ) : text >= 0 || text !== "" ? (
                  text
                ) : (
                  row.f_default || "0"
                )}
              </ToolTipPopover>
            ) : editable ? (
              showField ? (
                !showPrePost || (!row.f_prefix && !row.f_postfix && !rowKey) ? (
                  <input
                    key={row.group_name + "-" + row.f_name + "-" + record.index}
                    type="number"
                    className="input-number-field"
                    placeholder={this.getPlaceHolder(
                      row.f_placeholder,
                      row.name,
                      "Please enter " + row.name
                    )}
                    min={row.f_min_len > 0 ? row.f_min_len : 0}
                    max={row.f_max_len > 0 ? row.f_max_len : undefined}
                    disabled={this.isDisableField(row, record.index)}
                    defaultValue={row?.f_default}
                    value={
                      this.getFieldValue(
                        row.group_name,
                        row.f_name,
                        row.f_type,
                        record.index

                        // row.f_default
                      ) || ""
                    }
                    onChange={(evt) => {
                      let prevValue = this.getFieldValue(
                        row.group_name,
                        row.f_name,
                        row.f_type,
                        record.index,
                        row.f_default
                      );
                      window.AbstractInputLogTracker(
                        row,
                        evt,
                        prevValue,
                        record
                      );
                      this.onChangeEvent(evt, row, record.index);
                    }}
                    onBlur={(evt) =>
                      this.rowEvents(
                        row,
                        evt.hasOwnProperty("target") ? evt.target.value : evt,
                        record.index,
                        true
                      )
                    }
                    style={this.getStyle(row)}
                    id={
                      row.f_name +
                      (record.index !== undefined ? record.index : "")
                    }
                    onWheel={(evt) => evt.currentTarget.blur()}
                  />
                ) : (row.f_prefix || row.f_postfix) && !rowKey ? (
                  <GroupEle compact>
                    {row.f_prefix ? (
                      <span className="ant-input-group-addon prefix-left">
                        {row.f_prefix}
                      </span>
                    ) : undefined}
                    <input
                      key={row.group_name + "-" + row.f_name}
                      type="number"
                      className="input-number-field"
                      placeholder={this.getPlaceHolder(
                        row.f_placeholder,
                        row.name,
                        "Please enter " + row.name
                      )}
                      min={row.f_min_len > 0 ? row.f_min_len : 0}
                      max={row.f_max_len > 0 ? row.f_max_len : undefined}
                      disabled={this.isDisableField(row, record.index)}
                      value={this.getFieldValue(
                        row.group_name,
                        row.f_name,
                        row.f_type,
                        record.index,
                        row.f_default
                      )}
                      onChange={(evt) => {
                        let prevValue = this.getFieldValue(
                          row.group_name,
                          row.f_name,
                          row.f_type,
                          record.index,
                          row.f_default
                        );
                        window.AbstractInputLogTracker(
                          row,
                          evt,
                          prevValue,
                          record
                        );
                        this.onChangeEvent(evt, row, record.index);
                      }}
                      onBlur={(evt) =>
                        this.rowEvents(
                          row,
                          evt.hasOwnProperty("target") ? evt.target.value : evt,
                          record.index,
                          true
                        )
                      }
                      style={this.getStyle(row, false, true)}
                      id={
                        row.f_name +
                        (record.index !== undefined ? record.index : "")
                      }
                      onWheel={(evt) => evt.currentTarget.blur()}
                    />
                    {row.f_postfix ? (
                      <span className="ant-input-group-addon prefix-right">
                        {row.f_postfix}
                      </span>
                    ) : undefined}
                  </GroupEle>
                ) : text >= 0 || text !== "" ? (
                  text
                ) : (
                  row.f_default || "0"
                )
              ) : (
                ""
              )
            ) : text >= 0 || text !== "" ? (
              text
            ) : (
              row.f_default || "0"
            )}
          </>
        );
      case "date":
        return (
          <>
            {editable ? (
              showField ? (
                <DatePicker
                  allowClear
                  placeholder={this.getPlaceHolder(
                    row.f_placeholder,
                    row.name,
                    "" + row.name
                  )}
                  defaultValue={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index
                  )}
                  value={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  name={row.f_name}
                  onChange={(date, dateString) => {
                    let prevValue = this.getFieldValue(
                      row.group_name,
                      row.f_name,
                      row.f_type,
                      record.index,
                      row.f_default
                    );
                    window.AbstractDateLogtracker(row, date, prevValue, record);
                    this.onChangeEvent(dateString, row, record.index);
                  }}
                  disabled={this.isDisableField(row, record.index)}
                  disabledDate={(current) => {
                    return this.disabledDate(current, row, record.index);
                  }}
                  style={this.getStyle(row, true)}
                  format={row.f_format ? row.f_format : "YYYY-MM-DD"}
                />
              ) : (
                ""
              )
            ) : text >= 0 || text !== "" ? (
              text
            ) : (
              row.f_default
            )}
          </>
        );
      case "time":
        return (
          <>
            {editable ? (
              showField ? (
                <TimePicker
                  placeholder={this.getPlaceHolder(
                    row.f_placeholder,
                    row.name,
                    "" + row.name
                  )}
                  defaultValue={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  value={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index
                  )}
                  onChange={(date, dateString) => {
                    let prevValue = this.getFieldValue(
                      row.group_name,
                      row.f_name,
                      row.f_type,
                      record.index,
                      row.f_default
                    );
                    window.AbstractDateLogtracker(row, date, prevValue, record);
                    this.onChangeEvent(dateString, row, record.index);
                  }}
                  format={row.f_format ? row.f_format : "HH:mm"}
                  name={row.f_name}
                  showTime={{ format: "HH:mm" }}
                  disabled={this.isDisableField(row, record.index)}
                  style={this.getStyle(row, true)}
                />
              ) : (
                ""
              )
            ) : text >= 0 || text !== "" ? (
              text
            ) : (
              row.f_default
            )}
          </>
        );
      case "datetime":
        return (
          <>
            {editable ? (
              showField ? (
                <DatePicker
                  allowClear
                  defaultValue={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index
                  )}
                  showTime={{ format: "HH:mm" }}
                  format={row.f_format ? row.f_format : "YYYY-MM-DD HH:mm"}
                  placeholder={this.getPlaceHolder(
                    row.f_placeholder,
                    row.name,
                    "" + row.name
                  )}
                  value={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  onChange={(date, dateString) => {
                    let prevValue = this.getFieldValue(
                      row.group_name,
                      row.f_name,
                      row.f_type,
                      record.index,
                      row.f_default
                    );
                    window.AbstractDateLogtracker(row, date, prevValue, record);
                    this.onChangeEvent(dateString, row, record.index);
                  }}
                  onOk={(evt) =>
                    this.rowEvents(
                      row,
                      evt.hasOwnProperty("target") ? evt.target.value : evt,
                      record.index,
                      true
                    )
                  }
                  name={row.f_name}
                  id={row.f_name}
                  disabled={this.isDisableField(row, record.index)}
                  disabledDate={(current) => {
                    return this.disabledDate(current, row, record.index);
                  }}
                  //showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  style={this.getStyle(row, true)}
                />
              ) : (
                ""
              )
            ) : text >= 0 || text !== "" ? (
              text
            ) : (
              row.f_default
            )}
          </>
        );
      case "boolean":
        let fProperty = {};
        if (
          row.hasOwnProperty("f_dyc_extras") &&
          row.f_dyc_extras !== null &&
          row.f_dyc_extras !== undefined &&
          row.f_dyc_extras !== ""
        ) {
          fProperty = row["f_dyc_extras"];
          if (typeof fProperty === "string") {
            fProperty = JSON.parse(fProperty);
          }

          if (
            fProperty.hasOwnProperty("isCheckbox") &&
            fProperty["isCheckbox"] === true
          ) {
            let counter = undefined;
            let chkbox = (
              <Checkbox
                className="mb-3"
                checked={this.getFieldValue(
                  row.group_name,
                  row.f_name,
                  row.f_type,
                  record.index,
                  row.f_default
                )}
                defaultChecked={this.getFieldValue(
                  row.group_name,
                  row.f_name,
                  row.f_type,
                  record.index,
                  row.f_default
                )}
                name={row.f_name}
                disabled={this.isDisableField(row, record.index)}
                onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
              >
                {fProperty.hasOwnProperty("tableView") &&
                fProperty["tableView"] === true
                  ? ""
                  : row.name}
              </Checkbox>
            );

            if (
              fProperty.hasOwnProperty("showCounter") &&
              fProperty["showCounter"] === true
            ) {
              let chkValue = this.getStateObject(
                row.f_name + "_counter",
                record.index,
                row.group_name
              );
              counter = (
                <span className="link-counter-button">
                  <span
                    onClick={() =>
                      this.onChangeEvent(
                        (chkValue ? chkValue : 1) - 1,
                        row,
                        record.index,
                        "_counter"
                      )
                    }
                  >
                    <MinusCircleOutlined />
                  </span>
                  <span>{chkValue ? chkValue : 1}</span>
                  <span
                    onClick={() =>
                      this.onChangeEvent(
                        (chkValue ? chkValue : 1) + 1,
                        row,
                        record.index,
                        "_counter"
                      )
                    }
                  >
                    <PlusCircleOutlined />
                  </span>
                </span>
              );
            }

            return showField ? (
              <>
                {row.f_tool_tip ? (
                  <ToolTipPopover row={row}>
                    <>
                      {chkbox}
                      {counter}
                    </>
                  </ToolTipPopover>
                ) : (
                  <>
                    {chkbox}
                    {counter}
                  </>
                )}
              </>
            ) : (
              ""
            );
          } else {
            return showField ? (
              row.f_tool_tip ? (
                <ToolTipPopover row={row}>
                  <Switch
                    checkedChildren={
                      fProperty["checkedChildren"]
                        ? fProperty["checkedChildren"]
                        : "Yes"
                    }
                    unCheckedChildren={
                      fProperty["unCheckedChildren"]
                        ? fProperty["unCheckedChildren"]
                        : "No"
                    }
                    checked={this.getFieldValue(
                      row.group_name,
                      row.f_name,
                      row.f_type,
                      record.index,
                      row.f_default
                    )}
                    name={row.f_name}
                    disabled={this.isDisableField(row, record.index)}
                    onChange={(evt) =>
                      this.onChangeEvent(evt, row, record.index)
                    }
                  />
                </ToolTipPopover>
              ) : (
                <Switch
                  checkedChildren={
                    fProperty["checkedChildren"]
                      ? fProperty["checkedChildren"]
                      : "Yes"
                  }
                  unCheckedChildren={
                    fProperty["unCheckedChildren"]
                      ? fProperty["unCheckedChildren"]
                      : "No"
                  }
                  checked={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  name={row.f_name}
                  disabled={this.isDisableField(row, record.index)}
                  onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
                />
              )
            ) : (
              ""
            );
          }
        } else {
          return showField ? (
            row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                <Switch
                  defaultChecked={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  name={row.f_name}
                  disabled={this.isDisableField(row, record.index)}
                  onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
                />
              </ToolTipPopover>
            ) : (
              <Switch
                defaultChecked={this.getFieldValue(
                  row.group_name,
                  row.f_name,
                  row.f_type,
                  record.index,
                  row.f_default
                )}
                name={row.f_name}
                disabled={this.isDisableField(row, record.index)}
                onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
              />
            )
          ) : (
            ""
          );
        }
        break;
      case "link-field":
        return (
          <>
            {row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                <div
                  className="input-fields-layout"
                  style={this.getStyle(row)}
                  onClick={() =>
                    typeof showPopup === "function"
                      ? showPopup(
                          row,
                          record.index,
                          this.stateObject,
                          this.handelLinkPopup
                        )
                      : undefined
                  }
                >
                  {this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                </div>
              </ToolTipPopover>
            ) : (
              <div
                className="input-fields-layout"
                style={this.getStyle(row)}
                onClick={() =>
                  typeof showPopup === "function"
                    ? showPopup(
                        row,
                        record.index,
                        this.stateObject,
                        this.handelLinkPopup
                      )
                    : undefined
                }
              >
                {this.getFieldValue(
                  row.group_name,
                  row.f_name,
                  row.f_type,
                  record.index,
                  row.f_default
                )}
              </div>
            )}
          </>
        );
        break;

      case "alpha":
      default:
        return (
          <>
            {row.f_tool_tip ? (
              <ToolTipPopover row={row}>
                {editable ? (
                  showField ? (
                    <Input
                      placeholder={this.getPlaceHolder(
                        row.f_placeholder,
                        row.name,
                        "" + row.name
                      )}
                      addonBefore={row.f_prefix}
                      addonAfter={!rowKey ? row.f_postfix : ""}
                      value={this.getFieldValue(
                        row.group_name,
                        row.f_name,
                        row.f_type,
                        record.index,
                        row.f_default
                      )}
                      name={row.f_name}
                      disabled={this.isDisableField(row, record.index)}
                      onChange={(evt) =>
                        this.onChangeEvent(evt, row, record.index)
                      }
                      onBlur={(evt) =>
                        this.rowEvents(
                          row,
                          evt.hasOwnProperty("target") ? evt.target.value : evt,
                          record.index,
                          true
                        )
                      }
                      style={this.getStyle(row)}
                      className={!row.f_type ? "no-border" : undefined}
                      id={
                        row.f_name +
                        (record.index !== undefined ? record.index : "")
                      }
                    />
                  ) : (
                    ""
                  )
                ) : text >= 0 || text !== "" ? (
                  text
                ) : (
                  row.f_default
                )}
              </ToolTipPopover>
            ) : editable ? (
              showField ? (
                <Input
                  placeholder={this.getPlaceHolder(
                    row.f_placeholder,
                    row.name,
                    "" + row.name
                  )}
                  addonBefore={row.f_prefix}
                  addonAfter={!rowKey ? row.f_postfix : ""}
                  value={this.getFieldValue(
                    row.group_name,
                    row.f_name,
                    row.f_type,
                    record.index,
                    row.f_default
                  )}
                  name={row.f_name}
                  disabled={this.isDisableField(row, record.index)}
                  onChange={(evt) => this.onChangeEvent(evt, row, record.index)}
                  onBlur={(evt) =>
                    this.rowEvents(
                      row,
                      evt.hasOwnProperty("target") ? evt.target.value : evt,
                      record.index,
                      true
                    )
                  }
                  style={this.getStyle(row)}
                  className={!row.f_type ? "no-border" : undefined}
                  id={
                    row.f_name +
                    (record.index !== undefined ? record.index : "")
                  }
                />
              ) : (
                ""
              )
            ) : text >= 0 || text !== "" ? (
              text
            ) : (
              row.f_default
            )}
          </>
        );
    }
  };

  handelLinkPopup = (data, index, referenceElement) => {
    let groupKey = this.getGroupKey(referenceElement["group_name"]),
      ports_list = [];

    let final_data = [],
      groupData = Object.is(this.stateObject, undefined)
        ? []
        : this.stateObject[groupKey];

    let tempArr = [];
    if (
      groupData === undefined ||
      Object.is(this.stateObject, undefined) ||
      typeof this.stateObject == "undefined"
    ) {
      groupData = tempArr;
    }

    let action = {
      type: "add",
      formName: this.formName,
      groupName: groupKey,
      index: undefined,
      value: {},
    };

    let pushIndex = -9e6;
    let linkLable = referenceElement["f_label_display"]
      ? JSON.parse(referenceElement["f_label_display"])
      : {};
    let linkLabel = linkLable;
    if (
      linkLabel.hasOwnProperty("isDirectCopy") &&
      linkLabel.isDirectCopy == true
    ) {
      let bport =
        this.stateObject[linkLabel["fromField"]["groupKey"]][
          linkLabel["fromField"]["fieldName"]
        ];
      let strName =
        linkLabel["fromField"]["fieldName"] == "ballast_port"
          ? "ballast"
          : "load";
      if (bport == undefined || bport < 1) {
        openNotificationWithIcon(
          "warn",
          "Please select " + strName.toUpperCase() + " port first",
          3
        );
        return;
      }
    }

    if (linkLable.hasOwnProperty("isTable") && linkLable["isTable"] === false) {
      let distanceAction = Object.assign({}, action);

      action["value"][linkLable["nameField"]] = data["port"]["port_name"];
      action["value"][linkLable["idField"]] = data["port"]["id"];
      this.reduxState.dispatch(action);

      if (linkLable["distanceField"]["groupKey"]) {
        distanceAction["groupName"] = linkLable["distanceField"]["groupKey"];
      }
      distanceAction["value"] = {};

      if (data["distance"]["features"][0]["properties"]["total_length"] > 0) {
        if (
          linkLable.hasOwnProperty("isDirectCopy") &&
          linkLabel.isDirectCopy == true
        ) {
          distanceAction["value"][linkLable["distanceField"]["fieldName"]] = (
            data["distance"]["features"][0]["properties"]["total_length"] * 1
          ).toFixed(3);
        } else {
          let distance =
            data["distance"]["features"][0]["properties"]["total_length"] * 1;
          let speed =
            this.stateObject[
              linkLable["distanceField"]["fromField"]["groupKey"]
            ][linkLable["distanceField"]["fromField"]["fieldName"]] * 1;
          let voyageDays =
            this.stateObject != undefined
              ? (
                  this.stateObject[linkLable["distanceField"]["groupKey"]][
                    linkLable["distanceField"]["fieldName"]
                  ] *
                    1 +
                  distance / speed
                ).toFixed(2)
              : (distance / speed).toFixed(2);
          distanceAction["value"][linkLable["distanceField"]["fieldName"]] =
            voyageDays;
        }
      } else {
        distanceAction["value"][linkLable["distanceField"]["fieldName"]] =
          data["distance"]["features"][0]["properties"]["total_length"];
      }

      this.reduxState.dispatch(distanceAction);
    } else if (
      linkLable.hasOwnProperty("destination") &&
      linkLable.hasOwnProperty("isDistance") &&
      linkLable["isDistance"] === false &&
      linkLable.hasOwnProperty("isTable") &&
      linkLable["isTable"] === true &&
      index == -1
    ) {
      action["value"][linkLable["nameField"]] = data["port"]["port_name"];
      action["value"][linkLable["idField"]] = data["port"]["id"];
      this.reduxState.dispatch(action);
    } else if (
      linkLable.hasOwnProperty("isDistance") &&
      linkLable["isDistance"] === false &&
      linkLable.hasOwnProperty("isTable") &&
      linkLable["isTable"] === true
    ) {
      if (
        index === -1 &&
        referenceElement.frm_type &&
        referenceElement.frm_type == "Normal"
      ) {
        // normal view may have undefined index
        action["index"] =
          index > 0
            ? index
            : index === undefined || index === -1
            ? undefined
            : 0;
      } else {
        //table view has defined index starting 0
        action["index"] = index > 0 ? index : 0;
      }
      action["value"][linkLable["nameField"]] =
        data["port"]["port_name"].trimStart();
      action["value"][linkLable["idField"]] = data["port"]["id"];
      action["value"]["latitude"] = data["port"]["latitude"];
      action["value"]["locode"] = data["port"]["locode"];
      action["value"]["longitude"] = data["port"]["longitude"];
      this.reduxState.dispatch(action);
    } else if (
      linkLabel.hasOwnProperty("frmCode") &&
      linkLabel["frmCode"] === "dem_des_form"
    ) {
      let action = {
        type: "add",
        formName: this.formName,
        groupName: groupKey,
        index: undefined,
        value: {},
      };
      action["index"] = index > 0 ? index : 0;
      action["value"][linkLable["fieldName"]] =
        data?.["dem_des_amt"] > 0 ? "Despatch" : "Demmurage";
      action["value"][linkLable["updateField"]] = data?.["dem_des_amt"] ?? 0;
      this.reduxState.dispatch(action);
      //  dem_disp_amt
    } else if (
      linkLabel.hasOwnProperty("frmCode") &&
      linkLabel["frmCode"] === "worldscale_rate"
    ) {
      let action = {
        type: "add",
        formName: this.formName,
        groupName: groupKey,
        index: undefined,
        value: {},
      };
      action["index"] = index > 0 ? index : 0;
      action["value"][linkLable["fieldName"]] = data?.["frat_rate"] ?? 0;
      action["value"][linkLable["updateField"]] = data?.["frat_rate"] ?? 0;
      this.reduxState.dispatch(action);
      //  dem_disp_amt
    } else if (
      linkLabel.hasOwnProperty("frmCode") &&
      linkLabel["frmCode"] === "other_cost_detail_form"
    ) {
      let action = {
        type: "add",
        formName: this.formName,
        groupName: groupKey,
        index: undefined,
        value: {},
      };

      action["value"][linkLable["fieldName"]] = data?.["total_sum"] ?? "0.00";
      action["value"][linkLable["updateField"]] = data?.["total_sum"] ?? "0.00";
      action["value"]["othercostsummary"] = data["othercost"];
      action["value"]["othercost_id"] = data["id"];
      this.reduxState.dispatch(action);
    } else if (
      linkLable.hasOwnProperty("isDistance") &&
      linkLable["isDistance"] === false
    ) {
      action["value"][linkLable["nameField"]] =
        data["port"]["port_name"].trimStart();
      action["value"][linkLable["idField"]] = data["port"]["id"];
      this.reduxState.dispatch(action);
    } else if (
      linkLable.hasOwnProperty("frmCode") &&
      linkLabel.frmCode === "isvoyageform"
    ) {
      let action = {
        type: "add",
        formName: this.formName,
        groupName: groupKey,
        index: undefined,
        value: {},
      };

      action["index"] = index > 0 ? index : 0;
      action["value"][linkLable["fieldName"]] = data["voyage_number"];
      this.reduxState.dispatch(action);
    } else {
      if (
        data &&
        data["distance"] !== undefined &&
        data["distance"]["features"] !== undefined
      ) {
        data["distance"]["features"].map((e, ind) => {
          ports_list.push({
            port_id: data["port"]["id"],
            port: data["port"]["port_name"].trimStart(), //TODO leave for backward compatibility to be REMOVED @Tech
            port_name: data["port"]["port_name"].trimStart(),
            seca_length: e["properties"]["seca_length"],
            hra_length: e["properties"]["hra_length"],
            crossed: Array.isArray(e["properties"]["crossed"])
              ? e["properties"]["crossed"].join(", ")
              : "",
            latitude: data["port"]["latitude"],
            locode: data["port"]["locode"],
            longitude: data["port"]["longitude"],
            miles: e["properties"]["total_length"],
            is_eur: data?.port?.european_status == 1 ? "True" : "False",
            s_type: "1",
            wf_per: this.stateObject?.dwf ? this.stateObject?.dwf : 5,
            editable: true,
            id: pushIndex + index,
            index: index,
            key: groupKey + "_table_row_" + index,
            ocd: true,
          });
          pushIndex = pushIndex + 1;
        });
      } else {
        if (index < 0) {
          index = 0;
        }

        ports_list.push({
          port_id: data["port"] && data["port"]["id"] ? data["port"]["id"] : "",
          port: data["port"]["port_name"].trimStart(), //TODO leave for backward compatibility to be REMOVED @Tech
          port_name: data["port"]["port_name"].trimStart(),
          seca_length: data["port"]["seca_length"]
            ? data["port"]["seca_length"]
            : 0,
          hra_length: data["port"]["hra_length"]
            ? data["port"]["hra_length"]
            : 0,
          crossed: Array.isArray(data["port"]["crossed"])
            ? data["port"]["crossed"].join(", ")
            : "",
          latitude: data["port"]["latitude"],
          locode: data["port"]["locode"],
          longitude: data["port"]["longitude"],
          miles: data["port"]["total_length"]
            ? data["port"]["total_length"]
            : 0,
          is_eur: data?.port?.european_status == 1 ? "True" : "False",
          wf_per: this.stateObject?.dwf ? this.stateObject?.dwf : 5,
          s_type: "1",
          editable: true,
          id: pushIndex,
          index: index,
          key: groupKey + "_table_row_" + index,
          ocd: true,
        });

        // openNotificationWithIcon('warn', 'Please select the port again, selection interrupted', 2);
      }
      if (groupData && groupData.length > index && index !== -1) {
        groupData.map((e, i) => {
          if (i !== index) {
            final_data.push(e);
          } else {
            ports_list.map((pe) => final_data.push(pe));
          }
        });
      } else if (groupData && groupData.length === index && index !== -1) {
        final_data = Object.assign([], groupData);
        final_data[index] = Object.assign({}, ports_list[0]);
        if (ports_list.length > 1) {
          ports_list.map((e, i) => {
            if (i > 0) final_data.push(e);
          });
        }
      } else if (index === -1) {
        index = 0;
        final_data = Object.assign([], groupData);

        final_data[index] = Object.assign({}, ports_list[0]);

        if (ports_list.length > 1) {
          ports_list.map((e, i) => {
            if (i > 0) final_data.push(e);
          });
        }
      }

      if (groupKey) {
        action["value"][groupKey] = final_data;
        this.reduxState.dispatch(action);
      }
    }
    if (referenceElement.f_evt) {
      let aa = JSON.parse(referenceElement.f_evt);
      if (aa["eventLoops"]) {
        this.eventChanin(aa["eventLoops"], referenceElement.group_name);
      }
    }
  };

  // update by amar dixit   19-05-2022 =====  for diosabled field option

  isDisableField = (row, index) => {
    let counter = 0;
    let g = this.getGroupKey(row["group_name"]);
    let disableField = row.f_readonly === 1 ? true : false;
    let sv = this.stateObject;
    if (!disableField && sv && JSON.stringify(sv) !== JSON.stringify({})) {
      if (row["group_name"] && row["group_name"] !== "" && index >= 0) {
        let g = this.getGroupKey(row["group_name"]);
        disableField =
          sv.hasOwnProperty(g) &&
          sv[g][index] &&
          sv[g][index].hasOwnProperty("disablefield") &&
          sv[g][index]["disablefield"].length > 0 &&
          sv[g][index]["disablefield"].indexOf(row.f_name) >= 0;
      } else if (row["group_name"] && row["group_name"] !== "" && !index) {
        let g = this.getGroupKey(row["group_name"]);
        disableField =
          sv?.hasOwnProperty(g) &&
          sv[g]?.hasOwnProperty("disablefield") &&
          sv[g]["disablefield"].length > 0 &&
          sv[g]["disablefield"].indexOf(row.f_name) >= 0;
      } else if (
        !row["group_name"] &&
        !index &&
        sv.hasOwnProperty("disablefield") &&
        sv["disablefield"].length > 0 &&
        sv["disablefield"].indexOf(row.f_name) >= 0
      ) {
        disableField = true;
      } else if (
        row["group_name"] &&
        row["group_name"] !== "" &&
        !index &&
        row["group_name"] == "---------------"
      ) {
        disableField = true;
      }
    }

    return disableField;
  };

  getStyle = (row, hasMinWidth = false, hasDynamicWidth = false) => {
    let style = {};

    if (row.f_alignement === 2) {
      style["textAlign"] = "center";
    } else if (row.f_alignement === 3) {
      style["textAlign"] = "right";
    }

    if (
      !hasDynamicWidth &&
      hasMinWidth &&
      row.f_width &&
      row.frm_type.toLowerCase() !== "table"
    ) {
      style["minWidth"] = row.f_width;
    }

    if (
      !hasDynamicWidth &&
      row.f_width &&
      row.frm_type.toLowerCase() !== "table"
    ) {
      style["width"] = row.f_width;
    } else if (hasDynamicWidth) {
      style["width"] = "calc(100% - 50px)";
    }

    if (row.f_type === "link-field" || row.f_type === "show-text") {
      style["overflow"] = "hidden";
    }

    if (!row.f_type) {
      style["borderRightWidth"] = "none";
    }
    // console.log(this.isEmailValidated, row.f_name);

    // if (row.f_name == "email" && !this.isEmailValidated) {
    //   style["border"] = "2px solid red";
    // }

    // if (row.f_name == "email") {
    //   console.log(!this.isEmailValidated);

    //   if (!this.isEmailValidated) {
    //     style["border"] = "2px solid red";
    //   }
    // }

    // console.log(style);

    return style && JSON.stringify(style) !== JSON.stringify({})
      ? style
      : undefined;
  };

  resolveFormula = (f_event, index, action, row, value) => {
    let formula = f_event["formula"];
    let valueIs = 0;
    let factor = "1.0000";

    if (
      f_event["DropDownReplace"] &&
      row["f_dyc_extras"] &&
      row["f_dyc_extras"]["options"] &&
      row["f_dyc_extras"]["options"].length > 0
    ) {
      let option = row["f_dyc_extras"]["options"].filter(
        (e) => e.id === value * 1
      );
      if (option.length > 0) {
        factor = option[0].factor;
      }
      f_event["DropDownReplace"].map((e) => {
        formula = formula.replaceAll(e, factor);
      });
    }

    if (f_event["externalReplace"]) {
      if (typeof f_event["externalReplace"] !== "string") {
        if (f_event["externalReplace"].hasOwnProperty("replace")) {
          f_event["externalReplace"]["replace"].map((e, key) => {
            if (
              typeof e !== "string" &&
              e.hasOwnProperty("condition") &&
              e["condition"] === "BLANK" &&
              !this.stateObject[action["groupName"]][
                action["index"]
              ].hasOwnProperty(e["matchField"])
            ) {
              formula = formula.replaceAll(
                e,
                this.stateObject[f_event[e["replace"]]]
              );
            } else if (typeof e === "string") {
              //console.log("fieldevent" + f_event[e].groupCode + "." + f_event[e].fieldName);
              if (f_event[e].hasOwnProperty("groupCode")) {
                //formula = formula.replaceAll(e, this.stateObject[f_event[e].groupCode][key][f_event[e].fieldName])

                if (f_event[e].fieldName == "commision") {
                  formula = formula.replaceAll(
                    e,
                    this.stateObject.commission[key].commision
                  );
                } else {
                  formula = formula.replaceAll(
                    e,
                    this.stateObject[f_event[e].groupCode][
                      f_event[e].fieldName
                    ] == "N/A" ||
                      this.stateObject[f_event[e].groupCode][
                        f_event[e].fieldName
                      ] == ""
                      ? 0.0
                      : this.stateObject[f_event[e].groupCode][
                          f_event[e].fieldName
                        ]
                  );
                }
              } else {
                formula = formula.replaceAll(
                  e,
                  this.stateObject[f_event[e]] == "N/A" ||
                    this.stateObject[f_event[e]] == ""
                    ? 0.0
                    : this.stateObject[f_event[e]]
                );
              }
              // console.log(formula)
            }
          });
        } else {
          f_event["externalReplace"].map((e) => {
            if (
              typeof e !== "string" &&
              e.hasOwnProperty("condition") &&
              e["condition"] === "BLANK" &&
              !this.stateObject[action["groupName"]][
                action["index"]
              ].hasOwnProperty(e["matchField"])
            ) {
              formula = formula.replaceAll(
                e,
                this.stateObject[f_event[e["replace"]]]
              );
            } else if (typeof e === "string") {
              formula = formula.replaceAll(e, this.stateObject[f_event[e]]);
            }
            //console.log(formula)
          });
        }
      } else if (
        typeof f_event["externalReplace"] !== "string" &&
        f_event["externalReplace"].hasOwnProperty("replace") &&
        f_event["externalReplace"].hasOwnProperty("groupCode")
      ) {
        f_event["externalReplace"]["replace"].map((e, i) => {
          if (
            typeof e !== "string" &&
            e.hasOwnProperty("condition") &&
            e["condition"] === "BLANK" &&
            !this.stateObject[action["groupName"]][
              action["index"]
            ].hasOwnProperty(e["matchField"])
          ) {
            formula = formula.replaceAll(
              e,
              this.stateObject[f_event[e["replace"]]]
            );
          } else if (
            typeof e !== "string" &&
            e.hasOwnProperty("condition") &&
            e["condition"].toUpperCase() === "EQ" &&
            e.hasOwnProperty("fromField")
          ) {
            let _me = this.stateObject[
              f_event["externalReplace"]["groupCode"]
            ].filter(
              (me, mi) =>
                me[e["fromField"]] &&
                this.stateObject[f_event["updateTo"]["groupCode"]] &&
                this.stateObject[f_event["updateTo"]["groupCode"]][mi] &&
                this.stateObject[f_event["updateTo"]["groupCode"]][mi][
                  e["matchField"]
                ] &&
                me[e["fromField"]] ===
                  this.stateObject[f_event["updateTo"]["groupCode"]][mi][
                    e["matchField"]
                  ]
            );
            formula =
              _me && _me.length > 0
                ? formula.replaceAll(
                    e["replace"],
                    _me[0][f_event[e["replace"]]]
                  )
                : formula.replaceAll(e["replace"], 0);
          } else if (typeof e === "string") {
            if (
              this.stateObject.hasOwnProperty(
                f_event["externalReplace"]["groupCode"]
              )
            ) {
              formula = formula.replaceAll(
                e,
                this.stateObject[f_event["externalReplace"]["groupCode"]][
                  f_event[e]
                ]
              );
            } else {
              formula = formula.replaceAll(e, 0);
            }
          }
        });
      }
    }

    if (
      f_event["withInReplace"] &&
      f_event["withInReplace"]["groupCode"] &&
      f_event["withInReplace"]["groupCode"] !== "" &&
      !f_event["withInReplace"].hasOwnProperty("dynamicGroup")
    ) {
      f_event["withInReplace"]["replace"].map((e) => {
        let vb = "";
        if (e.search("-") >= 0 && index > 0) {
          e = e.replaceAll("-", "");
          vb =
            this.stateObject[f_event["withInReplace"]["groupCode"]][index - 1][
              f_event[e]
            ];
          formula = formula.replaceAll(
            e,
            vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
          );
        } else if (e.search("-") >= 0 && index === 0) {
          e = e.replaceAll("-", "");
          vb =
            this.stateObject[f_event["withInReplace"]["groupCode"]][index][
              f_event[e]
            ];
          formula = formula.replaceAll(
            e,
            vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
          );
        } else if (index >= 0) {
          vb =
            this.stateObject[f_event["withInReplace"]["groupCode"]] &&
            this.stateObject[f_event["withInReplace"]["groupCode"]][index] &&
            this.stateObject[f_event["withInReplace"]["groupCode"]][index][
              f_event[e]
            ];
          formula = formula.replaceAll(
            e,
            vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
          );
        } else {
          vb = this.stateObject[f_event["withInReplace"]["groupCode"]][
            f_event[e]
          ]
            ? this.stateObject[f_event["withInReplace"]["groupCode"]][
                f_event[e]
              ]
            : 0;
          formula = formula.replaceAll(
            e,
            vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
          );
        }
      });
    } else if (
      f_event["withInReplace"] &&
      f_event["withInReplace"]["groupCode"] &&
      f_event["withInReplace"]["groupCode"] !== "" &&
      f_event["withInReplace"].hasOwnProperty("dynamicGroup") &&
      f_event["withInReplace"]["dynamicGroup"] === true
    ) {
      f_event["withInReplace"]["replace"].map((e) => {
        let vb = "";
        if (typeof e === "object") {
          if (e.hasOwnProperty("convertDHM") && e.convertDHM === true) {
            let p = e.fieldName;
            if (p.search("-") >= 0 && index > 0) {
              p = p.replaceAll("-", "");
              vb = this.stringToDaysHoursMinutsObject(
                this.stateObject[action["groupName"]][index - 1][f_event[p]],
                true
              );
              formula = formula.replaceAll(
                p,
                vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
              );
            } else if (p.search("-") >= 0 && index === 0) {
              p = p.replaceAll("-", "");
              vb = this.stringToDaysHoursMinutsObject(
                this.stateObject[action["groupName"]][index][f_event[p]],
                true
              );
              formula = formula.replaceAll(
                p,
                vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
              );
            } else {
              vb = this.stringToDaysHoursMinutsObject(
                this.stateObject[action["groupName"]][index][f_event[p]],
                true
              );
              formula = formula.replaceAll(
                p,
                vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
              );
            }
          }
        } else {
          if (e.search("-") >= 0 && index > 0) {
            e = e.replaceAll("-", "");
            vb = this.stateObject[action["groupName"]][index - 1][f_event[e]];
            formula = formula.replaceAll(
              e,
              vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
            );
          } else if (e.search("-") >= 0 && index === 0) {
            e = e.replaceAll("-", "");
            vb = this.stateObject[action["groupName"]][index][f_event[e]];
            formula = formula.replaceAll(
              e,
              vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
            );
          } else {
            // if (f_event[e]=="counting"){
            //   console.log("amar")
            //   vb ="100"
            // }else{
            vb = this.stateObject[action["groupName"]][index][f_event[e]];
            //}

            formula = formula.replaceAll(
              e,
              vb !== "" ? (isNaN(vb * 1) ? 0 : vb * 1) : 0
            );
          }
        }
      });
    }

    if (formula && !isNaN(eval(formula))) {
      valueIs = eval(formula);
    }

    //console.log(valueIs)

    if (f_event["roundOff"] && f_event["roundOff"] !== "") {
      valueIs = valueIs.toFixed(f_event["roundOff"]);
    }

    if (f_event && f_event.hasOwnProperty("convertDHM")) {
      valueIs = this.numberToDaysHoursMinutsObject(parseInt(valueIs));
      valueIs =
        this.numberPad(valueIs["days"], 2) +
        "D:" +
        this.numberPad(valueIs["hours"], 2) +
        "H:" +
        this.numberPad(valueIs["minutes"], 2) +
        "M";
      action["value"][f_event["updateTo"]] = valueIs;
    } else if (typeof f_event["updateTo"] !== "string") {
      action["groupName"] = f_event["updateTo"]["groupCode"];
      if (typeof f_event["updateTo"]["fieldName"] !== "string") {
        f_event["updateTo"]["fieldName"].map(
          (e) => (action["value"][e] = isFinite(valueIs) ? valueIs : "N/A")
        );
      } else {
        action["value"][f_event["updateTo"]["fieldName"]] = isFinite(valueIs)
          ? valueIs
          : "N/A";
      }
    } else {
      if (
        f_event.hasOwnProperty("noUpdateGroup") &&
        f_event["noUpdateGroup"] === true
      ) {
        action["groupName"] = undefined;
      }
      action["value"][f_event["updateTo"]] = isFinite(valueIs)
        ? valueIs
        : "N/A";
    }
    this.reduxState.dispatch(action);
  };

  nextResolveFormula = (f_event, index, nextIndex, action) => {
    let formula = f_event["nextFormula"];
    let valueIs = 0;
    let stObject = this.getStateObject();
    let gKey = f_event["withInReplace"]["groupCode"];

    if (
      stObject &&
      stObject.hasOwnProperty(gKey) &&
      stObject[gKey].hasOwnProperty(index) &&
      stObject[gKey].hasOwnProperty(nextIndex)
    ) {
      f_event["withInReplace"]["replace"].map((wr) => {
        formula = formula.replaceAll(
          wr,
          stObject[gKey][index][f_event[wr]]
            ? stObject[gKey][index][f_event[wr]]
            : 0
        );
      });
      f_event["withInReplace"]["nextReplace"].map((wr) => {
        formula = formula.replaceAll(
          wr,
          stObject[gKey][nextIndex][f_event[wr]]
            ? stObject[gKey][nextIndex][f_event[wr]]
            : 0
        );
      });

      valueIs = eval(formula);
      if (f_event["roundOff"] && f_event["roundOff"] !== "") {
        valueIs = valueIs.toFixed(f_event["roundOff"]);
      }

      action["index"] = nextIndex;
      action["value"][f_event["updateTo"]] = isNaN(valueIs) ? 0 : valueIs;
      this.reduxState.dispatch(action);
    }
  };

  dateAdded = (f_event, index, action, cb) => {
    if (
      f_event["dateAdd"].hasOwnProperty("fromField") &&
      f_event["dateAdd"].hasOwnProperty("updateTo") &&
      f_event["dateAdd"].hasOwnProperty("index") &&
      index > 0
    ) {
      let idx =
        index + (f_event["dateAdd"]["index"] ? f_event["dateAdd"]["index"] : 0);
      if (
        this.stateObject[action["groupName"]] &&
        this.stateObject[action["groupName"]][idx] &&
        this.stateObject[action["groupName"]][idx][
          f_event["dateAdd"]["fromField"]
        ]
      ) {
        let dt = moment(
          this.stateObject[action["groupName"]][idx][
            f_event["dateAdd"]["fromField"]
          ].toString()
        );
        let av =
          this.stateObject[action["groupName"]][index][
            f_event["dateAdd"]["addField"]
          ];
        let diffAV = av * 1 - parseInt(av);
        let hours = parseInt(24 * diffAV);
        let minutes = parseInt(60 * (24 * diffAV - hours));

        action["value"][f_event["dateAdd"]["updateTo"]] = dt
          .add({ days: parseInt(av), hours: hours, minutes: minutes })
          .format("YYYY-MM-DDTHH:mm");
        this.reduxState.dispatch(action);
        if (typeof cb === "function") cb();
      }
    } else if (
      f_event["dateAdd"].hasOwnProperty("fromField") &&
      f_event["dateAdd"].hasOwnProperty("updateTo") &&
      !f_event["dateAdd"]["index"]
    ) {
      if (
        this.stateObject[action["groupName"]] &&
        this.stateObject[action["groupName"]][index] &&
        this.stateObject[action["groupName"]][index][
          f_event["dateAdd"]["fromField"]
        ]
      ) {
        let dt = moment(
          this.stateObject[action["groupName"]][index][
            f_event["dateAdd"]["fromField"]
          ].toString()
        );
        let av =
          this.stateObject[action["groupName"]][index][
            f_event["dateAdd"]["addField"]
          ];
        let diffAV = av * 1 - parseInt(av);
        let hours = parseInt(24 * diffAV);
        let minutes = parseInt(60 * (24 * diffAV - hours));

        action["value"][f_event["dateAdd"]["updateTo"]] = dt
          .add({ days: parseInt(av), hours: hours, minutes: minutes })
          .format("YYYY-MM-DDTHH:mm");
        this.reduxState.dispatch(action);
        if (typeof cb === "function") cb();
      }
    }
  };

  nextDateAdded = (f_event, index, action, nextIndex) => {
    if (
      f_event["nextDateAdd"].hasOwnProperty("fromField") &&
      f_event["nextDateAdd"].hasOwnProperty("updateTo") &&
      !f_event["nextDateAdd"]["index"] &&
      this.stateObject[action["groupName"]] &&
      this.stateObject[action["groupName"]][index] &&
      this.stateObject[action["groupName"]][index][
        f_event["nextDateAdd"]["fromField"]
      ]
    ) {
      let dt = moment(
        this.stateObject[action["groupName"]][index][
          f_event["nextDateAdd"]["fromField"]
        ].toString()
      );
      if (action.formName !== "new_laytime_calculation_form") {
        if (f_event["nextDateAdd"].hasOwnProperty("addField")) {
          let av =
            this.stateObject[action["groupName"]][nextIndex][
              f_event["nextDateAdd"]["addField"]
            ];
          let days = parseInt(av);
          let hours = parseInt((av - days) * 24);
          let minutes = parseInt(((av - days) * 24 - hours) * 60);
          action["index"] = nextIndex;
          action["value"][f_event["nextDateAdd"]["updateTo"]] = dt
            .add({ days: days, hours: hours, minutes: minutes })
            .format("YYYY-MM-DDTHH:mm");
        } else {
          action["index"] = nextIndex;
          action["value"][f_event["nextDateAdd"]["updateTo"]] =
            dt.format("YYYY-MM-DDTHH:mm");
        }
      }

      if (
        f_event["nextDateAdd"].hasOwnProperty("getDay") &&
        f_event["nextDateAdd"]["getDay"].hasOwnProperty("fromField") &&
        f_event["nextDateAdd"]["getDay"].hasOwnProperty("updateTo")
      ) {
        let ff = action["value"][f_event["nextDateAdd"]["getDay"]["fromField"]];
        if (ff && typeof ff === "string") {
          ff = moment(ff);
        }
        if (ff) {
          action["value"][f_event["nextDateAdd"]["getDay"]["updateTo"]] =
            ff.format("ddd");
        }
      }
      this.reduxState.dispatch(action);
    }
  };

  setData = (
    fieldName,
    fieldData,
    groupData,
    index = undefined,
    calledFrom = undefined,
    formName = undefined
  ) => {
    let values = {};
    if (calledFrom !== undefined && calledFrom === "COPYST") {
      //delete this.stateObject[fieldName];
    }
    let action = {
      type: "add",
      groupName: fieldName,
      index: index,
      formName: formName,
      value: {},
    }; //TODO changed to fieldName was undefined
    values[fieldName] = fieldData;
    if (
      groupData &&
      typeof groupData === "object" &&
      groupData.hasOwnProperty("length") &&
      groupData.length > 0
    ) {
      groupData.map((e) => (values[e.groupName] = e.value));
    } else if (groupData && groupData !== "" && typeof groupData === "string") {
      action["groupName"] = groupData;
    } else if (!groupData) {
      action["groupName"] = fieldName;
    }
    action["value"] = values;
    this.reduxState.dispatch(action);
  };

  exchangeFromFieldToGroupFields = (f_event, egAction, value) => {
    f_event.map((eg) => {
      let gA = Object.assign({}, egAction);
      gA["groupName"] = this.getGroupKey(eg.gKey);
      gA["value"] = {};
      if (
        this.stateObject.hasOwnProperty(gA["groupName"]) &&
        this.stateObject[gA["groupName"]].length > 0
      ) {
        this.stateObject[gA["groupName"]].map((sga, sgi) => {
          gA["index"] = sgi;
          if (typeof eg.toField !== "string") {
            eg.toFields.map((fd) => {
              if (typeof fd === "string") {
                gA["value"][fd] = value;
              } else if (
                typeof fd === "object" &&
                fd.hasOwnProperty("field") &&
                fd.hasOwnProperty("conditionField") &&
                fd.hasOwnProperty("conditionValue")
              ) {
                let gAV = this.stateObject[egAction["groupName"]];
                gAV.map((_g) => {
                  if (
                    _g.hasOwnProperty(fd["conditionField"]) &&
                    _g[fd["conditionField"]].toLowerCase() ===
                      fd["conditionValue"]
                  ) {
                    gA["value"][fd["field"]] = _g[fd["valueField"]];
                  }
                });
              }
            });
          } else {
            gA["value"][eg.toFields] = value;
          }
          this.reduxState.dispatch(gA);
        });
      } else {
        if (typeof eg.toField !== "string") {
          eg.toFields.map((fd) => (gA["value"][fd] = value));
        } else {
          gA["value"][eg.toFields] = value;
        }

        this.reduxState.dispatch(gA);
      }
    });
  };

  stringToDaysHoursMinutsObject = (str, isNum = false) => {
    let dhmObject = { days: 0, hours: 0, minutes: 0 };
    if (str) {
      str = str.toLowerCase();
      if (str.indexOf("d") >= 0) {
        str = str.split("d");
        dhmObject["days"] = parseInt(str[0]);
        str = str[1].replace(":", "");
      }

      if (typeof str === "string" && str.indexOf("h") >= 0) {
        str = str.split("h");
        dhmObject["hours"] = parseInt(str[0]);
        str = str[1].replace(":", "");
      }

      if (typeof str === "string" && str.indexOf("m") >= 0) {
        str = str.split("m");
        dhmObject["minutes"] = parseInt(str[0]);
      }
    }

    if (isNum === true) {
      return parseInt(
        parseInt(dhmObject["days"] * 24 * 60) +
          parseInt(dhmObject["hours"] * 60) +
          parseInt(dhmObject["minutes"])
      );
    }

    return dhmObject;
  };

  numberToDaysHoursMinutsObject = (num) => {
    let dhmObject = { days: 0, hours: 0, minutes: 0 };
    if (num) {
      dhmObject["days"] = parseInt(num / (24 * 60));
      dhmObject["hours"] = parseInt((num - dhmObject["days"] * 24 * 60) / 60);
      dhmObject["minutes"] = parseInt(
        num - (dhmObject["days"] * 24 * 60 + dhmObject["hours"] * 60)
      );
    }

    return dhmObject;
  };

  numberPad(num, size) {
    if (num == 0) {
      return "00";
    } else {
      return num;
    }
    var s = "000000000" + num;
    return s.substr(s.length - size);
  }

  dateObject = (val, valObj = undefined, isTime = false) => {
    let dtObj = { date: 0, month: 0, year: 0, hour: 0, minute: 0 };
    if (valObj) {
      dtObj = Object.assign(dtObj, valObj);
    }

    if (isTime === true && val) {
      if (val && val !== "" && typeof val === "string") {
        val = moment(val);
      }
      dtObj["hour"] = val.hours();
      dtObj["minute"] = val.minutes();
    } else if (val) {
      if (val && val !== "" && typeof val === "string") {
        val = moment(val);
      }
      dtObj = { date: val.date(), month: val.month(), year: val.year() };
    }

    return dtObj;
  };

  testamar1 = () => {
    const { miles, itrspeed, wf_per } = this.stateObject;

    // console.log({ miles, itrspeed, wf_per });
    let speed = itrspeed - itrspeed * wf_per * 0.01;
    let days = parseFloat(miles / (speed * 24)).toFixed(2);

    let consarr = this.stateObject["-"];
    consarr?.map(
      (el) => (el["cons"] = parseFloat(el["consvalue"] * days).toFixed(2))
    );

    let action = {
      type: "add",
      formName: this.formName,
      groupName: undefined,
      index: undefined,
      value: { days: days, next_port_duration: days },
    };

    this.reduxState.dispatch(action);

    let action1 = {
      type: "add",
      formName: this.formName,
      groupName: "-",
      index: undefined,
      value: { "-": consarr },
    };

    this.reduxState.dispatch(action1);
  };

  isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  prevDateDifference = (f_event, value, stateObject, action, index) => {
    try {
      if (
        f_event["prevDateDifference"].hasOwnProperty("fromField") &&
        f_event["prevDateDifference"].hasOwnProperty("updateTo") &&
        f_event["prevDateDifference"].hasOwnProperty("items")
      ) {
        let items = stateObject[action["groupName"]];
        let from =
          items[f_event["prevDateDifference"]["items"][index]][
            f_event["prevDateDifference"]["fromField"]
          ];

        let to =
          items[f_event["prevDateDifference"]["items"][index]][
            f_event["prevDateDifference"]["toField"]
          ];

        let fromdate = this.isValidDate(from);
        let todate = this.isValidDate(to);

        if (fromdate && todate) {
          let diff = (to.diff(from, "seconds") / (24 * 60 * 60)).toFixed(2);
          action["index"] = f_event["prevDateDifference"]["updateTo"]["index"];
          action[f_event["prevDateDifference"]["updateTo"]["fieldName"]] = diff;
          this.reduxState.dispatch(action);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // adjustDatesWithDurationAfterInsertion(payload, index) {
  //   let laytimeArray = [...payload.laytime];
  //   console.log({payload,index,laytimeArray})
  //   for (let i = 1; i < laytimeArray.length; i++) {
  //     let prevItem = laytimeArray[i - 1];
  //     let currentItem = laytimeArray[i];
  //     // Update the from_date of the current item to be the to_date of the previous item
  //     currentItem.from_date = prevItem.to_date;

  //     if (index === i) {
  //       // Calculate the to_date based on existing from_date and to_date, ignoring the duration
  //       currentItem.to_date = moment(currentItem.from_date)
  //         .add(
  //           moment(currentItem.to_date).diff(
  //             moment(currentItem.from_date),
  //             "minutes"
  //           ),
  //           "minutes"
  //         )
  //         .format("YYYY-MM-DD HH:mm");
  //     } else if (currentItem.duration) {
  //       // Calculate the to_date based on the duration
  //       let [days, hours, minutes] = currentItem.duration
  //         .split(/D|H|M/)
  //         .map(Number);
  //       currentItem.to_date = moment(currentItem.from_date)
  //         .add(days, "days")
  //         .add(hours, "hours")
  //         .add(minutes, "minutes")
  //         .format("YYYY-MM-DD HH:mm");
  //     }

  //     // Shift all subsequent elements' from_date and to_date based on their duration
  //     for (let j = i + 1; j < laytimeArray.length; j++) {
  //       let previousItem = laytimeArray[j - 1];
  //       let nextItem = laytimeArray[j];

  //       // Set the from_date of the next item to the to_date of the previous item
  //       nextItem.from_date = previousItem.to_date;

  //       // Calculate the to_date for the next item based on its duration
  //       if (nextItem.duration && index !== j) {
  //         let [days, hours, minutes] = nextItem.duration
  //           .split(/D|H|M/)
  //           .map(Number);
  //         nextItem.to_date = moment(nextItem.from_date)
  //           .add(days, "days")
  //           .add(hours, "hours")
  //           .add(minutes, "minutes")
  //           .format("YYYY-MM-DD HH:mm");
  //       }
  //     }
  //   }
  //   console.log("laytimeArray after",laytimeArray)
  //   return { laytime: laytimeArray };
  // }

  adjustDatesWithDurationAfterInsertion(payload, index) {
    let laytimeArray = [...payload.laytime];

    // Helper function to calculate to_date based on from_date and duration
    const calculateToDate = (fromDate, duration) => {
      // Initialize default values for days, hours, and minutes
      let days = 0;
      let hours = 0;
      let minutes = 0;

      // Extract numbers for days, hours, and minutes using regex
      const daysMatch = duration.match(/(\d+)D/);
      const hoursMatch = duration.match(/(\d+)H/);
      const minutesMatch = duration.match(/(\d+)M/);

      if (daysMatch) days = parseInt(daysMatch[1], 10);
      if (hoursMatch) hours = parseInt(hoursMatch[1], 10);
      if (minutesMatch) minutes = parseInt(minutesMatch[1], 10);

      return moment(fromDate)
        .add(days, "days")
        .add(hours, "hours")
        .add(minutes, "minutes")
        .format("YYYY-MM-DD HH:mm");
    };

    // Helper function to calculate duration between two dates
    const calculateDuration = (fromDate, toDate) => {
      let duration = moment.duration(moment(toDate).diff(moment(fromDate)));
      let days = Math.floor(duration.asDays());
      let hours = duration.hours();
      let minutes = duration.minutes();
      return `${days}D:${hours}H:${minutes}M`;
    };
    // Update elements starting from the index
    for (let i = index + 1; i < laytimeArray.length; i++) {
      let prevItem = laytimeArray[i - 1];
      let currentItem = laytimeArray[i];

      // Update the from_date of the current item to be the to_date of the previous item
      currentItem.from_date = prevItem.to_date;

      // If duration is missing, calculate it based on from_date and to_date
      if (!currentItem.duration && currentItem.to_date) {
        currentItem.duration = calculateDuration(
          currentItem.from_date,
          currentItem.to_date
        );
      }

      // Calculate the to_date based on from_date and duration
      currentItem.to_date = calculateToDate(
        currentItem.from_date,
        currentItem.duration
      );

      // Optionally, you can handle cases where currentItem.to_date is missing
      // and/or additional logic based on your requirements
    }

    return { laytime: laytimeArray };
  }

  rowEvents = async (row, value, index, isBlur = false) => {
    try {
      let f_event = undefined;
      let action = {
        type: "add",
        formName: this.formName,
        groupName: this.getGroupKey(row.group_name),
        index: index,
        value: {},
      };

      if (
        row.f_evt &&
        row.f_evt !== "" &&
        row.f_evt !== null &&
        typeof row.f_evt === "string"
      ) {
        f_event = JSON.parse(row.f_evt);
      }

      if (
        f_event &&
        f_event.hasOwnProperty("blur") &&
        !f_event["blur"] &&
        isBlur === true
      ) {
        return false;
      } else if (
        f_event &&
        f_event.hasOwnProperty("blur") &&
        f_event["blur"] === true &&
        isBlur === false
      ) {
        return false;
      }

      if (f_event && f_event.hasOwnProperty("amarwf")) {
        this.stateObject[f_event["amarwf"].groupName]?.map((el, i) => {
          if (value) {
            this.stateObject[f_event["amarwf"].groupName][i]["wf_per"] = value;
            return;
          }
        });
        this.reduxState.dispatch(action);
      }

      if (f_event && f_event.hasOwnProperty("amar")) {
        this.stateObject[f_event["amar"].groupName]?.map((el, i) => {
          if (
            el.passage == 1 &&
            el.s_type == 2 &&
            index == 0 &&
            row.name == "Ballast"
          ) {
            this.stateObject[f_event["amar"].groupName][i]["speed"] = value;
            return;
          } else if (
            el.passage == 1 &&
            el.s_type == 1 &&
            index == 1 &&
            row.name == "Ballast"
          ) {
            this.stateObject[f_event["amar"].groupName][i]["speed"] = value;
            return;
          } else if (
            el.passage == 2 &&
            el.s_type == 1 &&
            index == 1 &&
            row.name == "Laden"
          ) {
            this.stateObject[f_event["amar"].groupName][i]["speed"] = value;
            return;
          } else if (
            el.passage == 2 &&
            el.s_type == 2 &&
            index == 0 &&
            row.name == "Laden"
          ) {
            this.stateObject[f_event["amar"].groupName][i]["speed"] = value;
            return;
          }
        });
        this.reduxState.dispatch(action);
      }

      if (f_event && f_event.hasOwnProperty("showUrl")) {
        if (value) {
          let response = await getAPICall(
            `${URL_WITH_VERSION}${f_event["showUrl"].apiLink}${value}`
          );
          let respData = await response;
          f_event["showUrl"]["updateGroup"].map((e) => {
            this.stateObject[e] = respData["data"][e];
            this.stateObject[f_event["showUrl"]["tco_Fieldname"]] =
              respData["data"][f_event["showUrl"]["tco_Fieldname"]];
          });
          this.reduxState.dispatch(action);
        }
      }

      if (f_event && f_event.hasOwnProperty("vijay")) {
        if (value == "104") {
          let elements = document.getElementById("quantity");
          elements.setAttribute("value", "1.00");
          elements.setAttribute("placeholder", "1.00");
          elements.setAttribute("disabled", "");
          elements.classList.add("ant-input-disabled");
          action["value"]["quantity"] = "1.00";
        }
        if (value == "38") {
          let elements = document.getElementById("quantity");
          elements.setAttribute("value", "0.00");
          elements.setAttribute("placeholder", "0.00");
          elements.removeAttribute("disabled");
          elements.classList.remove("ant-input-disabled");
          action["value"]["quantity"] = "0.00";
        }
        this.reduxState.dispatch(action);
      }

      if (f_event && f_event.hasOwnProperty("makepayment")) {
        let actValues = {};
        let postData = {};
        let elements = document.getElementById("period_to");
        if (this.stateObject[f_event["makepayment"]["purchaseType"]] != "1") {
          if (
            this.stateObject.hasOwnProperty("actualID") &&
            this.stateObject["actualID"] > 0 &&
            this.stateObject["actualID"] !== ""
          ) {
            postData["actual_id"] = this.stateObject?.["actualID"];
            postData["pt"] =
              this.stateObject[f_event["makepayment"]["purchaseType"]];
            postData["from"] =
              this.stateObject[f_event["makepayment"]["fromField"]];
          }
          let response = await awaitPostAPICall(
            `${URL_WITH_VERSION}${f_event["makepayment"]["apiLink"]}`,
            postData
          );
          let respData = await response;

          elements.setAttribute("disabled", "");
          elements.classList.add("ant-input-disabled");
          if (respData?.["data"]?.["msg"]) {
            openNotificationWithIcon("info", respData.data.msg, 3);
            actValues[f_event["makepayment"]["showInField"]] =
              respData["data"]["invoice"];
            actValues[f_event["makepayment"]["amountField"]] = "0.00";
            actValues[f_event["makepayment"]["checkBoxes"]] =
              respData["data"]["---------------"] || {};

            action["value"] = actValues;
            this.reduxState.dispatch(action);
            return;
          }

          actValues[f_event["makepayment"]["showInField"]] =
            respData["data"]["invoice"];
          actValues[f_event["makepayment"]["amountField"]] =
            respData["data"]["amount"];
          //
          actValues[f_event["makepayment"]["amountField2"]] =
            respData["data"]["invoice_total"];
          //
          actValues[f_event["makepayment"]["checkBoxes"]] =
            respData["data"]["---------------"];
          action["value"] = actValues;

          this.reduxState.dispatch(action);
        } else {
          elements.removeAttribute("disabled");
          elements.classList.remove("ant-input-disabled");

          // //bellow code is added by vikas for updating on Payment Type change
          const updatedData = this.getState();

          if (updatedData.period_to) {
            delete updatedData["period_to"];
          }
          action["value"] = updatedData;
          action["value"]["-"] = [];
          action["value"]["amount"] = "0.00";
          action["value"]["---------------"] = {};
          action["value"]["days"] = "0.00";

          this.reduxState.dispatch(action);
        }
      }

      if (f_event && f_event.hasOwnProperty("frightvijay")) {
        this.stateObject[".."] &&
          this.stateObject[".."].length > 0 &&
          this.stateObject[".."].map((ele, ind) => {
            let elements = ele["total_amount"];
            this.stateObject["commission"] &&
              this.stateObject["commission"].length > 0 &&
              this.stateObject["commission"].map((el, index) => {
                if (ele["cargo_id"] === el["cargo_id"]) {
                  if (el.rate_type == 2) {
                    el["total_amount"] = el["commision"];
                  } else {
                    el["total_amount"] = el["commision"] * elements * 0.01;
                  }
                }
              });
          });
      }

      if (f_event && f_event.hasOwnProperty("testamar")) {
        if (value) {
          let actValues = {};
          let arr = [];
          let pend_amt = 0;
          this.stateObject["-"] &&
            this.stateObject["-"].length > 0 &&
            this.stateObject["-"].map((ele, ind) => {
              if (ele["outstanding"] == undefined || !ele["outstanding"]) {
                this.stateObject["-"][ind]["outstanding"] = 0.0;
              }
              if (ele["amount"] == undefined || !ele["amount"]) {
                this.stateObject["-"][ind]["amount"] = 0.0;
              }

              if (
                this.stateObject["--"][index]["avialble"] < ele["outstanding"]
              ) {
                pend_amt = this.stateObject["--"][index]["avialble"];
                this.stateObject["--"][index]["avialble"] = 0;
              }
              if (this.stateObject["--"][index]["avialble"] == 0) {
                pend_amt = 0;
              }

              if (
                this.stateObject["--"][index]["avialble"] >= ele["outstanding"]
              ) {
                pend_amt = ele["outstanding"];
                this.stateObject["--"][index]["avialble"] =
                  this.stateObject["--"][index]["avialble"] -
                  ele["outstanding"];
              }

              let obj = {
                invoice_no: ele["invoice_no"],
                adv_trans: this.stateObject["--"][index]["trans_no"],
                amount: pend_amt,
                date: new Date().toJSON(),
              };
              arr.push(obj);
            });
          this.stateObject["---"] = [...arr];
          this.reduxState.dispatch(action);
        }
      }

      if (f_event && f_event.hasOwnProperty("frightper")) {
        this.stateObject[".."] &&
          this.stateObject[".."].length > 0 &&
          this.stateObject[".."].map((ele, ind) => {
            if (ele.f == "104") {
              ele["total_amount"] = ele["inv"] * ele["lumb"] * 0.01;
            } else if (ele.f == "38") {
              ele["total_amount"] =
                ele["cp_qty"] * ele["frt_rate"] * ele["inv"] * 0.01;
            }
          });
      }

      if (f_event && f_event.hasOwnProperty("frightamar")) {
        if (value == "104") {
          let elements = document.getElementById("frt_rate" + index);
          // let elementsinv = document.getElementById('inv' + index);
          let elementslumb = document.getElementById("lumb" + index);
          elementslumb.removeAttribute("disabled");
          elementslumb.classList.remove("ant-input-disabled");

          elements.setAttribute("disabled", "");
          elements.classList.add("ant-input-disabled");

          // elementsinv.setAttribute('value', "0");
          // elementsinv.setAttribute('placeholder', "0");
          // elementsinv.setAttribute('disabled', '');
          // elementsinv.classList.add("ant-input-disabled");
          action["value"]["inv" + index] = "0";
        }
        if (value == "38") {
          let elements = document.getElementById("frt_rate" + index);
          let elementsinv = document.getElementById("inv" + index);
          let elementslumb = document.getElementById("lumb" + index);
          elements.removeAttribute("disabled");
          elementsinv.removeAttribute("value");
          elements.classList.remove("ant-input-disabled");
          elementsinv.setAttribute("placeholder", "0.00");

          elementsinv.removeAttribute("disabled");
          elementsinv.classList.remove("ant-input-disabled");
          action["value"]["inv" + index] = "90.00";
          elementslumb.setAttribute("disabled", "");
          elementslumb.classList.add("ant-input-disabled");
        }
        this.reduxState.dispatch(action);
      }

      // if (f_event && f_event.hasOwnProperty("dropdown_disable")) {
        
      //   // console.log("@####", f_event, document.getElementById("tci_code"));
       
      //     let tciCodeElement = document.getElementById("tci_code");
      //     // console.log("@####", f_event, tciCodeElement);
      //     if (tciCodeElement && value == "262") {
      //       // Disable the tci_code dropdown
      //       tciCodeElement.classList.add("no-pointer","disabled-look");
      //       tciCodeElement.setAttribute("disabled", "true");
      //       tciCodeElement.setAttribute("placeholder", "Select TCI Code");
      //       this.reduxState.dispatch(action);
      //     } else {
      //       // Enable the tci_code dropdown if the condition is not met
      //       tciCodeElement.removeAttribute("disabled", "false");
      //       tciCodeElement.classList.remove("no-pointer","disabled-look");
      //       this.reduxState.dispatch(action);
      //     }
        
      //   this.reduxState.dispatch(action);
      // }

      if (f_event && f_event.hasOwnProperty("pcons")) {
        //let fueltype = this.stateObject[row.group_name][index]['fuel_code'].toLowerCase()
        let fueltype =
          this.stateObject[row.group_name]?.[index]?.fuel_code?.toLowerCase();
        this.stateObject[f_event["pcons"].groupNameto].map((el, i) => {
          if (
            el.passage == 1 &&
            row.name == "Ballast" &&
            this.stateObject[f_event["pcons"].groupNamefrom][i]["passage"] ==
              "1"
          ) {
            let consumtion = el.tsd;
            let finalVal = parseFloat(value) * parseFloat(consumtion);
            finalVal = finalVal ? finalVal.toFixed(2) : finalVal;
            if (finalVal == 0) {
              finalVal = finalVal.toFixed(2);
            }
            this.stateObject[f_event["pcons"].groupNamefrom][i][fueltype] =
              finalVal;
          } else if (
            el.passage == 2 &&
            row.name == "Laden" &&
            this.stateObject[f_event["pcons"].groupNamefrom][i]["passage"] ==
              "2"
          ) {
            let consumtion = el.tsd;
            let finalVal = parseFloat(value) * parseFloat(consumtion);
            finalVal = finalVal ? finalVal.toFixed(2) : finalVal;
            if (finalVal == 0) {
              finalVal = finalVal.toFixed(2);
            }
            this.stateObject[f_event["pcons"].groupNamefrom][i][fueltype] =
              finalVal;
          }
        });
        this.reduxState.dispatch(action);
      }

      if (
        f_event &&
        f_event.hasOwnProperty("isAverageBunkerPlan") &&
        f_event["isAverageBunkerPlan"]
      ) {
        let avaction = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(row.group_name),
          index: index,
          value: {},
        };
        let rowValue = this.stateObject["averagebunkerplan"][index];
        const { endPrice, bunkerExpense } =
          calculateAverageBunkerPlan(rowValue);
        avaction["value"]["end_prc"] = endPrice;
        avaction["value"]["bunker_exp"] = bunkerExpense;
        this.reduxState.dispatch(avaction);
      }

      if (
        f_event &&
        f_event.hasOwnProperty("isLIFOBunkerPlan") &&
        f_event["isLIFOBunkerPlan"]
      ) {
        let avaction = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(row.group_name),
          index: index,
          value: {},
        };
        let rowValue = this.stateObject["lifobunkerplan"][index];
        const { lastinprice, endPrice } = calculateLifoBunkerPlan(rowValue);
        avaction["value"]["bunker_exp"] = lastinprice;
        avaction["value"]["end_prc"] = endPrice;
        this.reduxState.dispatch(avaction);
      }

      if (
        f_event &&
        f_event.hasOwnProperty("isFIFOBunkerPlan") &&
        f_event["isFIFOBunkerPlan"]
      ) {
        let avaction = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(row.group_name),
          index: index,
          value: {},
        };
        let rowValue = this.stateObject["fifobunkerplan"][index];
        const { firstinprice, endPrice } = calculateFifoBunkerPlan(rowValue);
        avaction["value"]["bunker_exp"] = firstinprice;
        avaction["value"]["end_prc"] = endPrice;

        this.reduxState.dispatch(avaction);
      }

      // comapre all condtion accroding

      if (f_event && f_event.hasOwnProperty("comparetype")) {
        this.stateObject[f_event["comparetype"]["groupName"]].map(
          (ele, ind) => {
            if (ele.fuel_type == value) {
              //Modifications has to be done here for per port consumption table
              openNotificationWithIcon(
                "error",
                <div
                  className="notify-error"
                  dangerouslySetInnerHTML={{
                    __html: f_event["comparetype"]["msg"],
                  }}
                />,
                5
              );

              if (index > -1) {
                // only splice array when item is found
                this.stateObject[f_event["comparetype"]["groupName"]] =
                  this.stateObject[f_event["comparetype"]["groupName"]]
                    .slice(0, index)
                    .concat(
                      this.stateObject[
                        f_event["comparetype"]["groupName"]
                      ].slice(index + 1)
                    );
              }
            }
          }
        );
      }

      if (
        f_event &&
        f_event.hasOwnProperty("updateList1") &&
        f_event["updateList1"].hasOwnProperty("apiLink")
      ) {
        let response = await getAPICall(
          `${URL_WITH_VERSION}${f_event["updateList1"]["apiLink"]}${value}`
        );
        let respData = await response;
        if (
          respData &&
          respData.hasOwnProperty("data") &&
          respData["data"] === false
        ) {
          openNotificationWithIcon(
            "error",
            <div
              className="notify-error"
              dangerouslySetInnerHTML={{ __html: respData["message"] }}
            />,
            5
          );
        } else {
          if (this.instanceBy && typeof this.instanceBy === "function") {
            this.instanceBy(f_event["updateList1"], respData.data);
          }
        }
        this.reduxState.dispatch(action);
      }

      if (f_event && f_event.hasOwnProperty("plcons")) {
        let consarr = this.stateObject["."] ?? [];
        let _bunker = this.stateObject[f_event["plcons"]["groupNamefrom"]];
        this.stateObject[f_event["plcons"]["groupNameto"]].map((el, index) => {
          let { tsd = 0, eca_days = 0, passage, funct, days = 0, xpd = 0 } = el;
          let bunkerobj = {
            ..._bunker[index],
          };
          bunkerobj["pc_ifo"] = portfuelCons("3", consarr, funct, days, xpd);
          bunkerobj["pc_vlsfo"] = portfuelCons("5", consarr, funct, days, xpd);
          bunkerobj["pc_lsmgo"] = portfuelCons("7", consarr, funct, days, xpd);
          bunkerobj["pc_mgo"] = portfuelCons("4", consarr, funct, days, xpd);
          bunkerobj["pc_ulsfo"] = portfuelCons("10", consarr, funct, days, xpd);
          _bunker[index] = { ...bunkerobj };
        });
        let grpname = this.getGroupKey(f_event["plcons"]["groupNamefrom"]);
        let totalaction = {
          type: "add",
          formName: this.formName,
          groupName: grpname,
          index: undefined,
          value: {},
        };
        totalaction["value"][grpname] = [..._bunker];
        this.reduxState.dispatch(totalaction);
      }

      if (
        !isNaN(value) &&
        f_event &&
        f_event.hasOwnProperty("formatData") &&
        f_event["formatData"].hasOwnProperty("fieldName") &&
        f_event["formatData"].hasOwnProperty("dhm") &&
        f_event["formatData"]["dhm"] === true
      ) {
        // if (f_event["formatData"]["fieldName"] == "allowed") {
        //   openNotificationWithIcon(
        //     "error",
        //     <div
        //       className="notify-error"
        //       dangerouslySetInnerHTML={{ __html: "Please use given format!" }}
        //     />,
        //     5
        //   );
        // } else {
        let data = this.numberToDaysHoursMinutsObject(value),
          act = Object.assign({}, action);
        act["value"][f_event["formatData"]["fieldName"]] =
          this.numberPad(data["days"], 2) +
          "D:" +
          this.numberPad(data["hours"], 2) +
          "H:" +
          this.numberPad(data["minutes"], 2) +
          "M";
        this.reduxState.dispatch(act);
        //}
      }

      if (
        f_event &&
        f_event.hasOwnProperty("exchangeValue") &&
        f_event["exchangeValue"].hasOwnProperty("fieldName") &&
        f_event["exchangeValue"].hasOwnProperty("localFieldName")
      ) {
        let egAction = Object.assign({}, action);
        if (row["f_dyc_extras"] && row["f_dyc_extras"]["options"]) {
          let option = row["f_dyc_extras"]["options"].filter(
            (e) => "" + e.id === "" + value
          );
          if (option && option.length === 1) {
            if (
              typeof f_event["exchangeValue"]["localFieldName"] === "string" &&
              typeof f_event["exchangeValue"]["fieldName"] === "string"
            ) {
              action["value"][f_event["exchangeValue"]["localFieldName"]] =
                option[0][f_event["exchangeValue"]["fieldName"]];
            } else {
              f_event["exchangeValue"]["fieldName"].map((e, i) => {
                action["value"][f_event["exchangeValue"]["localFieldName"][i]] =
                  option[0][e];
              });
            }
            this.reduxState.dispatch(action);
          }
        } else {
          action["value"][f_event["exchangeValue"]["fieldName"]] = value;
          this.reduxState.dispatch(action);
        }

        if (f_event["exchangeValue"].hasOwnProperty("exchangeGroup")) {
          this.exchangeFromFieldToGroupFields(
            f_event["exchangeValue"]["exchangeGroup"],
            egAction,
            value
          );
        }

        if (
          value &&
          f_event &&
          f_event.hasOwnProperty("differenceValue") &&
          f_event["differenceValue"].hasOwnProperty("fromField") &&
          f_event["differenceValue"].hasOwnProperty("assignField")
        ) {
          if (value && typeof value === "string")
            value = moment(new Date(value));
          let startDate = moment(
            this.stateObject[f_event["differenceValue"]["fromField"]]
          );
          let actValues = {},
            diffDays = 0;
          if (row["group_name"] && row["group_name"] !== "" && index > -1) {
            startDate = moment(
              this.stateObject[action["groupName"]][index][
                f_event["differenceValue"]["fromField"]
              ]
            );
          }

          diffDays = (
            value.diff(startDate, "seconds") /
            (24 * 60 * 60)
          ).toFixed(2);
          // diffDays = value.diff(startDate.subtract(1, 'day'), 'days');
          actValues[f_event["differenceValue"]["assignField"]] = diffDays;

          if (
            f_event.hasOwnProperty("showList") &&
            f_event["showList"].hasOwnProperty("apiLink") &&
            f_event["showList"].hasOwnProperty("showInField") &&
            diffDays > 0
          ) {
            let postData = {
              days: diffDays,
              from: this.stateObject[f_event["differenceValue"]["fromField"]],
              to: value.format("YYYY-MM-DD"),
            };
            if (
              this.stateObject.hasOwnProperty("actualID") &&
              this.stateObject["actualID"] > 0 &&
              this.stateObject["actualID"] !== ""
            ) {
              postData["actual_id"] = this.stateObject["actualID"];
            }
            let response = await awaitPostAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}`,
              postData
            );

            let respData = await response;
            actValues[f_event["showList"]["showInField"]] = respData["data"];
          }

          action["value"] = actValues;
          this.reduxState.dispatch(action);
        }
      }

      if (
        f_event &&
        f_event.hasOwnProperty("apiLink") &&
        f_event.hasOwnProperty("showInField")
      ) {
        // console.log(' >>> showInField');

        let multiUpdateAction = Object.assign({}, action);
        let egAction = Object.assign({}, action);
        let response = undefined;
        let respData = { data: {} };

        if (
          f_event.hasOwnProperty("fieldMinLength") &&
          f_event.fieldMinLength > 0 &&
          value.length >= f_event.fieldMinLength
        ) {
          if (
            f_event.hasOwnProperty("vesselPath") &&
            f_event["vesselPath"] === false
          ) {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event.apiLink}${value}`
            );
          } else {
            response = await getAPICall(
              `${URL_WITH_VERSION}/vessel${f_event.apiLink}${value}`
            );
          }
          respData = await response;

          if (
            respData &&
            respData.hasOwnProperty("data") &&
            respData["data"] === false
          ) {
            // alert("hi")
            openNotificationWithIcon(
              "error",
              <div
                className="notify-error"
                dangerouslySetInnerHTML={{ __html: respData["message"] }}
              />,
              5
            );
          } else {
            action["value"][f_event.showInField] =
              response.data[f_event.valueFrom];
          }

          this.reduxState.dispatch(action);
        } else if (
          f_event.hasOwnProperty("fieldMinLength") &&
          f_event.fieldMinLength > 0 &&
          value.length < f_event.fieldMinLength
        ) {
          response = {
            data: {
              short_name: "",
            },
          };
          action["value"][f_event.showInField] =
            response.data[f_event.valueFrom];
          this.reduxState.dispatch(action);
        } else if (
          !f_event.hasOwnProperty("fieldMinLength") &&
          f_event.hasOwnProperty("addOptionsIn")
        ) {
          if (value) {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event.apiLink}${value}`
            );
            respData = await response;

            if (this.instanceBy && typeof this.instanceBy === "function") {
              this.instanceBy(f_event, respData.data);
            }
          }
        } else if (
          !f_event.hasOwnProperty("fieldMinLength") &&
          !f_event.hasOwnProperty("addOptionsIn")
        ) {
          if (
            typeof f_event["valueFrom"] !== "string" &&
            f_event["valueFrom"].length > 0
          ) {
            let params = {},
              sendParams = "";
            f_event["valueFrom"].map((e) => {
              if (e.hasOwnProperty("isGroup") && e["isGroup"] === true) {
                params[e["name"]] =
                  this.stateObject[action["groupName"]][action["index"]][
                    e["fieldName"]
                  ];
              } else if (e.hasOwnProperty("fieldValue")) {
                params[e["name"]] = e["fieldValue"];
              } else {
                params[e["name"]] = this.stateObject[e["fieldName"]];
              }
            });
            sendParams = objectToQueryStringFunc(params);
            //patch for TCOV
            // if (params.st == 'ECO (Kt/Cons.)') {
            //   let url = `${URL_WITH_VERSION}${f_event.apiLink}?${sendParams}`;
            //   axios.get(url)
            //     .then(response => this.setState({ spdConsResponse: response.data }));
            //   console.log("response" + this.spdConsResponse)
            // } else {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event.apiLink}?${sendParams}`
            );
            // }
          } else {
            const startsWithSpecialChar = /^[^a-zA-Z0-9]/.test(value);

            if (!startsWithSpecialChar) {
              try {
                response = await getAPICall(
                  `${URL_WITH_VERSION}${f_event.apiLink}${value}`
                );
                // console.log(response);
              } catch (error) {
                console.error("API call failed:", error);
              }
            } else {
              openNotificationWithIcon(
                "info",
                "Company name cannot start with special character"
              );
            }
          }
          respData = response;

          if (
            typeof f_event.showInField !== "string" &&
            f_event.showInField.length > 0
          ) {
            if (
              respData &&
              respData.hasOwnProperty("data") &&
              respData.data.length > 0
            ) {
              f_event.showInField.map(
                (e) => (action["value"][e] = respData["data"][e])
              );
            } else {
              var allowedInputs = [
                "ballast_spd",
                "ballast_con",
                "laden_spd",
                "laden_con",
              ];
              f_event.showInField.map((e) => {
                if (allowedInputs.includes(e.toString())) {
                  let elements = document.getElementById(e.toString() + index);

                  if (elements != null && respData.hasOwnProperty("data")) {
                    if (
                      Object.getOwnPropertyNames(respData.data).length === 0
                    ) {
                      elements.removeAttribute("disabled");
                      elements.classList.remove("ant-input-disabled");
                    } else if (e.toString().includes("_con")) {
                      elements.setAttribute("value", respData.data.ballast_con);
                      elements.setAttribute("disabled", "");
                      elements.classList.add("ant-input-disabled");
                    }
                  }
                }
              });
            }
          } else {
            if (respData["data"] === false && !this.stateObject["id"]) {
              // console.log("errrrrr", respData);
              openNotificationWithIcon(
                "error",
                <div
                  className="notify-error"
                  dangerouslySetInnerHTML={{ __html: respData["message"] }}
                />,
                5
              );
            }

            if (f_event.hasOwnProperty("fromValue")) {
              action["value"][f_event.showInField] =
                response.data[f_event.fromValue];
            } else {
              if (this.stateObject["id"]) {
                if (response.data == "false") {
                  action["value"][f_event.showInField] =
                    response.data[f_event.valueFrom];
                }
              } else {
                action["value"][f_event.showInField] =
                  response.data[f_event.valueFrom];
              }
            }
          }
          //
          this.reduxState.dispatch(action);
        }

        if (
          f_event.hasOwnProperty("updateTo") &&
          f_event["updateTo"].hasOwnProperty("fromField") &&
          f_event["updateTo"].hasOwnProperty("toField")
        ) {
          let action = {
            type: "add",
            formName: this.formName,
            groupName: this.getGroupKey(row.group_name),
            index: undefined,
            value: {},
          };
          let fromField = row.f_dyc_extras.options.filter(
            (e) => e.id * 1 === value * 1
          );
          if (fromField && fromField.length > 0) {
            if (
              typeof f_event["updateTo"]["fromField"] !== "string" &&
              typeof f_event["updateTo"]["toField"] !== "string"
            ) {
              f_event["updateTo"]["fromField"].map((e, i) => {
                action["value"][f_event["updateTo"]["toField"][i]] =
                  fromField[0][e]
                    ? fromField[0][e]
                    : respData["data"][e]
                    ? respData["data"][e]
                    : undefined;
              });
            } else {
              action["value"][f_event["updateTo"]["toField"]] =
                fromField[0][f_event["updateTo"]["fromField"]];
            }

            // this.stateObject["bunkers"]=value["portconsp.tableperday"];
            this.reduxState.dispatch(action);
          }
        }

        if (
          f_event.hasOwnProperty("updateMultiValues") &&
          f_event["updateMultiValues"].hasOwnProperty("groupName") &&
          f_event["updateMultiValues"].hasOwnProperty("condition") &&
          f_event["updateMultiValues"].hasOwnProperty("valueFrom")
        ) {
          let updateRows =
            this.stateObject[f_event["updateMultiValues"]["groupName"]];
          let condition = f_event["updateMultiValues"]["condition"];

          if (
            updateRows &&
            typeof updateRows === "object" &&
            updateRows.length > 0
          ) {
            updateRows.map((e, i) => {
              condition.map((ce) => {
                if (
                  parseInt(e[ce["matchColumn"][0]]) ===
                    parseInt(ce["valueIs"][0]) &&
                  parseInt(e[ce["matchColumn"][1]]) ===
                    parseInt(ce["valueIs"][1])
                ) {
                  e[ce["to"]] =
                    respData["data"][f_event["updateMultiValues"]["valueFrom"]];
                }
              });
            });

            multiUpdateAction["groupName"] =
              f_event["updateMultiValues"]["groupName"];
            multiUpdateAction["index"] = undefined;
            multiUpdateAction["value"][
              f_event["updateMultiValues"]["groupName"]
            ] = updateRows;
            this.reduxState.dispatch(multiUpdateAction);
          }
        }
        if (
          f_event.hasOwnProperty("exchangeValue") &&
          f_event["exchangeValue"].hasOwnProperty("exchangeGroup")
        ) {
          this.exchangeFromFieldToGroupFields(
            f_event["exchangeValue"]["exchangeGroup"],
            egAction,
            value
          );
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("differenceValue") &&
        f_event["differenceValue"].hasOwnProperty("fromField") &&
        f_event["differenceValue"].hasOwnProperty("assignField")
      ) {
        //console.log(' >>> differenceValue', value);
        let egAction = Object.assign({}, action);
        let prevDateDifference = Object.assign({}, action);

        if (value && value !== "") {
          let startDate = moment(
            this.stateObject[f_event["differenceValue"]["fromField"]]
          );
          let actValues = {},
            diffDays = 0;

          if (typeof value === "string") value = moment(value);

          if (row["group_name"] && row["group_name"] !== "" && index > -1) {
            startDate = moment(
              this.stateObject[action["groupName"]][index][
                f_event["differenceValue"]["fromField"]
              ]
            );
          }

          if (
            f_event.hasOwnProperty("differenceValue") &&
            f_event["differenceValue"].hasOwnProperty("test")
          ) {
            if (index > 0) {
              startDate = moment(
                this.stateObject[action["groupName"]][index - 1][
                  f_event["differenceValue"]["fromField"]
                ]
              );
            }
          }

          diffDays = (value.diff(startDate, "minutes") / (24 * 60)).toFixed(2);
          if (
            f_event["differenceValue"].hasOwnProperty("format") &&
            f_event["differenceValue"]["format"] === "hours"
          ) {
            diffDays = (value.diff(startDate, "seconds") / (60 * 60)).toFixed(
              2
            );
          }
          if (diffDays == "NaN") {
            diffDays = 0;
          }

          // diffDays = value.diff(startDate.subtract(1, 'day'), 'days');
          actValues[f_event["differenceValue"]["assignField"]] = diffDays;

          if (
            f_event.hasOwnProperty("showList") &&
            f_event["showList"].hasOwnProperty("apiLink") &&
            f_event["showList"].hasOwnProperty("showInField") &&
            diffDays > 0
          ) {
            let postData = {
              days: diffDays,
              from: moment(
                this.stateObject[f_event["differenceValue"]["fromField"]]
              ).format("YYYY-MM-DD HH:mm:ss"),
              to: value.format("YYYY-MM-DDTHH:mm:ss"),
              pt: this.stateObject[f_event["differenceValue"]["purchaseType"]],
            };

            if (
              this.stateObject.hasOwnProperty("actualID") &&
              this.stateObject["actualID"] > 0 &&
              this.stateObject["actualID"] !== ""
            ) {
              postData["actual_id"] = this.stateObject["actualID"];
            }
            let response = await awaitPostAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}`,
              postData
            );
            let respData = await response;

            localStorage.setItem(
              "savemakepayment",
              JSON.stringify(respData.row)
            );
            if (respData["data"] === false) {
              openNotificationWithIcon(
                "error",
                <div
                  className="notify-error"
                  dangerouslySetInnerHTML={{ __html: respData["message"] }}
                />,
                5
              );
            } else {
              if (
                f_event["showList"].hasOwnProperty("isInvoice") &&
                f_event["showList"]["isInvoice"] === true
              ) {
                actValues[f_event["showList"]["showInField"]] =
                  respData["data"]["invoice"];

                actValues[f_event["showList"]["amountField"]] =
                  respData["data"]["amount"];
                //
                actValues[f_event["showList"]["amountField2"]] =
                  respData["data"]["amount"];
                //
                actValues[f_event["showList"]["checkBoxes"]] =
                  respData["data"]["---------------"];
              } else {
                actValues[f_event["showList"]["showInField"]] =
                  respData["data"];
              }
            }
          }

          if (
            parseInt(diffDays) === 0 &&
            f_event["differenceValue"].hasOwnProperty("sameValueField") &&
            f_event["differenceValue"]["sameValueField"] !== ""
          ) {
            actValues[f_event["differenceValue"]["sameValueField"]] =
              this.stateObject[action["groupName"]][action["index"]][
                f_event["differenceValue"]["fromField"]
              ];
          }

          if (
            f_event["differenceValue"].hasOwnProperty("groupCode") &&
            f_event["differenceValue"]["groupCode"] !== ""
          ) {
            action["groupName"] = f_event["differenceValue"]["groupCode"];
          }

          action["value"] = actValues;
          this.reduxState.dispatch(action);
        }

        if (f_event.hasOwnProperty("exchangeGroup")) {
          this.exchangeFromFieldToGroupFields(
            f_event["exchangeGroup"],
            egAction,
            value
          );
        }

        if (
          f_event.hasOwnProperty("prevDateDifference") &&
          typeof f_event["prevDateDifference"] === "object"
        ) {
          this.prevDateDifference(
            f_event,
            value,
            this.stateObject,
            prevDateDifference,
            index
          );
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("amountCalc") &&
        f_event["amountCalc"].hasOwnProperty("assignField") &&
        f_event["amountCalc"].hasOwnProperty("calcutionAsOn") &&
        f_event["amountCalc"].hasOwnProperty("amountField")
      ) {
        // console.log(' >>> amountCalc');
        let amountValue =
          this.stateObject[f_event["amountCalc"]["amountField"]];
        let calcVal = [];
        f_event["amountCalc"]["calcutionAsOn"].forEach((e) => {
          calcVal.push(
            this.stateObject[e] ? (this.stateObject[e] * amountValue) / 100 : 0
          );
        });
        calcVal.map((e) => (amountValue = amountValue + e));
        action["value"][f_event["amountCalc"]["assignField"]] = amountValue;
        this.reduxState.dispatch(action);
      } else if (
        f_event &&
        f_event.hasOwnProperty("updateTo") &&
        f_event.hasOwnProperty("valueFrom") &&
        f_event.hasOwnProperty("matchColumn") &&
        f_event.hasOwnProperty("valueColumn")
      ) {
        // console.log(' >>> updateTo');
        let fRow = row?.f_dyc_extras?.options?.filter(
          (e) => e[f_event["matchColumn"]] * 1 === value * 1
        );
        let actionRow = Object.assign({}, action);
        let actionPur = Object.assign({}, action);
        if (fRow && fRow.length > 0) {
          if (
            typeof f_event["updateTo"] !== "string" &&
            f_event["updateTo"].length > 0 &&
            typeof f_event["valueColumn"] !== "string" &&
            f_event["valueColumn"].length > 0
          ) {
            f_event["valueColumn"].map((vc, vi) => {
              action["value"][f_event["updateTo"][vi]] = fRow[0][vc];
            });
          } else {
            action["value"][f_event["updateTo"]] =
              fRow[0][f_event["valueColumn"]];
          }
          // console.log("action",{action,value,row,stateObject:this.stateObject})
          if (
            action.formName == "tcov_full_estimate_form" &&
            action?.value?.dwt
          ) {
            // if(!action["value"].hasOwnProperty("cargos")){
            //   action["value"].cargos =[{cp_qty:Math.round(parseFloat(action.value?.dwt)*0.9)}]
            // }
            if (this.stateObject?.cargos?.length === 1) {
              action["value"].cargos = [
                {
                  ...this.stateObject?.cargos[0],
                  cp_qty: Math.round(parseFloat(action.value?.dwt) * 0.9),
                },
              ];
            } else if (this.stateObject?.cargos?.length > 1) {
              action["value"].cargos = this.stateObject.cargos.map((cargo) => ({
                ...cargo,
                cp_qty: Math.round(parseFloat(action.value?.dwt) * 0.9),
              }));
            }
          }
          this.reduxState.dispatch(action);
        }

        if (
          f_event.hasOwnProperty("showList") &&
          f_event["showList"].hasOwnProperty("apiLink") &&
          f_event["showList"].hasOwnProperty("updateColumn") &&
          f_event["showList"].hasOwnProperty("valueColumn") &&
          f_event["showList"].hasOwnProperty("dataFrom")
        ) {
          let response;

          if (f_event["showList"].hasOwnProperty("amar")) {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}${
                this.stateObject["vesseldetails"][
                  f_event["showList"]["dataFrom"]
                ]
              }/${value}`
            );
          } else if (f_event["showList"].hasOwnProperty("roushan")) {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}${
                this.stateObject["tcidetails"][f_event["showList"]["dataFrom"]]
              }/${value}`
            );
          } else {
            response = await getAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}${value}`
            );

            if (response.data["."].hasOwnProperty("eco_data")) {
              const eco_data = response.data["."].eco_data;

              const order = ["IFO", "VLSFO", "ULSFO", "LSMGO", "MGO"];

              const orderDict = order.reduce((acc, item, index) => {
                acc[item] = index;
                return acc;
              }, {});

              const sortedEcoData = eco_data.sort(
                (a, b) => orderDict[a.fuel_code] - orderDict[b.fuel_code]
              );
              response.data["eco_data"] = sortedEcoData;
            }

            // if (response.data["."]) {
            //   response.data["."].cp_data.map((ele) => {
            //     ele.fuel_code = ele.fuel_type;
            //   });
            // }
          }

          let respData = await response;
          if (respData?.data?.type_code) {
            let d = new Date();
            d.setTime(d.getTime() + 3600 * 10 * 1000);
            cookies.set("typecode", `${respData?.data?.type_code}`, {
              path: "/",
              expires: d,
            });
          }

          // updarted by amar 08-09-2022
          if (
            typeof f_event["showList"]["updateColumn"] !== "string" &&
            f_event["showList"]["updateColumn"].hasOwnProperty("from") &&
            f_event["showList"]["updateColumn"].hasOwnProperty("to") &&
            f_event["showList"]["updateColumn"].hasOwnProperty("index")
          ) {
            let valColumn =
              respData["data"][f_event["showList"]["valueColumn"]];
            f_event["showList"]["updateColumn"]["from"].map((key, value) => {
              actionRow["value"][
                f_event["showList"]["updateColumn"]["to"][value]
              ] =
                valColumn[f_event["showList"]["updateColumn"]["index"]][
                  f_event["showList"]["updateColumn"]["from"][value]
                ];
              actionRow["value"][
                f_event["showList"]["updateColumn"]["to"][value]
              ] =
                respData["data"][f_event["showList"]["valueColumn"]][
                  f_event["showList"]["updateColumn"]["index"]
                ][f_event["showList"]["updateColumn"]["from"][value]];
            });
            //  actionRow['value'][f_event['showList']['updateColumn']['to'][0]] = valColumn[f_event['showList']['updateColumn']['index']][f_event['showList']['updateColumn']['from'][0]];
            //  actionRow['value'][f_event['showList']['updateColumn']['to'][1]] = respData['data'][f_event['showList']['valueColumn']][f_event['showList']['updateColumn']['index']][f_event['showList']['updateColumn']['from'][1]];
          } else {
            actionRow["value"][f_event["showList"]["updateColumn"]] =
              respData["data"][f_event["showList"]["valueColumn"]];
          }

          if (
            f_event["showList"].hasOwnProperty("otherUpdateColumn") &&
            f_event["showList"]["otherUpdateColumn"].hasOwnProperty(
              "sourceColumn"
            ) &&
            f_event["showList"]["otherUpdateColumn"].hasOwnProperty(
              "dependentOn"
            )
          ) {
            let dependentOn =
              f_event["showList"]["otherUpdateColumn"]["dependentOn"];
            let sourceColumn =
              f_event["showList"]["otherUpdateColumn"]["sourceColumn"];
            actionRow["value"][sourceColumn] =
              respData["data"][sourceColumn][dependentOn];
            actionRow["value"]["purchase_data"] =
              respData["data"][sourceColumn];
          }

          this.reduxState.dispatch(actionRow);
        }

        if (
          f_event &&
          f_event.hasOwnProperty("showFields") &&
          f_event["showFields"].hasOwnProperty("apiLink") &&
          f_event["showFields"].hasOwnProperty("showInField")
        ) {
          let response = await getAPICall(
            `${URL_WITH_VERSION}${f_event["showFields"].apiLink}${value}`
          );
          let respData = await response;
          if (
            respData &&
            respData.hasOwnProperty("data") &&
            respData["data"] === false
          ) {
            if (
              f_event["showFields"]["fromField"] &&
              typeof f_event["showFields"]["fromField"] !== "string"
            ) {
              f_event["showFields"]["fromField"].map((ff, fi) => {
                actionPur["value"][
                  f_event["showFields"]["showInField"][fi]
                ] = 0;
              });
            }
            openNotificationWithIcon(
              "error",
              <div
                className="notify-error"
                dangerouslySetInnerHTML={{ __html: respData["message"] }}
              />,
              5
            );
          } else {
            if (
              f_event["showFields"]["fromField"] &&
              typeof f_event["showFields"]["fromField"] !== "string"
            ) {
              f_event["showFields"]["fromField"].map((ff, fi) => {
                actionPur["value"][f_event["showFields"]["showInField"][fi]] =
                  respData["data"][ff] ? respData["data"][ff] : 0;
              });
            } else {
              actionPur["value"][f_event["showFields"]["showInField"]] =
                response.data[f_event["showFields"]["fromField"]];
            }
          }

          this.reduxState.dispatch(actionPur);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("roushanfindSpeed") &&
        f_event["roushanfindSpeed"]
      ) {
        let portitarr = JSON.parse(
          JSON.stringify(this.stateObject[f_event.groupName])
        );

        Array.isArray(portitarr) &&
          portitarr.forEach((el, ind) =>
            this.roushanfindrspeed(f_event, ind, undefined)
          );
      } else if (
        f_event &&
        f_event.hasOwnProperty("matchUpdate") &&
        f_event["matchUpdate"].hasOwnProperty("apiLink")
      ) {
        if (
          f_event["matchUpdate"].hasOwnProperty("groupCode") &&
          f_event["matchUpdate"]["groupCode"] &&
          f_event["matchUpdate"]["groupCode"] !== ""
        ) {
          let response = await getAPICall(
            `${URL_WITH_VERSION}${f_event["matchUpdate"].apiLink}${value}`
          );

          let respData = await response;

          let stateValue =
            this.stateObject[f_event["matchUpdate"]["groupCode"]];
          if (
            stateValue &&
            typeof stateValue === "object" &&
            stateValue.length > 0
          ) {
            stateValue.map((e) => {
              let item = respData["data"].filter(
                (e1) =>
                  e1[f_event["matchUpdate"]["matchColumn"]] ===
                  e[f_event["matchUpdate"]["matchColumn"]]
              );
              if (item && item.length === 1) {
                e[f_event["matchUpdate"]["matchValue"]] =
                  item[0][f_event["matchUpdate"]["matchValue"]];
              }
            });
            action["value"][f_event["matchUpdate"]["groupCode"]] = stateValue;
          } else if (
            stateValue &&
            typeof stateValue === "object" &&
            !stateValue.length
          ) {
            let item = respData["data"].filter(
              (e1) =>
                e1[f_event["matchUpdate"]["matchColumn"]] ===
                stateValue[f_event["matchUpdate"]["matchColumn"]]
            );
            if (item && item.length === 1) {
              stateValue[f_event["matchUpdate"]["matchValue"]] =
                item[0][f_event["matchUpdate"]["matchValue"]];
            }
            action["value"][f_event["matchUpdate"]["groupCode"]] = stateValue;
          }
        }

        if (
          f_event &&
          f_event.hasOwnProperty("updateFields") &&
          f_event["updateFields"].hasOwnProperty("updateTo") &&
          f_event["updateFields"].hasOwnProperty("valueFrom") &&
          f_event["updateFields"].hasOwnProperty("matchColumn") &&
          f_event["updateFields"].hasOwnProperty("valueColumn")
        ) {
          let fRow = row.f_dyc_extras.options.filter(
            (e) => e[f_event["updateFields"]["matchColumn"]] * 1 === value * 1
          );
          if (fRow.length > 0) {
            if (
              typeof f_event["updateFields"]["updateTo"] !== "string" &&
              f_event["updateFields"]["updateTo"].length > 0 &&
              typeof f_event["updateFields"]["valueColumn"] !== "string" &&
              f_event["updateFields"]["valueColumn"].length > 0
            ) {
              f_event["updateFields"]["valueColumn"].map((vc, vi) => {
                action["value"][f_event["updateFields"]["updateTo"][vi]] =
                  fRow[0][vc] || "";
              });
            } else {
              action["value"][f_event["updateFields"]["updateTo"]] =
                fRow[0][f_event["updateFields"]["valueColumn"]] || "";
            }
          }
        }

        if (
          f_event &&
          f_event.hasOwnProperty("showFields") &&
          f_event["showFields"].hasOwnProperty("apiLink") &&
          f_event["showFields"].hasOwnProperty("showInField")
        ) {
          let response = await getAPICall(
            `${URL_WITH_VERSION}${f_event["showFields"].apiLink}${value}`
          );
          let respData = await response;
          if (
            respData &&
            respData.hasOwnProperty("data") &&
            respData["data"] === false
          ) {
            if (f_event["showFields"].apiLink !== "/tci/get-fields/")
              //patch to avoid tci msg
              openNotificationWithIcon(
                "error",
                <div
                  className="notify-error"
                  dangerouslySetInnerHTML={{ __html: respData["message"] }}
                />,
                5
              );
          } else {
            if (
              f_event["showFields"]["fromField"] &&
              typeof f_event["showFields"]["fromField"] !== "string"
            ) {
              f_event["showFields"]["fromField"].map((ff, fi) => {
                action["value"][f_event["showFields"]["showInField"][fi]] =
                  respData["data"][ff] ? respData["data"][ff] : 0;
              });
            } else {
              action["value"][f_event["showFields"]["showInField"]] =
                response.data[f_event["showFields"]["fromField"]] || "";
            }
          }
        }
        this.reduxState.dispatch(action);

        if (
          f_event &&
          f_event.hasOwnProperty("updateList") &&
          f_event["updateList"].hasOwnProperty("apiLink")
        ) {
          let response = await getAPICall(
            `${URL_WITH_VERSION}${f_event["updateList"]["apiLink"]}${value}`
          );
          let respData = await response;

          if (
            respData &&
            respData.hasOwnProperty("data") &&
            respData["data"] === false
          ) {
            openNotificationWithIcon(
              "error",
              <div
                className="notify-error"
                dangerouslySetInnerHTML={{ __html: respData["message"] }}
              />,
              5
            );
          } else {
            if (this.instanceBy && typeof this.instanceBy === "function") {
              this.instanceBy(f_event["updateList"], respData.data, value);
            }
          }
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("interChangeValue") &&
        f_event["interChangeValue"].hasOwnProperty("valueFrom") &&
        f_event["interChangeValue"].hasOwnProperty("condition")
      ) {
        // console.log('interChangeValue')
        let oldValue = Object.assign(
          {},
          this.stateObject[f_event["interChangeValue"]["valueFrom"]]
        );
        let bv = Object.assign(
          [],
          this.stateObject[f_event["interChangeValue"]["valueTo"]]
        );
        let bvi = [],
          valueIsObject = false;
        value = !value ? false : value;
        f_event["interChangeValue"]["condition"].map((e) => {
          if (typeof e.valueIs !== "object" && value === e.valueIs) {
            bvi = Object.assign([], oldValue[e.to]);
            bvi.map((e1) => {
              let item = bv.filter(
                (e2) => e1[e["matchColumn"]] === e2[e["matchColumn"]]
              );
              if (item && item.length === 1) {
                e1[e["copyValue"]] = item[0][e["copyValue"]];
              }
            });
          } else if (
            typeof e.valueIs === "object" &&
            e.valueIs.hasOwnProperty("length") &&
            e.valueIs.length > 0
          ) {
            if (
              bv[action["index"]][e.matchColumn[0]] === e.valueIs[0] &&
              bv[action["index"]][e.matchColumn[1]] === e.valueIs[1] &&
              oldValue[e.index]
            ) {
              bv[action["index"]][e.to] = oldValue[e.index][e.copyValue];
              valueIsObject = true;
            }
          }
        });
        if (valueIsObject === true) bvi = bv;
        action["value"][f_event["interChangeValue"]["valueTo"]] = bvi;
        this.reduxState.dispatch(action);
      } else if (
        f_event &&
        f_event.hasOwnProperty("rfindSpeed") &&
        f_event["rfindSpeed"]
      ) {
        /*

            this code has been transfered to the roushanfindrspeed function

        let portitarr = JSON.parse(
          JSON.stringify(this.stateObject[f_event.groupName])
        );
        let portitobj = portitarr[index];
        let speedarr = this.stateObject[f_event.fromGroup];
        let wfper = portitobj["wf_per"];
        let spdobj = {};

        let speed = 0;
        let effspeed = 0;
        let gsd = 0;

        if (portitobj["s_type"] == "1") {
          //  eco
          spdobj = speedarr[1];
          speed =
            value == "1"
              ? spdobj?.["ballast_spd"] ?? 0
              : spdobj?.["laden_spd"] ?? 0;
        } else if (portitobj["s_type"] == "2") {
          // cp
          spdobj = speedarr[0];
          speed =
            value == "1"
              ? spdobj?.["ballast_spd"] ?? 0
              : spdobj?.["laden_spd"] ?? 0;
        } else if (portitobj["s_type"] == "3") {
          // warranted
          spdobj = speedarr[2];
          speed =
            value == "1"
              ? spdobj?.["ballast_spd"] ?? 0
              : spdobj?.["laden_spd"] ?? 0;
        }
        effspeed = parseFloat(speed) * (1 - wfper * 0.01);

        gsd = portitobj["miles"] / (speed * 24);
        let tsd = portitobj["miles"] / (effspeed * 24);
        let xsd = tsd * 1 - gsd * 1;

        portitobj["speed"] = isNaN(speed) ? "0.00" : speed;
        portitobj["eff_speed"] = isNaN(effspeed) ? "0.00" : effspeed.toFixed(2);
        portitobj["gsd"] = isNaN(gsd) ? "0.00" : parseFloat(gsd).toFixed(2);
        portitobj["tsd"] = isNaN(gsd) ? "0.00" : parseFloat(tsd).toFixed(2);
        portitobj["xsd"] = isNaN(gsd) ? "0.00" : parseFloat(xsd).toFixed(2);

        portitarr[index] = { ...portitobj };

        let action = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(f_event.groupName),
          index: undefined,
          value: {},
        };
        action["value"] = {
          [f_event.groupName]: portitarr,
        };
        this.reduxState.dispatch(action);

*/
        this.roushanfindrspeed(f_event, index, value);
      } else if (
        f_event &&
        f_event.hasOwnProperty("formula") &&
        f_event.hasOwnProperty("updateTo") &&
        (f_event.hasOwnProperty("withInReplace") ||
          f_event.hasOwnProperty("externalReplace"))
      ) {
        // console.log('formula')
        if (
          f_event.hasOwnProperty("matchAndUpdate") &&
          f_event["matchAndUpdate"].hasOwnProperty("match") &&
          f_event["matchAndUpdate"].hasOwnProperty("updateTo")
        ) {
          let mu = Object.assign({}, action);
          let fRow = row.f_dyc_extras.options.filter(
            (e) => e[f_event["matchAndUpdate"]["match"]] * 1 === value * 1
          );
          if (fRow && fRow.length === 1) {
            mu["value"][f_event["matchAndUpdate"]["updateTo"]] =
              fRow[0][f_event["matchAndUpdate"]["valueFrom"]];
          }
          this.reduxState.dispatch(mu);
        }

        this.resolveFormula(f_event, index, Object.assign({}, action));
      } else if (
        f_event &&
        f_event.hasOwnProperty("formulas") &&
        f_event["formulas"].length > 0
      ) {
        let totalObject = Object.assign({}, action);
        let groupAction = Object.assign({}, action);
        let copyValue = Object.assign({}, action);
        let setListValue = Object.assign({}, action);
        f_event["formulas"].map((e) => {
          this.resolveFormula(e, index, Object.assign({}, action), row, value);
        });

        if (
          f_event["copyValue"] &&
          f_event["copyValue"].hasOwnProperty("updateTo")
        ) {
          if (f_event["copyValue"].hasOwnProperty("externalField")) {
            action["value"][f_event["copyValue"]["updateTo"]] =
              this.stateObject[f_event["copyValue"]["externalField"]];
            this.reduxState.dispatch(action);
          }
        }

        if (
          f_event.hasOwnProperty("dateAdds") &&
          typeof f_event["dateAdds"] === "object" &&
          f_event["dateAdds"].hasOwnProperty("length")
        ) {
          f_event["dateAdds"].map((fda) => {
            this.dateAdded(fda, index, Object.assign({}, action));
          });
        } else if (
          f_event.hasOwnProperty("dateAdd") &&
          typeof f_event["dateAdd"] === "object"
        ) {
          this.dateAdded(f_event, index, Object.assign({}, action));
        }

        if (
          f_event.hasOwnProperty("totalGSum") &&
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"].hasOwnProperty("to") &&
          !f_event["totalGSum"].hasOwnProperty("multipleFromGroup")
        ) {
          let fromGKey = this.getGroupKey(
            f_event["totalGSum"]["from"]["groupKey"]
          );
          let toGKey = this.getGroupKey(f_event["totalGSum"]["to"]["groupKey"]);
          let sum = {},
            totalObj = 0;
          if (f_event["totalGSum"]["from"].hasOwnProperty("field")) {
            this.stateObject[fromGKey].map((e) => {
              if (
                e &&
                e.hasOwnProperty(f_event["totalGSum"]["from"]["field"]) &&
                e[f_event["totalGSum"]["from"]["field"]] * 1 > 0
              ) {
                totalObj =
                  totalObj + e[f_event["totalGSum"]["from"]["field"]] * 1;
              }
            });
            totalObject["groupName"] = toGKey;
            totalObject["index"] = undefined;
            totalObject["value"][f_event["totalGSum"]["to"]["field"]] =
              totalObj;
            if (f_event["totalGSum"]["to"].hasOwnProperty("index")) {
              totalObject["index"] = f_event["totalGSum"]["to"]["index"];
            }
            this.reduxState.dispatch(totalObject);
          } else if (f_event["totalGSum"]["from"].hasOwnProperty("fields")) {
            try {
              let totalRows = this.stateObject[fromGKey].length;

              f_event["totalGSum"]["from"]["fields"].map((fe) => {
                if (!sum.hasOwnProperty(fe)) sum[fe] = 0;
                this.stateObject[fromGKey]?.map((e) => {
                  if (e && e.hasOwnProperty(fe) && e[fe] * 1 >= 0) {
                    sum[fe] = sum[fe] + e[fe] * 1;
                  }
                  if (e && e.hasOwnProperty(fe) && e[fe] * 1 <= 0) {
                    sum[fe] = sum[fe] - e[fe] * 1;
                  }
                });
              });
              totalObject["groupName"] = toGKey;
              totalObject["index"] = undefined;
              if (f_event["totalGSum"]["to"].hasOwnProperty("index")) {
                totalObject["index"] = f_event["totalGSum"]["to"]["index"];
              }

              let round_Off = f_event["totalGSum"]["roundOff"]
                ? f_event["totalGSum"]["roundOff"]
                : 2;
              f_event["totalGSum"]["from"]["fields"].map((fe, fi) => {
                totalObject["value"][f_event["totalGSum"]["to"]["fields"][fi]] =
                  parseFloat(sum[fe]).toFixed(round_Off);
              });

              if (totalObject?.["value"]?.["total_eff_spd"]) {
                totalObject["value"]["total_eff_spd"] = (
                  parseFloat(totalObject["value"]["total_eff_spd"]) / totalRows
                ).toFixed(2);
                totalObject["value"]["total_speed"] = (
                  parseFloat(totalObject["value"]["total_speed"]) / totalRows
                ).toFixed(2);
              }

              if (totalObject?.["value"]?.["ttl_eff_speed"]) {
                totalObject["value"]["ttl_eff_speed"] = (
                  parseFloat(totalObject["value"]["ttl_eff_speed"]) / totalRows
                ).toFixed(2);
                totalObject["value"]["ttl_speed"] = (
                  parseFloat(totalObject["value"]["ttl_speed"]) / totalRows
                ).toFixed(2);
              }

              this.reduxState.dispatch(totalObject);

              if (f_event["totalGSum"] && f_event["totalGSum"]["loadqtysum"]) {
                let portitr = this.stateObject["portitinerary"];
                let totalloadqty = portitr.reduce((ac, el) => {
                  // Parse the l_d_qty value from the current element, defaulting to 0 if it's not a valid number
                  const ldQty = parseFloat(el?.l_d_qty) || 0;
                  // Check the funct condition to determine whether to add or subtract ldQty
                  if (el.funct === "9") {
                    // Subtract ldQty if funct is "9"
                    return ac - ldQty;
                  } else {
                    // Add ldQty otherwise
                    return ac + ldQty;
                  }
                }, 0);
                let totalaction = {
                  type: "add",
                  formName: this.formName,
                  groupName: this.getGroupKey("totalitinerarysummary"),
                  index: undefined,
                  value: {},
                };
                totalaction["value"]["totalitinerarysummary"] = {
                  total_lq: totalloadqty.toFixed(2),
                };
                this.reduxState.dispatch(totalaction);
              }

              if (
                f_event["totalGSum"].hasOwnProperty("copyValue") &&
                typeof f_event["totalGSum"]["copyValue"] === "object" &&
                f_event["totalGSum"]["copyValue"].length > 0
              ) {
                copyValue["value"] = {};
                f_event["totalGSum"]["copyValue"].map((e) => {
                  copyValue["value"] = {};
                  copyValue["value"][e["toField"]] =
                    totalObject["value"][e["fromField"]];
                  copyValue["groupName"] = undefined;
                  copyValue["index"] = undefined;

                  if (typeof e["groupKey"] === "string") {
                    copyValue["groupName"] = e["groupKey"];
                  }

                  this.reduxState.dispatch(copyValue);
                });
              } else if (
                f_event["totalGSum"].hasOwnProperty("copyValue") &&
                f_event["totalGSum"]["copyValue"].hasOwnProperty("groupKey") &&
                f_event["totalGSum"]["copyValue"]["groupKey"] === false
              ) {
                copyValue["value"] = {};
                if (
                  typeof f_event["totalGSum"]["copyValue"]["fromField"] ===
                  "string"
                ) {
                  copyValue["value"][
                    f_event["totalGSum"]["copyValue"]["toField"]
                  ] =
                    totalObject["value"][
                      f_event["totalGSum"]["copyValue"]["fromField"]
                    ];
                } else if (
                  typeof f_event["totalGSum"]["copyValue"]["fromField"] ===
                    "object" &&
                  f_event["totalGSum"]["copyValue"]["fromField"].length > 0
                ) {
                  f_event["totalGSum"]["copyValue"]["fromField"].map((e, i) => {
                    copyValue["value"][
                      f_event["totalGSum"]["copyValue"]["toField"][i]
                    ] = totalObject["value"][e];
                  });
                }

                copyValue["groupName"] = undefined;
                copyValue["index"] = undefined;
                this.reduxState.dispatch(copyValue);
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        } else if (
          f_event.hasOwnProperty("totalGSum") &&
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"].hasOwnProperty("to") &&
          f_event["totalGSum"].hasOwnProperty("multipleFromGroup") &&
          f_event["totalGSum"]["multipleFromGroup"] === true
        ) {
          let totalObj = 0;
          f_event["totalGSum"]["from"].map((tf) => {
            let fromGKey = this.getGroupKey(tf["groupKey"]);
            tf["fields"].map((fe) => {
              this.stateObject[fromGKey].map((e) => {
                if (e && e.hasOwnProperty(fe) && e[fe] * 1 > 0) {
                  totalObj =
                    totalObj +
                    e[fe] *
                      (tf.hasOwnProperty("multiple") ? tf["multiple"] : 1);
                } else {
                  totalObj = totalObj + e[fe] * 1;
                }
              });
            });
          });

          totalObject["groupName"] = undefined;
          totalObject["index"] = undefined;

          if (
            f_event["totalGSum"]["to"].hasOwnProperty("groupKey") &&
            f_event["totalGSum"]["to"]["groupKey"] !== ""
          ) {
            totalObject["groupName"] = this.getGroupKey(
              f_event["totalGSum"]["to"]["groupKey"]
            );
          }

          f_event["totalGSum"]["to"]["fields"].map((fe) => {
            totalObject["value"][fe] = totalObj;
            if (
              f_event["totalGSum"]["roundOff"] &&
              f_event["totalGSum"]["roundOff"] !== ""
            ) {
              totalObject["value"][fe] = totalObj.toFixed(
                f_event["totalGSum"]["roundOff"]
              );
            }
          });
          this.reduxState.dispatch(totalObject);
        }

        if (
          f_event.hasOwnProperty("groupTotalOneToAnother") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("secondGroup") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("condition") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("formatDHM") &&
          f_event["groupTotalOneToAnother"]["formatDHM"] === true
        ) {
          groupAction["groupName"] =
            f_event["groupTotalOneToAnother"]["secondGroup"];
          this.stateObject[
            f_event["groupTotalOneToAnother"]["secondGroup"]
          ].map((e, i) => {
            if (
              f_event["groupTotalOneToAnother"]["condition"]["operator"] ===
                "=" &&
              eval(
                `'${this.getGroupKey(
                  e[f_event["groupTotalOneToAnother"]["condition"]["to"]]
                )}'==='${
                  action[f_event["groupTotalOneToAnother"]["condition"]["from"]]
                }'`
              ) === true
            ) {
              groupAction["index"] = i;
            }
          });

          let total = 0;
          this.stateObject[action["groupName"]].map((e) => {
            total =
              total +
              this.stringToDaysHoursMinutsObject(
                e[f_event["groupTotalOneToAnother"]["fromFieldName"]],
                true
              );
          });
          total = this.numberToDaysHoursMinutsObject(total);
          groupAction["value"][
            f_event["groupTotalOneToAnother"]["toFieldName"]
          ] =
            this.numberPad(total["days"], 2) +
            "D:" +
            this.numberPad(total["hours"], 2) +
            "H:" +
            this.numberPad(total["minutes"], 2) +
            "M";
          this.reduxState.dispatch(groupAction);
        }

        if (
          f_event.hasOwnProperty("showList") &&
          f_event.hasOwnProperty("method") &&
          f_event["showList"].hasOwnProperty("apiLink") &&
          f_event["showList"].hasOwnProperty("fromField") &&
          f_event["showList"].hasOwnProperty("updateGroup")
        ) {
          if (
            f_event["method"].toUpperCase() === "POST" &&
            typeof f_event["showList"]["fromField"] === "string" &&
            f_event["showList"]["fromField"].toUpperCase() === "COBJ"
          ) {
            const request = await awaitPostAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}`,
              this.stateObject
            );
            const respData = await request["data"];
            let groupKey = this.getGroupKey(f_event["showList"]["updateGroup"]);
            setListValue["groupName"] = undefined;
            setListValue["index"] = undefined;
            setListValue["value"] = {};
            setListValue["value"][groupKey] = respData;
            setListValue["value"][f_event["showList"]["updateGroup"]] =
              respData;
            this.reduxState.dispatch(setListValue);
          }
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("nextFormula") &&
        f_event.hasOwnProperty("updateTo") &&
        (f_event.hasOwnProperty("withInReplace") ||
          f_event.hasOwnProperty("externalReplace"))
      ) {
        //console.log('formula')
        this.nextResolveFormula(
          f_event,
          index,
          index + 1,
          Object.assign({}, action)
        );
      } else if (
        f_event &&
        f_event.hasOwnProperty("dateAdd") &&
        typeof f_event["dateAdd"] === "object"
      ) {
        // console.log('dateAdd')
        this.dateAdded(f_event, index, Object.assign({}, action), () => {
          if (
            f_event &&
            f_event.hasOwnProperty("nextDateAdd") &&
            typeof f_event["nextDateAdd"] === "object" &&
            this.stateObject[action["groupName"]] &&
            this.stateObject[action["groupName"]][index + 1]
          ) {
            this.nextDateAdded(
              f_event,
              index + 1,
              Object.assign({}, action),
              index + 1
            );
          }
        });

        if (
          f_event &&
          f_event.hasOwnProperty("getDay") &&
          typeof f_event["getDay"] === "object" &&
          f_event["getDay"].hasOwnProperty("updateTo") &&
          f_event["getDay"].hasOwnProperty("fromField")
        ) {
          let ff =
            this.stateObject[action["groupName"]][index][
              f_event["getDay"]["fromField"]
            ];
          if (ff && typeof ff === "string") {
            ff = moment(ff);
          }
          if (ff) {
            action["value"][f_event["getDay"]["updateTo"]] = ff.format("ddd");
            this.reduxState.dispatch(action);
          }
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("nextDateAdd") &&
        typeof f_event["nextDateAdd"] === "object"
      ) {
        let tsl = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(row.group_name),
          index: index,
          value: {},
        };
        let dateDiffAssign = Object.assign({}, tsl);
        let addDateAssign = Object.assign({}, tsl);
        let ftDate = Object.assign({}, tsl);

        // let currentdate = this.stateObject[f_event["nextDateAdd"]["fromField"]];
        // let days = this.stateObject[f_event["nextDateAdd"]["addField"]];
        // const value = this.nextdate(currentdate, days);
        // tsl["value"][f_event["nextDateAdd"]["updateTo"]] =
        //   dayjs(value).format("YYYY-MM-DD HH:mm");
        // this.reduxState.dispatch(tsl);

        if (
          f_event.hasOwnProperty("postAPIList") &&
          f_event["postAPIList"].hasOwnProperty("updateGroup") &&
          f_event["postAPIList"].hasOwnProperty("dynamicGroup") &&
          f_event["postAPIList"]["dynamicGroup"] === true &&
          f_event["postAPIList"].hasOwnProperty("apiLink")
        ) {
          this.reduxState.dispatch({
            type: "add",
            formName: this.formName,
            groupName: undefined,
            index: undefined,
            value: { processing: false },
          });
          let _url = `${URL_WITH_VERSION}${f_event["postAPIList"]["apiLink"]}`;
          let data = this.getStateObject(action["groupName"]);
          let postData = f_event["postAPIList"]["postData"];
          postData[f_event["postAPIList"]["postDataKey"]] = data.filter(
            (e) => typeof e === "object"
          );

          postData[f_event["postAPIList"]["postDataKey"]].map((e, i) => {
            if (e && e["from_date"] && e["to_date"]) {
              // postData[f_event["postAPIList"]["postDataKey"]][i]["from_time"] =
              //   moment(e["from_time"]).isValid() === true
              //     ? moment(e["from_time"]).format("HH:mm")
              //     : e["from_time"];
              // postData[f_event["postAPIList"]["postDataKey"]][i]["to_time"] =
              //   moment(e["to_time"]).isValid() === true
              //     ? moment(e["to_time"]).format("HH:mm")
              //     : e["to_time"];
              postData[f_event["postAPIList"]["postDataKey"]][i]["from_date"] =
                moment(e["from_date"]).isValid() === true
                  ? moment(e["from_date"]).format("YYYY-MM-DD HH:mm")
                  : e["from_date"];
              postData[f_event["postAPIList"]["postDataKey"]][i]["to_date"] =
                moment(e["to_date"]).isValid() === true
                  ? moment(e["to_date"]).format("YYYY-MM-DD HH:mm")
                  : e["to_date"];
            }
          });
          let adjustedData = { ...postData };
          if (index !== postData?.laytime?.length - 1) {
            adjustedData = this.adjustDatesWithDurationAfterInsertion(
              postData,
              index
            );
          }
          let request = await awaitPostAPICall(_url, adjustedData);
          // let request = await awaitPostAPICall(_url, postData);
          let response = await request["data"];

          if (
            response &&
            response.hasOwnProperty("change") &&
            response.hasOwnProperty("timeSorttedList")
          ) {
            let __tsl = {
              type: "add",
              formName: this.formName,
              groupName: this.getGroupKey(row.group_name),
              index: undefined,
              value: {},
            };

            if (response.change === true) {
              response.timeSorttedList.map((e) => {
                let f = e["from_date"];
                let t = e["to_date"];
                e["from_date"] = moment(f).format("YYYY-MM-DD HH:mm");
                //e["from_time"] = moment(f).format("HH:mm");
                e["to_date"] = moment(t).format("YYYY-MM-DD HH:mm");
                //e["to_time"] = moment(t).format("HH:mm");
                //e["day"] = e["from_date"].day;
              });
            }
            __tsl["value"][tsl["groupName"]] = response.timeSorttedList;
            this.reduxState.dispatch(__tsl);
            this.reduxState.dispatch({
              type: "add",
              formName: this.formName,
              groupName: undefined,
              index: undefined,
              value: { processing: false },
            });
          }
        }

        if (
          f_event.hasOwnProperty("differenceValueDHM") &&
          f_event["differenceValueDHM"].hasOwnProperty("combineFromValue") &&
          f_event["differenceValueDHM"].hasOwnProperty("combineToValue") &&
          f_event["differenceValueDHM"].hasOwnProperty("updateTo")
        ) {
          let fromDate = this.dateObject(
              this.stateObject[action["groupName"]][index][
                f_event["differenceValueDHM"]["combineFromValue"][0]
              ]
            ),
            toDate = this.dateObject(
              this.stateObject[action["groupName"]][index][
                f_event["differenceValueDHM"]["combineToValue"][0]
              ]
            );

          fromDate = this.dateObject(
            this.stateObject[action["groupName"]][index][
              f_event["differenceValueDHM"]["combineFromValue"][1]
            ],
            fromDate,
            true
          );
          toDate = this.dateObject(
            this.stateObject[action["groupName"]][index][
              f_event["differenceValueDHM"]["combineToValue"][1]
            ],
            toDate,
            true
          );

          let fDate = moment(fromDate);
          let tDate = moment(toDate);
          let diff = tDate.diff(fDate, "minutes");
          let vAss = this.numberToDaysHoursMinutsObject(diff);
          dateDiffAssign["value"][f_event["differenceValueDHM"]["updateTo"]] =
            this.numberPad(vAss["days"], 2) +
            "D:" +
            this.numberPad(vAss["hours"], 2) +
            "H:" +
            this.numberPad(vAss["minutes"], 2) +
            "M";
          this.reduxState.dispatch(dateDiffAssign);
        }

        if (
          f_event.hasOwnProperty("addValueDHM") &&
          f_event["addValueDHM"].hasOwnProperty("fromValue") &&
          f_event["addValueDHM"].hasOwnProperty("updateTo") &&
          f_event["addValueDHM"].hasOwnProperty("dynamicGroup") &&
          f_event["addValueDHM"]["dynamicGroup"] === true
        ) {
          if (f_event["addValueDHM"].hasOwnProperty("formulas")) {
            f_event["addValueDHM"]["formulas"].map((e) => {
              this.resolveFormula(
                e,
                index,
                Object.assign({}, action),
                row,
                value
              );
            });
          }

          let sumVal = 0;
          this.stateObject[addDateAssign["groupName"]].map((e) => {
            sumVal =
              sumVal +
              this.stringToDaysHoursMinutsObject(
                e[f_event["addValueDHM"]["fromValue"]],
                true
              );
          });

          sumVal = this.numberToDaysHoursMinutsObject(sumVal);

          this.stateObject[f_event["addValueDHM"]["updateGroup"]].map(
            (e, i) => {
              if (
                this.getGroupKey(e.port_name) === addDateAssign["groupName"]
              ) {
                addDateAssign["groupName"] =
                  f_event["addValueDHM"]["updateGroup"];
                addDateAssign["index"] = i;
              }
            }
          );

          addDateAssign["value"][f_event["addValueDHM"]["updateTo"]] =
            this.numberPad(sumVal["days"], 2) +
            "D:" +
            this.numberPad(sumVal["hours"], 2) +
            "H:" +
            this.numberPad(sumVal["minutes"], 2) +
            "M";
          this.reduxState.dispatch(addDateAssign);
        }

        if (
          f_event.hasOwnProperty("commComp") &&
          f_event["commComp"].hasOwnProperty("fromField") &&
          f_event["commComp"].hasOwnProperty("toField") &&
          f_event["commComp"].hasOwnProperty("updateTo") &&
          f_event["commComp"].hasOwnProperty("dynamicGroup")
        ) {
          let f = this.stateObject[action["groupName"]].filter(
            (e) =>
              e[f_event["commComp"]["fromField"]["matchField"]] * 1 ===
              f_event["commComp"]["fromField"]["valueIs"] * 1
          );
          let t = this.stateObject[action["groupName"]].filter(
            (e) =>
              e[f_event["commComp"]["toField"]["matchField"]] * 1 ===
              f_event["commComp"]["toField"]["valueIs"] * 1
          );
          let fd = {},
            td = {};

          if (
            f &&
            f.length === 1 &&
            f[0].hasOwnProperty(f_event["commComp"]["fromField"]["field"][0]) &&
            f[0][f_event["commComp"]["fromField"]["field"][0]] !== ""
          ) {
            fd = this.dateObject(
              f[0][f_event["commComp"]["fromField"]["field"][0]]
            );
            if (
              f[0].hasOwnProperty(
                f_event["commComp"]["fromField"]["field"][1]
              ) &&
              f[0][f_event["commComp"]["fromField"]["field"][1]] !== ""
            ) {
              fd = this.dateObject(
                f[0][f_event["commComp"]["fromField"]["field"][1]],
                fd,
                true
              );
            }
          }

          if (
            t &&
            t.length === 1 &&
            t[0].hasOwnProperty(f_event["commComp"]["toField"]["field"][0]) &&
            t[0][f_event["commComp"]["toField"]["field"][0]] !== ""
          ) {
            td = this.dateObject(
              t[0][f_event["commComp"]["toField"]["field"][0]]
            );
            if (
              t[0].hasOwnProperty(f_event["commComp"]["toField"]["field"][1]) &&
              t[0][f_event["commComp"]["toField"]["field"][1]] !== ""
            ) {
              td = this.dateObject(
                t[0][f_event["commComp"]["toField"]["field"][1]],
                td,
                true
              );
            }
          }

          if (
            fd &&
            JSON.stringify({}) !== JSON.stringify(fd) &&
            td &&
            JSON.stringify({}) !== JSON.stringify(td)
          ) {
            fd = moment(fd);
            td = moment(td);
            let diff = td.diff(fd, "minutes");
            let vAss = this.numberToDaysHoursMinutsObject(diff);
            this.stateObject[f_event["commComp"]["groupCode"]].map((e, i) => {
              if (
                this.getGroupKey(e[f_event["commComp"]["grupMatch"]]) ===
                action["groupName"]
              ) {
                ftDate["groupName"] = f_event["commComp"]["groupCode"];
                ftDate["index"] = i;
                ftDate["value"][f_event["commComp"]["updateTo"]] =
                  this.numberPad(vAss["days"], 2) +
                  "D:" +
                  this.numberPad(vAss["hours"], 2) +
                  "H:" +
                  this.numberPad(vAss["minutes"], 2) +
                  "M";
                this.reduxState.dispatch(ftDate);
              }
            });
          }
        }

        if (this.stateObject[action["groupName"]]) {
          this.nextDateAdded(
            f_event,
            index,
            Object.assign({}, action),
            index + 1
          );
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("updatetotalamountRoushan") &&
        f_event["updatetotalamountRoushan"]
      ) {
        let _current =
          this.stateObject[f_event["fromgroup"]][f_event["fromField"]];

        let tsl = {
          type: "add",
          formName: this.formName,
          groupName: undefined,
          index: undefined,
          value: {},
        };
        tsl["value"][f_event["toField"]] = _current;
        this.reduxState.dispatch(tsl);
      } else if (
        f_event &&
        f_event.hasOwnProperty("prevDateDifference") &&
        typeof f_event["prevDateDifference"] === "object"
      ) {
        let prevDateDifference = Object.assign({}, action);
        this.prevDateDifference(
          f_event,
          value,
          this.stateObject,
          prevDateDifference
        );
      } else if (
        f_event &&
        f_event.hasOwnProperty("externalField") &&
        f_event["externalField"] === true &&
        f_event.hasOwnProperty("copyData") &&
        JSON.stringify("{}") !== JSON.stringify(f_event["copyData"])
      ) {
        let copyFields = f_event["copyData"];
        let totalObject = Object.assign({}, action);
        if (
          typeof copyFields["fromField"] !== "string" &&
          typeof copyFields["toField"] !== "string"
        ) {
          copyFields["fromField"].map((e, i) => {
            if (
              typeof e !== "string" &&
              e.hasOwnProperty("fieldName") &&
              e.hasOwnProperty("groupCode")
            ) {
              if (typeof copyFields["toField"][i] !== "string") {
                action["value"][copyFields["toField"][i]["fieldName"]] =
                  this.stateObject[e.groupCode][e.fieldName];
              } else {
                action["value"][copyFields["toField"][i]] =
                  this.stateObject[e.groupCode][e.fieldName];
              }
            } else {
              action["value"][copyFields["toField"][i]] = this.stateObject[e];
            }
          });
          if (
            copyFields.hasOwnProperty("isGroup") &&
            copyFields["isGroup"] === false
          ) {
            action["groupName"] = undefined;
          }
          this.reduxState.dispatch(action);
        } else {
          action["value"][copyFields["toField"]] =
            this.stateObject[copyFields["fromField"]];
          if (
            copyFields.hasOwnProperty("isGroup") &&
            copyFields["isGroup"] === false
          ) {
            action["groupName"] = undefined;
          }
          this.reduxState.dispatch(action);
        }

        if (
          f_event.hasOwnProperty("totalGSum") &&
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"].hasOwnProperty("to")
        ) {
          let fromGKey = this.getGroupKey(
            f_event["totalGSum"]["from"]["groupKey"]
          );

          let toGKey = this.getGroupKey(f_event["totalGSum"]["to"]["groupKey"]);
          let totalObj = 0;
          this.stateObject[fromGKey].map((e) => {
            if (
              e &&
              e.hasOwnProperty(f_event["totalGSum"]["from"]["field"]) &&
              e[f_event["totalGSum"]["from"]["field"]] * 1 > 0
            ) {
              totalObj =
                totalObj + e[f_event["totalGSum"]["from"]["field"]] * 1;
            }
          });

          totalObject["groupName"] = toGKey;
          totalObject["index"] = undefined;
          totalObject["value"][f_event["totalGSum"]["to"]["field"]] = totalObj;
          if (f_event["totalGSum"]["to"].hasOwnProperty("index")) {
            totalObject["index"] = f_event["totalGSum"]["to"]["index"];
          }
          this.reduxState.dispatch(totalObject);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("groupTotal") &&
        f_event["groupTotal"] === true &&
        f_event.hasOwnProperty("totalGSum") &&
        f_event["totalGSum"].hasOwnProperty("from") &&
        f_event["totalGSum"].hasOwnProperty("to")
      ) {
        let fromGKey = this.getGroupKey(
          f_event["totalGSum"]["from"]["groupKey"]
        );
        let toGKey = this.getGroupKey(f_event["totalGSum"]["to"]["groupKey"]);
        let sum = {},
          totalObj = 0;
        if (
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"]["from"].hasOwnProperty("field")
        ) {
          this.stateObject[fromGKey].map((e) => {
            if (
              f_event["totalGSum"].hasOwnProperty("condition") &&
              f_event["totalGSum"]["condition"] &&
              row["f_type"] === "boolean"
            ) {
              if (
                e &&
                e.hasOwnProperty(f_event["totalGSum"]["from"]["field"]) &&
                e[f_event["totalGSum"]["from"]["field"]] * 1 > 0
              ) {
                totalObj =
                  totalObj +
                  (Boolean(e[f_event["totalGSum"]["condition"]["field"]]) ===
                  Boolean(f_event["totalGSum"]["condition"]["value"])
                    ? e[f_event["totalGSum"]["from"]["field"]] * 1
                    : 0);
              }
            } else if (
              f_event["totalGSum"].hasOwnProperty("condition") &&
              f_event["totalGSum"]["condition"] &&
              row["f_type"] != "boolean"
            ) {
              if (
                e &&
                e.hasOwnProperty(f_event["totalGSum"]["from"]["field"]) &&
                e[f_event["totalGSum"]["from"]["field"]] * 1 > 0
              ) {
                totalObj =
                  totalObj +
                  (Boolean(e[f_event["totalGSum"]["condition"]["field"]]) ===
                  Boolean(f_event["totalGSum"]["condition"]["value"])
                    ? e[f_event["totalGSum"]["from"]["field"]] * 1
                    : 0);
              }
            } else if (
              e &&
              e.hasOwnProperty(f_event["totalGSum"]["from"]["field"]) &&
              e[f_event["totalGSum"]["from"]["field"]] * 1 > 0
            ) {
              totalObj =
                totalObj + e[f_event["totalGSum"]["from"]["field"]] * 1;
            }
          });
          action["groupName"] = toGKey;
          action["index"] = undefined;
          action["value"][f_event["totalGSum"]["to"]["field"]] = totalObj;
          if (f_event["totalGSum"]["to"].hasOwnProperty("index")) {
            action["index"] = f_event["totalGSum"]["to"]["index"];
          }
          this.reduxState.dispatch(action);
        } else if (
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"]["from"].hasOwnProperty("fields")
        ) {
          f_event["totalGSum"]["from"]["fields"].map((fe) => {
            if (!sum.hasOwnProperty(fe)) sum[fe] = 0;
            this.stateObject[fromGKey].map((e) => {
              if (
                f_event["totalGSum"].hasOwnProperty("condition") &&
                f_event["totalGSum"]["condition"] &&
                row["f_type"] === "boolean"
              ) {
                if (e && e.hasOwnProperty(fe) && e[fe] * 1 > 0) {
                  sum[fe] =
                    sum[fe] +
                    (Boolean(e[f_event["totalGSum"]["condition"]["field"]]) ===
                    Boolean(f_event["totalGSum"]["condition"]["value"])
                      ? e[fe] * 1
                      : 0);
                }
              } else if (e && e.hasOwnProperty(fe) && e[fe] * 1 > 0) {
                sum[fe] = sum[fe] + e[fe] * 1;
              }
            });
          });
          action["groupName"] = toGKey;
          action["index"] = undefined;
          if (f_event["totalGSum"]["to"].hasOwnProperty("index")) {
            action["index"] = f_event["totalGSum"]["to"]["index"];
          }

          f_event["totalGSum"]["from"]["fields"].map((fe, fi) => {
            action["value"][f_event["totalGSum"]["to"]["fields"][fi]] = sum[fe];
          });
          this.reduxState.dispatch(action);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("groupTotal") &&
        f_event.hasOwnProperty("totalGSum") &&
        f_event["groupTotal"] === true &&
        typeof f_event["totalGSum"] === "object" &&
        f_event["totalGSum"].hasOwnProperty("length") &&
        f_event["totalGSum"].length > 0
      ) {
        f_event["totalGSum"].map((fgs, i) => {
          let actionID = Object.assign({}, action);
          let /*sum = {}, */ totalObj = 0;
          let fromGKey = this.getGroupKey(
            f_event["totalGSum"][i]["from"]["groupKey"]
          );
          let toGKey = this.getGroupKey(
            f_event["totalGSum"][i]["to"]["groupKey"]
          );
          let toObject = this.stateObject[toGKey];
          this.stateObject[fromGKey].map((e) => {
            if (
              f_event["totalGSum"][i].hasOwnProperty("condition") &&
              f_event["totalGSum"][i]["condition"] &&
              row["f_type"] === "boolean"
            ) {
              if (
                e &&
                e.hasOwnProperty(f_event["totalGSum"][i]["from"]["field"]) &&
                e[f_event["totalGSum"][i]["from"]["field"]] * 1 > 0
              ) {
                totalObj =
                  totalObj * 1 +
                  (Boolean(e[f_event["totalGSum"][i]["condition"]["field"]]) ===
                  Boolean(f_event["totalGSum"][i]["condition"]["value"])
                    ? e[f_event["totalGSum"][i]["from"]["field"]] * 1
                    : 0);

                totalObj = totalObj.toFixed(2);
              }
            } else if (
              e &&
              e.hasOwnProperty(f_event["totalGSum"][i]["from"]["field"]) &&
              e[f_event["totalGSum"][i]["from"]["field"]] * 1 > 0
            ) {
              totalObj =
                totalObj + e[f_event["totalGSum"][i]["from"]["field"]] * 1;
            }
          });

          if (
            f_event["totalGSum"][i]["to"].hasOwnProperty("isList") &&
            f_event["totalGSum"][i]["to"]["isList"] === true
          ) {
            actionID["groupName"] = undefined;
            actionID["index"] = undefined;

            toObject.map((tobj) => {
              if (tobj["funct"] === "1" || tobj["funct"] === "2") {
                tobj[f_event["totalGSum"][i]["to"]["field"]] = totalObj;
                if (f_event["totalGSum"][i]["to"].hasOwnProperty("index")) {
                  actionID["index"] = f_event["totalGSum"][i]["to"]["index"];
                }
              }
            });

            actionID["value"][toGKey] = toObject;
            this.reduxState.dispatch(actionID);
          } else {
            actionID["groupName"] = toGKey;
            actionID["index"] = undefined;
            actionID["value"][f_event["totalGSum"][i]["to"]["field"]] =
              totalObj;
            if (f_event["totalGSum"][i]["to"].hasOwnProperty("index")) {
              actionID["index"] = f_event["totalGSum"][i]["to"]["index"];
            }
            this.reduxState.dispatch(actionID);
          }
        });
      } else if (
        f_event &&
        f_event.hasOwnProperty("dateGMT") &&
        f_event["dateGMT"].hasOwnProperty("fromField") &&
        f_event["dateGMT"].hasOwnProperty("toField")
      ) {
        if (
          this.stateObject.hasOwnProperty(f_event["dateGMT"]["fromField"]) &&
          this.stateObject[f_event["dateGMT"]["fromField"]]
        ) {
          let ff = this.stateObject[f_event["dateGMT"]["fromField"]].valueOf();
          action["value"][f_event["dateGMT"]["toField"]] = ff;
          this.reduxState.dispatch(action);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("conditionUpdate") &&
        JSON.stringify(f_event["conditionUpdate"]) !== JSON.stringify({})
      ) {
        if (
          f_event["conditionUpdate"].hasOwnProperty("eq") &&
          f_event["conditionUpdate"]["eq"].hasOwnProperty("gKey")
        ) {
          if (
            this.stateObject.hasOwnProperty(
              f_event["conditionUpdate"]["eq"]["gKey"]
            ) &&
            this.stateObject[
              f_event["conditionUpdate"]["eq"]["gKey"]
            ].hasOwnProperty(f_event["conditionUpdate"]["eq"]["fieldName"]) &&
            this.stateObject[f_event["conditionUpdate"]["eq"]["gKey"]][
              f_event["conditionUpdate"]["eq"]["fieldName"]
            ] === f_event["conditionUpdate"]["eq"]["compareValue"]
          ) {
            action["groupName"] =
              f_event["conditionUpdate"]["eq"]["updateField"];
            action["value"][f_event["conditionUpdate"]["eq"]["updateField"]] =
              this.stateObject[f_event["conditionUpdate"]["eq"]["fromField"]];
            this.reduxState.dispatch(action);
          } else if (
            f_event["conditionUpdate"]["otherWise"] &&
            this.stateObject.hasOwnProperty(
              f_event["conditionUpdate"]["eq"]["gKey"]
            ) &&
            this.stateObject[
              f_event["conditionUpdate"]["eq"]["gKey"]
            ].hasOwnProperty(f_event["conditionUpdate"]["eq"]["fieldName"]) &&
            this.stateObject[f_event["conditionUpdate"]["eq"]["gKey"]][
              f_event["conditionUpdate"]["eq"]["fieldName"]
            ] !== f_event["conditionUpdate"]["eq"]["compareValue"]
          ) {
            action["groupName"] =
              f_event["conditionUpdate"]["otherWise"]["updateField"];
            action["value"][
              f_event["conditionUpdate"]["otherWise"]["updateField"]
            ] =
              this.stateObject[
                f_event["conditionUpdate"]["otherWise"]["fromField"]
              ];
            this.reduxState.dispatch(action);
          }
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("totalothercostRoushan") &&
        f_event["totalothercostRoushan"]
      ) {
        let _stobject = this.stateObject;
        let totalamount = _stobject["othercost"].reduce(
          (ac, el) => ac + (el.amount ? el.amount : 0) * 1,
          0
        );
        let avaction = {
          type: "add",
          formName: this.formName,
          groupName: undefined,
          index: undefined,
          value: {},
        };

        avaction["value"]["total_sum"] = totalamount.toFixed(2);
        this.reduxState.dispatch(avaction);
      } else if (
        f_event &&
        f_event.hasOwnProperty("balanceDays") &&
        f_event["balanceDays"].hasOwnProperty("updateTo") &&
        f_event["balanceDays"].hasOwnProperty("fromField")
      ) {
        let stObj = this.stateObject[f_event["balanceDays"]["gKey"]];
        let balanceD = { days: 0, hours: 0, minutes: 0 };
        let eleDays = 0;

        f_event["balanceDays"]["fromField"].map((e) => {
          let str =
            stObj.hasOwnProperty(index) &&
            stObj[index].hasOwnProperty(f_event["balanceDays"][e])
              ? stObj[index][f_event["balanceDays"][e]]
              : undefined;
          let obj = this.stringToDaysHoursMinutsObject(str, true);
          f_event["balanceDays"]["formula"] = f_event["balanceDays"][
            "formula"
          ].replaceAll(e, obj);
        });

        eleDays = eval(f_event["balanceDays"]["formula"]);
        balanceD = this.numberToDaysHoursMinutsObject(eleDays);
        action["value"][f_event["balanceDays"]["updateTo"]] =
          this.numberPad(balanceD["days"], 2) +
          "D:" +
          this.numberPad(balanceD["hours"], 2) +
          "H:" +
          this.numberPad(balanceD["minutes"], 2) +
          "M";
        this.reduxState.dispatch(action);

        if (
          f_event.hasOwnProperty("showInExternalField") &&
          f_event["showInExternalField"].hasOwnProperty("length") &&
          f_event["showInExternalField"].length > 0
        ) {
          let days = this.stateObject[action["groupName"]];
          if (!action.index) {
            action["index"] = 0;
          }

          let counter = 0;
          let formula1;
          let aa;

          f_event["showInExternalField"].map((sef) => {
            if (sef.hasOwnProperty("condition") && sef.condition.length === 3) {
              if (eleDays > sef.condition[0]) {
                this.setData(sef.toFieldName, sef.condition[1], sef.gKey);
              } else {
                this.setData(sef.toFieldName, sef.condition[2], sef.gKey);
              }
            } else {
              if (typeof sef["fromFieldName"] !== "string") {
                let totalDays = {};
                days.map((e) => {
                  sef["fromFieldName"].map((ffne) => {
                    if (
                      !totalDays.hasOwnProperty(ffne) &&
                      typeof ffne === "string"
                    )
                      totalDays[ffne] = 0;
                    if (
                      typeof ffne === "string" &&
                      e.hasOwnProperty(ffne) &&
                      e[ffne] &&
                      e[ffne] !== ""
                    ) {
                      totalDays[ffne] =
                        totalDays[ffne] +
                        this.stringToDaysHoursMinutsObject(e[ffne], true);
                    } else if (
                      typeof ffne !== "string" &&
                      ffne.hasOwnProperty("formula")
                    ) {
                      let formula = ffne["formula"];
                      ffne["replace"].map((re) => {
                        if (typeof ffne[re] === "string") {
                          formula = formula.replaceAll(
                            re,
                            e[ffne[re]]
                              ? this.stringToDaysHoursMinutsObject(
                                  e[ffne[re]],
                                  true
                                )
                              : 0
                          );
                        } else if (
                          /* code update by amar dixit --27-02-2023   for lay time standerd and reviserbile formula */
                          typeof ffne[re] !== "string" &&
                          ffne[re].hasOwnProperty("length")
                        ) {
                          if (eleDays < 0) {
                            aa = e[ffne[re][1]];
                            formula = formula.replaceAll(
                              re,
                              e[ffne[re][1]] ? e[ffne[re][1]] : 0
                            );
                          } else if (eleDays > 0) {
                            aa = e[ffne[re][2]];
                            formula = formula.replaceAll(
                              re,
                              e[ffne[re][2]] ? e[ffne[re][2]] : 0
                            );
                          }
                        }
                      });

                      if (
                        this.stateObject["..."]["calculation"] == "65" &&
                        counter == 0
                      ) {
                        eleDays = this.stringToDaysHoursMinutsObject(
                          this.stateObject[".."][0]["total_balance"],
                          true
                        );
                        formula1 = aa * eleDays;
                        counter = 1;
                      }
                      if (
                        this.stateObject["..."]["calculation"] == "46" &&
                        counter == 0
                      ) {
                        eleDays = this.stringToDaysHoursMinutsObject(
                          this.stateObject[".."][0]["total_balance"],
                          true
                        );
                        let total_rate = 0;
                        this.stateObject["."].map((e, id) => {
                          if (eleDays < 0) {
                            total_rate += +e.dem_rate;
                          } else {
                            total_rate += +e.dispatch_rate;
                          }
                        });
                        formula1 = total_rate * eleDays;
                        counter = 1;
                      }

                      if (
                        this.stateObject["..."]["calculation"] == "65" ||
                        this.stateObject["..."]["calculation"] == "46"
                      ) {
                        formula = formula1;
                      }

                      /*   end for code pathc  */

                      try {
                        formula = eval(formula).toFixed(2);
                      } catch (e) {
                        if (e instanceof SyntaxError) {
                          // alert("test ok amar");
                        }
                      }
                      totalDays[ffne["field"]] = formula;
                    }
                  });
                });

                sef["fromFieldName"].map((ffne, ffni) => {
                  let ffnev = ffne;
                  if (typeof ffne !== "string") {
                    ffnev = ffne["field"];
                  }

                  if (sef.hasOwnProperty("dhm") && sef["dhm"] === true) {
                    let dhm = this.numberToDaysHoursMinutsObject(
                      totalDays[ffnev]
                    );
                    this.setData(
                      sef["toFieldName"][ffni],
                      this.numberPad(dhm["days"], 2) +
                        "D:" +
                        this.numberPad(dhm["hours"], 2) +
                        "H:" +
                        this.numberPad(dhm["minutes"], 2) +
                        "M",
                      sef.gKey,
                      sef.index
                    );
                  } else {
                    this.setData(
                      sef["toFieldName"][ffni],
                      (totalDays[ffnev] / (24 * 60)).toFixed(2),
                      sef.gKey
                    );
                  }
                });
              } else {
                this.setData(
                  sef.toFieldName,
                  (eleDays / (24 * 60)).toFixed(2),
                  sef.gKey
                );
              }
            }
          });
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("showList") &&
        f_event.hasOwnProperty("isGroupUpdate") &&
        f_event.hasOwnProperty("method")
      ) {
        let sendData = {},
          conditionData = f_event["showList"].hasOwnProperty("condition")
            ? f_event["showList"]["condition"]
            : {};
        let totalObject = Object.assign({}, action);
        if (f_event.hasOwnProperty("sendDataHeads")) {
          f_event["sendDataHeads"].map((e, i) => {
            let val = this.stateObject[f_event["showList"]["valueColumn"][i]];

            if (f_event["showList"]["valueColumn"][i].indexOf("->") >= 0) {
              let vq = f_event["showList"]["valueColumn"][i].split("->");
              if (vq.length > 1) {
                val = this.stateObject[vq[0]];
                let rv = row["f_dyc_extras"]["options"].find(
                  (e) => e.id * 1 === val * 1
                );
                if (
                  typeof rv === "object" &&
                  rv.hasOwnProperty("length") &&
                  rv.length > 0
                ) {
                  val = rv[0][vq[1]];
                } else if (
                  typeof rv === "object" &&
                  !rv.hasOwnProperty("length")
                ) {
                  val = rv[vq[1]];
                }
              }
            }
            sendData[e] = val && val !== "" ? val : undefined;
          });
        }

        if (
          conditionData &&
          conditionData.hasOwnProperty("fieldName") &&
          conditionData.hasOwnProperty("fieldValue") &&
          conditionData.hasOwnProperty("operator")
        ) {
          let valF =
            this.stateObject[f_event["showList"]["condition"]["fieldName"]];
          if (
            conditionData["operator"] === "eq" &&
            (!valF || (valF && parseInt(valF) !== conditionData["fieldValue"]))
          )
            return;
          if (
            conditionData["operator"] === "ne" &&
            valF &&
            parseInt(valF) === conditionData["fieldValue"]
          )
            return;
        }

        if (f_event["method"].toUpperCase() === "POST") {
          // sendData['tci_id'] = this.stateObject.tc_code;   causing the issue in tci tc commision

          let sendKeys = Object.keys(sendData);
          if (sendKeys.length > 0) {
            const request = await awaitPostAPICall(
              `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}`,
              sendData
            );

            const respData = await request["data"];

            if (respData === false) {
              openNotificationWithIcon(
                "error",
                <div
                  className="notify-error"
                  dangerouslySetInnerHTML={{ __html: request["message"] }}
                />,
                5
              );
            }
            if (
              f_event["showList"].hasOwnProperty("isInvoice") &&
              f_event["showList"]["isInvoice"] === true
            ) {
              let op = Object.assign({}, action);
              if (respData["invoice"])
                op["value"][f_event["showList"]["showInField"]] =
                  respData["invoice"];
              if (respData["amount"])
                op["value"][f_event["showList"]["amountField"]] =
                  respData["amount"];
              //
              op["value"][f_event["showList"]["amountField2"]] =
                respData["amount"];
              //
              if (respData["---------------"])
                op["value"][f_event["showList"]["checkBoxes"]] =
                  respData["---------------"];
              if (
                respData.hasOwnProperty("invInfo") &&
                typeof respData["invInfo"] === "object"
              ) {
                op["value"] = Object.assign(op["value"], respData["invInfo"]);
              }

              this.reduxState.dispatch(op);
            } else if (
              f_event["showList"].hasOwnProperty("gkey") &&
              f_event["showList"]["gkey"] !== "" &&
              f_event["isGroupUpdate"] === true
            ) {
              let op = Object.assign({}, action);
              let keyVal = {};
              if (
                typeof f_event["showList"]["gkey"] !== "string" &&
                f_event["showList"]["gkey"].length > 0
              ) {
                f_event["showList"]["gkey"].map(
                  (e) => (keyVal[e] = respData[e])
                );
              } else {
                keyVal[f_event["showList"]["gkey"]] = respData;
              }
              if (
                f_event.hasOwnProperty("showExtraValue") &&
                f_event["showExtraValue"].hasOwnProperty("to") &&
                f_event["showExtraValue"].hasOwnProperty("from")
              ) {
                keyVal[f_event["showExtraValue"]["to"]] =
                  sendData[f_event["showExtraValue"]["from"]];
              }
              op["value"] = Object.assign(op["value"], keyVal);
              this.reduxState.dispatch(op);
            } else {
              f_event["showList"]["fromColumn"].map((e, i) => {
                let op = Object.assign({}, action);
                op["groupName"] = f_event["showList"]["updateColumn"][i];
                op["value"][f_event["showList"]["updateColumn"][i]] =
                  respData[e];
                this.reduxState.dispatch(op);
              });
            }
          } else if (
            f_event.hasOwnProperty("showList") &&
            f_event.hasOwnProperty("method") &&
            f_event["showList"].hasOwnProperty("apiLink") &&
            f_event["showList"].hasOwnProperty("fromField") &&
            f_event["showList"].hasOwnProperty("updateGroup")
          ) {
            if (
              f_event["method"].toUpperCase() === "POST" &&
              typeof f_event["showList"]["fromField"] === "string" &&
              f_event["showList"]["fromField"].toUpperCase() === "COBJ"
            ) {
              let op = Object.assign({}, action);
              const request = await awaitPostAPICall(
                `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}`,
                this.stateObject
              );
              const respData = await request["data"];
              let groupKey = this.getGroupKey(
                f_event["showList"]["updateGroup"]
              );
              op["groupName"] = undefined;
              op["index"] = undefined;
              op["value"] = {};
              op["value"][groupKey] = respData;
              op["value"][f_event["showList"]["updateGroup"]] = respData;
              this.reduxState.dispatch(op);
            }
          }
        } else if (f_event["method"].toUpperCase() === "GET") {
          let params = "?";
          let op = Object.assign({}, action);
          if (typeof f_event["showList"]["dataFrom"] !== "string") {
            f_event["showList"]["dataFrom"].map(
              (e) =>
                (params =
                  params +
                  (params !== "?" ? "&" : "") +
                  e["fieldName"] +
                  "=" +
                  this.stateObject[e["valueColumn"]])
            );
          } else {
            params = this.stateObject[f_event["showList"]["dataFrom"]];
          }

          const request = await getAPICall(
            `${URL_WITH_VERSION}${f_event["showList"]["apiLink"]}${params}`,
            sendData
          );
          const respData = await request["data"];

          if (respData) {
            if (typeof f_event["showList"]["updateColumn"] === "string") {
              op["value"][f_event["showList"]["updateColumn"]] = respData;
            } else if (
              typeof f_event["showList"]["updateColumn"] === "object" &&
              f_event["showList"]["updateColumn"].length > 0
            ) {
              f_event["showList"]["updateColumn"].map(
                (e) => (op["value"][e] = respData[e])
              );
            }
            this.reduxState.dispatch(op);
          } else {
            openNotificationWithIcon(
              "error",
              "No Cargo found in associated charterer !"
            );
          }

          if (
            f_event["showList"].hasOwnProperty("disable") &&
            f_event["showList"]["disable"]
          ) {
            setTimeout(() => {
              respData[".."] &&
                respData[".."].length > 0 &&
                respData[".."].map((e, index) => {
                  if (e.f == "104") {
                    // let elements = document.getElementById('inv' + index);
                    // elements.setAttribute('placeholder', "0.00");
                    // elements.setAttribute('disabled', '');
                    // elements.classList.add("ant-input-disabled");
                    // op['value']['inv' + index] = "0.00";
                    // e.inv = '0.00'
                    let elements1 = document.getElementById("frt_rate" + index);
                    elements1.disabled = true;
                    elements1.classList.add("ant-input-disabled");
                  } else if (e.f == "38") {
                    let elements = document.getElementById("lumb" + index);
                    if (elements) elements.disabled = true;
                    //elements.setAttribute('disabled', '');
                    //elements.classList.add("ant-input-disabled");
                  }
                });
            }, 2000);
          }
        }

        if (
          f_event.hasOwnProperty("totalGSum") &&
          f_event["totalGSum"].hasOwnProperty("from") &&
          f_event["totalGSum"].hasOwnProperty("to") &&
          f_event["totalGSum"].hasOwnProperty("multipleFromGroup") &&
          f_event["totalGSum"]["multipleFromGroup"] === true
        ) {
          let totalObj = 0;
          f_event["totalGSum"]["from"].map((tf) => {
            let fromGKey = this.getGroupKey(tf["groupKey"]);
            tf["fields"].map((fe) => {
              this.stateObject[fromGKey].map((e) => {
                if (e && e.hasOwnProperty(fe) && e[fe] * 1 > 0) {
                  totalObj =
                    totalObj +
                    e[fe] *
                      (tf.hasOwnProperty("multiple") ? tf["multiple"] : 1);
                } else {
                  totalObj = totalObj + e[fe] * 1;
                }
              });
            });
          });

          totalObject["groupName"] = undefined;
          totalObject["index"] = undefined;

          if (
            f_event["totalGSum"]["to"].hasOwnProperty("groupKey") &&
            f_event["totalGSum"]["to"]["groupKey"] !== ""
          ) {
            totalObject["groupName"] = this.getGroupKey(
              f_event["totalGSum"]["to"]["groupKey"]
            );
          }

          f_event["totalGSum"]["to"]["fields"].map((fe) => {
            totalObject["value"][fe] = totalObj;
            if (
              f_event["totalGSum"]["roundOff"] &&
              f_event["totalGSum"]["roundOff"] !== ""
            ) {
              totalObject["value"][fe] = totalObj.toFixed(
                f_event["totalGSum"]["roundOff"]
              );
            }
          });

          this.reduxState.dispatch(totalObject);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("getDay") &&
        typeof f_event["getDay"] === "object" &&
        f_event["getDay"].hasOwnProperty("updateTo") &&
        f_event["getDay"].hasOwnProperty("fromField")
      ) {
        let ff =
          this.stateObject[action["groupName"]][index][
            f_event["getDay"]["fromField"]
          ];
        if (ff && typeof ff === "string") {
          ff = moment(ff);
        }
        if (ff) {
          action["value"][f_event["getDay"]["updateTo"]] = ff.format("ddd");
          this.reduxState.dispatch(action);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("differenceValueDHM") &&
        f_event["differenceValueDHM"].hasOwnProperty("fromField") &&
        f_event["differenceValueDHM"].hasOwnProperty("assignField")
      ) {
        let startDate =
          this.stateObject[f_event["differenceValueDHM"]["fromField"]];
        let endDate =
          this.stateObject[f_event["differenceValueDHM"]["toField"]];
        let diffDays = 0;

        if (startDate !== "" && endDate !== "") {
          startDate = moment(startDate);
          endDate = moment(endDate);

          diffDays = parseInt(endDate.diff(startDate, "minutes"));
          let diffStr = this.numberToDaysHoursMinutsObject(diffDays);
          diffStr =
            this.numberPad(diffStr["days"], 2) +
            "D:" +
            this.numberPad(diffStr["hours"], 2) +
            "H:" +
            this.numberPad(diffStr["minutes"], 2) +
            "M";

          if (f_event["differenceValueDHM"].hasOwnProperty("updateGroup")) {
            let groupData = Object.assign(
              [],
              this.stateObject[f_event["differenceValueDHM"]["updateGroup"]]
            );
            action["groupName"] = f_event["differenceValueDHM"]["updateGroup"];
            action["index"] = undefined;
            groupData.map((e, i) => {
              if (f_event["differenceValueDHM"].hasOwnProperty("diffFields")) {
                let ffv = this.stringToDaysHoursMinutsObject(
                  groupData[i][
                    f_event["differenceValueDHM"]["diffFields"]["from"]
                  ],
                  true
                );
                let diff = this.numberToDaysHoursMinutsObject(ffv - diffDays);
                groupData[i][f_event["differenceValueDHM"]["assignField"]] =
                  diffStr;
                groupData[i][
                  f_event["differenceValueDHM"]["diffFields"]["assign"]
                ] =
                  this.numberPad(diff["days"], 2) +
                  "D:" +
                  this.numberPad(diff["hours"], 2) +
                  "H:" +
                  this.numberPad(diff["minutes"], 2) +
                  "M";
              } else {
                groupData[i][f_event["differenceValueDHM"]["assignField"]] =
                  diffStr;
              }
            });
            action["value"][action["groupName"]] = groupData;
          } else {
            action[f_event["differenceValue"]["assignField"]] = diffStr;
          }

          this.reduxState.dispatch(action);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("isDisablecargoFields") &&
        f_event["isDisablecargoFields"]
      ) {
        if (value == "104") {
          let elements = document.getElementById("frat_rate" + index);
          elements.disabled = true;
          let element1 = document.getElementById("lumsum" + index);
          element1.disabled = false;
        }
        if (value == "38") {
          let element = document.getElementById("lumsum" + index);
          element.disabled = true;
          let element1 = document.getElementById("frat_rate" + index);
          element1.disabled = false;
        }
        if (value == "259") {
          document.getElementById("lumsum" + index).disabled = true;
          document.getElementById("frat_rate" + index).disabled = true;
        }
        this.reduxState.dispatch(action);
      } else if (
        f_event &&
        f_event.hasOwnProperty("addValueDHM") &&
        f_event["addValueDHM"].hasOwnProperty("fromValue") &&
        f_event["addValueDHM"].hasOwnProperty("updateTo") &&
        f_event["addValueDHM"].hasOwnProperty("dynamicGroup") &&
        f_event["addValueDHM"]["dynamicGroup"] === true
      ) {
        let addDateAssign = Object.assign({}, action);
        let groupAction = Object.assign({}, action);

        if (f_event["addValueDHM"].hasOwnProperty("formulas")) {
          f_event["addValueDHM"]["formulas"].map((e) => {
            this.resolveFormula(
              e,
              index,
              Object.assign({}, action),
              row,
              value
            );
          });
        }

        let sumVal = 0;
        this.stateObject[addDateAssign["groupName"]].map((e) => {
          sumVal =
            sumVal +
            this.stringToDaysHoursMinutsObject(
              e[f_event["addValueDHM"]["fromValue"]],
              true
            );
        });

        sumVal = this.numberToDaysHoursMinutsObject(sumVal);

        this.stateObject[f_event["addValueDHM"]["updateGroup"]].map((e, i) => {
          if (this.getGroupKey(e.port_name) === addDateAssign["groupName"]) {
            addDateAssign["groupName"] = f_event["addValueDHM"]["updateGroup"];
            addDateAssign["index"] = i;
          }
        });

        addDateAssign["value"][f_event["addValueDHM"]["updateTo"]] =
          this.numberPad(sumVal["days"], 2) +
          "D:" +
          this.numberPad(sumVal["hours"], 2) +
          "H:" +
          this.numberPad(sumVal["minutes"], 2) +
          "M";
        this.reduxState.dispatch(addDateAssign);

        if (
          f_event.hasOwnProperty("groupTotalOneToAnother") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("secondGroup") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("condition") &&
          f_event["groupTotalOneToAnother"].hasOwnProperty("formatDHM") &&
          f_event["groupTotalOneToAnother"]["formatDHM"] === true
        ) {
          groupAction["groupName"] =
            f_event["groupTotalOneToAnother"]["secondGroup"];
          this.stateObject[
            f_event["groupTotalOneToAnother"]["secondGroup"]
          ].map((e, i) => {
            if (
              f_event["groupTotalOneToAnother"]["condition"]["operator"] ===
                "=" &&
              eval(
                `'${this.getGroupKey(
                  e[f_event["groupTotalOneToAnother"]["condition"]["to"]]
                )}'==='${
                  action[f_event["groupTotalOneToAnother"]["condition"]["from"]]
                }'`
              ) === true
            ) {
              groupAction["index"] = i;
            }
          });

          let total = 0;
          this.stateObject[action["groupName"]].map((e) => {
            total =
              total +
              this.stringToDaysHoursMinutsObject(
                e[f_event["groupTotalOneToAnother"]["fromFieldName"]],
                true
              );
          });
          total = this.numberToDaysHoursMinutsObject(total);
          groupAction["value"][
            f_event["groupTotalOneToAnother"]["toFieldName"]
          ] =
            this.numberPad(total["days"], 2) +
            "D:" +
            this.numberPad(total["hours"], 2) +
            "H:" +
            this.numberPad(total["minutes"], 2) +
            "M";
          this.reduxState.dispatch(groupAction);
        }
      } else if (
        f_event &&
        f_event.hasOwnProperty("addDays") &&
        f_event["addDays"].hasOwnProperty("fromField") &&
        f_event["addDays"].hasOwnProperty("updateField")
      ) {
        if (value && value !== "") {
          let svf = this.stateObject[f_event["addDays"]["fromField"]]
            ? this.stateObject[f_event["addDays"]["fromField"]]
            : f_event["addDays"].hasOwnProperty("groupName")
            ? this.stateObject[f_event["addDays"]["groupName"]][
                f_event["addDays"]["fromField"]
              ]
            : null;
          let sf =
            svf != undefined && svf != null ? moment(svf.toString()) : null;
          let ad = null,
            vs = [],
            d = 0,
            h = 0,
            m = 0;
          if (sf && sf !== "") {
            sf = typeof sf === "string" ? moment(sf) : sf;
            vs = value.split(".");
            if (vs.length === 2 && parseInt(vs[1]) > 0) {
              d = vs[0];
              h = parseInt(parseFloat("0." + vs[1]) * 24);
              m = parseInt((parseInt(parseFloat("0." + vs[1]) * 24) - h) * 60);
              ad = sf.add(d, "days").add(h, "hours").add(m, "minutes");
            } else {
              ad = sf.add(value, "days");
            }
            if (f_event["addDays"].hasOwnProperty("groupName")) {
              // specific handling
              action["groupName"] = f_event["addDays"]["groupName"];
            }
            action["value"][f_event["addDays"]["updateField"]] =
              moment(ad).format("YYYY-MM-DDTHH:mm");
            this.reduxState.dispatch(action);
          }
        }
      } else if (f_event && f_event.hasOwnProperty("testamar1")) {
        this.testamar1();
      } else if (f_event && f_event.hasOwnProperty("sumReposExpense")) {
        const {
          daily_hire = 0,
          voyage_exp = 0,
          misc_exp = 0,
        } = this.stateObject;

        let totalexpense = daily_hire * 1 + voyage_exp * 1 + misc_exp * 1;

        let action1 = {
          type: "add",
          formName: this.formName,
          groupName: undefined,
          index: undefined,
          value: { total_repos_exp: parseFloat(totalexpense).toFixed(2) },
        };

        this.reduxState.dispatch(action1);
      } else if (
        f_event &&
        f_event.hasOwnProperty("costresponseanalysis") &&
        f_event["costresponseanalysis"]
      ) {
        let consarr = this.stateObject["-"];
        consarr = consarr.map((el, ind) => {
          if (ind == index) {
            let { price, cons } = el;
            let cost = price * cons;

            return { ...el, cost: parseFloat(cost ?? 0).toFixed(2) };
          } else {
            return el;
          }
        });

        let action1 = {
          type: "add",
          formName: this.formName,
          groupName: "-",
          index: undefined,
          value: { "-": consarr },
        };

        this.reduxState.dispatch(action1);
      } else if (
        f_event &&
        f_event.hasOwnProperty("prashant") &&
        f_event["prashant"]
      ) {
      } else if (
        f_event &&
        f_event.hasOwnProperty("prashantm") &&
        f_event["prashantm"]
      ) {
        // const updateAdjArr = this.stateObject["tcbunkeradjustment"].map(
        //   (item, indx) => {
        //     if (indx === index) {
        //       let cal =
        //         Number(item.net_bunker_value) *
        //         (Number(item.per_allowed) / 100);
        //       item.all_bunker_amount = parseFloat((cal ?? 0).toFixed(2));
        //     }
        //     return item;
        //   }
        // );
        // if (
        //   this.stateObject.hasOwnProperty("use_tax_decision") &&
        //   (this.stateObject["use_tax_decision"] === true ||
        //     this.stateObject["use_tax_decision"] === 1)
        // ) {
        //   let amount =
        //     Number(this.stateObject["invoice_total"]) *
        //       (Number(this.stateObject["vat_gst"]) / 100) +
        //     Number(this.stateObject["invoice_total"]);
        //   let adjArrAction = {
        //     type: "add",
        //     formName: this.formName,
        //     groupName: undefined,
        //     index: undefined,
        //     value: { total_amount: amount.toFixed(2) },
        //   };
        //   this.reduxState.dispatch(adjArrAction);
        // }
      } else if (
        (row && row.f_g_frm_type === 368) ||
        row.f_g_frm_type === 374 ||
        row.group_name === "Bunkers"
      ) {
        // TCI/TCO Page, within the Bunker Table, in the Delivery Difference row, added the formula

        let actualDelIfoQty = Number(
          this.stateObject?.bunkers[0]?.[row["f_name"]]
        );
        let estDelIfoQty = Number(
          this.stateObject?.bunkers[1]?.[row["f_name"]]
        );
        let sum = actualDelIfoQty - estDelIfoQty;

        let newState = this.stateObject.bunkers.map((item, indx) => {
          if (indx === 2) {
            let newItem = { ...item, [row["f_name"]]: sum };
            newItem["editable"] = false;
            return newItem;
          } else {
            return item;
          }
        });

        //console.log('newState.....',newState);
        let action1 = {
          type: "add",
          formName: this.formName,
          groupName: "bunkers",
          index: undefined,
          value: { bunkers: newState },
        };

        this.reduxState.dispatch(action1);
      } else if (
        f_event &&
        f_event.hasOwnProperty("sameValue") &&
        f_event["sameValue"].hasOwnProperty("fromField") &&
        f_event["sameValue"].hasOwnProperty("toField")
      ) {
        let fromValue = undefined;
        if (
          action["groupName"] &&
          action["groupName"] !== "" &&
          action["index"] >= 0
        ) {
          fromValue =
            this.stateObject[action["groupName"]][action["index"]][
              f_event["sameValue"]["fromField"]
            ];
        } else if (!action["groupName"] && !action["index"]) {
          fromValue = this.stateObject[f_event["sameValue"]["fromField"]];
        }

        if (fromValue) {
          action["value"][f_event["sameValue"]["toField"]] = fromValue;
          this.reduxState.dispatch(action);
        }
      } else if (f_event && f_event.hasOwnProperty("isChangedEcafuelRoushan")) {
        let portitarr = this.stateObject["portitinerary"];
        let bunkerarr = this.stateObject["bunkerdetails"];
        let consarr = this.stateObject["."];

        let _bunkerarr = bunkerarr?.map((el, ind) => {
          let { eca_days } = el;
          let passagetype = portitarr?.[ind]?.["passage"];
          let cpvalue = findCpPassage(value, passagetype, consarr);
          let eca_consp = cpvalue * eca_days;
          return { ...el, eca_consp: eca_consp.toFixed(2) };
        });
        let action = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey("bunkerdetails"),
          index: undefined,
          value: {},
        };
        action["value"]["bunkerdetails"] = _bunkerarr;
        this.reduxState.dispatch(action);
      } else if (
        f_event &&
        f_event.hasOwnProperty("isupdateSeaconsRoushan") &&
        f_event["isupdateSeaconsRoushan"]
      ) {
        const stobject = this.stateObject;
        let bunkerarr = [];
        stobject.portitinerary.map((el, ind) => {
          const values = BunkerDetailsCalculation(stobject, ind);
          let bukerobj = { ...stobject["bunkerdetails"][ind], ...values };
          bunkerarr.push(bukerobj);
        });
        let grpname = "bunkerdetails";
        let bunkeraction = {
          type: "add",
          formName: this.formName,
          groupName: grpname,
          index: undefined,
          value: {},
        };
        bunkeraction["value"][grpname] = [...bunkerarr];
        this.reduxState.dispatch(bunkeraction);
      } else if (f_event && f_event.hasOwnProperty("boolCheckAPI")) {
        let boolCheckAPI = f_event["boolCheckAPI"];

        let data = {};
        const makePaymentData = this.getState();
        if (boolCheckAPI["method"] === "POST") {
          boolCheckAPI["dataField"].map((e) => (data[e] = this.stateObject[e]));
          const request = await awaitPostAPICall(
            `${URL_WITH_VERSION}${boolCheckAPI["uri"]}`,
            data
          );

          const response = await request["data"];
          // bellow code is for final amount cal on checkbox action
          // //
          // if (boolCheckAPI["uri"] == "/make_payment/check-list" ||
          //   boolCheckAPI["uri"] == "/make_payment/tco-check-list" &&
          //   response.hasOwnProperty("amount")){
          //   response['invoice_total'] = response['amount']
          // }
          if (
            makePaymentData.hasOwnProperty("exch_rate") &&
            response.hasOwnProperty("amount")
          ) {
            const exch_rate = parseFloat(makePaymentData["exch_rate"]);
            const amountCal = parseFloat(response["amount"]);
            makePaymentData["final_amt_loc"] = (exch_rate * amountCal)
              .toFixed(2)
              .toString();
            // //
            // makePaymentData['invoice_total'] = response["amount"]
            action["value"] = makePaymentData;
            this.reduxState.dispatch(action);
          }

          if (!response) {
            if (
              boolCheckAPI["uri"] == "/make_payment/check-list" ||
              boolCheckAPI["uri"] == "/make_payment/tco-check-list"
            ) {
              openNotificationWithIcon("info", request["message"], 5);
            } else {
              openNotificationWithIcon("error", request["message"], 5);
            }
            return;
          }
          //debugger
          action["value"] = {};
          action["groupName"] = undefined;

          if (
            typeof boolCheckAPI["toField"] !== "string" &&
            boolCheckAPI["toField"].length > 0
          ) {
            boolCheckAPI["toField"].map((e) => {
              let at = Object.assign({}, action);
              at["value"] = {};

              if (
                typeof e === "object" &&
                response.hasOwnProperty("invoice") &&
                response["invoice"] &&
                e["fieldName"] === "-"
              ) {
                at["value"][e["fieldName"]] = response["invoice"];
              } else {
                at["value"][e["fieldName"]] = response[e["fieldName"]];
              }
              if (e.hasOwnProperty("groupKey")) at["groupName"] = e["groupKey"];

              if (data["purchase_type"] == "3") {
                at["value"]["-"] = data["-"];
                at["value"]["amount"] = makePaymentData["amount"];
              }

              this.reduxState.dispatch(at);
            });
          } else if (typeof boolCheckAPI["toField"] === "string") {
            //debugger
            action["value"][boolCheckAPI["toField"]] =
              response[boolCheckAPI["toField"]];
            this.reduxState.dispatch(action);
          }
        }
      } else if (f_event && f_event.hasOwnProperty("isSyncDateRoushan")) {
        let _portdatedetails = this.stateObject["portdatedetails"];

        let firstdate = dayjs(value).format("YYYY-MM-DD HH:mm");
        let stateData = this.stateObject;
        let totalvoydays = 0;
        // Check if firstdate is a valid date-time format
        if (dayjs(firstdate).isValid()) {
          const newarr = updateThetableBYDate(
            _portdatedetails,
            firstdate,
            index
          );

          totalvoydays = newarr.reduce(
            (ac, el) => ac + el.tsd * 1 + el.pdays * 1,
            0
          );

          let gpkey = this.getGroupKey(row.group_name);

          if (stateData.hasOwnProperty("tot_voy_days")) {
            stateData["tot_voy_days"] = totalvoydays.toFixed(2);
          }
          let dateaction = {
            type: "add",
            formName: this.formName,
            groupName: gpkey,
            index: undefined,
            value: {},
          };
          dateaction["value"][gpkey] = newarr;
          this.reduxState.dispatch(dateaction);

          let counter = "";
          let _bunker =
            stateData?.["portitinerary"] &&
            Array.isArray(newarr) &&
            stateData?.["portitinerary"]?.length > 0 &&
            stateData?.["portitinerary"]?.map((el, ind) => {
              let obj = stateData?.["bunkerdetails"]?.[ind]
                ? { ...stateData?.["bunkerdetails"][ind] }
                : {};

              if (newarr[ind]) {
                obj["arrival_date_time"] = newarr[ind]["arrival_date_time"];
                obj["departure"] = newarr[ind]["departure"];
              }
              counter = ind;

              return obj;
            });

          stateData["commence_date"] = _bunker[0]["arrival_date_time"];
          stateData["completing_date"] = _bunker[counter]["departure"];

          let bunkeraction = {
            type: "add",
            formName: this.formName,
            groupName: "bunkerdetails",
            index: undefined,
            value: {},
          };

          bunkeraction["value"]["bunkerdetails"] = _bunker;
          this.reduxState.dispatch(bunkeraction);
        } else {
          // If firstdate is not a valid date-time format, set it to an empty string
          firstdate = ""; // or firstdate = "0000-00-00 00:00" or any other default value as needed
        }
      } else if (f_event && f_event.hasOwnProperty("conditionPopup")) {
        if (
          typeof f_event["conditionPopup"] === "object" &&
          f_event["conditionPopup"].hasOwnProperty("length") &&
          f_event["conditionPopup"].length > 0
        ) {
          f_event["conditionPoup"].map((ce) => {
            let conditionValue = true;
            ce["condition"].map((e) => {
              if (
                e.hasOwnProperty("gt") &&
                e.hasOwnProperty("fromField") &&
                this.stateObject[e["fromField"]] * 1 > e["gt"]
              ) {
                conditionValue = conditionValue && true;
              } else {
                conditionValue = conditionValue && false;
              }
            });

            if (conditionValue === true) {
              action["value"][row["f_name"]] = ce["changeValue"]["toValue"];
            } else if (conditionValue === false) {
              action["value"][row["f_name"]] = ce["changeValue"]["otherWise"];
              openNotificationWithIcon(
                "error",
                ce["changeValue"]["otherWiseMessage"],
                5
              );
            }
          });
        } else if (
          typeof f_event["conditionPopup"] === "object" &&
          f_event["conditionPopup"].hasOwnProperty("changeValue") &&
          f_event["conditionPopup"].hasOwnProperty("condition")
        ) {
          let conditionValue = true;
          f_event["conditionPopup"]["condition"].map((e) => {
            if (
              e.hasOwnProperty("gt") &&
              e.hasOwnProperty("fromField") &&
              this.stateObject[e["fromField"]] * 1 > e["gt"]
            ) {
              conditionValue = conditionValue && true;
            } else {
              conditionValue = conditionValue && false;
            }
          });

          if (conditionValue === true) {
            action["value"][row["f_name"]] =
              f_event["conditionPopup"]["changeValue"]["toValue"];
          } else if (conditionValue === false) {
            action["value"][row["f_name"]] =
              f_event["conditionPopup"]["changeValue"]["otherWise"];
            openNotificationWithIcon(
              "error",
              f_event["conditionPopup"]["changeValue"]["otherWiseMessage"],
              5
            );
          }
        }

        this.reduxState.dispatch(action);
      } else if (f_event && f_event.hasOwnProperty("updateLdQtyRoushan")) {
        let cargoarr = this.stateObject["cargos"];
        let portitr = this.stateObject["portitinerary"];
        let totalcpqty = cargoarr.reduce((ac, el) => {
          return ac + parseFloat(el?.cp_qty ?? 0);
        }, 0);

        portitr.map((el) => (el.l_d_qty = totalcpqty.toFixed(2)));

        let totalloadqty = portitr.reduce((ac, el) => {
          if (el.funct == "9") {
            return ac - parseFloat(el?.l_d_qty ?? 0);
          } else {
            return ac + parseFloat(el?.l_d_qty ?? 0);
          }
        }, 0);

        let action = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey("portitinerary"),
          index: undefined,
          value: {},
        };
        action["value"]["portitinerary"] = portitr;
        this.reduxState.dispatch(action);

        let totalaction = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey("totalitinerarysummary"),
          index: undefined,
          value: {},
        };
        totalaction["value"]["totalitinerarysummary"] = {
          total_lq: totalloadqty.toFixed(2),
        };

        this.reduxState.dispatch(totalaction);
      } else if (f_event && f_event["roushan"] && f_event["totalEtsExpense"]) {
        let totalexpenseaction = {
          type: "add",
          formName: this.formName,
          groupName: this.getGroupKey(f_event["fromFieldGroup"]),
          index: undefined,
          value: {},
        };
        const euetsarr = this.stateObject[f_event["togroupName"]];
        const price =
          this.stateObject[f_event["fromFieldGroup"]][f_event["fromField"]];
        let totalEtsExpense = 0;
        euetsarr &&
          euetsarr.length > 0 &&
          euetsarr.map((el, _index) => {
            let action = {
              type: "add",
              formName: this.formName,
              groupName: this.getGroupKey(f_event["togroupName"]),
              index: _index,
              value: {},
            };
            let _price = parseFloat(el["ttl_eu_ets"] * 1 * price).toFixed(2);
            action["value"][f_event["updateFields"]] = _price;
            totalEtsExpense += parseFloat(_price);
            this.reduxState.dispatch(action);
          });

        totalexpenseaction["value"]["ttl_co2_cost"] =
          totalEtsExpense.toFixed(2);

        this.reduxState.dispatch(totalexpenseaction);
      } else if (f_event && f_event["isDemDesRoushan"]) {
        let value = 0,
          demdesamount = 0;
        let demaction = {
          type: "add",
          formName: this.formName,
          groupName: undefined,
          index: undefined,
          value: {},
        };
        let demdesdays = this.stateObject[f_event["fromField"]];

        let demvalue = this.stateObject[f_event["DemfieldName"]];

        if (demvalue) {
          let demaction = {
            type: "add",
            formName: this.formName,
            groupName: undefined,
            index: undefined,
            value: {},
          };

          demaction["value"]["des_rate"] = (demvalue / 2).toFixed(2);

          this.reduxState.dispatch(demaction);
        }
        if (demdesdays > 0) {
          value = this.stateObject[f_event["DesfieldName"]];
          demdesamount = isNaN(demdesdays) ? 0 : demdesdays * 1 * value;
        } else {
          value = this.stateObject[f_event["DemfieldName"]];
          demdesamount = isNaN(demdesdays) ? 0 : demdesdays * 1 * value;
        }
        demaction["value"][f_event["updateField"]] = demdesamount.toFixed(2);
        this.reduxState.dispatch(demaction);
        demaction["value"]["des_amt"] = demdesamount > 0 ? 61 : 60;
        this.reduxState.dispatch(demaction);
      } else if (
        f_event &&
        f_event.hasOwnProperty("isEstDays") &&
        f_event["isEstDays"]
      ) {
        const fieldval1 = this.stateObject[f_event["fromField1"]];
        const fieldval2 = this.stateObject[f_event["fromField2"]];
        let updatedvalue = value - (fieldval1 * 1 + fieldval2 * 1);
        let dayaction = {
          type: "add",
          formName: this.formName,
          groupName: undefined,
          index: undefined,
          value: {},
        };
        dayaction["value"][f_event["updateField"]] = updatedvalue.toFixed(2);
        this.reduxState.dispatch(dayaction);
        dayaction["value"]["des_days"] = updatedvalue > 0 ? 61 : 60;
        this.reduxState.dispatch(dayaction);
      }

      // Event Calling in Loop
      if (f_event && f_event.hasOwnProperty("eventLoops")) {
        this.eventChanin(f_event["eventLoops"], row["group_name"], index);
      } else if (f_event && f_event.hasOwnProperty("noGroupEventLoops")) {
        this.eventChanin(f_event["noGroupEventLoops"], undefined, undefined);
      }
    } catch (err) {
      console.log("err in rowevents", err);
    }
  };

  roushanfindrspeed = (f_event, index, value) => {
    let portitarr = JSON.parse(
      JSON.stringify(this.stateObject[f_event.groupName])
    );
    let portitobj = portitarr[index];

    let speedarr = this.stateObject[f_event.fromGroup];
    let wfper = portitobj["wf_per"];
    let spdobj = {};

    let speed = 0;
    let effspeed = 0;
    let gsd = 0;

    // if (portitobj["s_type"] == "1") {
    //   //  eco
    //   spdobj = speedarr[1];
    //   speed =
    //     value == "1"
    //       ? spdobj?.["ballast_spd"] ?? 0
    //       : spdobj?.["laden_spd"] ?? 0;
    // } else if (portitobj["s_type"] == "2") {
    //   // cp
    //   spdobj = speedarr[0];
    //   speed =
    //     value == "1"
    //       ? spdobj?.["ballast_spd"] ?? 0
    //       : spdobj?.["laden_spd"] ?? 0;
    // } else if (portitobj["s_type"] == "3") {
    //   // warranted
    //   spdobj = speedarr[2];
    //   speed =
    //     value == "1"
    //       ? spdobj?.["ballast_spd"] ?? 0
    //       : spdobj?.["laden_spd"] ?? 0;
    // }

    speed = findspeed(speedarr, portitobj["s_type"], portitobj["passage"]);

    effspeed = parseFloat(speed) * (1 - wfper * 0.01);
    gsd = portitobj["miles"] / (speed * 24);
    let tsd = portitobj["miles"] / (effspeed * 24);
    gsd = isFinite(gsd) ? gsd.toFixed(2) : 0;
    tsd = isFinite(tsd) ? tsd.toFixed(2) : 0;
    let xsd = tsd * 1 - gsd * 1;

    portitobj["speed"] = isNaN(speed) ? "0.00" : speed;
    portitobj["eff_speed"] = isNaN(effspeed) ? "0.00" : effspeed.toFixed(2);
    portitobj["gsd"] = isNaN(gsd) ? "0.00" : parseFloat(gsd).toFixed(2);
    portitobj["tsd"] = isNaN(gsd) ? "0.00" : parseFloat(tsd).toFixed(2);
    portitobj["xsd"] = isNaN(gsd) ? "0.00" : parseFloat(xsd).toFixed(2);

    portitarr[index] = { ...portitobj };

    let action = {
      type: "add",
      formName: this.formName,
      groupName: this.getGroupKey(f_event.groupName),
      index: undefined,
      value: {},
    };
    action["value"] = {
      [f_event.groupName]: portitarr,
    };
    this.reduxState.dispatch(action);
  };

  eventChanin = (f_event, oldGroup, index) => {
    let events = [];
    let ff = this.originalState.getFormFields();
    f_event &&
      f_event.length > 0 &&
      f_event.map((e) => {
        let obj = ff.filter((e1) => e["f_id"] === e1["f_id"]);
        if (obj && obj.length === 1) {
          if (
            e.hasOwnProperty("group") &&
            e["group"] !== "" &&
            e.hasOwnProperty("index") &&
            e.hasOwnProperty("dynamic") &&
            e["dynamic"] === true
          ) {
            let giv = this.getStateObject(e["group"]);
            let igiv = -1;
            giv.map((g, ig) => {
              if (
                this.getGroupKey(g[e["dyncName"]]) ===
                  this.getGroupKey(oldGroup) &&
                igiv === -1
              )
                igiv = ig;
            });
            if (igiv >= 0) {
              events.push(
                this.rowEvents(
                  obj[0],
                  giv && giv[igiv] && giv[igiv][obj[0]["f_name"]]
                    ? giv[igiv][obj[0]["f_name"]]
                    : "",
                  igiv,
                  true
                )
              );
            }
          } else if (
            e.hasOwnProperty("group") &&
            e["group"] !== "" &&
            e.hasOwnProperty("index") &&
            e["index"].toLowerCase() === "same" &&
            !e.hasOwnProperty("dynamic")
          ) {
            let giv = this.getStateObject(e["group"]);
            events.push(
              this.rowEvents(
                obj[0],
                giv && giv[index] && giv[index][obj[0]["f_name"]]
                  ? giv[index][obj[0]["f_name"]]
                  : "",
                index,
                true
              )
            );
          } else if (
            e.hasOwnProperty("group") &&
            e["group"] !== "" &&
            e.hasOwnProperty("index") &&
            e["index"] >= 0 &&
            !e.hasOwnProperty("dynamic")
          ) {
            let giv = this.getStateObject(e["group"]);
            events.push(
              this.rowEvents(
                obj[0],
                giv && giv[e["index"]] && giv[e["index"]][obj[0]["f_name"]]
                  ? giv[e["index"]][obj[0]["f_name"]]
                  : "",
                e["index"],
                true
              )
            );
          } else if (
            e.hasOwnProperty("group") &&
            e["group"] !== "" &&
            !e.hasOwnProperty("index")
          ) {
            let giv = this.getStateObject(e["group"]);
            events.push(
              this.rowEvents(
                obj[0],
                giv && giv[obj[0]["f_name"]] ? giv[obj[0]["f_name"]] : "",
                undefined,
                true
              )
            );
          } else if (!e.hasOwnProperty("group") && !e.hasOwnProperty("index")) {
            let giv = this.getStateObject(obj[0]["f_name"]);
            events.push(
              this.rowEvents(obj[0], giv ? giv : "", undefined, true)
            );
          }
        }
      });

    return false;
  };

  nextdate = (currentdate, days) => {
    let sf = dayjs(currentdate);
    let ad = null,
      vs = [],
      d = 0,
      h = 0,
      m = 0;
    if (sf && sf !== "") {
      sf = typeof sf === "string" ? dayjs(sf) : sf;
      days = days.toString();
      vs = days.split(".");

      if (vs.length === 2 && parseInt(vs[1]) > 0) {
        d = vs[0];
        h = parseInt(parseFloat("0." + vs[1]) * 24);
        m = parseInt((parseInt(parseFloat("0." + vs[1]) * 24) - h) * 60);
        ad = sf.add(d, "day").add(h, "hour").add(m, "minute");
      } else {
        ad = sf.add(days, "day");
      }
    }

    return ad; // return the next date according the current date and days
  };

  onChangeEvent = (evt, row, index, record = null, subName = undefined) => {
    if (row.f_name === "eca_fuel_grade")
      if (evt === "7") {
        openNotificationWithIcon(
          "info",
          "Please check the BALLAST and LADEN Sea Consumption Rates for IFO or VLSFO",
          3
        );
      } else if (evt === "10") {
        //openNotificationWithIcon("info","You have selected ULSFO",13);
      }

    let alternateSt = this.originalState.getOriginalState();
    let action = {
      type: "add",
      formName: this.formName,
      groupName: this.getGroupKey(row.group_name),
      index: index,
      value: {},
    };
    let evtValue = undefined,
      eVal = undefined;
    if (subName && subName !== "" && row.f_type === "boolean") {
      if (evt <= 0) {
        action["value"][row.f_name + subName] = 1;
      } else {
        action["value"][row.f_name + subName] = evt;
      }

      this.reduxState.dispatch(action);
    } else {
      if (
        row.f_type === "boolean" &&
        row.f_dyc_extras &&
        row.f_dyc_extras != ""
      ) {
        let de = JSON.parse(row.f_dyc_extras);
        if (
          de &&
          de.hasOwnProperty("isCheckbox") &&
          de["isCheckbox"] === true
        ) {
          evtValue =
            evt.hasOwnProperty("target") &&
            evt["target"].hasOwnProperty("checked")
              ? evt["target"]["checked"]
              : false;
        } else {
          evtValue = evt.hasOwnProperty("target") ? evt.target.value : evt;
        }
      } else if (row.f_type === "number") {
        evtValue = evt.hasOwnProperty("target")
          ? evt.target.value.toString()
          : evt;
      } else if (evt) {
        evtValue = evt.hasOwnProperty("target") ? evt.target.value : evt;
      }

      if (
        (!evtValue || evtValue === "" || evtValue != false) &&
        alternateSt &&
        JSON.stringify(alternateSt) !== JSON.stringify({})
      ) {
        if (
          action.groupName &&
          action.index >= 0 &&
          alternateSt[action.groupName] &&
          alternateSt[action.groupName][action.index] &&
          alternateSt[action.groupName][action.index][row.f_name]
        ) {
          evtValue = alternateSt[action.groupName][action.index][row.f_name];
        } else if (
          action.groupName &&
          !action.index &&
          alternateSt[action.groupName] &&
          alternateSt[action.groupName] &&
          alternateSt[action.groupName][row.f_name]
        ) {
          evtValue = alternateSt[action.groupName][row.f_name];
        } else if (
          !action.groupName &&
          !action.index &&
          alternateSt[row.f_name]
        ) {
          evtValue = alternateSt[row.f_name];
        }
      }
      if (["date", "datetime"].indexOf(row.f_type) >= 0) {
        if (evtValue) {
          evtValue = moment(evtValue).format("YYYY-MM-DDTHH:mm");
        }
        // else{
        //   evtValue=null
        // }
      } else if (row.f_type == "time") {
        evtValue = moment(evtValue).format("HH:mm");
      }

      // action["value"][row.f_name] =
      //   row.f_type === "number" && evtValue.split(".")[0] === ""
      //     ? "" + evtValue    // causing blast bonus save issue in tc estimate
      //     : evtValue;

      action["value"][row.f_name] =
        row.f_type === "number" && evtValue.split(".")[0] === "" ? 0 : evtValue;

      // need to upgrade this for validation to common function
      if (row.f_type === "number" && evtValue < 0 && row.f_regex) {
        openNotificationWithIcon(
          "error",
          <div
            className="notify-error"
            dangerouslySetInnerHTML={{ __html: "Value Must Be Greater Than 0" }}
          />,
          5
        );
        return false;
      }

      // console.log("row.name",row.name)

      if (this.popOverIds.includes(row.f_id)) {
        action["value"][row.f_name] = index;
        evtValue = index;
        if (row?.f_name === "vessel_id") {
          action["value"]["vesselInfo"] = record;
        }
        // console.log("evtValue", evtValue);
        // console.log("action", action);
        // console.log("record",record)
      }

      // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // if (regex.test(action?.value?.email)) {
      //   this.isEmailValidated = true;
      // } else {
      //   this.isEmailValidated = false;
      // }

      // console.log("this.isEmailValidated....", this.isEmailValidated);

      this.reduxState.dispatch(action);
      this.rowEvents(row, evtValue, index);

      if (row.f_name == "s_type") {
        setTimeout(() => {
          var fieldName = "speed";
          var element = document.getElementById(fieldName + index);
          if (element != null) {
            element.focus();

            setTimeout(() => {
              element.blur();
            }, 100);
          }
        }, 200);
      }

      //checking Delivery & Redelivery date selection
      if (row.group_name === "Delivery Term") {
        const tempState = this.getState();
        if (tempState.hasOwnProperty("deliveryterm")) {
          const delivery_est_gmt =
            tempState["deliveryterm"]?.[0]?.est_gmt || "";
          const delivery_actual_gmt =
            tempState["deliveryterm"]?.[0]?.actual_gmt || "";
          const redelivery_est_gmt =
            tempState["deliveryterm"]?.[1]?.est_gmt || "";
          const redelivery_actual_gmt =
            tempState["deliveryterm"]?.[1]?.actual_gmt || "";
          const deliveryEstDate = new Date(delivery_est_gmt);
          const deliveryActualDate = new Date(delivery_actual_gmt);
          const redeliveryEstDate = new Date(redelivery_est_gmt);
          const redeliveryActualDate = new Date(redelivery_actual_gmt);
          if (redelivery_est_gmt && !delivery_est_gmt) {
            openNotificationWithIcon("info", "Select Delivery Estimated Date");
            return;
          }
          if (redelivery_actual_gmt && !delivery_actual_gmt) {
            openNotificationWithIcon("info", "Select Delivery Actual Date");
            return;
          }
          if (deliveryEstDate > redeliveryEstDate) {
            openNotificationWithIcon(
              "info",
              "Redelivery Estimated Date should be after Delivery Estimated Date"
            );
            return;
          }
          if (deliveryActualDate > redeliveryActualDate) {
            openNotificationWithIcon(
              "info",
              "Redelivery Actual Date should be after Delivery Actual Date"
            );
            return;
          }
        }
      }

      if (
        (row.f_name === "to_date" && row.group_name === "Hire Term") ||
        (row.group_name === "Commission Term" && row.f_name === "to_gmt")
      ) {
        const tempState = this.getState();
        const redelivery_actual_gmt =
          tempState["deliveryterm"]?.[1]?.actual_gmt || "";
        const redeliveryActualDate = new Date(redelivery_actual_gmt);
        const hiredate = new Date(evtValue);
        if (hiredate > redeliveryActualDate) {
          openNotificationWithIcon(
            "info",
            " TO Date should be less than Redelivery Actual Date.",
            3
          );
          return;
        }
      }

      // Use handleGroupUpdate for Hire Term and Commission Term
      if (["Hire Term", "Commission Term"].includes(row.group_name)) {
        this.handleGroupUpdate(
          row.group_name,
          action.index,
          row.f_name,
          evtValue
        );
      }
    }
  };
}

export default DynamicFormFields;
