import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Modal, Spin } from "antd";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import RightBarUI from "../../components/RightBarUI";
import VesselFormReport from "../../routes/data-center-reports/VesselFormReport";
import URL_WITH_VERSION, {
  URL_WITHOUT_VERSION,
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  useStateCallback,
} from "../../shared";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import Attachment from "./Attachment";
import VoyageManagerInfo from "./VoyageManagerInfo";
import ParsedInfo from "../../routes/voyage-list/components/ParsedInfo";
import VesselUploadImage from "./VesselUploadImage";
import SearchVesselInput from "./SearchVesselInput";
import MarineSpin from "./MarineSpin";

const { Content } = Layout;

const initialportcons = [
  {
    editable: true,
    index: 0,
    con_type: 3,
    con_g: 2,
    id: -9e6 + 0,
    con_unit: 2,
    con_loading: "0.00",
    con_disch: "0.00",
    con_ideal_on: "0.00",
    con_capacity: "0.00",
    con_margin: "0.00",
    con_heat: "0.00",
    con_heat_p: "0.00",
    con_heat_pp: "0.00",
    con_igs: "0.00",
    con_clean: "0.00",
    con_maneuv: "0.00",
    con_av: "0.00",
  },
  {
    editable: true,
    index: 1,
    con_type: 10,
    con_g: 4,
    id: -9e6 + 1,
    con_unit: 2,
    con_loading: "0.00",
    con_disch: "0.00",
    con_ideal_on: "0.00",
    con_capacity: "0.00",
    con_margin: "0.00",
    con_heat: "0.00",
    con_heat_p: "0.00",
    con_heat_pp: "0.00",
    con_igs: "0.00",
    con_clean: "0.00",
    con_maneuv: "0.00",
    // con_av: "0.00",
  },
  {
    editable: true,
    index: 2,
    con_type: 5,
    con_g: 3,
    id: -9e6 + 2,
    con_unit: 2,
    con_loading: "0.00",
    con_disch: "0.00",
    con_ideal_on: "0.00",
    con_capacity: "0.00",
    con_margin: "0.00",
    con_heat: "0.00",
    con_heat_p: "0.00",
    con_heat_pp: "0.00",
    con_igs: "0.00",
    con_clean: "0.00",
    con_maneuv: "0.00",
    con_av: "0.00",
  },
  {
    editable: true,
    index: 3,
    con_type: 7,
    con_g: 4,
    id: -9e6 + 3,
    con_unit: 2,
    con_loading: "0.00",
    con_disch: "0.00",
    con_ideal_on: "0.00",
    con_capacity: "0.00",
    con_margin: "0.00",
    con_heat: "0.00",
    con_heat_p: "0.00",
    con_heat_pp: "0.00",
    con_igs: "0.00",
    con_clean: "0.00",
    con_maneuv: "0.00",
    con_av: "0.00",
  },
  {
    editable: true,
    index: 4,
    con_type: 4,
    con_g: 3,
    id: -9e6 + 4,
    con_unit: 2,
    con_loading: "0.00",
    con_disch: "0.00",
    con_ideal_on: "0.00",
    con_capacity: "0.00",
    con_margin: "0.00",
    con_heat: "0.00",
    con_heat_p: "0.00",
    con_heat_pp: "0.00",
    con_igs: "0.00",
    con_clean: "0.00",
    con_maneuv: "0.00",
    con_av: "0.00",
  },
];

const initialseacons = [
  {
    editable: true,
    index: 1,
    speed_type: 1,
    ballast_laden: 1,
    id: -9e6 + 10,
    engine_load: 85,
    ifo: "0.00",
    vlsfo: "0.00",
    ulsfo: "0.00",
    lsmgo: "0.00",
    mgo: "0.00",
  },
  {
    editable: true,
    index: 2,
    speed_type: 1,
    ballast_laden: 2,
    id: -9e6 + 11,
    engine_load: 85,
    ifo: "0.00",
    vlsfo: "0.00",
    ulsfo: "0.00",
    lsmgo: "0.00",
    mgo: "0.00",
  },
];

const VesselSchedule = (props) => {
  const [state, setState] = useStateCallback({
    frmName: "vessel_schedule",
    responseData: { frm: [], tabs: [], active_tab: {} },
    formData: {
      id: 0,
      "portconsp.tableperday": [
        {
          editable: true,
          index: 0,
          con_type: 3,
          con_g: 2,
          id: -9e6 + 0,
          con_unit: 2,
          con_loading: "0.00",
          con_disch: "0.00",
          con_ideal_on: "0.00",
          con_capacity: "0.00",
          con_margin: "0.00",
          con_heat: "0.00",
          con_heat_p: "0.00",
          con_heat_pp: "0.00",
          con_igs: "0.00",
          con_clean: "0.00",
          con_maneuv: "0.00",
          con_av: "0.00",
        },
        {
          editable: true,
          index: 1,
          con_type: 10,
          con_g: 4,
          id: -9e6 + 1,
          con_unit: 2,
          con_loading: "0.00",
          con_disch: "0.00",
          con_ideal_on: "0.00",
          con_capacity: "0.00",
          con_margin: "0.00",
          con_heat: "0.00",
          con_heat_p: "0.00",
          con_heat_pp: "0.00",
          con_igs: "0.00",
          con_clean: "0.00",
          con_maneuv: "0.00",
          // con_av: "0.00",
        },
        {
          editable: true,
          index: 2,
          con_type: 5,
          con_g: 3,
          id: -9e6 + 2,
          con_unit: 2,
          con_loading: "0.00",
          con_disch: "0.00",
          con_ideal_on: "0.00",
          con_capacity: "0.00",
          con_margin: "0.00",
          con_heat: "0.00",
          con_heat_p: "0.00",
          con_heat_pp: "0.00",
          con_igs: "0.00",
          con_clean: "0.00",
          con_maneuv: "0.00",
          con_av: "0.00",
        },
        {
          editable: true,
          index: 3,
          con_type: 7,
          con_g: 4,
          id: -9e6 + 3,
          con_unit: 2,
          con_loading: "0.00",
          con_disch: "0.00",
          con_ideal_on: "0.00",
          con_capacity: "0.00",
          con_margin: "0.00",
          con_heat: "0.00",
          con_heat_p: "0.00",
          con_heat_pp: "0.00",
          con_igs: "0.00",
          con_clean: "0.00",
          con_maneuv: "0.00",
          con_av: "0.00",
        },
        {
          editable: true,
          index: 4,
          con_type: 4,
          con_g: 3,
          id: -9e6 + 4,
          con_unit: 2,
          con_loading: "0.00",
          con_disch: "0.00",
          con_ideal_on: "0.00",
          con_capacity: "0.00",
          con_margin: "0.00",
          con_heat: "0.00",
          con_heat_p: "0.00",
          con_heat_pp: "0.00",
          con_igs: "0.00",
          con_clean: "0.00",
          con_maneuv: "0.00",
          con_av: "0.00",
        },
      ],
      "seaspdconsp.tableperday": [
        {
          editable: true,
          index: 1,
          speed_type: 1,
          ballast_laden: 1,
          id: -9e6 + 10,
          engine_load: 85,
          ifo: "0.00",
          vlsfo: "0.00",
          ulsfo: "0.00",
          lsmgo: "0.00",
          mgo: "0.00",
        },
        {
          editable: true,
          index: 2,
          speed_type: 1,
          ballast_laden: 2,
          id: -9e6 + 11,
          engine_load: 85,
          ifo: "0.00",
          vlsfo: "0.00",
          ulsfo: "0.00",
          lsmgo: "0.00",
          mgo: "0.00",
        },
      ],
    },
    vessel_img: "",
    isVisible: false,
    speedConsumptionForm: "vessel_speed_fuel_consumption_details",
    vesselConsumptionData: {},
    spdconsumptionData: {},
    isEdit: true,
    isShowAddButton: props.isShowAddButton || false,
    visibleSummary: false,
    visibleDrawer: false,
    title: undefined,
    loadComponent: undefined,
    width: 1200,
    frmVisible: true,
    // showSideListBar:props.showSideListBar === false ? props.showSideListBar : true,
    showSideListBar: false,
    isShowVesselFormReport: false,
    VoyageManagerInfoModal: false,
    agencyvslEdit: props.agencyVisselEdit,
    selectedID: null,
    vmInfoData: null,
    marineData: props.marineData,
    showVesselUploadImage: false,
    vesselImage: "",
  });

  const [isVoyageManagerInfo, setIsVoyageManagerInfo] = useState(false);
  useEffect(() => {
    (async () => {
      const { formData } = state;
      const response = await getAPICall(
        `${URL_WITHOUT_VERSION}get/${state.frmName}`
      );
      const data = await response["data"];
      let fd = Object.assign(formData, props.formData || {});

      formref.current = _.cloneDeep(fd);
      setState((prevState) => ({
        ...prevState,
        responseData: data,
        formData: fd,
        isEdit: false,
      }));
    })();
  }, []);
  useEffect(() => {
    if (isVoyageManagerInfo === true) {
      getTableData();
    }
  }, [isVoyageManagerInfo]);
  const getTableData = async () => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/vm/edit?ae=${state?.formData["vessel_id"]}`
      );
      const respData = await response["data"];
      if (respData.length > 0) {
        setState((prevState) => ({
          ...prevState,
          vmInfoData: respData,
          VoyageManagerInfoModal: true,
        }));
      } else {
        openNotificationWithIcon(
          "info",
          "No VM Data available for this Vessel"
        );
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  let formref = useRef(null);

  const reFreshForm = () => {
    const { formData } = state;

    if (formData.id) {
      editMode(state.formData.id);
    } else {
      _onCreateFormData();
    }
  };

  const getVesselImage = (url) => {
    setState((prev) => ({
      ...prev,
      formData: {
        ...prev.formData,
        vessel_img: url,
      },
    }));
  };

  const openVesselFormReport = async (showVesselFormReport, id) => {
    if (showVesselFormReport) {
      if (state.formData.id) {
        try {
          // for report Api
          const responseReport = await getAPICall(
            `${URL_WITH_VERSION}/vessel/report?e=${state.formData.id}`
          );
          const respDataReport = await responseReport["data"];

          if (respDataReport && respDataReport.hasOwnProperty("id")) {
            setState((prevState) => ({
              ...prevState,
              reportFormData: respDataReport,
              isShowVesselFormReport: showVesselFormReport,
            }));
          } else {
            openNotificationWithIcon(
              "error",
              "Unable to show report, possibly missing report data !",
              5
            );
          }
        } catch (err) {
          console.log("error", err);
          openNotificationWithIcon("error", "Something Went wrong", 3);
        }
      } else {
        openNotificationWithIcon(
          "info",
          "Please Save the Vessel Form First.",
          5
        );
      }
    } else {
      setState({
        ...state,
        isShowVesselFormReport: showVesselFormReport,
      });
    }
  };

  const onClickPlus = async (action, pdata) => {
    let portconsdata = [];
    if (
      pdata.hasOwnProperty("portconsp.tableperday") &&
      pdata["portconsp.tableperday"].length > 0
    ) {
      pdata["portconsp.tableperday"].map((key, index) => {
        if (
          pdata.hasOwnProperty("seaspdconsp.tableperday") &&
          pdata["seaspdconsp.tableperday"].length > 0
        ) {
          let d = pdata["seaspdconsp.tableperday"].filter(
            (e) => e.id === action.ID
          );
          d = d.length === 1 ? d[0] : [];
          if (d.hasOwnProperty("children") && d["children"].length > 0) {
            d["children"].map((p, i) => {
              if (p["fuel_type"] * 1 === key["con_type"] * 1) {
                portconsdata.push({
                  id: p["id"],
                  fuel_type: key.con_type,
                  fuel_value: p["fuel_value"],
                  editable: true,
                });
              } else {
                portconsdata.push({
                  id: 0 - i,
                  fuel_type: key.con_type,
                  editable: true,
                });
              }
              return true;
            });
          } else {
            portconsdata.push({
              id: 0 - index,
              fuel_type: key.con_type,
              editable: true,
            });
          }
        } else {
          portconsdata.push({
            id: 0 - index,
            fuel_type: key.con_type,
            editable: true,
          });
        }
        return true;
      });
    }

    const response = await getAPICall(
      `${URL_WITH_VERSION}/vessel/vsfcd/edit/${action.ID}`
    );
    const data = await response["data"];
    let itemData = Object.assign(portconsdata, data["fuel_type"]);
    if (action.ID && action.ID < 0) {
      openNotificationWithIcon("error", "Save Vessel Data first");
    } else if (data && data["spd_id"] < 0 && action.ID < 0) {
      openNotificationWithIcon("error", "Save Vessel Data first");
    } else {
      if (data === false && action.ID > 0) {
        itemData.map(
          (e, i) =>
            (itemData[i] = Object.assign(
              { index: i, id: -9e6 + i },
              itemData[i]
            ))
        );
        itemData = {
          id: data["id"],
          spd_id: action.ID,
          vesselspeedfuelconsumption: itemData,
        };
      } else if (data && data["spd_id"] > 0) {
        itemData.map(
          (e, i) =>
            (itemData[i] = Object.assign(
              { index: i, id: -9e6 + i },
              itemData[i]
            ))
        );
        itemData = {
          id: data["id"],
          spd_id: data["spd_id"],
          vesselspeedfuelconsumption: itemData,
        };
      } else {
        itemData = {
          id: data["id"],
          spd_id: action.ID,
          vesselspeedfuelconsumption: itemData,
        };
      }
      setState(
        (prevState) => ({ ...prevState, vesselConsumptionData: itemData }),
        () => {
          setState({ ...state, isVisible: true });
        }
      );
    }
  };

  const onCancel = () => setState({ ...state, isVisible: false });

  const onCloseDrawer = () =>
    setState({
      ...state,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    });

  const onClickRightMenu = (key, options) => {
    const { formData } = state;
    onCloseDrawer();
    let loadComponent = undefined;
    switch (key) {
      case "attachment":
        if (formData["id"] && formData["id"] > 0) {
          loadComponent = (
            <Attachment frmData={formData} onClose={onCloseDrawer} />
          );
        } else {
          openNotificationWithIcon("info", "Please select vessel from list");
        }
        break;
      case "vm":
        if (formData.vessel_id) {
          setIsVoyageManagerInfo(!isVoyageManagerInfo);
        } else {
          openNotificationWithIcon("info", "Please select vessel from list");
        }
        break;
      case "sideMap": {
        if (!state?.marineData?.[0]?.SHIPNAME) {
          if (state.formData.hasOwnProperty("vessel_name")) {
            openNotificationWithIcon("info", "Vessel data not found");
          } else {
            openNotificationWithIcon(
              "info",
              "Please Save the Vessel Form First"
            );
          }
          return;
        }
        loadComponent = <ParsedInfo marineData={state.marineData[0]} />;
        break;
      }
      default:
        break;
    }

    setState({
      ...state,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    });
  };

  const hasEmptyConType = (array) => {
    return array.some((obj) => obj.con_type === "");
  };

  const validateEvent = (data, cb) => {
    let isFaluty = false,
      message = undefined;
    if (
      data.hasOwnProperty("portconsp.tableperday") &&
      data["portconsp.tableperday"].length > 0 &&
      hasEmptyConType(data["portconsp.tableperday"])
    ) {
      isFaluty = true;
      message =
        "Please ensure the fuel type is selected in the Port Consumption Table (Per Day).";
    }

    if (
      data.hasOwnProperty("scrubber") &&
      (data["scrubber"] === false || !data["scrubber"])
    ) {
      if (
        data.hasOwnProperty("portconsp.tableperday") &&
        data["portconsp.tableperday"].length > 0
      ) {
        data["portconsp.tableperday"].forEach((e) => {
          if (e.con_g === 2) {
            isFaluty = true;
          }
        });
      }
      if (isFaluty === true) {
        message = "You can't choose High Sulphur Content";
      }
    }
    if (data.hasOwnProperty("seaspdconsp.tableperday")) {
      // this is special check to verify if Ballast / Laden  leg is selected for any speed_type (ECO / CP / WARRANTED)
      let needle = "";
      let obj = data["seaspdconsp.tableperday"].map((row) => {
        if (!row.hasOwnProperty("ballast_laden")) {
          row["ballast_laden"] = 1;
        }
        if (!row.hasOwnProperty("speed_type")) {
          row["speed_type"] = 1;
        }
        return row;
      });
      let filterObj = {};
      let filterObjTemp = [];
      let column = ["ballast_laden", "speed_type"];
      //let strTemp = '';
      let ballastCount = 0,
        ladenCount = 0;
      let ecoCount = 0,
        CPCount = 0,
        WarrantCount = 0;
      // iterate over each element in the array
      for (let i = 0; i < obj.length; i++) {
        for (const [key, value] of Object.entries(obj[i])) {
          if (key === column[0]) {
            if (value.toString() === "1") {
              //strTemp += `${key}: ${value}` + ', ';
              ballastCount++;
            } else if (value.toString() === "2") {
              ladenCount++;
            }
          } else if (key === column[1]) {
            if (value.toString() === "1") {
              //strTemp += `${key}: ${value}` + ', ';
              ecoCount = ecoCount < 1 ? ecoCount + 1 : ecoCount;
            } else if (value.toString() === "2") {
              CPCount = CPCount < 1 ? CPCount + 1 : CPCount;
            } else if (value.toString() === "3") {
              WarrantCount = WarrantCount < 1 ? WarrantCount + 1 : WarrantCount;
            }
          }
        }
      }
      if (
        ballastCount !== ladenCount ||
        ballastCount + ladenCount !== (ecoCount + CPCount + WarrantCount) * 2
      ) {
        isFaluty = true;
        // openNotificationWithIcon(
        //   message,
        //   "info",
        //   "Passage Type mismatch ! ballast/ laden should be selected for each speed Type"
        // );
        message =
          "Please select the combination for each speed type, such as ECO (Ballast/Laden) and CP (Ballast/Laden).";
        // "Passage Type mismatch ! ballast/ laden should be selected for each speed Type";
      }
      return cb(isFaluty, message);
    } else {
      isFaluty = true;
      message = "Consumption data is not send, application issue";
      return cb(isFaluty, message);
    }
  };

  // const editMode = async (id) => {
  //   setState({ ...state, isEdit: true });
  //   const response = await getAPICall(`${URL_WITH_VERSION}/vessel/list/${id}`);

  //   const respData = await response["data"];
  //   console.log(response.data,"responceeditdata");
  //   respData["id"] = respData["vessel_id"];
  //   // setState({ ...state, formData: respData }, () =>
  //   //   setState({ ...state, isEdit: false })
  //   // );
  //   const updatedState = {
  //     ...state,
  //     formData: { ...state.formData, ...respData, id: respData.vessel_id },
  //     isEdit: false,
  //   };

  //   // Set the updated state
  //   setState(updatedState);
  // };

  const editMode = async (id) => {
    // Set isEdit to true initially
    console.log({ id });
    setState({ ...state, isEdit: true });
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/list/${id}`
      );
      const respData = response.data;
      // this code is not understand only comment when fix than remove
      // let marinVessel = props.marineData;
      // console.log("sddsdds",props)
      // if (!props.marineData) {
      //   let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${respData.vessel_name}`;
      //   let marineData = await getAPICall(url);
      //   marinVessel = marineData.filter(
      //     (item) =>
      //       item.SHIPNAME.toLowerCase() === respData.vessel_name.toLowerCase()
      //   );
      // }

      const updatedState = {
        ...state,
        formData: {
          ...state.formData,
          ...respData,
          id: respData.vessel_id,
        },
        isEdit: false,
        //marineData: marinVessel,
      };
      formref.current = _.cloneDeep(updatedState.formData);
      setState(updatedState);
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({ ...state, isEdit: false });
    }
  };

  const modifyKeys = (postData) => {
    for (let key in postData) {
      if (postData.hasOwnProperty(key)) {
        if (typeof postData[key] === "object" && postData[key] !== null) {
          modifyKeys(postData[key]);
        } else if (
          typeof postData[key] === "string" &&
          postData[key].trim() === ""
        ) {
          postData[key] = "";
        }
      }
    }
    return postData;
  };

  const validateFormData = (postData) => {
    if (postData.vessel_name && postData.vessel_name.startsWith(" ")) {
      return {
        isValid: false,
        message: "Vessel Name can't start with a space.",
      };
    }

    return { isValid: true };
  };

  const saveFormData = (postData, innerCB) => {
    // console.log("postData", postData);
    // if (!postData?.id) {
    //   postData["vesseldwtdrafts"][0] =
    //     postData["vesseldwtdrafts"][0]["vesseldwtdrafts"][0];
    // }

    if (postData.hasOwnProperty("imo_no") && postData["imo_no"].length !== 7) {
      openNotificationWithIcon(
        "info",
        "IMO number must be exactly 7 digits.",
        3
      );
      return;
    }

    if (postData.hasOwnProperty("vessel_img")) {
      postData["vessel_img"] = state.formData?.vessel_img;
    }
    const { frmName } = state;
    const validation = validateFormData(postData);
    if (!validation.isValid) {
      openNotificationWithIcon("info", validation.message, 3);
      return;
    }

    let _url = "save";
    let _method = "post";

    if (postData.hasOwnProperty("vessel_id")) {
      _url = "update";
      _method = "put";
      postData["id"] = postData["vessel_id"];
    }
    // sanitize(postData);
    // Object.keys(postData).forEach((key) => (postData[key] === null) && delete postData[key]);

    postData.hasOwnProperty("seaspdconsp.tableperday") &&
      postData["seaspdconsp.tableperday"].length > 0 &&
      postData["seaspdconsp.tableperday"].map((el) => {
        el["ifo"] = el["ifo"] ? el["ifo"] : "0.00";
        el["mgo"] = el["mgo"] ? el["mgo"] : "0.00";
        el["vlsfo"] = el["vlsfo"] ? el["vlsfo"] : "0.00";
        el["ulsfo"] = el["ulsfo"] ? el["ulsfo"] : "0.00";
        el["lsmgo"] = el["lsmgo"] ? el["lsmgo"] : "0.00";
        el["engine_load"] = el["engine_load"] ? el["engine_load"] : 0;
      });

    postData.hasOwnProperty("portconsp.tableperday") &&
      postData["portconsp.tableperday"].length > 0 &&
      postData["portconsp.tableperday"].map((el) => {
        el["con_type"] = el["con_type"] ? el["con_type"] : "";
        el["con_loading"] = el["con_loading"] ? el["con_loading"] : "0.00";
        el["con_unit"] = el["con_unit"] ? el["con_unit"] : "2";
        el["con_disch"] = el["con_disch"] ? el["con_disch"] : "0.00";
        el["con_ideal_on"] = el["con_ideal_on"] ? el["con_ideal_on"] : "0.00";
        el["con_capacity"] = el["con_capacity"] ? el["con_capacity"] : "0.00";
        el["con_margin"] = el["con_margin"] ? el["con_margin"] : "0.00";
        el["con_heat"] = el["con_heat"] ? el["con_heat"] : "0.00";
        el["con_heat_p"] = el["con_heat_p"] ? el["con_heat_p"] : "0.00";
        el["con_heat_pp"] = el["con_heat_pp"] ? el["con_heat_pp"] : "0.00";
        el["con_igs"] = el["con_igs"] ? el["con_igs"] : "0.00";
        el["con_clean"] = el["con_clean"] ? el["con_clean"] : "0.00";
        el["con_maneuv"] = el["con_maneuv"] ? el["con_maneuv"] : "0.00";
        el["con_av"] = el["con_av"] ? el["con_av"] : "0.00";
      });

    const mandatoryFieldObj = {
      vessel_name: "Vessel Name",
      vessel_code: "Vessel Code",
      vessel_dwt: "Vessel DWT",
      imo_no: "IMO No",
    };
    const requiredFields = Object.keys(mandatoryFieldObj);
    const missingFields = requiredFields.filter((field) => !postData[field]);
    console.log({ missingFields, postData });

    if (missingFields.length == requiredFields.length) {
      // when all mandatory fields are empty.
      openNotificationWithIcon("info", `Please fill all the mandatory fields.`);
      return;
    } else if (
      (missingFields.length !== requiredFields.length &&
        missingFields.length > 0) ||
      postData.imo_no == 0
    ) {
      // Show alert with missing field names
      // openNotificationWithIcon('info',`Please fill in the following fields: ${missingFields.join(', ')}`);
      openNotificationWithIcon(
        "info",
        <div>
          Please fill the mandatory field:{" "}
          <b>
            <i>{mandatoryFieldObj[missingFields[0]]}</i>
          </b>
        </div>
      );
      return;
    }

    validateEvent(postData, (isFail, message) => {
      if (isFail === true) {
        openNotificationWithIcon(
          "info",
          <div dangerouslySetInnerHTML={{ __html: message }} />,
          5
        );
      } else {
        postAPICall(
          `${URL_WITH_VERSION}/vessel/${_url}?frm=${frmName}`,
          // postData,
          modifyKeys(postData),
          _method,
          (data) => {
            if (data.data) {
              openNotificationWithIcon("success", data.message);
              if (_url === "save") {
                window.emitNotification({
                  n_type: "Vessel Added",
                  msg: window.notificationMessageCorrector(
                    `Vessel, (${postData.vessel_name}), is Added by ${window.userName}`
                  ),
                });
              } else {
                window.emitNotification({
                  n_type: "Vessel Updated",
                  msg: window.notificationMessageCorrector(
                    `Vessel, (${postData.vessel_name}), is Updated by ${window.userName}`
                  ),
                });
              }
              editMode(data?.row?.vessel_id || data?.row);

              if (
                props.modalCloseEvent &&
                typeof props.modalCloseEvent === "function"
              ) {
                //this.props.modalCloseEvent(data['row']);
              }
              if (
                data.hasOwnProperty("row") &&
                typeof data["row"]?.vessel_id === "number"
              ) {
                editMode(data["row"]?.vessel_id);
              }
            } else {
              let dataMessage = data.message;
              let msg = "<div className='row'>";

              if (typeof dataMessage !== "string") {
                Object.keys(dataMessage).map(
                  (i) =>
                    (msg +=
                      "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
                );
              } else {
                msg += dataMessage;
              }

              msg += "</div>";
              openNotificationWithIcon(
                "info",
                <div dangerouslySetInnerHTML={{ __html: msg }} />
              );
            }
          }
        );
      }
    });
  };

  const onSaveFuelConsumption = (data) => {
    const { speedConsumptionForm } = state;
    setState({ ...state, spdconsumptionData: data });
    postAPICall(
      `${URL_WITH_VERSION}/vessel/vsfcd?frm=${speedConsumptionForm}`,
      data,
      "post",
      (resp) => {
        if (resp.data) {
          openNotificationWithIcon("success", resp.message);
          onCancel();
          // this.loadSubTableData(data);
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      }
    );
  };

  const _onCreateFormData = () => {
    setState({ ...state, frmVisible: false }, () => {
      setState({
        ...state,
        formData: {
          "portconsp.tableperday": [
            {
              editable: true,
              index: 1,
              con_type: 3,
              con_g: 2,
              id: -9e6 + 1,
              con_unit: 2,
            },
            {
              editable: true,
              index: 2,
              con_type: 10,
              con_g: 4,
              id: -9e6 + 2,
              con_unit: 2,
            },
            {
              editable: true,
              index: 3,
              con_type: 5,
              con_g: 3,
              id: -9e6 + 3,
              con_unit: 2,
            },
            {
              editable: true,
              index: 4,
              con_type: 7,
              con_g: 4,
              id: -9e6 + 4,
              con_unit: 2,
            },
            {
              editable: true,
              index: 5,
              con_type: 4,
              con_g: 3,
              id: -9e6 + 5,
              con_unit: 2,
            },
          ],

          "seaspdconsp.tableperday": [
            {
              editable: true,
              index: 1,
              speed_type: 1,
              ballast_laden: 1,
              id: -9e6 + 10,
              engine_load: 85,
            },
            {
              editable: true,
              index: 2,
              speed_type: 1,
              ballast_laden: 2,
              id: -9e6 + 11,
              engine_load: 85,
            },
          ],
        },
        frmVisible: true,
        selectedID: null,
      });
    });
  };

  const _onLeftSideListClick = async (vesselId) => {
    setState((prevState) => ({
      ...prevState,
      frmVisible: false,
      formData: {},
    }));
    const response = await getAPICall(
      `${URL_WITH_VERSION}/vessel/list/${vesselId}`
    );

    const respData = await response["data"];

    let marinVessel = props.marineData;

    let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${respData.vessel_name}`;
    let marineData = await getAPICall(url);
    marinVessel = marineData?.filter(
      (item) =>
        item.SHIPNAME.toLowerCase() === respData.vessel_name.toLowerCase()
    );
    // if (!marinVessel.length) {
    //   openNotificationWithIcon("info", "Vessel data not found");
    // }

    setState((prevState) => ({
      ...prevState,
      formData: respData,
      showSideListBar: false,
      selectedID: vesselId,
      frmVisible: true,
      marineData: marinVessel,
    }));
  };

  const onClickExtraIcon = async (action, data) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey === "Vessel Dwt Drafts") {
      groupKey = "vesseldwtdrafts";
      frm_code = "tab_vessel_dwt_drafts";
    }

    if (groupKey === "Port Consp. Table (Per Day)") {
      groupKey = "portconsp.tablepd";
      frm_code = "tab_vessel_consumption";
    }

    if (groupKey === "Sea Spd Consp. Table (Per Day)") {
      groupKey = "seaconsp.tablepd";
      frm_code = "tab_vessel_consumption";
    }

    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
        key: data.key,
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  const _onDeleteFormData = (data) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => onVesselDeletedClick(data),
    });
  };

  const onVesselDeletedClick = (data) => {
    if (data.id) {
      let _url = `${URL_WITH_VERSION}/vessel/delete`;
      apiDeleteCall(_url, { id: data.id }, (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          _onCreateFormData();
          // if (this.props.modalCloseEvent) {
          //   this.props.modalCloseEvent();
          // }
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const setVesselData = async (value) => {
    let data = { ...value };
    const getVesselCode = await getAPICall(
      `${process.env.REACT_APP_URL}v1/vessel/generate/code?vessel_name=${data?.vessel_name}`
    );

    if (getVesselCode?.data) {
      data.vessel_code = getVesselCode?.data?.vessel_code;
    } else {
      openNotificationWithIcon(
        "info",
        getVesselCode?.message || "Failed to generate vessel code"
      );
    }
    console.log({ data });
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        vessel_name: data?.vessel_name || "",
        vessel_type: data?.vessel_type || "",
        type_code: data?.type_code || "",
        year_built: data?.year_built || "",
        vessel_code: data?.vessel_code || "",
        imo_no: data?.imo_no || "",
        vessel_dwt: data?.dwt || "0",
      },
    }));
    setIsLoading(false);
  };

  const {
    isShowVesselFormReport,
    reportFormData,
    agencyvslEdit,
    showSideListBar,
    loadComponent,
    title,
    frmVisible,
    visibleDrawer,
    frmName,
    formData,
    isVisible,
    speedConsumptionForm,
    vesselConsumptionData,
    isEdit,
    isShowAddButton,
    selectedID,
  } = state;
  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                {frmName && frmVisible && isEdit === false ? (
                  <article className="article">
                    <div className="box box-default">
                      <div className="box-body">
                        {isLoading ? (
                          <MarineSpin />
                        ) : (
                          <NormalFormIndex
                            key={"key_" + frmName + "_0"}
                            formClass="label-min-height"
                            formData={formData}
                            showForm={true}
                            frmCode={frmName}
                            addForm={true}
                            showToolbar={[
                              {
                                isLeftBtn: [
                                  {
                                    key: "s2",
                                    isSets: [
                                      agencyvslEdit === true
                                        ? undefined
                                        : {
                                            id: "7",
                                            key: "menu-fold",
                                            type: <MenuFoldOutlined />,
                                            withText: "List",
                                            showToolTip: true,
                                            event: "menu-fold",
                                          },
                                      //(isShowAddButton === true ? { id: "99999", key: "isadd", type: "add", withText: "Add New", "event": (key, r, innerCB) => { innerCB() } } : undefined),
                                      agencyvslEdit === true
                                        ? undefined
                                        : {
                                            id: "1",
                                            key: "add",
                                            type: <PlusOutlined />,
                                            withText: "Add New",
                                            showToolTip: true,
                                            event: (key, data) =>
                                              _onCreateFormData(),
                                          },
                                      {
                                        id: "3",
                                        key: "save",
                                        type: <SaveOutlined />,
                                        withText: "Save",
                                        showToolTip: true,
                                        event: (key, data) => {
                                          saveFormData(data);
                                        },
                                      },

                                      formData &&
                                        formData["id"] &&
                                        formData["id"] > 0 && {
                                          id: "6",
                                          key: "delete",
                                          type: <DeleteOutlined />,
                                          withText: "Delete",
                                          showToolTip: true,
                                          event: (key, data) =>
                                            _onDeleteFormData(data),
                                        },

                                      {
                                        id: "20",
                                        key: "refresh",
                                        type: <SyncOutlined />,
                                        withText: "Refresh",
                                        showToolTip: true,
                                        event: () => {
                                          reFreshForm();
                                        },
                                      },
                                    ],
                                  },
                                ],

                                isRightBtn: [
                                  {
                                    key: "rbs1",
                                    isSets: [
                                      {
                                        key: "vessel_search",
                                        isDropdown: 0,
                                        type: (
                                          <SearchVesselInput
                                            state={state}
                                            setState={setState}
                                            setIsLoading={setIsLoading}
                                            setVesselData={setVesselData}
                                          />
                                        ),
                                        withText:
                                          "Search vessel from Marine Traffic ",
                                        showToolTip: true,
                                        menus: null,

                                        // event: () => {
                                        //   if(state.formData.id){
                                        //     setState((prev) => ({
                                        //       ...prev,
                                        //       showVesselUploadImage: true,
                                        //     }));
                                        //   }else{
                                        //     openNotificationWithIcon("info","Please save a vessel first")
                                        //   }

                                        // },
                                      },
                                      {
                                        key: "image",
                                        isDropdown: 0,
                                        type: "Vessel Image",
                                        withText: "Upload vessel image",
                                        showToolTip: true,
                                        menus: null,
                                        event: () => {
                                          if (state.formData.id) {
                                            setState((prev) => ({
                                              ...prev,
                                              showVesselUploadImage: true,
                                            }));
                                          } else {
                                            openNotificationWithIcon(
                                              "info",
                                              "Please save a vessel first"
                                            );
                                          }
                                        },
                                      },
                                      {
                                        key: "reports",
                                        isDropdown: 1,
                                        type: "Reports",
                                        withText:
                                          "To view and Generate Reports",

                                        showToolTip: true,
                                        menus: [
                                          {
                                            href: null,
                                            icon: null,
                                            label: "Vessel Details Report",
                                            modalKey: null,
                                            event: () => {
                                              openVesselFormReport(true);
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],

                                isResetOption: false,
                              },
                            ]}
                            inlineLayout={true}
                            isShowFixedColumn={[
                              "Port Consp. Table (Per Day)",
                              "Sea Spd Consp. Table (Per Day)",
                            ]}
                            sideList={{
                              selectedID: selectedID,
                              showList: true,
                              title: "Vessel List",
                              uri: "/vessel/list?l=0",
                              columns: [
                                "vessel_name",
                                "vessel_dwt",
                                "vessel_type_name",
                              ],
                              icon: true,
                              searchString:
                                '"Vessel Name","Vessel DWT","Vessel Type"',
                              rowClickEvent: (evt) =>
                                _onLeftSideListClick(evt.vessel_id),
                            }}
                            tableRowDeleteAction={(action, data) =>
                              onClickExtraIcon(action, data)
                            }
                            showSideListBar={
                              agencyvslEdit === true ? false : showSideListBar
                            }
                          />
                        )}
                      </div>
                    </div>
                  </article>
                ) : undefined}
                {isVisible === true && vesselConsumptionData ? (
                  <Modal
                    title={"Fuel Type Vs Consumption"}
                    open={isVisible}
                    width="70%"
                    onCancel={onCancel}
                    style={{ top: "10px" }}
                    bodyStyle={{
                      height: 600,
                      overflowY: "auto",
                      padding: "0.5rem",
                    }}
                    footer={null}
                  >
                    <div className="body-wrapper">
                      <article className="article">
                        <div
                          className="box box-default"
                          style={{ padding: "15px" }}
                        >
                          {
                            <NormalFormIndex
                              key={"key_" + speedConsumptionForm + "_0"}
                              formClass="label-min-height"
                              formData={Object.assign(
                                { id: 0 },
                                vesselConsumptionData
                              )}
                              showForm={true}
                              frmCode={speedConsumptionForm}
                              addForm={true}
                              showButtons={[
                                {
                                  id: "cancel",
                                  title: "Reset",
                                  type: "danger",
                                },
                                {
                                  id: "save",
                                  title: "Save",
                                  type: "primary",
                                  event: (data) => {
                                    onSaveFuelConsumption(data);
                                  },
                                },
                              ]}
                              inlineLayout={true}
                            />
                          }
                        </div>
                      </article>
                    </div>
                  </Modal>
                ) : undefined}
              </div>
            </div>
          </Content>
        </Layout>
        {
          <RightBarUI
            pageTitle="vessel-righttoolbar"
            callback={(data, options) => onClickRightMenu(data, options)}
          />
        }
        {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          <Drawer
            title={
              state.title === "Attachment" ? "Document Upload" : state.title
            }
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined}
      </Layout>

      {isShowVesselFormReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Report"
          open={isShowVesselFormReport}
          onCancel={() => openVesselFormReport(false)}
          width="95%"
          footer={null}
        >
          <VesselFormReport data={reportFormData} />
        </Modal>
      ) : undefined}

      {state.VoyageManagerInfoModal && state.vmInfoData.length ? (
        <Modal
          style={{ top: "2%" }}
          title="Voyage Manager Info"
          open={state.VoyageManagerInfoModal}
          onCancel={() =>
            setState((pre) => ({ ...pre, VoyageManagerInfoModal: false }))
          }
          width="95%"
          bodyStyle={{ overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <VoyageManagerInfo
            vesselId={formData["vessel_id"]}
            vmInfoData={state.vmInfoData}
          />
        </Modal>
      ) : undefined}

      {state.showVesselUploadImage ? (
        <Modal
          onCancel={() =>
            setState((prev) => ({ ...prev, showVesselUploadImage: false }))
          }
          title="Upload Vessel Image"
          footer={null}
          open={state.showVesselUploadImage}
        >
          <VesselUploadImage
            getVesselImage={getVesselImage}
            formData={state.formData}
          />
          <p style={{
            color:"red"
          }}>
            (* Only images smaller than 5 MB can be uploaded.)
          </p>
        </Modal>
      ) : null}
    </div>
  );
};

export default VesselSchedule;
