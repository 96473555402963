import React from "react";
import NormalFormIndex from "../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  openNotificationWithIcon,
} from "../../../shared";
import Tde from "../../../routes/tde/Tde";
import { Modal } from "antd";
import CreateInvoice from "../../../routes/create-invoice/CreateInvoice";
import * as moment from "moment";
import InvoicePopup from "../../../routes/create-invoice/InvoicePopup";
import { DeleteOutlined, SaveOutlined, EditOutlined } from "@ant-design/icons";
import Attachment from "../../../shared/components/Attachment";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../shared/attachments";
//import Remarks from "../../../shared/remarks";
class CommissionPayment extends React.Component {
  initialCommissionData = {};
  constructor(props) {
    super(props);
    this.initialCommissionData = this.props.commissionData || {};
    this.state = {
      responseData: { frm: [], tabs: [], active_tab: {} },
      frmKey: "frm_key_" + this.props.frmCode,
      frmName: "tco_commission_entry",
      formData: this.props.formData || {},
      commissionData: this.props.commissionData || {},
      frmOptions: this.props.frmOptions || [],
      showTDE: false,
      isEdit: this.props.isEdit || false,
      frmVisible: false,
      TdeList: null,
      oldFromData: this.props.oldFromData || {},
      showInvoice: false,
      invoiceReport: null,
      p_dt_from: moment(),
      p_dt_to: moment(),
      showInvoicePopup: false,
      popupdata: null,
      // isRemarkModel: false,
    };
  }

  componentDidMount = () => {
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          frmVisible: true,
        }),
      1000
    );
  };

  saveFormData = (data) => {
    this.setState({ ...this.state, frmVisible: false });
    const { frmName } = this.state;
    let suURL = `${URL_WITH_VERSION}/commission/tco-save?frm=${frmName}`;
    let suMethod = "POST";
    let arr = [];
    delete data["."]["final_amt_loc"];
    if (data["-"] && data["-"].length > 0) {
      data["-"].forEach((e) => {
        delete e["final_amt_loc"];
        delete e["id"];
      });

      data["-"].map((el) => {
        let obj = {};
        obj = {
          ...el,
          p_dt_from: el.p_dt_from ? moment(el.p_dt_from) : "",
          p_dt_to: el.p_dt_to ? moment(el.p_dt_to) : "",
        };
        arr.push(obj);
      });
    }

    data["-"] = arr;

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        if (data.row && data.row.invoice_no) {
          this.setCommissionData(data.row.invoice_no);
        }
        // this.setState({ ...this.state, frmVisible:true });
      } else {
        this.setState({ formData: data, frmVisible: true });
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  setCommissionData = async (invoice_no) => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/commission/tco-edit?e=${invoice_no}`
      );
      const respData = await response["data"];
      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/tco-borker/commission?t=tco&e=${respData.tco_id}`
      );
      const respSData = await response1["data"];
      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        { key: "remmitance_bank", data: respSData["banks"] },
      ];
      Object.assign(respData, { disablefield: ["broker"] });

      respData["mycompny_id"] = respSData["mycompny_id"];
      //respData['.']['total']= commissionData['inv_total']
      this.setState({
        commissionData: respData,
        isEdit: true,
        frmOptions: frmOptions,
        loading: true,
        frmVisible: true,
      });
    } catch (err) {
      this.setState({ ...this.state, frmVisible: true });
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  updateFormData = (data) => {
    this.setState({ ...this.state, frmVisible: false });
    const invoice_no = data.invoice_no;
    // if(data.disablefield){
    //   delete data.disablefield
    // }
    delete data["."]["final_amt_loc"];
    if (data["-"] && data["-"].length > 0) {
      data["-"].forEach((e) => {
        delete e["final_amt_loc"];
      });
    }
    const { frmName, commissionData } = this.state;
    let suURL = `${URL_WITH_VERSION}/commission/tco-update?frm=${frmName}`;
    let suMethod = "PUT";

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);

        this.setCommissionData(commissionData["invoice_no"]);
        // this.setState({ ...this.state, frmVisible: true });
        if (this.props.hasOwnProperty("closeModal")) {
          //this.props.closeModal();
        }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  showTDEForm = async (boolVal) => {
    const { commissionData, oldFromData } = this.state;

    if (commissionData["inv_status"] == 241) {
      openNotificationWithIcon("info", "Please Change the Invoice Status.", 3);
      return;
    }

    let tde_id = 0;
    let new_array = [];
    const response = await getAPICall(`${URL_WITH_VERSION}/tde/list`);
    const tdeList = response["data"];
    let TdeList =
      tdeList && tdeList.length > 0
        ? tdeList.filter((el) => commissionData["invoice_no"] === el.invoice_no)
        : [];
    if (TdeList && TdeList.length > 0) {
      tde_id = TdeList[0]["id"];
    }
    if (tde_id !== 0) {
      const editData = await getAPICall(
        `${URL_WITH_VERSION}/tde/edit?e=${tde_id}`
      );
      const tdeEditData = await editData["data"];
      const responseData = await getAPICall(
        `${URL_WITH_VERSION}/address/edit?ae=${commissionData["broker"]}`
      );
      const responseAddressData = responseData["data"];
      let account_no =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["account_no"]
          : "";
      let swift_code =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["swift_code"]
          : "";
      if (commissionData["-"] && commissionData["-"].length > 0) {
        commissionData["-"].map((e, index) => {
          let accounting_data = {
            vessel_name: tdeEditData["vessel"],
            voyage: oldFromData["voyage_number"],
            ap_ar_acct: tdeEditData["ar_pr_account_no"],
            vessel_code: oldFromData["vessel_code"],
            company: tdeEditData["bill_via"],
            invoice_type: commissionData["inv_type"],
            amount: e.tc_amount,
            account: swift_code,
            description: e.description,
            lob: oldFromData.company_lob,
            voy_no: oldFromData["voyage_number"],
            id: -9e6 + index,
          };
          new_array.push(accounting_data);
          return true;
        });
        tdeEditData["accounting"] = new_array;
        tdeEditData["voyage_manager_id"] = oldFromData["voyage_number"];
      }
      this.setState({ ...this.state, TdeList: tdeEditData, showTDE: boolVal });
    } else {
      const responseData = await getAPICall(
        `${URL_WITH_VERSION}/address/edit?ae=${commissionData["broker"]}`
      );
      const responseAddressData = responseData["data"];

      let account_no =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["account_no"]
          : "";
      let swift_code =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["swift_code"]
          : "";

      let frmData = {};

      frmData = {
        bill_via: oldFromData.my_company_lob,
        invoice: commissionData["acc_type"],
        invoice_no: commissionData["invoice_no"],
        invoice_date: commissionData["invoice_date"],
        invoice_type: commissionData["inv_type"],
        received_date: commissionData["recieved_date"],
        vessel: commissionData["vessel_id"],
        vendor: commissionData["broker"],
        voyage_manager_id: oldFromData["voyage_number"],
        inv_status: commissionData["inv_status"],
        invoice_amount: commissionData["inv_total"],
        account_base: commissionData["inv_total"],
        payment_term: commissionData["payment_terms"],
        ar_pr_account_no: account_no,
        voyage: oldFromData["voyage_number"],
        po_number: commissionData["po_number"],
        accounting: [],
      };

      if (commissionData["-"] && commissionData["-"].length > 0) {
        commissionData["-"].map((e, index) => {
          let accounting_data = {
            vessel_name: commissionData["vessel_id"],
            voyage: oldFromData["voyage_number"],
            ap_ar_acct: account_no,
            vessel_code: oldFromData["vessel_code"],
            company: oldFromData.my_company_lob,
            account: swift_code,
            amount: e.commission,
            description: e.description,
            lob: oldFromData.company_lob,
            port: oldFromData["b_port_name"],
            id: -9e6 + index,
          };

          new_array.push(accounting_data);
        });
        frmData["accounting"] = new_array;

        //return true;
      }

      this.setState({ ...this.state, TdeList: frmData, showTDE: boolVal });
    }
  };

  invoiceModal = async (data = {}) => {
    let { formData, invoiceReport, commissionData } = this.state;
    try {
      if (Object.keys(data).length == 0) {
        const response = await getAPICall(
          `${URL_WITH_VERSION}/commission/tco-report?e=${commissionData.invoice_no}&my_id=${commissionData.mycompny_id}`
        );
        const respData = await response["data"];
        if (respData) {
          this.setState(
            {
              ...this.state,
              invoiceReport: respData,
              popupdata: respData,
            },
            () => this.setState({ ...this.state, showInvoicePopup: true })
          );
          // this.showHideModal(true, "InvoicePopup");
        } else {
          openNotificationWithIcon("error", "Sorry, Unable to Show Invoice", 3);
        }
      } else {
        this.setState({
          ...this.state,
          invoiceReport: { ...invoiceReport, ...data },
          //  showInvoice: true
        });
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  handleok = () => {
    const { invoiceReport } = this.state;
    if (invoiceReport["isSaved"]) {
      //  this.showHideModal(false, "InvoicePopup");
      this.setState({ ...this.state, showInvoicePopup: false });
      setTimeout(() => {
        // this.showHideModal(true, "InvoiceModal");
        this.setState({ ...this.state, showInvoice: true });
      }, 2000);
      this.setState({ ...this.state, invoiceReport: invoiceReport });
    } else {
      openNotificationWithIcon(
        "info",
        "Please click on Save to generate invoice.",
        3
      );
    }
  };

  onClickExtraIcon = async (action, data) => {
    let groupKey = action["gKey"];
    let frm_code = "tco_commission_entry";

    let delete_id = data && data.id;
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey.replace(/\s/g, "").toLowerCase(),
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  _onDeleteFormData = (data, innerCB) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => this.deleteFormData(data, innerCB),
    });
  };

  deleteFormData = async (data, innerCB) => {
    let delete_data = {
      id: innerCB.id,
    };
    postAPICall(
      `${URL_WITH_VERSION}/commission/tco-delete`,
      delete_data,
      "delete",
      (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          this.setState(
            (prevState) => ({
              ...prevState,
              formData: {},
              commissionData: this.initialCommissionData,
              isEdit: false,
              frmVisible: false,
            }),
            () =>
              this.setState((prevState) => ({ ...prevState, frmVisible: true }))
          );
          this.props.onCancelModal(false);
        } else {
          openNotificationWithIcon("error", response.message);
        }
      }
    );
  };

  render() {
    const {
      frmName,
      commissionData,
      frmOptions,
      showTDE,
      isEdit,
      frmVisible,
      TdeList,
      popupdata,
      showInvoice,
      invoiceReport,
      showInvoicePopup,
    } = this.state;
    //commissionData["."] = { total: commissionData["inv_total"] };

    const ShowAttachment = async (isShowAttachment) => {
      let loadComponent = undefined;
      //const { id } = this.state.formData;
      const id = this.state.commissionData?.invoice_no;
      if (id && isShowAttachment) {
        const attachments = await getAttachments(id, "INV");
        const callback = (fileArr) =>
          uploadAttachment(fileArr, id, "INV", "port-expense");
        loadComponent = (
          <Attachment
            uploadType="Estimates"
            attachments={attachments}
            onCloseUploadFileArray={callback}
            deleteAttachment={(file) =>
              deleteAttachment(file.url, file.name, "INV", "port-expense")
            }
            tableId={0}
          />
        );
        this.setState((prevState) => ({
          ...prevState,
          isShowAttachment: isShowAttachment,
          loadComponent: loadComponent,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isShowAttachment: isShowAttachment,
          loadComponent: undefined,
        }));
      }
    };

    // const handleRemark = () => {
    //   setState(prevState => ({
    //     ...prevState,
    //     isRemarkModel: true,
    //   }));
    // }

    return (
      <div className="body-wrapper">
        {frmName ? (
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                {frmVisible ? (
                  <NormalFormIndex
                    key={"key_" + frmName + "_0"}
                    formClass="label-min-height"
                    frmCode={frmName}
                    formData={commissionData}
                    tableFormAddNewRow={true}
                    showForm={true}
                    addForm={true}
                    // showButtons={[
                    //   { id: 'cancel', title: 'Reset', type: 'danger' },
                    //   {
                    //     'id': isEdit === true ? 'update' : 'save',
                    //     'title': isEdit === true ? 'Update' : 'Save',
                    //     'type': 'primary',
                    //     'event': (data, innerCB) => { isEdit === true ? this.updateFormData(data, innerCB) : this.saveFormData(data, innerCB) },
                    //   },
                    // ]}
                    showToolbar={[
                      {
                        isLeftBtn: [
                          {
                            key: "a2",
                            isSets: [
                              {
                                id: "2",
                                key: "save",
                                type: <SaveOutlined />,
                                withText: "",
                                event: (key, data) =>
                                  isEdit
                                    ? this.updateFormData(data)
                                    : this.saveFormData(data),
                              },
                              isEdit && {
                                id: "2",
                                key: "delete",
                                type: <DeleteOutlined />,
                                withText: "",
                                event: (data, innerCB) =>
                                  this._onDeleteFormData(data, innerCB),
                              },
                              // {
                              //   id: "3",
                              //   key: "edit",
                              //   type: <EditOutlined />,
                              //   withText: "Remark",
                              //   showToolTip: true,
                              //   event: (key, data) => this.handleRemark(),
                              // }
                            ],
                          },
                        ],
                        isRightBtn: [
                          {
                            key: "a2",
                            isSets: [
                              isEdit && {
                                key: "invoice",
                                isDropdown: 0,
                                withText: "Create Invoice",
                                type: "",
                                menus: null,
                                event: () => {
                                  this.invoiceModal({}, true);
                                },
                              },
                              isEdit && {
                                key: "tde",
                                isDropdown: 0,
                                withText: "TDE",
                                type: "",
                                menus: null,
                                event: () => this.showTDEForm(true),
                              },
                              {
                                id: "3",
                                key: "attachment",
                                isDropdown: 0,
                                withText: "Attachment",
                                type: "",
                                menus: null,
                                event: (key, data) => {
                                  data &&
                                  data.hasOwnProperty("id") &&
                                  data["id"] > 0
                                    ? ShowAttachment(true)
                                    : openNotificationWithIcon(
                                        "info",
                                        "Please save the Invoice First.",
                                        3
                                      );
                                },
                              },
                            ],
                          },
                        ],
                        isResetOption: false,
                      },
                    ]}
                    inlineLayout={true}
                    isShowFixedColumn={["-"]}
                    frmOptions={frmOptions}
                    tableRowDeleteAction={(action, data) =>
                      this.onClickExtraIcon(action, data)
                    }
                    summary={[{ gKey: "-", showTotalFor: ["commission"] }]}
                  />
                ) : undefined}
              </div>
            </div>
          </article>
        ) : undefined}
        {this.state.isShowAttachment ? (
          <Modal
            style={{ top: "2%" }}
            title="Upload Attachment"
            open={this.state.isShowAttachment}
            onCancel={() => ShowAttachment(false)}
            width="50%"
            footer={null}
          >
            {this.state.loadComponent}
          </Modal>
        ) : undefined}

        {showTDE && (
          <Modal
            title="TDE"
            open={showTDE}
            width="80%"
            onCancel={() => this.showTDEForm(false)}
            style={{ top: "10px" }}
            bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
            footer={null}
          >
            <Tde
              invoiceType="tc-commission"
              isEdit={
                TdeList != null && TdeList.id && TdeList.id > 0 ? true : false
              }
              formData={TdeList}
              modalCloseEvent={() => this.showTDEForm(false)}
              saveUpdateClose={() => this.setState({ showTDE: false })}
            />
          </Modal>
        )}

        {showInvoice && (
          <Modal
            className="page-container"
            style={{ top: "2%" }}
            title="Download Invoice"
            open={showInvoice}
            onCancel={() => this.setState({ showInvoice: false })}
            width="95%"
            footer={null}
          >
            <CreateInvoice
              type="commissionInvoice"
              commissionInvoice={invoiceReport}
            />
          </Modal>
        )}

        {showInvoicePopup ? (
          <Modal
            style={{ top: "2%" }}
            title="Invoice"
            open={showInvoicePopup}
            onCancel={() => this.setState({ showInvoicePopup: false })}
            width="95%"
            okText="Create Pdf"
            onOk={this.handleok}
          >
            <InvoicePopup
              data={popupdata}
              updatepopup={(data) => this.invoiceModal(data)}
            />
          </Modal>
        ) : undefined}
      </div>
    );
  }
}

export default CommissionPayment;
